/**
 * 基本的にDTOクラスは、APIか共通リポジトリにあるので注意。
 */

export class DtoItemsGetReq {
  //ページャー用パラメータ 通常はExtendsされる
  /**
   * 取得数。ページ内の件数。（リクエストの値をそのまま返す）
   */
  public count: number | null = null;
  /**
   * 相対位置。何ページ目を取得するか。1から始まる。（リクエストの値をそのまま返す）
   */
  public cursor: number | null = null;
  /**
   * 全件数。対象となるアイテム数
   * レスポンス時のみに使用。
   */
  public maxcount: number | null = null;
  /**
   * 絶対位置。基本的には、対象となるテーブルのIdを指定する。
   * ※リクエスト時に指定された場合はそのまま返す。
   * ※複数のテーブルを使用する場合はカンマで区切って返す。
   * */
  public maxid: number | null = null;

  /**
   * キーワード。タイトルと作者に一致。
   */
  public keyword: string | null = null;
  /**
   * ソート方法。新着(new) / ランキング(rank) / 人気(pop)
   */
  public sort: string | null = null;
  /**
   * 絞り込み方法。絞り込み：予約受付中(reserve) / 発売中(onsale)
   *
   */
  public search: string | null = null;
  /**
   * 50音検索モード用 指定されると通常検索ではなくなる。
   * シリーズ名用
   */
  public m50_series: string | null = null;
  /**
   * 50音検索モード用 指定されると通常検索ではなくなる。
   * 著者名用
   */
  public m50_author: string | null = null;
  /**
   * マイリスト一覧用 シリーズコード指定用
   */
  public seriescodes: string | null = null;

  // public IsValid(chkPager: boolean): string[] {
  //   // let res: string[] = new Array();
  //   // //ページャー用入力値チェック

  //   // //相関チェック

  //   // return res;
  // }
}
