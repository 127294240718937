import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntitySearchSupplys } from "../entity/EntitySearchSupplys";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoSearchSupplysGetReq extends EntitySearchSupplys {
  //パラメーター
  //検索条件
  public projectNo_start: number | null = null;
  public projectNo_end: number | null = null;
  public projectName: string | null = null;
  public proClientid: number | null = null;
  public projectSponsorName: string | null = null;
  public staffId: string | null = null;
  public claimNo_start: number | null = null;
  public claimNo_end: number | null = null;
  public supplyMonth_start: Date | null = null;
  public supplyMonth_end: Date | null = null;
  public supplyItem: number | null = null;
  public supplySubject: number | null = null;
  public supplyBreakdown: number | null = null;
  public supplierid: number | null = null;
  public projectType: number | null = null;
  public supplyRemarks: string | null = null;
  public supplyDateExpPayment_start: Date | null = null;
  public supplyDateExpPayment_end: Date | null = null;

  public VariationType: number | null = null;
  public ItemType: number | null = null;
  public chkClaim: number | null = null;
  public subject: number | null = null;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(this.IsValid());
    }

    return res;
  }

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (
      !commonRegx.isMatch(
        this.c_supply_id!.toString(),
        "^\\d+$",
        commonRegx._stdOption
      )
    ) {
      res.push("type error");
    }

    return res;
  }
}
