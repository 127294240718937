
import { Options, Vue } from "vue-class-component";
import { Cl_staffImple } from "../../functions/Cl_staffImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    Mo_modal_alert,
  },
})
export default class Tm_header extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  //----------------------
  //基本操作用メソッド
  //----------------------
  async logout() {
    await Cl_staffImple.signOut(this.$store);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal4logout(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_logout;
    modal.showModal();
  }
  async closeModal4logout(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_logout;
    modal.closeModal();
  }
}
