
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EntitySalesImple } from "mediadept-common/src/common/entity/EntitySalesImple";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import "@vuepic/vue-datepicker/dist/main.css";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";

@Options({
  components: {
    vSelect,
    Mt_dtp,
    Mt_dtp4month,
    Mo_modal_l,
  },
})
export default class Or_selectClient extends Vue {
  @Prop({ required: true })
  evDatas: {
    selectedItem: EntityClient;
    isSelected: boolean;
  };

  // ----------

  delegate4list = new DtoMastersGetRes();

  clientData_all: EntityClient[] = [];
  clientData_a: EntityClient[] = [];
  clientData_ka: EntityClient[] = [];
  clientData_sa: EntityClient[] = [];
  clientData_ta: EntityClient[] = [];
  clientData_na: EntityClient[] = [];
  clientData_ha: EntityClient[] = [];
  clientData_ma: EntityClient[] = [];
  clientData_ya: EntityClient[] = [];
  clientData_ra: EntityClient[] = [];
  clientData_wa: EntityClient[] = [];

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  domSearchTxt = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.getTabData();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      Cl_common.logger(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  async getTabData() {
    const items = this.delegate4list.items4Client || [];

    this.clientData_all = this.filterByKanaRange(items, null);

    this.clientData_a = this.filterByKanaRange(items, /^[あいうえお]/);
    this.clientData_ka = this.filterByKanaRange(
      items,
      /^[かきくけこがぎぐげご]/
    );
    this.clientData_sa = this.filterByKanaRange(
      items,
      /^[さしすせそざじずぜぞ]/
    );
    this.clientData_ta = this.filterByKanaRange(
      items,
      /^[たちつてとだぢづでど]/
    );
    this.clientData_na = this.filterByKanaRange(items, /^[なにぬねの]/);
    this.clientData_ha = this.filterByKanaRange(
      items,
      /^[はひふへほばびぶべぼぱぴぷぺぽ]/
    );
    this.clientData_ma = this.filterByKanaRange(items, /^[まみむめも]/);
    this.clientData_ya = this.filterByKanaRange(items, /^[やゆよ]/);
    this.clientData_ra = this.filterByKanaRange(items, /^[らりるれろ]/);
    this.clientData_wa = this.filterByKanaRange(items, /^[わをん]/);

    // 他の行も同様に追加
    // 他の処理があればここに追加
  }

  filterByKanaRange(
    items: EntityClient[],
    regex: RegExp | null
  ): EntityClient[] {
    let itemsTmp: EntityClient[] = JSON.parse(JSON.stringify(items));
    if (regex != null) {
      //50音フィルタリング
      itemsTmp = itemsTmp.filter((item) =>
        regex.test(item.c_client_name_pt!)
      ) as EntityClient[];
    }

    //指定文字フィルタリング
    itemsTmp = itemsTmp.filter((itemsTmp) =>
      itemsTmp.c_client_name!.includes(this.domSearchTxt)
    );

    //ソート
    return itemsTmp.sort((a, b) => {
      // 両方のフィールドがnullまたはundefinedの場合、0を返して等しいと見なす
      if (!a.c_client_name_pt && !b.c_client_name_pt) return 0;
      // aのフィールドがnullまたはundefinedの場合、bが先に来るようにする
      if (!a.c_client_name_pt) return 1;
      // bのフィールドがnullまたはundefinedの場合、aが先に来るようにする
      if (!b.c_client_name_pt) return -1;
      // 両方のフィールドが存在する場合、localeCompareを使用してソート
      return a.c_client_name_pt.localeCompare(b.c_client_name_pt, "ja");
    });
  }

  setItem(item: EntityClient) {
    this.evDatas.selectedItem = item;
    this.evDatas.isSelected = true;
    this.closeModalclaim();
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    modalComp.showModal();
  }

  async closeModalclaim(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    modalComp.closeModal();
  }
}
