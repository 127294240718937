
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";

//テーブル関係インポート
// import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";

//DTOインポート
import { DtoItemsGetReq } from "@/dto/DtoItemsGetReq";
import { DtoItemsGetRes, item } from "@/dto/DtoItemsGetRes";
import Cl_items from "@/functions/Cl_items";

@Options({
  components: {
    VueGoodTable,
  },
})
export default class Teble4goodtable extends Vue {
  //表示に必要なパラメータを宣言
  // ----------------------------------------
  /**
   * カラム宣言
   */
  columns = [
    {
      label: "詳細",
      field: "c_series_code",
      type: "number",
    },
    {
      label: "シリーズ名",
      field: "c_series_name",
    },
    {
      label: "読み仮名",
      field: "c_series_name_pt",
    },
    {
      label: "出版社",
      field: "c_series_publisher",
    },
    {
      label: "出版日時",
      field: "c_title_date_release",
    },
    {
      label: "価格",
      field: "c_title_exp_price",
    },
  ];
  /**
   * 行データ
   */
  rows: item[] = [];

  /**
   * 行数
   */
  totalRecords = 0;

  //処理用のメソッド
  // ----------------------------------------

  async updateTable(currentPage: number) {
    //ローダー表示※必ずしもボタンからたどる必要はない。
    //-----------------
    const panel = document.getElementById("btn2")?.closest(".panel-container");
    panel?.classList.add("enable-loader");

    try {
      //処理

      //DTO作成
      //---------------------
      let dtoItemsGetReq = new DtoItemsGetReq();

      dtoItemsGetReq.count = 10;
      dtoItemsGetReq.cursor = currentPage;
      dtoItemsGetReq.keyword = "異世界";
      dtoItemsGetReq.sort = "new";
      dtoItemsGetReq.search = "onsale";

      //API実行
      //---------------------
      let itemsClient = new Cl_items();

      let response = await itemsClient.getList(dtoItemsGetReq);
      Cl_common.logger(response);

      //データ入力
      //---------------------
      this.rows = response.items!;
      this.totalRecords = response.maxcount!;
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
    }
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    console.log(params);

    //テーブル更新処理
    this.updateTable(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    console.log(params);

    //テーブル更新処理
  }
}
