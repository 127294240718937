
import { Options, Vue } from "vue-class-component";
import { Cl_staffImple } from "../functions/Cl_staffImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Vuex from "vuex";

// import { Cl_client } from "@/functions/Cl_client";
// import { DtoClientsGetReqImple } from "mediadept-common/src/common/dto/DtoClientsGetReqImple";
@Options({
  components: {
    Mo_modal_alert,
  },
})
export default class Login extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;
  loginid = "";
  loginpass = "";
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4Modal = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  async login() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("loaderLgin");
    panel?.classList.add("enable-loaderLgin");

    try {
      //空白
      if (this.loginid == "" || this.loginpass == "") {
        this.message4Modal = "IDとパスワードを入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal;
        modal.showModal();
        return;
      }

      //ログイン
      await Cl_staffImple.signIn(this.loginid, this.loginpass, this.$store);

      //画面遷移
      //-----------------
      this.$router.push("/dashboard");
    } catch (e) {
      this.message4Modal = "IDもしくはパスワードが間違っています";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal;
      modal.showModal();
      return;
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loaderLgin");
    }
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal(): Promise<void> {
    const modal: any = this.$refs.Mo_modal;
    modal.closeModal();
  }
}
