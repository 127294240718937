import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import { DtoOutputqueuesPostReq } from "mediadept-common/src/common/dto/abst/DtoOutputqueuesPostReq";
import { DtoOutputqueuesPostRes } from "mediadept-common/src/common/dto/abst/DtoOutputqueuesPostRes";
import { DtoOutputqueuesPutReq } from "mediadept-common/src/common/dto/abst/DtoOutputqueuesPutReq";
import { DtoOutputqueuesPutRes } from "mediadept-common/src/common/dto/abst/DtoOutputqueuesPutRes";
import { DtoOutputqueuesDeleteReq } from "mediadept-common/src/common/dto/abst/DtoOutputqueuesDeleteReq";
import { DtoOutputqueuesDeleteRes } from "mediadept-common/src/common/dto/abst/DtoOutputqueuesDeleteRes";

export class Cl_outputqueue {
  public static async getList(
    dto: DtoOutputqueuesGetReqImple
  ): Promise<DtoOutputqueuesGetResImple> {
    return Object.assign(
      new DtoOutputqueuesGetResImple(),
      await CallAPI.getRequest(
        "/outputqueues?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoOutputqueuesGetReqImple
  ): Promise<DtoOutputqueuesGetResImple> {
    return Object.assign(
      new DtoOutputqueuesGetResImple(),
      await CallAPI.getRequest(
        "/outputqueues?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async post(
    dto: DtoOutputqueuesPostReq
  ): Promise<DtoOutputqueuesPostRes> {
    return Object.assign(
      new DtoOutputqueuesPostRes(),
      await CallAPI.postRequest("/outputqueues", dto)
    );
  }

  public static async put(
    dto: DtoOutputqueuesPutReq
  ): Promise<DtoOutputqueuesPutRes> {
    return Object.assign(
      new DtoOutputqueuesPutRes(),
      await CallAPI.putRequest("/outputqueues/" + dto.c_outputqueue_id, dto)
    );
  }

  public async del(
    dto: DtoOutputqueuesDeleteReq
  ): Promise<DtoOutputqueuesDeleteRes> {
    return Object.assign(
      new DtoOutputqueuesDeleteRes(),
      await CallAPI.deleteRequest(
        "/outputqueues?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
