import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSuppliersGetReqImple } from "mediadept-common/src/common/dto/DtoSuppliersGetReqImple";
import { DtoSuppliersGetResImple } from "mediadept-common/src/common/dto/DtoSuppliersGetResImple";
import { DtoSuppliersPostReq } from "mediadept-common/src/common/dto/abst/DtoSuppliersPostReq";
import { DtoSuppliersPostRes } from "mediadept-common/src/common/dto/abst/DtoSuppliersPostRes";
import { DtoSuppliersPutReq } from "mediadept-common/src/common/dto/abst/DtoSuppliersPutReq";
import { DtoSuppliersPutRes } from "mediadept-common/src/common/dto/abst/DtoSuppliersPutRes";
import { DtoSuppliersDeleteReq } from "mediadept-common/src/common/dto/abst/DtoSuppliersDeleteReq";
import { DtoSuppliersDeleteRes } from "mediadept-common/src/common/dto/abst/DtoSuppliersDeleteRes";

export class Cl_supplier {
  public static async getList(
    dto: DtoSuppliersGetReqImple
  ): Promise<DtoSuppliersGetResImple> {
    return Object.assign(
      new DtoSuppliersGetResImple(),
      await CallAPI.getRequest(
        "/suppliers?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoSuppliersGetReqImple
  ): Promise<DtoSuppliersGetResImple> {
    return Object.assign(
      new DtoSuppliersGetResImple(),
      await CallAPI.getRequest("/suppliers/" + dto.c_supplier_id)
    );
  }

  public static async post(
    dto: DtoSuppliersPostReq
  ): Promise<DtoSuppliersPostRes> {
    return Object.assign(
      new DtoSuppliersPostRes(),
      await CallAPI.postRequest("/suppliers", dto)
    );
  }

  public static async put(
    dto: DtoSuppliersPutReq
  ): Promise<DtoSuppliersPutRes> {
    return Object.assign(
      new DtoSuppliersPutRes(),
      await CallAPI.putRequest("/suppliers/" + dto.c_supplier_id, dto)
    );
  }

  public async del(dto: DtoSuppliersDeleteReq): Promise<DtoSuppliersDeleteRes> {
    return Object.assign(
      new DtoSuppliersDeleteRes(),
      await CallAPI.deleteRequest(
        "/suppliers?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
