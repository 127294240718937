import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoClientsGetReq } from "./abst/DtoClientsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoClientsGetReqImple extends DtoClientsGetReq {
  //売上先マスタ一覧の検索条件
  public clientId_start: number | null = null;
  public clientId_end: number | null = null;
  public clientName: string | null = null;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    // if (this.clientId_start != null && this.clientId_end == null) {
    //   res.push("idの指定が不正です");
    // }
    // if (this.clientId_start == null && this.clientId_end != null) {
    //   res.push("idの指定が不正です");
    // }
    if (this.clientId_start != null && this.clientId_end != null && this.clientId_start > this.clientId_end) {
      res.push("idの指定が不正です");
    }

    return res;
  }
}
