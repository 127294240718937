import { EntitySales4project } from "./EntitySales4project";
import { DtoMdDtp } from "../dto/DtoMdDtp";

export class EntitySalesImple extends EntitySales4project {
  //項目選択
  // public cnvItemType: number[] = [];

  //-------------------
  //フロントでの制御用
  //-------------------
  //フロントでの日付変換用
  public cnvDateIssue: DtoMdDtp = new DtoMdDtp();
  public cnvDateMonth: DtoMdDtp = new DtoMdDtp();
  public cnvDateStart: DtoMdDtp = new DtoMdDtp();
  public cnvDateEnd: DtoMdDtp = new DtoMdDtp();
  public cnvDateExpPayment: DtoMdDtp = new DtoMdDtp();
  public cnvDateCmpPayment: DtoMdDtp = new DtoMdDtp();

  //フロントでの金額変換用
  public cnvPrice: string = "";

  //フロントでの商品名用

  public cnvItemName: string = "";
  //フロントでの請求書番号 売上
  public cnvClaimnumTmp: string = "";

  //行選択用
  public isSelect: boolean = false;

  //-------------------

  //承認済み請求書と紐づいている
  public isApprovedClaim: number | null = null;

  //設定されている請求書の税抜合計金額
  public subTotal4Claim: number | null = null;

  //金額不一致警告用
  public isMissMatch: number | null = null;
}
