export class EntitySearchSales {
  public c_sales_id: number | null = null;
  public c_project_id: number | null = null;
  public c_sales_rownum: number | null = null;
  public c_claim_id: number | null = null;
  public c_sales_issue: Date | null = null;
  public c_sales_month: Date | null = null;

  // public c_sales_subject:number | null = null;
  // public c_sales_breakdown:number | null = null;
  // public c_client_id:number | null = null;
  public c_client_name: string | null = null;
  public c_sales_price: number | null = null;
  public c_sales_date_exp_payment: Date | null = null;
  public c_sales_date_cmp_payment: Date | null = null;

  //Join project
  public c_project_number: number | null = null;
  public c_project_name: string | null = null;
  public c_project_sponsor_name: string | null = null;
  public pro_c_client_id: number | null = null;
  public pro_c_client_name: string | null = null;

  public c_staff_name: string | null = null;
  public c_item_name: string | null = null;
  public c_variation_name: string | null = null;
  public c_variation_type: number | null = null;
  public c_claim_flag_sales: number | null = null;
  public c_claim_number: number | null = null;
  public c_sales_claimnum_tmp: number | null = null;
  public c_claim_flag_del: number | null = null;

  public c_sales_taxrate: number | null = null;
  public c_sales_subject: number | null = null;
  public c_sales_item: number | null = null;
}
