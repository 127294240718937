export class EntityRequestBiztrip {
public c_request_biztrip_id:number | null = null;
public c_request_id:number | null = null;
public c_request_biztrip_etd:Date | null = null;
public c_request_biztrip_return:Date | null = null;
public c_request_biztrip_dest:string | null = null;
public c_request_biztrip_stay:string | null = null;
public c_request_biztrip_companion:string | null = null;
public c_request_biztrip_cont:string | null = null;
public c_request_biztrip_reg_user:string | null = null;
public c_request_biztrip_reg_at:Date | null = null;
public c_request_biztrip_upd_user:string | null = null;
public c_request_biztrip_upd_at:Date | null = null;
}