
import { Prop, Options, Vue } from "vue-property-decorator";
import "vue-select/dist/vue-select.css";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { Cl_common } from "@/functions/Cl_common";

@Options({
  components: {
    Mo_modal_l,
  },
})
export default class Or_map extends Vue {
  @Prop({ required: true })
  lat: number;
  @Prop({ required: true })
  lng: number;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  // map: any;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //コンポーネントが表示されていない、読み込み時に呼ばれるので注意。
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  //ボタン無効＋ローダー表示
  //-----------------
  async get() {
    const panel = document.getElementById("panel-body");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行 GoogleMap");

      //準備
      //-------
      //ロード待機
      let timer = setInterval(() => {
        if ((window as any).google) {
          clearInterval(timer);
          // console.log((window as any).google);

          let map = new (window as any).google.maps.Map(
            document.getElementById("map"),
            {
              center: { lat: this.lat, lng: this.lng },
              zoom: 15,
            }
          );

          new (window as any).google.maps.Marker({
            position: { lat: this.lat, lng: this.lng },
            map,
          });
        }
      }, 500);
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
    }
  }

  //------------------------------------
  //詳細データ変換系メソッド
  //------------------------------------

  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.modal_insurance;
    await modalComp.showModal();

    //起動時にマップを読み込み
    await this.get();
  }
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.modal_insurance;
    modalComp.closeModal();
  }
  // --------
}
