import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityMessage } from "../../entity/EntityMessage";

export class DtoMessagesPostReq extends EntityMessage {
  public IsValid(): string[] {
    let res: string[] = new Array();

    this.c_message_start = new Date(Date.parse(this.c_message_start!.toString()));
    this.c_message_end = new Date(Date.parse(this.c_message_end!.toString()));

    //単項目チェック
    //--------------
    if (this.c_message_start == null) {
      res.push("開始日が不正です");
    }
    if (this.c_message_end == null) {
      res.push("終了日が不正です");
    }
    //相関チェック
    //--------------
    if (this.c_message_start > this.c_message_end) {
      res.push("開始日が不正です");
    }

    return res;
  }
}
