import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoItemsGetReqImple } from "mediadept-common/src/common/dto/DtoItemsGetReqImple";
import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";
import { DtoItemsPostReq } from "mediadept-common/src/common/dto/abst/DtoItemsPostReq";
import { DtoItemsPostRes } from "mediadept-common/src/common/dto/abst/DtoItemsPostRes";
import { DtoItemsPutReq } from "mediadept-common/src/common/dto/abst/DtoItemsPutReq";
import { DtoItemsPutRes } from "mediadept-common/src/common/dto/abst/DtoItemsPutRes";
import { DtoItemsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoItemsDeleteReq";
import { DtoItemsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoItemsDeleteRes";

export class Cl_item {
  public static async getList(
    dto: DtoItemsGetReqImple
  ): Promise<DtoItemsGetResImple> {
    return Object.assign(
      new DtoItemsGetResImple(),
      await CallAPI.getRequest(
        "/items?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoItemsGetReqImple
  ): Promise<DtoItemsGetResImple> {
    return Object.assign(
      new DtoItemsGetResImple(),
      await CallAPI.getRequest("/items/" + dto.c_item_id)
    );
  }

  public static async post(dto: DtoItemsPostReq): Promise<DtoItemsPostRes> {
    return Object.assign(
      new DtoItemsPostRes(),
      await CallAPI.postRequest("/items", dto)
    );
  }

  public static async put(dto: DtoItemsPutReq): Promise<DtoItemsPutRes> {
    return Object.assign(
      new DtoItemsPutRes(),
      await CallAPI.putRequest("/items/" + dto.c_item_id, dto)
    );
  }

  public async del(dto: DtoItemsDeleteReq): Promise<DtoItemsDeleteRes> {
    return Object.assign(
      new DtoItemsDeleteRes(),
      await CallAPI.deleteRequest("/items?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
