
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_tableJyutu_row_sales from "@/components/molecules/Mo_tableJyutu_row_sales.vue";
import Mo_tableJyutu_row_supply from "@/components/molecules/Mo_tableJyutu_row_supply.vue";
import Or_selectItem from "@/components/organisms/Or_selectItem.vue";
import Or_selectSupplier from "@/components/organisms/Or_selectSupplier.vue";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

//プロジェクト
import { DtoProjectsGetResImple } from "mediadept-common/src/common/dto/DtoProjectsGetResImple";

//masters用
import { EntitySupplier } from "mediadept-common/src/common/entity/EntitySupplier";

//詳細用
import { EntitySalesImple } from "mediadept-common/src/common/entity/EntitySalesImple";
import { EntitySupplyImple } from "mediadept-common/src/common/entity/EntitySupplyImple";
import { Cl_common } from "@/functions/Cl_common";
import { EntityVariationImple } from "mediadept-common/src/common/entity/EntityVariationImple";
import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";
import { DtoClaimNumsPostReq } from "mediadept-common/src/common/dto/DtoClaimNumsPostReq";
import { DtoClaimNumsPostRes } from "mediadept-common/src/common/dto/DtoClaimNumsPostRes";
import {
  CONST_OP_TERM_ITEM,
  CONST_OP_SUPPLY_TYPE,
} from "mediadept-common/src/common/constant/Constants";
import { Cl_projectImple } from "@/functions/Cl_projectImple";
import { Cl_claimnum } from "@/functions/Cl_claimnum";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";

import { CommonProject } from "mediadept-common/src/common/service/CommonProject";
import { CommonPrice4MD } from "mediadept-common/src/common/function/CommonPrice4MD";
import { CommonDate4MD } from "mediadept-common/src/common/function/CommonDate4MD";

import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { EntityHolisticlock } from "mediadept-common/src/common/entity/EntityHolisticlock";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_tableJyutu_row_sales,
    Mo_tableJyutu_row_supply,
    Or_selectItem,
    Mo_modal_alert,
    Mt_dtp,
    VueDatePicker,
    Or_selectSupplier,
  },
})
export default class Or_tableJyutu extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  projectData: DtoProjectsGetResImple;
  // @Prop({ required: true })
  // items4Supplyers: EntitySupplier[];
  @Prop({ required: true })
  mastersData: DtoMastersGetRes;
  @Prop({ required: true })
  profitData: {
    sumSales: string;
    sumSupplys: string;
    profit: string;
    profitRatio: string;
  };

  //ソートの向き
  isDescSort4Sales = false;
  isDescSort4Supplys = false;
  isDescSort4Spplier = false;

  //仕入先選択用
  //明細行の商品入力用情報
  //------------------
  /**
   * 押下された商品選択ボタンのID。売上idか仕入idが入る。
   */
  callId4Supplier = 0;
  evDatas4SupplierRow: {
    selectedItem: EntitySupplier | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  //売上明細の一括入力用情報
  //------------------
  evDatas4Sales: {
    selectVariation: EntityVariationImple;
    data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
  } = {
    selectVariation: new EntityVariationImple(),
    data: new DtoItemsGetResImple(),
    isSelected: false,
    detStartDate: "",
    detCount: -1,
  };

  selectedItem4Sales: {
    itemId: number | null;
    selectedName: string;
  } = {
    itemId: null,
    selectedName: "",
  };

  bulk_U_ItemName = "商品を選択してください";
  bulk_U_SelectVariation: EntityVariationImple | null = null;
  bulk_U_Itemdata: DtoItemsGetResImple | null = null;
  bulk_U_Type: number[] = [];
  bulk_U_Count = 1;
  bulk_U_StartDate = new DtoMdDtp();
  bulk_U_EndDate = new DtoMdDtp();
  bulk_U_PriceSales = "";
  bulk_U_PriceSupply = "";
  bulk_U_Supplyer: number | null = null;
  bulk_U_ExpDate = new DtoMdDtp();

  //仕入明細の一括入力用情報
  //------------------
  evDatas4Supply: {
    selectVariation: EntityVariationImple;
    data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
  } = {
    selectVariation: new EntityVariationImple(),
    data: new DtoItemsGetResImple(),
    isSelected: false,
    detStartDate: "",
    detCount: -1,
  };

  selectedItem4Supply: {
    itemId: number | null;
    selectedName: string;
  } = {
    itemId: null,
    selectedName: "",
  };

  bulk_S_ItemName = "商品を選択してください";
  bulk_S_term: number | null = null;
  bulk_S_SelectVariation: EntityVariationImple | null = null;
  bulk_S_Itemdata: DtoItemsGetResImple | null = null;
  bulk_S_Type: number[] = [];
  bulk_S_Count = 1;
  bulk_S_StartDate = new DtoMdDtp();
  bulk_S_EndDate = new DtoMdDtp();
  bulk_S_Supplyer: number | null = null;
  bulk_S_PriceSupply = "";
  bulk_S_PayExDate = new DtoMdDtp();

  //明細行の商品入力用情報
  //------------------
  /**
   * 押下された商品選択ボタンが売上明細か仕入明細化を判定。
   */
  isSalesRow = false;
  /**
   * 押下された商品選択ボタンのID。売上idか仕入idが入る。
   */
  callId = 0;
  evDatas4row: {
    selectVariation: EntityVariationImple;
    data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
  } = {
    selectVariation: new EntityVariationImple(),
    data: new DtoItemsGetResImple(),
    isSelected: false,
    detStartDate: "",
    detCount: -1,
  };
  selectedItem: {
    itemId: number | null;
    selectedName: string;
  } = {
    itemId: null,
    selectedName: "",
  };
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opTermItem = CONST_OP_TERM_ITEM;
  opSupplyType = CONST_OP_SUPPLY_TYPE;

  bulk_U_SupplyType = "";
  bulk_S_SupplyType = "";

  /**
   * 仕入先の選択肢を構築する用
   */
  getOpSuppliers(): EntitySupplier[] {
    return this.mastersData.getSupplier4NoneDelete(null);
  }

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //    //仕入先の選択肢作成
    // this.opSuppliers = await this.mastersData.getSupplier4NoneDelete(null);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 売上月列をソート
   */
  async sortRowSales() {
    const elmUp = document.getElementById("sort4SalesUp");
    const elmDown = document.getElementById("sort4SalesDown");

    if (this.isDescSort4Sales) {
      this.projectData.sales.sort(
        (a, b) =>
          new Date(a.cnvDateMonth.date!).getTime() -
          new Date(b.cnvDateMonth.date!).getTime()
      );

      elmUp?.classList.remove("dispNone");
      elmDown?.classList.add("dispNone");
    } else {
      this.projectData.sales.sort(
        (a, b) =>
          new Date(b.cnvDateMonth.date!).getTime() -
          new Date(a.cnvDateMonth.date!).getTime()
      );

      elmUp?.classList.add("dispNone");
      elmDown?.classList.remove("dispNone");
    }

    this.isDescSort4Sales = !this.isDescSort4Sales;
  }
  /**
   * 計上月列をソート
   */
  async sortRowSupplys() {
    const elmUp = document.getElementById("sort4SupplyUp");
    const elmDown = document.getElementById("sort4SupplyDown");

    if (this.isDescSort4Supplys) {
      this.projectData.supplys.sort(
        (a, b) =>
          new Date(a.cnvDateMonth.date!).getTime() -
          new Date(b.cnvDateMonth.date!).getTime()
      );

      elmUp?.classList.remove("dispNone");
      elmDown?.classList.add("dispNone");
    } else {
      this.projectData.supplys.sort(
        (a, b) =>
          new Date(b.cnvDateMonth.date!).getTime() -
          new Date(a.cnvDateMonth.date!).getTime()
      );

      elmUp?.classList.add("dispNone");
      elmDown?.classList.remove("dispNone");
    }

    this.isDescSort4Supplys = !this.isDescSort4Supplys;
  }
  /**
   * 顧客名列をソート
   */
  async sortRowSupplys4Spplier() {
    const elmUp = document.getElementById("sort4SupplierUp");
    const elmDown = document.getElementById("sort4SupplierDown");

    if (this.isDescSort4Supplys) {
      this.projectData.supplys.sort(
        (a, b) => a.c_supplier_id! - b.c_supplier_id!
      );

      elmUp?.classList.remove("dispNone");
      elmDown?.classList.add("dispNone");
    } else {
      this.projectData.supplys.sort(
        (a, b) => b.c_supplier_id! - a.c_supplier_id!
      );

      elmUp?.classList.add("dispNone");
      elmDown?.classList.remove("dispNone");
    }

    this.isDescSort4Supplys = !this.isDescSort4Supplys;
  }
  //--------
  /**
   * 売上明細行を追加する
   */
  async addRowSales() {
    let tmp = new EntitySalesImple();

    //IDは選択処理で必要なので設定する
    tmp.c_sales_id = (await this.getMaxId4Sales()) + 1;
    // tmp.c_sales_price = 0;
    // tmp.cnvPrice = "0";
    tmp.c_sales_taxrate = 3;
    // tmp.cnvDateIssue.date = new Date()
    //  .toLocaleString("ja-JP", {
    //    year: "numeric",
    //     month: "2-digit",
    //    day: "2-digit",
    //   })
    //  .replaceAll("/", "-");
    // tmp.cnvDateMonth.date = new Date()
    //   .toLocaleString("ja-JP", { year: "numeric", month: "2-digit" })
    //   .replaceAll("/", "-");
    tmp.cnvItemName = "商品を選択してください";

    this.projectData.sales.push(tmp);
    await this.resetRowNum4Sales();
  }
  /**
   * 仕入明細行を追加する
   */
  async addRowSupply() {
    let tmp = new EntitySupplyImple();

    //IDは選択処理で必要なので設定する
    tmp.c_supply_id = (await this.getMaxId4Supply()) + 1;
    // tmp.c_supply_price = 0;
    // tmp.cnvPrice = "0";
    tmp.c_supply_taxrate = 3;
    tmp.c_supply_flag_chkclaim = 0;
    tmp.cnvItemName = "商品を選択してください";

    this.projectData.supplys.push(tmp);
    await this.resetRowNum4Supply();
  }
  //--------
  /**
   * 売上明細行を削除する
   */
  async delRowSales() {
    if (this.projectData.sales.length == 0) {
      return;
    }

    //削除対象の明細行に請求書が紐付いていないか確認
    //-------
    if (
      this.projectData.sales[this.projectData.sales.length - 1].c_claim_id !=
        null &&
      this.projectData.sales[this.projectData.sales.length - 1]
        .c_claim_flag_del == 0
    ) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf =
        "請求No：" +
        this.projectData.sales[this.projectData.sales.length - 1]
          .c_sales_claimnum_tmp +
        "の請求書が存在する為、売上明細行を削除できません。請求書の再編集または、削除を行ってください";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      return;
    }
    //削除対象の明細行が統合ロック済みでないか確認
    //-------
    const isDateMatching = (
      data: EntityHolisticlock[],
      targetDate: Date
    ): boolean => {
      return data.some(
        (item) =>
          new Date(item.c_holisticlock_date!).getTime() === targetDate.getTime()
      );
    };
    if (
      this.projectData.sales[this.projectData.sales.length - 1].cnvDateMonth
        .date != null &&
      this.projectData.lockDates.length != 0 &&
      isDateMatching(
        this.projectData.lockDates,
        new Date(
          this.projectData.sales[
            this.projectData.sales.length - 1
          ].cnvDateMonth.date!
        )
      )
    ) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf =
        "削除しようとしている仕入明細は、ロック機能によりロックされています。ロックを解除して削除してください。";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      return;
    }

    this.projectData.sales.pop();
    await this.resetRowNum4Sales();
  }
  /**
   * 選択中の売上明細行を削除する
   */
  async delRowSales4selectes() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (this.projectData.sales[i].isSelect) {
        //削除対象の明細行に請求書が紐付いていないか確認
        //-------
        if (
          this.projectData.sales[i].c_claim_id != null &&
          this.projectData.sales[i].c_claim_flag_del == 0
        ) {
          //モーダルのメッセージを設定
          this.message4ModalErrorConf =
            "請求No：" +
            this.projectData.sales[i].c_sales_claimnum_tmp +
            "の請求書が存在する為、売上明細行を削除できません。請求書の再編集または、削除を行ってください";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        }

        this.projectData.sales.splice(i, 1);

        await this.resetRowNum4Sales();
        break;
      }
    }
  }
  /**
   * 仕入明細行を削除する
   */
  async delRowSupply() {
    //削除対象の明細行が支払確定済みでないか確認
    //-------
    if (
      this.projectData.supplys[this.projectData.supplys.length - 1]
        .c_supply_flag_paid == 1
    ) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf =
        "削除しようとしている仕入明細は、支払が確定しています。削除する場合は、支払未確定状態に戻してください。";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      return;
    }
    //削除対象の明細行が統合ロック済みでないか確認
    //-------
    const isDateMatching = (
      data: EntityHolisticlock[],
      targetDate: Date
    ): boolean => {
      return data.some(
        (item) =>
          new Date(item.c_holisticlock_date!).getTime() === targetDate.getTime()
      );
    };
    if (
      this.projectData.supplys[this.projectData.supplys.length - 1].cnvDateMonth
        .date != null &&
      this.projectData.lockDates.length != 0 &&
      isDateMatching(
        this.projectData.lockDates,
        new Date(
          this.projectData.supplys[
            this.projectData.supplys.length - 1
          ].cnvDateMonth.date!
        )
      )
    ) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf =
        "削除しようとしている仕入明細は、ロック機能によりロックされています。ロックを解除して削除してください。";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      return;
    }

    this.projectData.supplys.pop();
    await this.resetRowNum4Supply();
  }
  /**
   * 選択中の仕入明細行を削除する
   */
  async delRowSupply4selectes() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (this.projectData.supplys[i].isSelect) {
        //削除対象の明細行が支払確定済みでないか確認
        //-------
        if (this.projectData.supplys[i].c_supply_flag_paid == 1) {
          //モーダルのメッセージを設定
          this.message4ModalErrorConf =
            "選択中の仕入明細は、支払が確定しています。削除する場合は、支払未確定状態に戻してください。";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        }

        this.projectData.supplys.splice(i, 1);
        await this.resetRowNum4Supply();
        break;
      }
    }
  }
  //--------
  /**
   * 選択中の売上明細行をコピーする
   */
  async copyRowSales() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (this.projectData.sales[i].isSelect) {
        let tmp = new EntitySalesImple();

        tmp = Object.assign(new EntitySalesImple(), this.projectData.sales[i]);

        //データ調整
        tmp.c_claim_number = null;
        tmp.c_claim_id = null;
        tmp.c_sales_id = (await this.getMaxId4Sales()) + 1;
        tmp.isSelect = false;

        //DTPコピー
        let dtpIssue = new DtoMdDtp();
        let dtpMonth = new DtoMdDtp();
        let dtpStart = new DtoMdDtp();
        let dtpEnd = new DtoMdDtp();
        let dtpExp = new DtoMdDtp();
        let dtpCmp = new DtoMdDtp();

        dtpIssue.date = tmp.cnvDateIssue.date;
        dtpMonth.date = tmp.cnvDateMonth.date;
        dtpStart.date = tmp.cnvDateStart.date;
        dtpEnd.date = tmp.cnvDateEnd.date;
        dtpExp.date = tmp.cnvDateExpPayment.date;
        dtpCmp.date = tmp.cnvDateCmpPayment.date;

        tmp.cnvDateIssue = dtpIssue;
        tmp.cnvDateMonth = dtpMonth;
        tmp.cnvDateStart = dtpStart;
        tmp.cnvDateEnd = dtpEnd;
        tmp.cnvDateExpPayment = dtpExp;
        tmp.cnvDateCmpPayment = dtpCmp;

        this.projectData.sales.push(tmp);

        await this.resetRowNum4Sales();
        break;
      }
    }
  }
  /**
   * 選択中の仕入明細行をコピーする
   */
  async copyRowSupply() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (this.projectData.supplys[i].isSelect) {
        let tmp = new EntitySupplyImple();

        tmp = Object.assign(
          new EntitySupplyImple(),
          this.projectData.supplys[i]
        );

        //データ調整
        tmp.c_claim_number = null;
        tmp.c_claim_id = null;
        tmp.c_supply_claimnum_tmp = null;
        tmp.cnvClaimnumTmp = "";

        tmp.c_supply_flag_paid = null;
        tmp.c_supply_flag_chkclaim = 0;
        tmp.c_pro2doc_id = null;
        tmp.c_supply_id = (await this.getMaxId4Supply()) + 1;
        tmp.isSelect = false;
        tmp.c_supply_flag_lock = 0;

        //DTPコピー
        let dtpMonth = new DtoMdDtp();
        let dtpStart = new DtoMdDtp();
        let dtpEnd = new DtoMdDtp();
        let dtpExp = new DtoMdDtp();

        // dtpMonth.date = tmp.cnvDateMonth.date;
        dtpMonth.date = null;
        dtpStart.date = tmp.cnvDateStart.date;
        dtpEnd.date = tmp.cnvDateEnd.date;
        dtpExp.date = null;

        tmp.cnvDateMonth = dtpMonth;
        tmp.cnvDateStart = dtpStart;
        tmp.cnvDateEnd = dtpEnd;
        tmp.cnvDateExpPayment = dtpExp;

        this.projectData.supplys.push(tmp);

        await this.resetRowNum4Supply();
        break;
      }
    }
  }

  //--------
  /**
   * 請求No採番　処理
   */
  async setSeikyuNo() {
    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("panel-editers");
    const panel2 = document.getElementById("panel-editers4det");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      if (
        this.projectData.c_project_billing_type != 1 &&
        this.projectData.c_project_billing_type != 2
      ) {
        Cl_common.logger("入金方法を指定してください");
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "入金方法を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      let dto = new DtoClaimNumsPostReq();

      //処理分岐
      //-------
      if (this.projectData.c_project_billing_type == 1) {
        //1:一括
        //-------
        /**
         * 空白明細への同一請求書NOの一括入力
         * 売上月が指定されていて、請求Noが未指定の明細に入力
         */

        //入力条件を満たす行があるかを確認
        //-------
        let inputRowCount = 0;
        for (let i = 0; i < this.projectData.sales.length; ++i) {
          if (
            this.projectData.sales[i].cnvClaimnumTmp == "" &&
            this.projectData.sales[i].cnvDateMonth.date != "" &&
            this.projectData.sales[i].cnvDateMonth.date != null &&
            this.projectData.sales[i].cnvDateMonth != undefined
          ) {
            inputRowCount++;
          }
        }
        //なければ処理終了
        if (inputRowCount == 0) {
          Cl_common.logger("処理対象なし");
          return;
        }

        dto.issueCount = 1;

        //API実行
        //-------
        const res = await Cl_claimnum.post(dto);
        Cl_common.logger(res);

        //入力
        //-------
        for (let i = 0; i < this.projectData.sales.length; ++i) {
          if (
            this.projectData.sales[i].cnvClaimnumTmp == "" &&
            this.projectData.sales[i].cnvDateMonth.date != "" &&
            this.projectData.sales[i].cnvDateMonth.date != null &&
            this.projectData.sales[i].cnvDateMonth != undefined
          ) {
            this.projectData.sales[i].cnvClaimnumTmp =
              res.claimNums[0].toString();
          }
        }
      } else {
        //2:分割
        //-------
        /**
         * 空白明細への連番請求書NOの一括入力
         * 売上月が指定されていて、請求Noが未指定の明細に入力
         */

        //入力条件を満たす行があるかを確認
        //-------
        let inputRowCount = 0;
        for (let i = 0; i < this.projectData.sales.length; ++i) {
          if (
            this.projectData.sales[i].cnvClaimnumTmp == "" &&
            this.projectData.sales[i].cnvDateMonth.date != "" &&
            this.projectData.sales[i].cnvDateMonth.date != null &&
            this.projectData.sales[i].cnvDateMonth != undefined
          ) {
            inputRowCount++;
          }
        }
        //なければ処理終了
        if (inputRowCount == 0) {
          Cl_common.logger("処理対象なし");
          return;
        }

        dto.issueCount = inputRowCount;

        //API実行
        //-------
        const res = await Cl_claimnum.post(dto);
        Cl_common.logger(res);

        //入力
        //-------
        let inputIndex = 0;
        for (let i = 0; i < this.projectData.sales.length; ++i) {
          if (
            this.projectData.sales[i].cnvClaimnumTmp == "" &&
            this.projectData.sales[i].cnvDateMonth.date != "" &&
            this.projectData.sales[i].cnvDateMonth.date != null &&
            this.projectData.sales[i].cnvDateMonth != undefined
          ) {
            this.projectData.sales[i].cnvClaimnumTmp =
              res.claimNums[inputIndex].toString();
            inputIndex++;
          }
        }
      }
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");
    }
  }
  /**
   * 新規請求No採番　処理
   */
  async setNewSeikyuNo() {
    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("panel-editers");
    const panel2 = document.getElementById("panel-editers4det");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //選択行を取得
      //-------
      let selectedrow: EntitySalesImple | null = null;
      for (let i = 0; i < this.projectData.sales.length; ++i) {
        if (this.projectData.sales[i].isSelect) {
          selectedrow = this.projectData.sales[i];
        }
      }

      if (selectedrow == null) {
        Cl_common.logger("選択行なし");
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "請求書Noを発行するには、対象の売上行を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //準備
      //-------
      let dto = new DtoClaimNumsPostReq();

      dto.issueCount = 1;

      //API実行
      //-------
      const res = await Cl_claimnum.post(dto);
      Cl_common.logger(res);

      selectedrow.cnvClaimnumTmp = res.claimNums[0].toString();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");
    }
  }

  //--------
  /**
   * 売上明細行の行番号を振りなおす
   */
  async resetRowNum4Sales() {
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      this.projectData.sales[i].c_sales_rownum = i + 1;
    }
  }
  /**
   * 仕入明細行の行番号を振りなおす
   */
  async resetRowNum4Supply() {
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      this.projectData.supplys[i].c_supply_rownum = i + 1;
    }
  }
  //--------
  /**
   * 売上明細行の選択時処理
   */
  async selectRow4Sales(ev: number) {
    // console.log(ev);
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (ev == this.projectData.sales[i].c_sales_id) {
        continue;
      }
      this.projectData.sales[i].isSelect = false;
    }
  }
  /**
   * 仕入明細行の選択時処理
   */
  async selectRow4Supply(ev: number) {
    // console.log(ev);
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (ev == this.projectData.supplys[i].c_supply_id) {
        continue;
      }
      this.projectData.supplys[i].isSelect = false;
    }
  }
  //--------
  /**
   * 売上明細行の商品選択起動
   */
  async itemSelect4salesRow(ev: number, rowItem: EntitySalesImple) {
    // console.log("売上明細行");
    // console.log(rowItem);
    this.isSalesRow = true;
    this.callId = ev;

    //
    this.selectedItem.itemId = rowItem.c_item_id;
    this.selectedItem.selectedName = rowItem.cnvItemName;

    this.showModalItem4row();
  }
  /**
   * 仕入明細行の商品選択起動
   */
  async itemSelect4supplyRow(ev: number) {
    // console.log("仕入明細行");
    // console.log(ev);
    this.isSalesRow = false;
    this.callId = ev;
    this.showModalItem4row();
  }
  /**
   * 仕入明細行の仕入先選択起動
   */
  async supplierSelect4supplyRow(ev: number) {
    this.callId4Supplier = ev;
    this.showModalSupplier4row();
  }

  //--------
  /**
   * 収支計算を行う
   */
  async profitCalc() {
    let sumSales = 0;
    let sumSupplys = 0;
    let profit = 0;
    let profitRatio = 0.0;

    for (let i = 0; i < this.projectData.sales.length; ++i) {
      sumSales += Number(
        this.projectData.sales[i].cnvPrice.replaceAll(",", "")
      );
    }
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      sumSupplys += Number(
        this.projectData.supplys[i].cnvPrice.replaceAll(",", "")
      );
    }

    profit = sumSales - sumSupplys;
    profitRatio = Number(((profit / sumSales) * 100).toFixed(2));

    // console.log(sumSales);
    // console.log(sumSupplys);
    // console.log(profit);
    // console.log(profitRatio);

    this.profitData.sumSales = sumSales.toString();
    this.profitData.sumSupplys = sumSupplys.toString();
    this.profitData.profit = profit.toString();
    if (!isNaN(profitRatio) && profitRatio != -Infinity) {
      this.profitData.profitRatio = profitRatio.toString();
    } else {
      this.profitData.profitRatio = "0";
    }

    // console.log("Infinity");
    // console.log(profitRatio);

    //カンマを付ける
    while (
      this.profitData.sumSales !=
      (this.profitData.sumSales = this.profitData.sumSales.replace(
        /^(-?\d+)(\d{3})/,
        "$1,$2"
      ))
    );
    while (
      this.profitData.sumSupplys !=
      (this.profitData.sumSupplys = this.profitData.sumSupplys.replace(
        /^(-?\d+)(\d{3})/,
        "$1,$2"
      ))
    );
    while (
      this.profitData.profit !=
      (this.profitData.profit = this.profitData.profit.replace(
        /^(-?\d+)(\d{3})/,
        "$1,$2"
      ))
    );
  }

  //--------
  /**
   * 売上明細行のsales_idの最大値取得
   */
  async getMaxId4Sales(): Promise<number> {
    let res = 0;
    for (let i = 0; i < this.projectData.sales.length; ++i) {
      if (
        this.projectData.sales[i].c_sales_id != null &&
        res < this.projectData.sales[i].c_sales_id!
      ) {
        res = this.projectData.sales[i].c_sales_id!;
      }
    }

    return res;
  }
  /**
   * 仕入明細行のsupply_idの最大値取得
   */
  async getMaxId4Supply(): Promise<number> {
    let res = 0;
    for (let i = 0; i < this.projectData.supplys.length; ++i) {
      if (
        this.projectData.supplys[i].c_supply_id != null &&
        res < this.projectData.supplys[i].c_supply_id!
      ) {
        res = this.projectData.supplys[i].c_supply_id!;
      }
    }

    return res;
  }

  /**
   * 売上一括入力実行
   * modeは、1:売上のみ;2:仕入のみ;3:売仕両方;
   */
  async bulk_U_input(mode: number) {
    //入力チェック
    //-------------------------
    // Cl_common.logger(this.evDatas4Sales);
    // Cl_common.logger(this.bulk_U_SupplyType);
    Cl_common.logger(this.bulk_U_StartDate);

    //ボタン無効＋ローダー表示
    //----
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //商品選択していない場合は処理不可
      //-----------
      if (!this.evDatas4Sales.isSelected) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "商品を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //請求方法がNULLの場合は処理不可
      //-----------
      if (
        this.projectData.c_project_billing_type != 1 &&
        this.projectData.c_project_billing_type != 2
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "入金方法を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //開始日と終了日が入力されていない場合は処理不可
      //-----------
      if (
        this.bulk_U_StartDate.date == null ||
        this.bulk_U_EndDate.date == null
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "開始日、終了日を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //売上用を含む処理の場合
      if (mode == 1 || mode == 3) {
        //総売上額が入力されていない場合は処理不可
        //-----------
        if (
          this.bulk_U_PriceSales == null ||
          this.bulk_U_PriceSales == undefined ||
          this.bulk_U_PriceSales == ""
        ) {
          //モーダルのメッセージを設定
          this.message4ModalErrorConf = "売上総額を入力してください";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        }
      }
      //仕入用を含む処理の場合
      if (mode == 2 || mode == 3) {
        //総仕入額が入力されていない場合は処理不可
        //-----------
        if (
          this.bulk_U_PriceSupply == null ||
          this.bulk_U_PriceSupply == undefined ||
          this.bulk_U_PriceSupply == ""
        ) {
          //モーダルのメッセージを設定
          this.message4ModalErrorConf = "仕入総額を入力してください";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        }
      }

      //----------

      let itemId = this.evDatas4Sales.data.c_item_id!;
      let variationId = this.evDatas4Sales.selectVariation.c_variation_id!;
      let itemVariName =
        this.evDatas4Sales.data.c_item_name +
        " " +
        this.evDatas4Sales.selectVariation.c_variation_name;
      let billingType = this.projectData.c_project_billing_type!;

      let term = this.evDatas4Sales.data.c_item_term!;

      let type = "";
      if (this.bulk_U_SupplyType == "") {
        type = this.evDatas4Sales.selectVariation.c_variation_type!;
      } else {
        type = this.bulk_U_SupplyType;
      }

      //入力後のチェック
      if (type == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "項目選択を指定するか、項目選択されているバリエーションを選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      let count = this.bulk_U_Count;

      let dateStart = new Date(this.bulk_U_StartDate.date);
      let dateEnd = new Date(this.bulk_U_EndDate.date);
      //入力後のチェック
      if (dateEnd <= dateStart) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "開始日よりも後の終了日を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //統合ロック中の日付が含まれないかを確認
      //-------
      let holisticlockDates = this.projectData.lockDates
        .map((obj) => new Date(obj.c_holisticlock_date!))
        .filter((date) => !isNaN(date.getTime()));
      //開始日、終了日を当月1日0時0分0秒に設定。
      const dateStart4Fd = CommonDate4MD.resetToFirstDayOfMonth(dateStart);
      const dateEnd4Fd = CommonDate4MD.resetToFirstDayOfMonth(dateEnd);
      //開始日、終了日の間の毎月1日0時0分0秒を取得。
      const compaDates = CommonDate4MD.getFirstDaysBetweenDates(
        dateStart4Fd,
        dateEnd4Fd
      );
      //統合ロックの配列と比較。
      if (CommonDate4MD.hasCommonDate(holisticlockDates, compaDates)) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "分割対象日付内に、ロック中の日付が含まれています。ロックを解除してから再実行してください。";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      let priceSales = Number(this.bulk_U_PriceSales.replaceAll(",", ""));
      let priceSupplys = Number(this.bulk_U_PriceSupply.replaceAll(",", ""));

      //一括入力実行
      //-------------------------
      //データ作成
      //-------
      const bulkSales = await CommonProject.creBulk4Sales(
        (await this.getMaxId4Sales()) + 1,
        itemId,
        variationId,
        itemVariName,
        billingType,
        term,
        Number(type),
        count,
        dateStart,
        dateEnd,
        priceSales,
        (await this.getMaxId4Supply()) + 1,
        priceSupplys,
        this.evDatas4Sales
      );

      //請求番号発行
      //----------------------------------------------

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimNumsPostReq();

      //処理分岐
      //-------
      if (this.projectData.c_project_billing_type == 1) {
        //1:一括
        //-------
        /**
         * 空白明細への同一請求書NOの一括入力
         * 売上月が指定されていて、請求Noが未指定の明細に入力
         */

        dto.issueCount = 1;

        //API実行
        //-------
        const res = await Cl_claimnum.post(dto);
        Cl_common.logger(res);

        //配列に請求番号入力
        //-------
        for (let i = 0; i < bulkSales.sales.length; ++i) {
          bulkSales.sales[i].cnvClaimnumTmp = res.claimNums[0].toString();
        }
      } else {
        //2:分割
        //-------
        /**
         * 空白明細への連番請求書NOの一括入力
         * 売上月が指定されていて、請求Noが未指定の明細に入力
         */

        //入力条件を満たす行があるかを確認
        //-------
        dto.issueCount = bulkSales.sales.length;

        //API実行
        //-------
        const res = await Cl_claimnum.post(dto);
        Cl_common.logger(res);

        //配列に請求番号入力
        //-------
        let inputIndex = 0;
        for (let i = 0; i < bulkSales.sales.length; ++i) {
          bulkSales.sales[i].cnvClaimnumTmp =
            res.claimNums[inputIndex].toString();
          inputIndex++;
        }
      }

      //入金予定日が入力されていれば、一括入力
      //-------
      if (this.bulk_U_ExpDate.date != null && this.bulk_U_ExpDate.date != "") {
        for (let i = 0; i < bulkSales.sales.length; ++i) {
          bulkSales.sales[i].cnvDateExpPayment.date = this.bulk_U_ExpDate.date;
        }
      }

      //明細にデータ入力
      //-------
      if (mode == 1 || mode == 3) {
        //売上入力
        this.projectData.sales = this.projectData.sales.concat(bulkSales.sales);
        await this.resetRowNum4Sales();
      }
      if (mode == 2 || mode == 3) {
        //仕入入力

        //仕入先ID
        let supplierId = this.bulk_U_Supplyer!;
        //保険申請依頼済みフラグ  保険申請フラグ1:必要;2:不要;
        let flagInsurance = 0;
        if (this.evDatas4Sales.data.c_item_insurance == 2) {
          flagInsurance = 1; //保険申請フラグが2（不要）なら申請済みとする
        }
        const bulkSupply = await CommonProject.creBulk4Supply(
          (await this.getMaxId4Supply()) + 1,
          itemId,
          variationId,
          itemVariName,
          Number(type),
          count,
          dateStart,
          dateEnd,
          priceSupplys,
          supplierId,
          flagInsurance
        );

        this.projectData.supplys = this.projectData.supplys.concat(bulkSupply);

        // this.projectData.supplys = this.projectData.supplys.concat(
        //   bulkSales.supplys
        // );
        await this.resetRowNum4Supply();
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 仕入一括入力実行
   */
  async bulk_S_input() {
    //入力チェック
    //-------------------------
    // Cl_common.logger(this.evDatas4Supply);
    // Cl_common.logger(this.bulk_S_SupplyType);
    Cl_common.logger(this.bulk_S_StartDate);

    //ボタン無効＋ローダー表示
    //----
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //商品選択していない場合は処理不可
      //-----------
      if (!this.evDatas4Supply.isSelected) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "商品を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //開始日と終了日が入力されていない場合は処理不可
      //-----------
      if (this.bulk_S_StartDate.date == "" || this.bulk_S_EndDate.date == "") {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "開始日、終了日を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //総仕入額が入力されていない場合は処理不可
      //-----------
      if (
        this.bulk_S_PriceSupply == null ||
        this.bulk_S_PriceSupply == undefined ||
        this.bulk_S_PriceSupply == ""
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "仕入総額を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //----------

      let itemId = this.evDatas4Supply.data.c_item_id!;
      let variationId = this.evDatas4Supply.selectVariation.c_variation_id!;
      let itemVariName =
        this.evDatas4Supply.data.c_item_name +
        " " +
        this.evDatas4Supply.selectVariation.c_variation_name;

      let type = "";
      if (this.bulk_S_SupplyType == "") {
        type = this.evDatas4Supply.selectVariation.c_variation_type!;
      } else {
        type = this.bulk_S_SupplyType;
      }

      //入力後のチェック
      if (type == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "項目選択を指定するか、項目選択されているバリエーションを選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      let count = this.bulk_S_Count;

      let dateStart = new Date(this.bulk_S_StartDate.date!);
      let dateEnd = new Date(this.bulk_S_EndDate.date!);
      //入力後のチェック
      if (dateEnd <= dateStart) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "開始日よりも後の終了日を指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //総仕入額
      let priceSupply = Number(this.bulk_S_PriceSupply.replaceAll(",", ""));

      //仕入先ID
      let supplierId = this.bulk_S_Supplyer!;

      //保険申請依頼済みフラグ  保険申請フラグ1:必要;2:不要;
      let flagInsurance = 0;
      if (this.evDatas4Supply.data.c_item_insurance == 2) {
        flagInsurance = 1; //保険申請フラグが2（不要）なら申請済みとする
      }

      //統合ロック中の日付が含まれないかを確認
      //-------
      let holisticlockDates = this.projectData.lockDates
        .map((obj) => new Date(obj.c_holisticlock_date!))
        .filter((date) => !isNaN(date.getTime()));
      //開始日、終了日を当月1日0時0分0秒に設定。
      const dateStart4Fd = CommonDate4MD.resetToFirstDayOfMonth(dateStart);
      const dateEnd4Fd = CommonDate4MD.resetToFirstDayOfMonth(dateEnd);
      //開始日、終了日の間の毎月1日0時0分0秒を取得。
      const compaDates = CommonDate4MD.getFirstDaysBetweenDates(
        dateStart4Fd,
        dateEnd4Fd
      );
      //統合ロックの配列と比較。
      if (CommonDate4MD.hasCommonDate(holisticlockDates, compaDates)) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "分割対象日付内に、ロック中の日付が含まれています。ロックを解除してから再実行してください。";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //一括入力実行
      //-------------------------

      const bulkSupply = await CommonProject.creBulk4Supply(
        (await this.getMaxId4Supply()) + 1,
        itemId,
        variationId,
        itemVariName,
        Number(type),
        count,
        dateStart,
        dateEnd,
        priceSupply,
        supplierId,
        flagInsurance
      );

      //支払予定日が入力されていれば、一括入力
      //-------
      if (
        this.bulk_S_PayExDate.date != null &&
        this.bulk_S_PayExDate.date != ""
      ) {
        for (let i = 0; i < bulkSupply.length; ++i) {
          bulkSupply[i].cnvDateExpPayment.date = this.bulk_S_PayExDate.date;
        }
      }

      this.projectData.supplys = this.projectData.supplys.concat(bulkSupply);
      await this.resetRowNum4Supply();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  /**
   * 売上用分割入力機能の商品選択時のイベント
   * watch用
   */
  @Watch("evDatas4Sales.isSelected")
  async selectItem4sales() {
    if (!this.evDatas4Sales.isSelected) {
      return;
    }
    // Cl_common.logger(this.evDatas4Sales.isSelected);
    // Cl_common.logger(ev.selectVariation);
    // Cl_common.logger(ev.data);

    this.bulk_U_SelectVariation = this.evDatas4Sales.selectVariation;
    this.bulk_U_Itemdata = this.evDatas4Sales.data;
    this.bulk_U_ItemName =
      this.bulk_U_Itemdata.c_item_name +
      " " +
      this.bulk_U_SelectVariation.c_variation_name;

    //項目選択入力
    //------------
    if (this.evDatas4Sales.selectVariation.c_variation_type != null) {
      this.bulk_U_SupplyType =
        this.evDatas4Sales.selectVariation.c_variation_type;
    }

    //日付入力
    //------------
    console.log("this.evDatas4Sales.detStartDate");
    console.log(this.evDatas4Sales.detStartDate);
    this.bulk_U_StartDate.date = this.evDatas4Sales.detStartDate.toString();

    //単純一年後
    // let tmpDate = new Date(this.evDatas4Sales.detStartDate);
    // tmpDate.setFullYear(tmpDate.getFullYear() + 1);
    // tmpDate.setDate(tmpDate.getDate() - 1);
    // this.bulk_U_EndDate = tmpDate.toISOString().split("T")[0];

    const salseDate = await CommonDate4MD.offsetDate4VariationTerm(
      new Date(this.evDatas4Sales.detStartDate),
      this.evDatas4Sales.selectVariation
    );
    if (salseDate != null) {
      // salseDate.setDate(salseDate.getDate() - 1);
      this.bulk_U_EndDate.date = salseDate
        .toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", "-");
    } else {
      this.bulk_U_EndDate.date = null;
    }

    //金額を計算して入力
    //------------
    //総売上額
    //-------
    this.bulk_U_PriceSales = (
      await CommonPrice4MD.cnvVariationPrice(
        this.evDatas4Sales.detCount,
        this.evDatas4Sales.selectVariation
      )
    ).toString();
    //カンマ補完
    while (
      this.bulk_U_PriceSales !=
      (this.bulk_U_PriceSales = this.bulk_U_PriceSales.replace(
        /^(-?\d+)(\d{3})/,
        "$1,$2"
      ))
    );

    //仕入先
    //-------
    if (
      this.evDatas4Sales.selectVariation != null &&
      this.evDatas4Sales.selectVariation.conditions != null
    ) {
      this.bulk_U_Supplyer =
        this.evDatas4Sales.selectVariation.conditions[0].c_supplier_id;
    }

    //総仕入額
    //-------
    this.bulk_U_PriceSupply = (
      await CommonPrice4MD.cnvSupplySumPrice(
        this.evDatas4Sales.selectVariation.conditions
      )
    ).toString();
    //カンマ補完
    while (
      this.bulk_U_PriceSupply !=
      (this.bulk_U_PriceSupply = this.bulk_U_PriceSupply.replace(
        /^(-?\d+)(\d{3})/,
        "$1,$2"
      ))
    );
  }
  /**
   * 仕入用分割入力機能の商品選択時のイベント
   * watch用
   */
  @Watch("evDatas4Supply.isSelected")
  async selectItem4supply() {
    if (!this.evDatas4Supply.isSelected) {
      return;
    }
    this.bulk_S_SelectVariation = this.evDatas4Supply.selectVariation;
    this.bulk_S_Itemdata = this.evDatas4Supply.data;

    this.bulk_S_ItemName =
      this.bulk_S_Itemdata.c_item_name +
      " " +
      this.bulk_S_SelectVariation.c_variation_name;

    //項目選択入力
    //------------
    if (this.evDatas4Supply.selectVariation.c_variation_type != null) {
      this.bulk_S_SupplyType =
        this.evDatas4Supply.selectVariation.c_variation_type;
    }

    //日付入力
    //------------
    this.bulk_S_StartDate.date = this.evDatas4Supply.detStartDate;

    //単純一年
    // let tmpDate = new Date(this.evDatas4Supply.detStartDate);
    // tmpDate.setFullYear(tmpDate.getFullYear() + 1);
    // tmpDate.setDate(tmpDate.getDate() - 1);
    // this.bulk_S_EndDate = tmpDate.toISOString().split("T")[0];

    const supplyDate = await CommonDate4MD.offsetDate4VariationTerm(
      new Date(this.evDatas4Supply.detStartDate),
      this.evDatas4Supply.selectVariation
    );

    if (supplyDate != null) {
      // supplyDate.setDate(supplyDate.getDate() - 1);
      this.bulk_S_EndDate.date = supplyDate
        .toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", "-");
    } else {
      this.bulk_S_EndDate.date = "";
    }

    //仕入先
    //-------
    if (
      this.evDatas4Supply.selectVariation != null &&
      this.evDatas4Supply.selectVariation.conditions != null
    ) {
      this.bulk_S_Supplyer =
        this.evDatas4Supply.selectVariation.conditions[0].c_supplier_id;
    }

    //総仕入額
    //-------
    this.bulk_S_PriceSupply = (
      await CommonPrice4MD.cnvSupplySumPrice(
        this.evDatas4Supply.selectVariation.conditions
      )
    ).toString();
    //カンマ補完
    while (
      this.bulk_S_PriceSupply !=
      (this.bulk_S_PriceSupply = this.bulk_S_PriceSupply.replace(
        /^(-?\d+)(\d{3})/,
        "$1,$2"
      ))
    );
  }

  /**
   * 明細行の商品選択時のイベント
   * watch用
   */
  @Watch("evDatas4row.isSelected")
  async selectItem() {
    if (!this.evDatas4row.isSelected) {
      return;
    }

    //売上か仕入かを判定
    //------------
    if (this.isSalesRow) {
      //売上明細
      //------------
      //idを判定
      //----------
      for (let index = 0; index < this.projectData.sales.length; index++) {
        if (this.projectData.sales[index].c_sales_id != this.callId) {
          continue;
        }
        //書き換えを実行
        //----------
        //初回選択時と、変更時で処理を変更
        if (this.projectData.sales[index].c_variation_id == null) {
          //初回　各項目を算出
          await Cl_projectImple.setItemSales4init(
            this.projectData.sales[index],
            this.projectData.supplys,
            this.evDatas4row
          );

          //仕入明細行の行数を振りなおす
          this.resetRowNum4Supply();
        } else {
          //更新 商品・バリエーション部分だけの書き換え
          await Cl_projectImple.setItemSales4upd(
            this.projectData.sales[index],
            this.evDatas4row
          );
        }

        break;
      }
    } else {
      //仕入明細
      //------------
      //idを判定
      //----------
      for (let index = 0; index < this.projectData.supplys.length; index++) {
        if (this.projectData.supplys[index].c_supply_id != this.callId) {
          continue;
        }
        //書き換えを実行
        //----------
        //初回選択時と、変更時で処理を変更
        if (this.projectData.supplys[index].c_variation_id == null) {
          //初回　※仕入は初回でも更新と同じ処理
          await Cl_projectImple.setItemSupply4upd(
            this.projectData.supplys[index],
            this.evDatas4row
          );
        } else {
          //更新 商品・バリエーション部分だけの書き換え
          await Cl_projectImple.setItemSupply4upd(
            this.projectData.supplys[index],
            this.evDatas4row
          );
        }

        break;
      }
    }
  }
  /**
   * 明細行の商品選択時のイベント
   * watch用
   */
  @Watch("evDatas4SupplierRow.isSelected")
  async selectSupplier() {
    if (!this.evDatas4SupplierRow.isSelected) {
      return;
    }

    //仕入明細
    //------------
    //idを判定
    //----------
    for (let index = 0; index < this.projectData.supplys.length; index++) {
      if (this.projectData.supplys[index].c_supply_id != this.callId4Supplier) {
        continue;
      }
      //書き換えを実行
      //----------
      this.projectData.supplys[index].c_supplier_id =
        this.evDatas4SupplierRow.selectedItem!.c_supplier_id!;
      break;
    }
  }

  /**
   * 売上一括入力用の商品選択ボタン押下時のModal呼び出し
   */
  async showModalItem4sales(): Promise<void> {
    //渡されている商品があれば表示、なければなし
    // console.log("ココはif前のthis.bulk_U_Itemdata!.c_item_id");
    // console.log(this.bulk_U_Itemdata!.c_item_id);

    if (
      this.bulk_U_Itemdata != null &&
      this.bulk_U_Itemdata.c_item_id != null
    ) {
      this.selectedItem4Sales.itemId = this.bulk_U_Itemdata!.c_item_id;
      this.selectedItem4Sales.selectedName = this.bulk_U_ItemName;
    } else {
      this.selectedItem4Sales.itemId = null;
      this.selectedItem4Sales.selectedName = "";
    }
    // console.log("ココはif後のthis.bulk_U_Itemdata!.c_item_id");
    // console.log(this.bulk_U_Itemdata!.c_item_id);

    //-------------------
    this.evDatas4Sales = {
      selectVariation: new EntityVariationImple(),
      data: new DtoItemsGetResImple(),
      isSelected: false,
      detStartDate: "",
      detCount: -1,
    };

    const modalComp: any = this.$refs.comp_itemSelect4sales;
    modalComp.showModal4Item();
  }
  /**
   * 仕入一括入力用の商品選択ボタン押下時のModal呼び出し
   */
  async showModalItem4supply(): Promise<void> {
    if (
      this.bulk_S_Itemdata != null &&
      this.bulk_S_Itemdata.c_item_id != null
    ) {
      this.selectedItem4Supply.itemId = this.bulk_S_Itemdata!.c_item_id;
      this.selectedItem4Supply.selectedName = this.bulk_S_ItemName;
    } else {
      this.selectedItem4Supply.itemId = null;
      this.selectedItem4Supply.selectedName = "";
    }

    //-------------------
    this.evDatas4Supply = {
      selectVariation: new EntityVariationImple(),
      data: new DtoItemsGetResImple(),
      isSelected: false,
      detStartDate: "",
      detCount: -1,
    };

    const modalComp: any = this.$refs.comp_itemSelect4supply;
    modalComp.showModal4Item();
  }
  /**
   * 明細行の商品選択ボタン押下時のModal呼び出し
   * 売上、仕入共用
   */
  async showModalItem4row(): Promise<void> {
    this.evDatas4row = {
      selectVariation: new EntityVariationImple(),
      data: new DtoItemsGetResImple(),
      isSelected: false,
      detStartDate: "",
      detCount: -1,
    };

    //選択している商品が有れば情報を渡す、なければnullを渡す

    const modalComp: any = this.$refs.comp_itemSelect4row;
    modalComp.showModal4Item();
  }
  /**
   * 明細行の仕入先選択ボタン押下時のModal呼び出し
   */
  async showModalSupplier4row(): Promise<void> {
    this.evDatas4SupplierRow = {
      selectedItem: null,
      isSelected: false,
    };

    const modalComp: any = this.$refs.comp_supplierSelect4row;
    modalComp.showModal();
  }

  //----------------------
  //その他のメソッド
  //----------------------
  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    // if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }
}
