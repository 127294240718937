import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoPaymentGetReq {
    //検索条件
    public claimNo_start: number | null = null;
    public claimNo_end: number | null = null;
    public ClaimDate_start: Date | null = null;
    public ClaimDate_end: Date | null = null;
    public Clientid: number | null = null;
    public ClaimTitle: string | null = null;
    public ClaimSponsorName: string | null = null;
    public ClaimPayLimit_start: Date | null = null;
    public ClaimPayLimit_end: Date | null = null;
    public StaffId: number | null = null;
    public PaymentStatus: number | null = null;

    public IsValidImple(chkPager: boolean): string[] {
        let res: string[] = new Array();


        return res;
    }

}