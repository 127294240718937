
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_message } from "@/functions/Cl_message";
import { DtoMessagesGetReqImple } from "mediadept-common/src/common/dto/DtoMessagesGetReqImple";
import { DtoMessagesGetResImple } from "mediadept-common/src/common/dto/DtoMessagesGetResImple";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";

@Options({
  components: {
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class Messagelist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoMessagesGetResImple();
  //ボタン制御用
  btnDisable = false;
  //検索条件dom
  domDate: Date | null = null;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "開始日",
      field: "c_message_start",
      // type: "date",
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      // dateOutputFormat: "yyyy/MM/dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "終了日",
      field: "c_message_end",
      // type: "date",
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      // dateOutputFormat: "yyyy/MM/dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "メッセージ",
      field: "c_message_cont",
    },
    // {
    //   label: "担当者",
    //   field: "USER_NM",
    //   thClass: "text-center",
    //   tdClass: "text-center",
    // },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoMessagesGetReqImple();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;
      if (this.domDate != null) {
        dto.date = this.domDate.toString();
      }

      //API実行
      //-------
      this.delegate = await Cl_message.getList(dto);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoMessagesGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "BulletinBoardMaster";
      if (this.domDate != null) {
        dto.date = this.domDate!.toString();
      }

      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 日付のフォーマット調整
   * @param date
   */
  cnvDatetime2Date(date: string): string {
    return date.split("T")[0].replaceAll("-", "/");
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/messageedit?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
