import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoPaymentGetReq } from "mediadept-common/src/common/dto/DtoPaymentGetReq";
import { DtoPaymentGetRes } from "mediadept-common/src/common/dto/DtoPaymentGetRes";

export class Cl_payment {
  public static async getList(
    dto: DtoPaymentGetReq
  ): Promise<DtoPaymentGetRes> {
    return Object.assign(
      new DtoPaymentGetRes(),
      await CallAPI.getRequest("/payment?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
