import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoAttendancesGetReq } from "./abst/DtoAttendancesGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoAttendancesGetReqImple extends DtoAttendancesGetReq {
  public pager = new DtoApiPager();

  /**
   * 動作モード 対応する定数を入力
   * 自身の出勤状況取得　MINE
   * 全体の出勤状況取得　VIEW
   * 一覧取得　NORMAL
   * 詳細読み込み　NORMAL
   *
   */
  public getmode: string | null = null;

  //----------------------
  //NORMAL用
  //----------------------
  /**
   * NORMAL用　社員IDを指定
   */
  public id: number | null = null;
  /**
   * NORMAL用　月をyyyy-MMで指定
   */
  public mo: string | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
