
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_client } from "@/functions/Cl_client";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoClientsGetReqImple } from "mediadept-common/src/common/dto/DtoClientsGetReqImple";
import { DtoClientsGetResImple } from "mediadept-common/src/common/dto/DtoClientsGetResImple";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";
import { DtoFileMastersGetResImple } from "mediadept-common/src/common/dto/DtoFileMastersGetResImple";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";

@Options({
  components: {
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class Uriagesakilist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClientsGetResImple();
  //ボタン制御用
  btnDisable = false;
  //検索条件dom
  domIdStart = "";
  domIdEnd = "";
  domClientName = "";

  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "売上先コード",
      field: "c_client_id",
    },
    {
      label: "売上先名",
      field: "c_client_name",
    },
    {
      label: "ふりがな",
      field: "c_client_name_pt",
    },
    {
      label: "担当者名",
      field: "c_client_staff_name",
    },
    {
      label: "ふりがな",
      field: "c_client_staff_name_pt",
    },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-search");
    const panel2 = document.getElementById("panel-list");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClientsGetReqImple();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;
      if (this.domIdStart != "" && this.domIdStart != null)
        dto.clientId_start = Number(this.domIdStart);
      if (this.domIdEnd != "" && this.domIdEnd != null)
        dto.clientId_end = Number(this.domIdEnd);

      dto.clientName = this.domClientName;

      //API実行
      //-------
      this.delegate = await Cl_client.getList(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoClientsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");
    }
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/uriagesakiedit?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "UriagesakiList";
      dto.fromCode = Number(this.domIdStart);
      dto.toCode = Number(this.domIdEnd);
      dto.name = this.domClientName;

      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);
      Cl_common.logger(res);
      Cl_common.logger(res.url);

      //レスポンス処理
      //-------
      location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
