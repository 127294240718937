import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoDocsGetReqImple } from "mediadept-common/src/common/dto/DtoDocsGetReqImple";
import { DtoDocsGetResImple } from "mediadept-common/src/common/dto/DtoDocsGetResImple";
import { DtoDocsPostReqImple } from "mediadept-common/src/common/dto/DtoDocsPostReqImple";
import { DtoDocsPostResImple } from "mediadept-common/src/common/dto/DtoDocsPostResImple";
import { DtoDocsPutReqImple } from "mediadept-common/src/common/dto/DtoDocsPutReqImple";
import { DtoDocsPutResImple } from "mediadept-common/src/common/dto/DtoDocsPutResImple";
import { DtoDocsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoDocsDeleteReq";
import { DtoDocsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoDocsDeleteRes";

export class Cl_doc {
  public static async getList(
    dto: DtoDocsGetReqImple
  ): Promise<DtoDocsGetResImple> {
    return Object.assign(
      new DtoDocsGetResImple(),
      await CallAPI.getRequest(
        "/docs?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoDocsGetReqImple
  ): Promise<DtoDocsGetResImple> {
    return Object.assign(
      new DtoDocsGetResImple(),
      await CallAPI.getRequest("/docs/" + dto.c_doc_id)
    );
  }

  public static async post(
    dto: DtoDocsPostReqImple
  ): Promise<DtoDocsPostResImple> {
    return Object.assign(
      new DtoDocsPostResImple(),
      await CallAPI.postRequest("/docs", dto)
    );
  }

  public static async put(
    dto: DtoDocsPutReqImple
  ): Promise<DtoDocsPutResImple> {
    return Object.assign(
      new DtoDocsPutResImple(),
      await CallAPI.putRequest("/docs/" + dto.c_doc_id, dto)
    );
  }

  public static async del(dto: DtoDocsDeleteReq): Promise<DtoDocsDeleteRes> {
    return Object.assign(
      new DtoDocsDeleteRes(),
      await CallAPI.deleteRequest("/docs/" + dto.c_doc_id)
    );
  }
}
