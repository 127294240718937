export class EntitySupply4project {
public c_supply_id:number | null = null;
public c_project_id:number | null = null;
public c_variation_id:number | null = null;
public c_supply_rownum:number | null = null;
public c_claim_id:number | null = null;
public c_supply_claimnum_tmp:number | null = null;
public c_supply_month:Date | null = null;
public c_supply_item:string | null = null;
public c_supply_count:number | null = null;
public c_supply_date_start:Date | null = null;
public c_supply_date_end:Date | null = null;
public c_supplier_id:number | null = null;
public c_supply_price:number | null = null;
public c_supply_taxrate:number | null = null;
public c_supply_date_exp_payment:Date | null = null;
public c_supply_flag_paid:number | null = null;
public c_supply_remarks:string | null = null;
public c_supply_flag_lock:number | null = null;
public c_supply_flag_insurance:number | null = null;
public c_supply_flag_alert:number | null = null;
public c_supply_reg_user:string | null = null;
public c_supply_reg_at:Date | null = null;
public c_supply_upd_user:string | null = null;
public c_supply_upd_at:Date | null = null;
public c_claim_number:number | null = null;
public c_item_id:number | null = null;
public c_variation_size:string | null = null;
public c_variation_name:string | null = null;
public c_variation_manag_name:string | null = null;
public c_item_type:number | null = null;
public c_item_name:string | null = null;
public c_item_insurance:number | null = null;
public c_supplier_name:string | null = null;
public c_item_manag_name:string | null = null;
public c_pro2doc_id:number | null = null;
public c_supply_flag_chkclaim:number | null = null;
public c_supply_subject:number | null = null;
}