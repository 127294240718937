import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityRequestLoa } from "../../entity/EntityRequestLoa";

export class DtoRequestLoasGetReq extends EntityRequestLoa {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (
      !commonRegx.isMatch(
        this.c_request_id!.toString(),
        "^\\d+$",
        commonRegx._stdOption
      )
    ) {
      res.push("type error");
    }

    return res;
  }
}
