import { EntitySupply4project } from "./EntitySupply4project";
import { DtoMdDtp } from "../dto/DtoMdDtp";

export class EntitySupplyImple extends EntitySupply4project {
  //項目選択
  // public cnvItemType: number[] = [];

  //フロントでの日付変換用
  public cnvDateMonth: DtoMdDtp = new DtoMdDtp();
  public cnvDateStart: DtoMdDtp = new DtoMdDtp();
  public cnvDateEnd: DtoMdDtp = new DtoMdDtp();
  public cnvDateExpPayment: DtoMdDtp = new DtoMdDtp();

  //フロントでの金額変換用
  public cnvPrice: string = "";

  //フロントでの商品名用
  public cnvItemName: string = "";

  //フロントでの請求書番号 仕入
  public cnvClaimnumTmp: string = "";

  //行選択用
  public isSelect: boolean = false;

  //-------------------
  //追加情報
  public c_doc_id: number | null = null;
}
