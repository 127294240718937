
import { Options, Vue } from "vue-class-component";
import { Cl_notifications, toasterType } from "@/functions/Cl_notifications";

@Options({})
export default class Toaster extends Vue {
  async showNotification(): Promise<void> {
    Cl_notifications.showToastr(
      toasterType.success,
      "おめでとう、通知は表示された"
    );
  }
  async showNotification2(): Promise<void> {
    Cl_notifications.showToastr(
      toasterType.success,
      "おめでとう、通知は表示された"
    );
    Cl_notifications.showToastr(toasterType.error, "error");
    Cl_notifications.showToastr(toasterType.warning, "warning");
    Cl_notifications.showToastr(toasterType.info, "info");
  }
}
