import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoStaffsGetReq } from "./abst/DtoStaffsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoStaffsGetReqImple extends DtoStaffsGetReq {
  //社員マスタ一覧の検索条件
  public staffNo_start: string | null = null;
  public staffNo_end: string | null = null;
  public staffName: string | null = null;

  /**
   * 勤怠管理用の一覧を出すためのフラグ
   * 1で勤怠管理用の一覧
   */
  public isAttendant: number | null = null;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
