import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoItemImagePostReq } from "mediadept-common/src/common/dto/abst/DtoItemImagePostReq";
import { DtoItemImagePostRes } from "mediadept-common/src/common/dto/abst/DtoItemImagePostRes";
import { DtoItemImageDeleteReq } from "mediadept-common/src/common/dto/abst/DtoItemImageDeleteReq";
import { DtoItemImageDeleteRes } from "mediadept-common/src/common/dto/abst/DtoItemImageDeleteRes";

export class Cl_itemimage {
  public static async post(
    dto: DtoItemImagePostReq
  ): Promise<DtoItemImagePostRes> {
    return Object.assign(
      new DtoItemImagePostRes(),
      await CallAPI.postRequest("/itemimages", dto)
    );
  }

  public static async del(
    dto: DtoItemImageDeleteReq
  ): Promise<DtoItemImageDeleteRes> {
    return Object.assign(
      new DtoItemImageDeleteRes(),
      await CallAPI.deleteRequest(
        "/itemimages?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
