import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoHolidaysGetReq } from "./abst/DtoHolidaysGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoHolidaysGetReqImple extends DtoHolidaysGetReq {
  //休日管理の検索条件
  public date_start: Date | null = null;
  public date_end: Date | null = null;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
