
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import { ref } from "vue";
import Cleave from "vue-cleave-component";

@Options({
  components: { Cleave },
})
export default class Mt_timeTxtBox extends Vue {
  @Prop({ default: false }) nolimit: boolean;
  @Prop({ required: true }) contTime: { contTimeValue: string };
  @Prop({ default: false }) isDisable: boolean;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  // @Prop({ default: () => ({ nolimit: false, contTime: "" }) })
  // props!: {
  //   nolimit: boolean;
  //   contTime: string; // contTime を props で受け取る
  // };

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    // if (this.nolimit) {
    //   this.$emit("update:contTime", this.contTime);
    // }
  }

  set(dateData: string) {
    let res = dateData.replace(/:00$/, "");

    return res;
  }

  add(dateData: string) {
    console.log("addメソッド内");
    console.log(dateData);

    let res = dateData.replace(/^(-?\d+)(\d{2})/, "$1:$2");

    console.log("res");
    console.log(res);

    return res;
  }
}
