
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_goal } from "@/functions/Cl_goal";
import { DtoGoalsGetReqImple } from "mediadept-common/src/common/dto/DtoGoalsGetReqImple";
import { DtoGoalsGetResImple } from "mediadept-common/src/common/dto/DtoGoalsGetResImple";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class Salestargetlist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoGoalsGetResImple();
  domYear = { label: "" };
  //ボタン制御用
  btnDisable = false;

  optionsOp = [
    { label: "2016" },
    { label: "2017" },
    { label: "2018" },
    { label: "2019" },
    { label: "2020" },
    { label: "2021" },
    { label: "2022" },
    { label: "2023" },
    { label: "2024" },
    { label: "2025" },
    { label: "2026" },
    { label: "2027" },
    { label: "2028" },
    { label: "2029" },
    { label: "2030" },
    { label: "2031" },
    { label: "2032" },
    { label: "2033" },
    { label: "2034" },
    { label: "2035" },
    { label: "2036" },
    { label: "2037" },
    { label: "2038" },
    { label: "2039" },
    { label: "2040" },
    { label: "2041" },
    { label: "2042" },
    { label: "2043" },
    { label: "2044" },
    { label: "2045" },
    { label: "2046" },
    { label: "2047" },
    { label: "2048" },
    { label: "2049" },
  ];
  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "年度",
      field: "c_goal_year",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "総目標売上額",
      field: "c_goal_earnings_1",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "総目標粗利額",
      field: "c_goal_gross_1",
      thClass: "text-center",
      tdClass: "text-center",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoGoalsGetReqImple();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      if (
        this.domYear != null &&
        this.domYear.label != null &&
        this.domYear.label != ""
      ) {
        dto.year = Number(this.domYear.label);
      }

      //API実行
      //-------
      this.delegate = await Cl_goal.getList(dto);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoGoalsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "SalesTargetMaster";
      if (
        this.domYear != null &&
        this.domYear.label != null &&
        this.domYear.label != ""
      ) {
        dto.year = Number(this.domYear.label);
      }

      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/salestargetedit?id=" + id);
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }

  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  totalEarnings(props: any) {
    return (
      props.row.c_goal_earnings_1 +
      props.row.c_goal_earnings_2 +
      props.row.c_goal_earnings_3 +
      props.row.c_goal_earnings_4 +
      props.row.c_goal_earnings_5 +
      props.row.c_goal_earnings_6 +
      props.row.c_goal_earnings_7 +
      props.row.c_goal_earnings_8 +
      props.row.c_goal_earnings_9 +
      props.row.c_goal_earnings_10 +
      props.row.c_goal_earnings_11 +
      props.row.c_goal_earnings_12
    ).toLocaleString();
  }

  totalGross(props: any) {
    return (
      props.row.c_goal_gross_1 +
      props.row.c_goal_gross_2 +
      props.row.c_goal_gross_3 +
      props.row.c_goal_gross_4 +
      props.row.c_goal_gross_5 +
      props.row.c_goal_gross_6 +
      props.row.c_goal_gross_7 +
      props.row.c_goal_gross_8 +
      props.row.c_goal_gross_9 +
      props.row.c_goal_gross_10 +
      props.row.c_goal_gross_11 +
      props.row.c_goal_gross_12
    ).toLocaleString();
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
