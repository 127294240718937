import { DtoApiPager } from "./abst/DtoApiPager";

/**
 * 列挙体　処理Typeの列挙体
 */
export enum e_aggTypes {
  none_none,
  badge_0,
  summary_graph_100,
  summary_list_200,
  client_graph_300,
  client_genre_400,
  client_ranking_500,
  client_list_600,
  staff_list_1900,
  staff_badge_2000,
  staff_summary_graph_2100,
  staff_genre_graph_2200,
  staff_ranking_client_2300,
  staff_project_list_2400,
}

export class DtoAggregatesGetReq {
  // 処理タイプ
  //-------
  public aggType: e_aggTypes = e_aggTypes.none_none;
  //-------------------
  //全体用
  //-------------------
  /**
   * 全体対象の指定期間の開始月
   */
  public dateStrat: string | null = null;
  /**
   * 全体対象の指定期間の終了月
   */
  public dateEnd: string | null = null;

  //-------------------
  //顧客タブ用
  //-------------------
  //条件指定
  //-------
  /**
   * 売上先
   */
  public clients: string | null = null;
  /**
   * 業種
   */
  public genres: string | null = null;
  //-------------------
  //営業担当タブ用
  //-------------------
  //条件指定
  //-------
  /**
   * staffID
   */
  public staffid: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    //-------------------
    const regex = /^\d{4}-(0[1-9]|1[0-2])$/; // yyyy-MM形式の正規表現
    //日付の単体チェック
    if (this.dateStrat == null || !regex.test(this.dateStrat)) {
      res.push("日付が不正です");
    }
    if (this.dateEnd == null || !regex.test(this.dateEnd)) {
      res.push("日付が不正です");
    }
    //日付の相関チェック
    // Dateオブジェクトに変換
    const startDate = new Date(`${this.dateStrat}-01`); // yyyy-MM-01として作成
    const endDate = new Date(`${this.dateEnd}-01`);

    // startDateがendDateより前、または同じであることを確認
    if (startDate > endDate) {
      res.push("日付の範囲が不正です");
    }

    //11ヶ月以内制限
    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthDiff = endDate.getMonth() - startDate.getMonth();

    // 年の差と月の差を合算して、11か月以内かをチェックする
    const totalMonthsDiff = yearDiff * 12 + monthDiff;

    // 差が 11 か月以内かどうかを検証
    if (totalMonthsDiff >= 0 && totalMonthsDiff <= 11) {
      //
    } else {
      res.push("12ヵ月以内の日付を指定してください");
    }

    //-------------------
    if (this.aggType == e_aggTypes.none_none) {
      res.push("処理タイプが不正です");
    }

    return res;
  }
}
