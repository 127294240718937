import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoDatadiagnosisGetResImple {
  public pager = new DtoApiPager();
  public items: item[] = [];
}
export class item {
  public no: string | null = null;
  public title: string | null = null;
  public target: string | null = null;
  public links: link[] = [];
}
export class link {
  public url: string | null = null;
}