import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoConditionsGetReqImple } from "mediadept-common/src/common/dto/DtoConditionsGetReqImple";
import { DtoConditionsGetResImple } from "mediadept-common/src/common/dto/DtoConditionsGetResImple";
import { DtoConditionsPostReqImple } from "mediadept-common/src/common/dto/DtoConditionsPostReqImple";
import { DtoConditionsPostResImple } from "mediadept-common/src/common/dto/DtoConditionsPostResImple";
import { DtoConditionsPutReq } from "mediadept-common/src/common/dto/abst/DtoConditionsPutReq";
import { DtoConditionsPutRes } from "mediadept-common/src/common/dto/abst/DtoConditionsPutRes";
import { DtoConditionsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoConditionsDeleteReq";
import { DtoConditionsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoConditionsDeleteRes";

export class Cl_condition {
  public static async getList(
    dto: DtoConditionsGetReqImple
  ): Promise<DtoConditionsGetResImple> {
    return Object.assign(
      new DtoConditionsGetResImple(),
      await CallAPI.getRequest(
        "/conditions?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoConditionsGetReqImple
  ): Promise<DtoConditionsGetResImple> {
    return Object.assign(
      new DtoConditionsGetResImple(),
      await CallAPI.getRequest("/conditions/" + dto.c_condition_id)
    );
  }

  public static async post(
    dto: DtoConditionsPostReqImple
  ): Promise<DtoConditionsPostResImple> {
    return Object.assign(
      new DtoConditionsPostResImple(),
      await CallAPI.postRequest("/conditions", dto)
    );
  }

  public static async put(
    dto: DtoConditionsPutReq
  ): Promise<DtoConditionsPutRes> {
    return Object.assign(
      new DtoConditionsPutRes(),
      await CallAPI.putRequest("/conditions/" + dto.c_condition_id, dto)
    );
  }

  public async del(
    dto: DtoConditionsDeleteReq
  ): Promise<DtoConditionsDeleteRes> {
    return Object.assign(
      new DtoConditionsDeleteRes(),
      await CallAPI.deleteRequest(
        "/conditions?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
