export class EntityClaimImple {
    public c_claim_id: number | null = null;
    public c_claim_number: number | null = null;
    public c_claim_datetime: Date | null = null;
    public c_client_id: number | null = null;
    public c_claim_sponsor_name: string | null = null;
    public c_claim_title_1: string | null = null;
    public c_claim_subtotal: number | null = null;
    public c_claim_total: number | null = null;
    public c_claim_flag_sales:number | null = null;
    public c_staff_id: number | null = null;
    // public c_sales_month: Date | null = null;
    public c_project_number: number | null = null;
    public c_sales_total_mismatch: number | null = null;

}