import { DtoRequestEntmtsPostReq } from "./abst/DtoRequestEntmtsPostReq";
import { EntityRequest } from "../entity/EntityRequest";

export class DtoRequestEntmtsPostReqImple extends DtoRequestEntmtsPostReq {
  public request = new EntityRequest();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック

    return res;
  }
}
