export class EntityClient {
public c_client_id:number | null = null;
public c_client_name:string | null = null;
public c_client_name_en:string | null = null;
public c_client_name_pt:string | null = null;
public c_client_company_type:number | null = null;
public c_client_flag_company_signage_posi:number | null = null;
public c_client_tel:string | null = null;
public c_client_fax:string | null = null;
public c_client_zip:string | null = null;
public c_client_address_1:string | null = null;
public c_client_address_2:string | null = null;
public c_client_flag_claim_addon:number | null = null;
public c_client_claim_zip:string | null = null;
public c_client_claim_address_1:string | null = null;
public c_client_claim_address_2:string | null = null;
public c_client_paysite:number | null = null;
public c_client_staff_name:string | null = null;
public c_client_staff_name_pt:string | null = null;
public c_client_depart:string | null = null;
public c_client_post:string | null = null;
public c_client_flag_post_signag_posi:number | null = null;
public c_client_remarks:string | null = null;
public c_client_flag_del:number | null = null;
public c_client_show_order:number | null = null;
public c_client_claim_language:number | null = null;
public c_client_genre:number | null = null;
public c_client_flag_type_ad:number | null = null;
public c_client_flag_type_mfg:number | null = null;
public c_client_flag_type_print:number | null = null;
public c_client_flag_type_event:number | null = null;
public c_client_flag_type_comm:number | null = null;
public c_client_flag_type_fashion:number | null = null;
public c_client_flag_type_beauty:number | null = null;
public c_client_flag_type_cosmetic:number | null = null;
public c_client_flag_type_device:number | null = null;
public c_client_flag_type_appliance:number | null = null;
public c_client_flag_type_caretc:number | null = null;
public c_client_flag_type_music:number | null = null;
public c_client_flag_type_sport:number | null = null;
public c_client_flag_type_trip:number | null = null;
public c_client_flag_type_game:number | null = null;
public c_client_flag_type_book:number | null = null;
public c_client_flag_type_movie:number | null = null;
public c_client_flag_type_food:number | null = null;
public c_client_flag_type_drink:number | null = null;
public c_client_flag_type_eatout:number | null = null;
public c_client_flag_type_transpt:number | null = null;
public c_client_flag_type_edu:number | null = null;
public c_client_flag_type_medical:number | null = null;
public c_client_flag_type_stock:number | null = null;
public c_client_flag_type_realty:number | null = null;
public c_client_flag_type_insure:number | null = null;
public c_client_flag_type_cfpa:number | null = null;
public c_client_flag_type_govtofc:number | null = null;
public c_client_flag_type_politics:number | null = null;
public c_client_flag_type_matching:number | null = null;
public c_client_flag_type_gamble:number | null = null;
public c_client_flag_type_others:number | null = null;
public c_client_reg_user:string | null = null;
public c_client_reg_at:Date | null = null;
public c_client_upd_user:string | null = null;
public c_client_upd_at:Date | null = null;
}