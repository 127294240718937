import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoVariationsGetReqImple } from "mediadept-common/src/common/dto/DtoVariationsGetReqImple";
import { DtoVariationsGetResImple } from "mediadept-common/src/common/dto/DtoVariationsGetResImple";
import { DtoVariationsPostReqImple } from "mediadept-common/src/common/dto/DtoVariationsPostReqImple";
import { DtoVariationsPostResImple } from "mediadept-common/src/common/dto/DtoVariationsPostResImple";
import { DtoVariationsPutReq } from "mediadept-common/src/common/dto/abst/DtoVariationsPutReq";
import { DtoVariationsPutRes } from "mediadept-common/src/common/dto/abst/DtoVariationsPutRes";
import { DtoVariationsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoVariationsDeleteReq";
import { DtoVariationsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoVariationsDeleteRes";

export class Cl_variation {
  public static async getList(
    dto: DtoVariationsGetReqImple
  ): Promise<DtoVariationsGetResImple> {
    return Object.assign(
      new DtoVariationsGetResImple(),
      await CallAPI.getRequest(
        "/variations?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoVariationsGetReqImple
  ): Promise<DtoVariationsGetResImple> {
    return Object.assign(
      new DtoVariationsGetResImple(),
      await CallAPI.getRequest("/variations/" + dto.c_variation_id)
    );
  }

  public static async post(
    dto: DtoVariationsPostReqImple
  ): Promise<DtoVariationsPostResImple> {
    return Object.assign(
      new DtoVariationsPostResImple(),
      await CallAPI.postRequest("/variations", dto)
    );
  }

  public static async put(
    dto: DtoVariationsPutReq
  ): Promise<DtoVariationsPutRes> {
    return Object.assign(
      new DtoVariationsPutRes(),
      await CallAPI.putRequest("/variations/" + dto.c_variation_id, dto)
    );
  }

  public async del(
    dto: DtoVariationsDeleteReq
  ): Promise<DtoVariationsDeleteRes> {
    return Object.assign(
      new DtoVariationsDeleteRes(),
      await CallAPI.deleteRequest(
        "/variations?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
