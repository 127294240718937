export class EntityRequestExpenses {
public c_request_expenses_id:number | null = null;
public c_request_id:number | null = null;
public c_request_expenses_date_draft:Date | null = null;
public c_request_expenses_type:number | null = null;
public c_request_expenses_tmppay_price:number | null = null;
public c_request_expenses_total:number | null = null;
public c_request_expenses_list:string | null = null;
public c_request_expenses_reg_user:string | null = null;
public c_request_expenses_reg_at:Date | null = null;
public c_request_expenses_upd_user:string | null = null;
public c_request_expenses_upd_at:Date | null = null;
}