import { EntitySalesImple } from "../entity/EntitySalesImple";
import { EntitySupplyImple } from "../entity/EntitySupplyImple";
import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { commonDate } from "xcommon-node/src/common/commonDate";

import { CommonDate4MD } from "../function/CommonDate4MD";
import { DtoItemsGetResImple } from "../dto/DtoItemsGetResImple";
import { EntityVariationImple } from "../entity/EntityVariationImple";

/**
 * 受注・売上・仕入の共通処理を担当する
 */
export class CommonProject {
  /**
   * 条件を受け取り、売上の一括入力用データを返す
   * billingType=1:一括;2:分割;
   * term=:長期;2:短期;
   */
  public static async creBulk4Sales(
    idMax: number,
    itemId: number,
    variationId: number,
    itemVariName: string,
    billingType: number,
    term: number,
    type: number,
    count: number,
    dateStart: Date,
    dateEnd: Date,
    priceSales: number,
    idMaxSupply: number,
    priceSupplys: number,
    evDatas4row: {
      selectVariation: EntityVariationImple;
      data: DtoItemsGetResImple;
      isSelected: boolean;
      detStartDate: string;
      detCount: number;
    }
  ): Promise<{ sales: EntitySalesImple[]; supplys: EntitySupplyImple[] }> {
    //準備
    //--------------------

    let resSupplys: EntitySupplyImple[] = [];
    let res: EntitySalesImple[] = [];
    let tmp: EntitySalesImple;
    let tmpDate = new Date(dateStart.getTime());
    let sumPrice = 0;
    let tmpPrice = 0;
    let sumPriceSupply = 0;
    let tmpPriceSupply = 0;

    // 1．契約月数を取得
    //--------------------
    let months = CommonProject.monthsBetween(dateStart, dateEnd);
    let pureMonths = months;
    //補正　1日開始でない場合+1ヵ月とする(1行追加する)
    if (dateStart.getDate() != 1) {
      months = months + 1;
    }

    // console.log("pureMonths");
    // console.log(pureMonths);
    // console.log("months");
    // console.log(months);

    // 2．契約月をループして以下の手順で金額を計算
    //--------------------
    for (let i = 0; i < months; i++) {
      tmp = new EntitySalesImple();
      tmpPrice = 0;
      //初月以外は1月づつ月をプラスしていく
      if (i != 0) {
        tmpDate.setMonth(tmpDate.getMonth() + 1);
      }

      //金額計算
      //------
      if (i == 0) {
        //初月
        //---
        if (dateStart.getDate() == 1) {
          //初月が1日から始まっている場合
          //ROUND(契約金額/月数,-3)
          tmpPrice = Math.round(priceSales / pureMonths / 1000) * 1000;
          tmpPriceSupply = Math.round(priceSupplys / pureMonths / 1000) * 1000;
        } else {
          //初月が1日から始まっていない場合
          //ROUND(契約金額/月数/30*（30-日）,-3)
          tmpPrice =
            Math.round(
              ((priceSales / pureMonths / 30) * (30 - dateEnd.getDate())) / 1000
            ) * 1000;
          tmpPriceSupply =
            Math.round(
              ((priceSupplys / pureMonths / 30) * (30 - dateEnd.getDate())) /
                1000
            ) * 1000;
        }
      } else if (i == months - 1) {
        //最終月
        //---
        //契約金額 - SUM(初月からの金額合計)
        tmpPrice = priceSales - sumPrice;
        tmpPriceSupply = priceSupplys - sumPriceSupply;
      } else {
        //初月、最終月以外
        //---
        //ROUND(契約金額/月数,-3)
        tmpPrice = Math.round(priceSales / pureMonths / 1000) * 1000;
        tmpPriceSupply = Math.round(priceSupplys / pureMonths / 1000) * 1000;
      }
      sumPrice += tmpPrice;
      sumPriceSupply += tmpPriceSupply;
      //---------------------------------------
      //入力
      //------
      //発行日
      if (billingType == 1) {
        // 1:一括
        tmp.cnvDateIssue.date = commonDate
          .cnvUTC2JST(new Date())
          .toISOString()
          .split("T")[0];
      } else {
        // ;2:分割;
        tmp.cnvDateIssue.date = new Date(tmpDate.setDate(1))
          .toISOString()
          .split("T")[0];
      }
      //売上月
      tmp.cnvDateMonth.date = commonRegx.maches4GroupName(
        tmpDate.toISOString(),
        "(?<gn>^\\d+[-]\\d+)[-]",
        commonRegx._stdOption
      );

      //id
      tmp.c_sales_id = idMax;
      idMax++;
      //長期・短期
      tmp.c_sales_term = term;
      //商品・バリエーション
      tmp.cnvItemName = itemVariName;
      tmp.c_item_id = itemId;
      tmp.c_variation_id = variationId;
      //項目選択
      tmp.c_sales_item = type.toString();
      //数量
      tmp.c_sales_count = count;
      //期間開始
      // console.log("dateStart");
      // console.log(dateStart);
      // console.log(dateStart.toISOString());
      tmp.cnvDateStart.date = dateStart.toISOString().split("T")[0];
      //期間終了
      tmp.cnvDateEnd.date = dateEnd.toISOString().split("T")[0];
      //金額
      tmp.cnvPrice = tmpPrice.toString();
      //消費税
      tmp.c_sales_taxrate = 3;
      //アラート
      tmp.c_sales_flag_alert = 0;
      //ロック
      tmp.c_sales_flag_lock = 0;

      //売上行入力
      res.push(tmp);

      //仕入行入力
      await this.setItemSupply4init(
        resSupplys,
        evDatas4row,
        tmp.cnvDateMonth.date,
        tmpPriceSupply
      );
    }

    //仕入にc_supply_idを設定
    for (let index = 0; index < resSupplys.length; index++) {
      idMaxSupply++;
      resSupplys[index].c_supply_id = idMaxSupply;
    }

    // return res;
    return { sales: res, supplys: resSupplys };
  }

  /**
   * 入力された商品情報を元に、仕入情報に展開する
   * priceSupplyは、対象Conditionの合計値とて入力する金額を入力する。
   */
  public static async setItemSupply4init(
    supplys: EntitySupplyImple[],
    evDatas4row: {
      selectVariation: EntityVariationImple;
      data: DtoItemsGetResImple;
      isSelected: boolean;
      detStartDate: string;
      detCount: number;
    },
    dateMonth: string | null,
    priceSupply: number | null
  ) {
    //-------------------------
    //一括処理用
    //-------------------------
    let percentages: number[] = [];
    let sumPrice = 0;
    let sumPriceTmp = 0;
    if (priceSupply != null) {
      //１．仕入条件に設定されている金額の合計を算出
      //-------------
      let totalAmount = evDatas4row.selectVariation.conditions.reduce(
        (sum, item) => sum + (item.c_condition_price || 0),
        0
      );

      // ２．各金額の割合を計算
      percentages = evDatas4row.selectVariation.conditions.map(
        (amount) =>
          Math.round(
            ((amount.c_condition_price || 0) / totalAmount) * 100 * 100
          ) / 100
      );

      // console.log("totalAmount");
      // console.log(totalAmount);
      // console.log("percentages");
      // console.log(percentages);
    }

    //-------------------------
    //4:売上月:期間終了の日付の所属する年月。開始日と期間から導出。
    const salseDate = await CommonDate4MD.offsetDate4VariationTerm(
      new Date(evDatas4row.detStartDate),
      evDatas4row.selectVariation
    );

    let dateTmp = "";

    if (salseDate != null) {
      dateTmp = salseDate
        .toLocaleString("ja-JP", { year: "numeric", month: "2-digit" })
        .split("/")
        .join("-");
      // .replaceAll("/", "-");
    }

    const conditionsTmp = evDatas4row.selectVariation.conditions;
    let supplyTmp = new EntitySupplyImple();

    for (let index = 0; index < conditionsTmp.length; index++) {
      supplyTmp = new EntitySupplyImple();

      //15:行No:自動入力
      //16:請求No:空白

      //17:計上月:期間終了の日付の所属する年月。開始日と期間から導出。
      if (dateMonth == null) {
        //通常入力
        if (dateTmp != "") {
          supplyTmp.cnvDateMonth.date = dateTmp;
        }
      } else {
        //一括入力
        supplyTmp.cnvDateMonth.date = dateMonth;
      }

      //18:商品選択:売上明細初期値連動
      supplyTmp.c_variation_id = evDatas4row.selectVariation.c_variation_id;
      supplyTmp.c_item_id = evDatas4row.selectVariation.c_item_id;
      supplyTmp.c_variation_name = evDatas4row.selectVariation.c_variation_name;
      supplyTmp.c_item_type = evDatas4row.data.c_item_type;
      supplyTmp.c_item_name = evDatas4row.data.c_item_name;
      supplyTmp.cnvItemName =
        evDatas4row.data.c_item_name +
        " " +
        evDatas4row.selectVariation.c_variation_name;

      //19:項目選択:売上明細入力時に選択した商品
      if (
        conditionsTmp[index].c_condition_type != null &&
        conditionsTmp[index].c_condition_type != ""
      ) {
        // supplyTmp.cnvItemType = await this.cnvStrArray2NumArray(
        //   conditionsTmp[index].c_condition_type!.split(",")
        // );
        supplyTmp.c_supply_item = conditionsTmp[index].c_condition_type!;
      }

      //X:数量:バリエーション選択時に入力した数量を記載
      supplyTmp.c_supply_count = evDatas4row.detCount;

      //20:期間開始:売上明細初期値連動
      supplyTmp.cnvDateStart.date = evDatas4row.detStartDate;

      //21:期間終了:売上明細初期値連動
      if (salseDate != null) {
        supplyTmp.cnvDateEnd.date = salseDate
          .toLocaleString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .join("-");
      } else {
        supplyTmp.cnvDateEnd.date = "";
      }

      //22:仕入先:商品マスタに設定されている仕入先の値
      supplyTmp.c_supplier_id = conditionsTmp[index].c_supplier_id;

      //23:金額:商品マスタに設定されている仕入金額(税抜)の値
      if (priceSupply == null) {
        //通常入力
        supplyTmp.cnvPrice = conditionsTmp[index].c_condition_price!.toString();
      } else {
        //一括入力
        // supplyTmp.cnvPrice = priceSupply.toString();

        if (index === conditionsTmp.length - 1) {
          //最終行
          supplyTmp.cnvPrice = (priceSupply - sumPrice).toString();
        } else {
          //最終行以外
          sumPriceTmp =
            Math.round((priceSupply * (percentages[index] / 100)) / 1000) *
            1000; //算出
          supplyTmp.cnvPrice = sumPriceTmp.toString(); //入力
          sumPrice += sumPriceTmp; //合計加算
        }
      }

      //24:消費税:0.1
      supplyTmp.c_supply_taxrate = 3;

      //25:支払予定日:設定された仕入先の支払いサイトを元に、計上月から算出
      let paydate: Date | null = null;
      if (salseDate != null) {
        paydate = await CommonDate4MD.offsetDate4PaySite(
          salseDate,
          conditionsTmp[index].supplierPaysite
        );
      }

      if (paydate != null) {
        const cnvDateExpPaymentTmp = paydate
          .toLocaleString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .join("-");

        supplyTmp.cnvDateExpPayment.date = cnvDateExpPaymentTmp;
      }

      //26:支払確定:未選択
      supplyTmp.c_supply_flag_paid = 0;

      //27:備考:空白

      supplys.push(supplyTmp);
    }
  }

  /**
   * 条件を受け取り、仕入の一括入力用データを返す
   */
  public static async creBulk4Supply(
    idMax: number,
    itemId: number,
    variationId: number,
    itemVariName: string,
    type: number,
    count: number,
    dateStart: Date,
    dateEnd: Date,
    priceSales: number,
    supplierId: number,
    flagInsurance: number
  ): Promise<EntitySupplyImple[]> {
    //準備
    //--------------------
    let res: EntitySupplyImple[] = [];
    let tmp: EntitySupplyImple;
    let tmpDate = new Date(dateStart.getTime());
    let sumPrice = 0;
    let tmpPrice = 0;

    // 1．契約月数を取得
    //--------------------
    let months = CommonProject.monthsBetween(dateStart, dateEnd);
    let pureMonths = months;
    //補正　1日開始でない場合+1ヵ月とする(1行追加する)
    if (dateStart.getDate() != 1) {
      months = months + 1;
    }

    // console.log("pureMonths");
    // console.log(pureMonths);
    // console.log("months");
    // console.log(months);

    // 2．契約月をループして以下の手順で金額を計算
    //--------------------
    for (let i = 0; i < months; i++) {
      tmp = new EntitySupplyImple();
      tmpPrice = 0;
      //初月以外は1月づつ月をプラスしていく
      if (i != 0) {
        tmpDate.setMonth(tmpDate.getMonth() + 1);
      }

      //金額計算
      //------
      if (i == 0) {
        //初月
        //---
        if (dateStart.getDate() == 1) {
          //初月が1日から始まっている場合
          //ROUND(契約金額/月数,-3)
          tmpPrice = Math.round(priceSales / pureMonths / 1000) * 1000;
        } else {
          //初月が1日から始まっていない場合
          //ROUND(契約金額/月数/30*（30-日）,-3)
          tmpPrice =
            Math.round(
              ((priceSales / pureMonths / 30) * (30 - dateEnd.getDate())) / 1000
            ) * 1000;
        }
      } else if (i == months - 1) {
        //最終月
        //---
        //契約金額 - SUM(初月からの金額合計)
        tmpPrice = priceSales - sumPrice;
      } else {
        //初月、最終月以外
        //---
        //ROUND(契約金額/月数,-3)
        tmpPrice = Math.round(priceSales / pureMonths / 1000) * 1000;
      }
      sumPrice += tmpPrice;
      //入力
      //------
      //計上月
      tmp.cnvDateMonth.date = commonRegx.maches4GroupName(
        tmpDate.toISOString(),
        "(?<gn>^\\d+[-]\\d+)[-]",
        commonRegx._stdOption
      );

      //id
      tmp.c_supply_id = idMax;
      idMax++;
      //商品・バリエーション
      tmp.cnvItemName = itemVariName;
      tmp.c_item_id = itemId;
      tmp.c_variation_id = variationId;
      //項目選択
      tmp.c_supply_item = type.toString();
      //数量
      tmp.c_supply_count = count;
      //期間開始
      // console.log("dateStart");
      // console.log(dateStart);
      // console.log(dateStart.toISOString());
      tmp.cnvDateStart.date = dateStart.toISOString().split("T")[0];
      //期間終了
      tmp.cnvDateEnd.date = dateEnd.toISOString().split("T")[0];
      //金額
      tmp.cnvPrice = tmpPrice.toString();
      //消費税
      tmp.c_supply_taxrate = 3;
      //アラート
      tmp.c_supply_flag_alert = 0;
      //ロック
      tmp.c_supply_flag_lock = 0;
      //仕入先ID
      tmp.c_supplier_id = supplierId;
      //保険フラグ
      tmp.c_supply_flag_insurance = flagInsurance;
      //支払済みフラグ
      tmp.c_supply_flag_paid = 0;

      res.push(tmp);
    }

    return res;
  }

  //-------------------------------
  /**
   * 2つの日付を受け取り、日付の年月を元に
   * 月数を返す。
   * @param date1
   * @param date2
   * @returns
   */
  public static monthsBetween(date1: Date, date2: Date): number {
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const day1 = date1.getDate();
    const day2 = date2.getDate();

    let monthCount = (year2 - year1) * 12 + month2 - month1;

    // date1の日がdate2の日より大きい場合は、monthCountから1を引く
    if (day1 > day2) {
      monthCount--;
    }

    return monthCount + 1; // 2つの日付を含めるために1を足す
  }
}
