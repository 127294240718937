import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityBank } from "../../entity/EntityBank";

export class DtoBanksGetReq extends EntityBank {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_bank_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
