/**
 * 基本的にDTOクラスは、APIか共通リポジトリにあるので注意。
 */

export class DtoItemsGetRes {
  //ページャー用パラメータ 通常はExtendsされる
  /**
   * 取得数。ページ内の件数。（リクエストの値をそのまま返す）
   */
  public count: number | null = null;
  /**
   * 相対位置。何ページ目を取得するか。1から始まる。（リクエストの値をそのまま返す）
   */
  public cursor: number | null = null;
  /**
   * 全件数。対象となるアイテム数
   * レスポンス時のみに使用。
   */
  public maxcount: number | null = null;
  /**
   * 絶対位置。基本的には、対象となるテーブルのIdを指定する。
   * ※リクエスト時に指定された場合はそのまま返す。
   * ※複数のテーブルを使用する場合はカンマで区切って返す。
   * */
  public maxid: number | null = null;

  //複数用
  public items: item[] | null = null;
}
export class item {
  //series
  public c_series_code: number | null = null;
  public c_series_name: string | null = null;
  public c_series_name_pt: string | null = null;
  public c_series_publisher: string | null = null;
  public c_series_publisher2: string | null = null;
  public c_series_flag_end: number | null = null;
  public c_series_flag_preend: number | null = null;
  public c_series_count_mylist: number | null = null;
  public c_series_max_date_release: Date | null = null;
  public c_series_flag_kokai_series: number | null = null;
  public c_series_publication_magazine: string | null = null;
  public c_series_publication_magazine_pt: string | null = null;
  public c_series_flag_del: number | null = null;
  public c_series_reg_at: Date | null = null;
  public c_series_reg_user: string | null = null;
  public c_series_upd_at: Date | null = null;
  public c_series_upd_user: string | null = null;

  //title最新刊分単体
  public c_title_code: number | null = null;
  // public c_series_code: number | null = null;
  public c_title_date_release: Date | null = null;
  public c_title_name: string | null = null;
  public c_title_name_pt: string | null = null;
  public c_title_volume: number | null = null;
  public c_title_exp_price: number | null = null;
  public c_title_price: number | null = null;
  public c_title_isbn: string | null = null;
  public c_title_asin: string | null = null;
  public c_title_discription: string | null = null;
  public c_title_url_img: string | null = null;
  public c_title_flag_kokai_series: number | null = null;
  public c_title_flag_del: number | null = null;
  public c_title_reg_at: Date | null = null;
  public c_title_reg_user: string | null = null;
  public c_title_upd_at: Date | null = null;
  public c_title_upd_user: string | null = null;

  //著者
  public authors: author[] | null = null;
}

export class author {
  public c_author_code: number | null = null;
  public c_author_name: string | null = null;
  public c_author_name_pt: string | null = null;
  public c_author_flag_del: number | null = null;
  public c_author_reg_at: Date | null = null;
  public c_author_reg_user: string | null = null;
  public c_author_upd_at: Date | null = null;
  public c_author_upd_user: string | null = null;
}
