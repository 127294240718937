import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityRequestTmppay } from "../../entity/EntityRequestTmppay";

export class DtoRequestTmppaysPostReq extends EntityRequestTmppay {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
