export class EntityDoc {
public c_doc_id:number | null = null;
public c_staff_id:number | null = null;
public c_client_id:number | null = null;
public c_supplier_id:number | null = null;
public c_claim_id:number | null = null;
public c_request_expenses_id:number | null = null;
public c_doc_name:string | null = null;
public c_doc_managtype:number | null = null;
public c_doc_type:number | null = null;
public c_doc_price:number | null = null;
public c_doc_date:Date | null = null;
public c_doc_customer:string | null = null;
public c_doc_customerstaff:string | null = null;
public c_staff_id4project:number | null = null;
public c_doc_remarks:string | null = null;
public c_doc_flag_issuer:number | null = null;
public c_doc_search:string | null = null;
public c_doc_flag_enab:number | null = null;
public c_doc_flag_reserve:number | null = null;
public c_doc_flag_del:number | null = null;
public c_doc_reg_user:string | null = null;
public c_doc_reg_at:Date | null = null;
public c_doc_upd_user:string | null = null;
public c_doc_upd_at:Date | null = null;
}