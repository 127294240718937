import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityRequestAddchg } from "../../entity/EntityRequestAddchg";

export class DtoRequestAddchgsPostReq extends EntityRequestAddchg {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
