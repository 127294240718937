
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoFileReportsGetResImple } from "mediadept-common/src/common/dto/DtoFileReportsGetResImple";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class Getujikanricreate extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;
  domCover = false;
  domYear = { label: "" };
  domMonth = { label: "" };

  optionsOp = [
    // { label: "2016" },
    // { label: "2017" },
    // { label: "2018" },
    // { label: "2019" },
    { label: "2020" },
    { label: "2021" },
    { label: "2022" },
    { label: "2023" },
    { label: "2024" },
    { label: "2025" },
    { label: "2026" },
    { label: "2027" },
    { label: "2028" },
    { label: "2029" },
    { label: "2030" },
    { label: "2031" },
    { label: "2032" },
    { label: "2033" },
    { label: "2034" },
    { label: "2035" },
    { label: "2036" },
    { label: "2037" },
    { label: "2038" },
    { label: "2039" },
    { label: "2040" },
    { label: "2041" },
    { label: "2042" },
    { label: "2043" },
    { label: "2044" },
    { label: "2045" },
    { label: "2046" },
    { label: "2047" },
    { label: "2048" },
    { label: "2049" },
  ];
  optionsOp2 = [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  //async mounted() {}

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    if (null == this.domYear || 0 == this.domYear.label.length) {
      alert("年度が未入力です。");
      return;
    }

    if (null == this.domMonth || 0 == this.domMonth.label.length) {
      alert("発行月が未入力です。");
      return;
    }

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "GetujiHokokuKanriList";
      dto.cover = this.domCover;
      dto.year = Number(this.domYear.label);
      dto.month = Number(this.domMonth.label);

      Cl_common.logger("this.domCover");
      Cl_common.logger(this.domCover);

      if (this.domCover) {
        dto.coverN = 1;
      } else {
        dto.coverN = 0;
      }

      Cl_common.logger(dto);

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗1");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗2");
      }
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
