import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoBanksGetReqImple } from "mediadept-common/src/common/dto/DtoBanksGetReqImple";
import { DtoBanksGetResImple } from "mediadept-common/src/common/dto/DtoBanksGetResImple";
import { DtoBanksPostReq } from "mediadept-common/src/common/dto/abst/DtoBanksPostReq";
import { DtoBanksPostRes } from "mediadept-common/src/common/dto/abst/DtoBanksPostRes";
import { DtoBanksPutReq } from "mediadept-common/src/common/dto/abst/DtoBanksPutReq";
import { DtoBanksPutRes } from "mediadept-common/src/common/dto/abst/DtoBanksPutRes";
import { DtoBanksDeleteReq } from "mediadept-common/src/common/dto/abst/DtoBanksDeleteReq";
import { DtoBanksDeleteRes } from "mediadept-common/src/common/dto/abst/DtoBanksDeleteRes";

export class Cl_bank {
  public static async getList(
    dto: DtoBanksGetReqImple
  ): Promise<DtoBanksGetResImple> {
    return Object.assign(
      new DtoBanksGetResImple(),
      await CallAPI.getRequest(
        "/banks?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoBanksGetReqImple
  ): Promise<DtoBanksGetResImple> {
    return Object.assign(
      new DtoBanksGetResImple(),
      await CallAPI.getRequest("/banks/" + dto.c_bank_id)
    );
  }

  public static async post(dto: DtoBanksPostReq): Promise<DtoBanksPostRes> {
    return Object.assign(
      new DtoBanksPostRes(),
      await CallAPI.postRequest("/banks", dto)
    );
  }

  public static async put(dto: DtoBanksPutReq): Promise<DtoBanksPutRes> {
    return Object.assign(
      new DtoBanksPutRes(),
      await CallAPI.putRequest("/banks/" + dto.c_bank_id, dto)
    );
  }

  public async del(dto: DtoBanksDeleteReq): Promise<DtoBanksDeleteRes> {
    return Object.assign(
      new DtoBanksDeleteRes(),
      await CallAPI.deleteRequest("/banks?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
