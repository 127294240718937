import { EntityDocfileImple } from "../entity/EntityDocfileImple";
import { EntityRequest } from "../entity/EntityRequest";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoRequestExpensessGetRes } from "./abst/DtoRequestExpensessGetRes";

export class DtoRequestExpensessGetResImple extends DtoRequestExpensessGetRes {
  public request = new EntityRequest();
  public docfile = new EntityDocfileImple();

  public pager = new DtoApiPager();
}
