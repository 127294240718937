import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoDatadiagnosisGetReqImple } from "mediadept-common/src/common/dto/DtoDatadiagnosisGetReqImple";
import { DtoDatadiagnosisGetResImple } from "mediadept-common/src/common/dto/DtoDatadiagnosisGetResImple";
// import { DtoDiagnosisPostReq } from "mediadept-common/src/common/dto/abst/DtoDiagnosisPostReq";
// import { DtoDiagnosisPostRes } from "mediadept-common/src/common/dto/abst/DtoDiagnosisPostRes";
// import { DtoDiagnosisPutReq } from "mediadept-common/src/common/dto/abst/DtoDiagnosisPutReq";
// import { DtoDiagnosisPutRes } from "mediadept-common/src/common/dto/abst/DtoDiagnosisPutRes";
// import { DtoDiagnosisDeleteReq } from "mediadept-common/src/common/dto/abst/DtoDiagnosisDeleteReq";
// import { DtoDiagnosisDeleteRes } from "mediadept-common/src/common/dto/abst/DtoDiagnosisDeleteRes";

export class Cl_diagnosis {
  public static async getList(
    dto: DtoDatadiagnosisGetReqImple
  ): Promise<DtoDatadiagnosisGetResImple> {
    return Object.assign(
      new DtoDatadiagnosisGetResImple(),
      await CallAPI.getRequest(
        "/datadiagnosis?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  // public static async get(
  //   dto: DtoDatadiagnosisGetReqImple
  // ): Promise<DtoDatadiagnosisGetResImple> {
  //   return Object.assign(
  //     new DtoDatadiagnosisGetResImple(),
  //     await CallAPI.getRequest("/datadiagnosis/" + dto.c_claim_id)
  //   );
  // }

  // public static async post(dto: DtoDiagnosisPostReq): Promise<DtoDiagnosisPostRes> {
  //   return Object.assign(
  //     new DtoDiagnosisPostRes(),
  //     await CallAPI.postRequest("/datadiagnosis", dto)
  //   );
  // }

  // public static async put(dto: DtoDiagnosisPutReq): Promise<DtoDiagnosisPutRes> {
  //   return Object.assign(
  //     new DtoDiagnosisPutRes(),
  //     await CallAPI.putRequest("/datadiagnosis/" + dto.c_claim_id, dto)
  //   );
  // }

  // public static async del(dto: DtoDiagnosisDeleteReq): Promise<DtoDiagnosisDeleteRes> {
  //   return Object.assign(
  //     new DtoDiagnosisDeleteRes(),
  //     await CallAPI.deleteRequest("/datadiagnosis/" + dto.c_claim_id)
  //   );
  // }
}
