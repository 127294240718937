export class EntitySearchClaimNo {
    public c_claim_id: number | null = null;
    public c_project_id: number | null = null;
    public c_claim_number: number | null = null;
    public c_claim_datetime: Date | null = null;
    public c_client_id: number | null = null;
    public c_staff_id: number | null = null;
    public c_claim_title_1: string | null = null;
    public c_claim_title_2: string | null = null;
    public c_claim_sponsor_name: string | null = null;
    public c_bank_id_1: number | null = null;
    public c_bank_id_2: number | null = null;
    public c_bank_id_3: number | null = null;
    public c_claim_subject: string | null = null;
    public c_claim_breakdown: string | null = null;
    public c_claim_paylimit: Date | null = null;
    public c_claim_flag_delivery: number | null = null;
    public c_claim_remarks: string | null = null;
    public c_claim_status: number | null = null;
    public c_claim_flag_sales: number | null = null;
    public c_claim_subtotal: number | null = null;
    public c_claim_total: number | null = null;
    public c_claim_depo_datetime: Date | null = null;
    public c_claim_flag_del: number | null = null;

    //Join sales    
    public c_sales_item: number | null = null;
    public c_sales_subject: number | null = null;
    public c_sales_breakdown: number | null = null;
    public c_sales_price:number | null = null;


    //変換用
    public c_project_number: number | null = null;
    public c_project_name: string | null = null;
    public c_client_name: string | null = null;

}