export class EntityRequestOvertime {
public c_request_overtime_id:number | null = null;
public c_request_id:number | null = null;
public c_request_overtime_type:number | null = null;
public c_request_overtime_ot_date:Date | null = null;
public c_request_overtime_ot_datetime_start_exp:string | null = null;
public c_request_overtime_ot_datetime_end_exp:string | null = null;
public c_request_overtime_ot_location:string | null = null;
public c_request_overtime_hol_date:Date | null = null;
public c_request_overtime_hol_datetime_start_exp:string | null = null;
public c_request_overtime_hol_datetime_end_exp:string | null = null;
public c_request_overtime_hol_location:string | null = null;
public c_request_overtime_compday_all:Date | null = null;
public c_request_overtime_compday_part:Date | null = null;
public c_request_overtime_compday_part_start_time:string | null = null;
public c_request_overtime_compday_part_end_time:string | null = null;
public c_request_overtime_cont:string | null = null;
public c_request_overtime_reg_user:string | null = null;
public c_request_overtime_reg_at:Date | null = null;
public c_request_overtime_upd_user:string | null = null;
public c_request_overtime_upd_at:Date | null = null;
}