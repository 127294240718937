import { EntityVariationImple } from "../entity/EntityVariationImple";

/**
 * メディアデプトプロジェクト内に関する日付の処理を担当する
 */
export class CommonDate4MD {
  /**
   * 開始日（年月日まで）からバリエーションで指定されてい期間分、オフセットした日付を返す。
   * 期間が入力されていない場合、nullを返す。
   * @param entityClaim
   * @param authData
   * @returns
   */
  public static async offsetDate4VariationTerm(
    startDate: Date,
    variation: EntityVariationImple
  ): Promise<Date | null> {
    //入力チェック
    //---------------
    if (
      variation.c_variation_term == null ||
      variation.c_variation_term_type == null ||
      !this.isInteger(variation.c_variation_term) ||
      !this.isInteger(variation.c_variation_term_type) ||
      variation.c_variation_term <= 0 ||
      variation.c_variation_term_type <= 0
    ) {
      return null;
    }
    //オフセット
    //---------------
    let res = startDate;
    switch (variation.c_variation_term_type) {
      case 1:
        //1:日間
        res.setDate(res.getDate() + (variation.c_variation_term! - 1));
        break;
      case 2:
        //2:週間
        res.setDate(res.getDate() + (7 * variation.c_variation_term! - 1));
        break;
      case 3:
        //3:ヵ月
        res.setMonth(res.getMonth() + variation.c_variation_term!);
        res.setDate(res.getDate() - 1);
        break;
      case 4:
        //4:年間
        res.setFullYear(res.getFullYear() + variation.c_variation_term!);
        res.setDate(res.getDate() - 1);
        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 基準日から売上先、支払先マスタで指定される支払いサイト分をオフセットした日付を返す。
   * paysiteが未設定か都度確認の場合はnullを返す
   * @param baseDate
   * @param paysite
   */
  public static async offsetDate4PaySite(
    baseDate: Date,
    paysite: number | null
  ): Promise<Date | null> {
    if (paysite == null || paysite == 1) {
      return null;
    }

    let res = baseDate;

    switch (paysite) {
      case 2:
        //2:当月末;
        res = await this.getLastDayOfMonth(baseDate);
        break;
      case 3:
        //3:翌末;
        res = await this.getLastDayOfNextMonth(baseDate);
        break;
      case 4:
        //4:翌翌末;
        res = await this.getLastDayOfNextNextMonth(baseDate);
        break;
      case 5:
        //5:翌々10日;
        res = await this.getNextNextMonth10th(baseDate);
        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 整数かどうかをbool型で返す。
   * @param value
   * @returns
   */
  public static isInteger(value: any): boolean {
    return typeof value === "number" && value % 1 === 0;
  }

  /**
   * 入力した日付の当月末日を返す
   * @param date
   * @returns
   */
  public static async getLastDayOfMonth(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the first day of the next month
    const firstDayOfNextMonth = new Date(year, month + 1, 1);

    // Subtract one day from the first day of the next month to get the last day of the current month
    const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

    return lastDayOfMonth;
  }

  /**
   * 入力した日付の翌月末日を返す
   * @param date
   * @returns
   */
  public static async getLastDayOfNextMonth(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the first day of the month after next
    const firstDayOfNextMonth = new Date(year, month + 2, 1);

    // Subtract one day from the first day of the month after next to get the last day of next month
    const lastDayOfNextMonth = new Date(firstDayOfNextMonth.getTime() - 1);

    return lastDayOfNextMonth;
  }

  /**
   * 入力した日付の翌々月末日を返す
   * @param date
   * @returns
   */
  public static async getLastDayOfNextNextMonth(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the first day of the month after next next
    const firstDayOfNextNextMonth = new Date(year, month + 3, 1);

    // Subtract one day from the first day of the month after next next to get the last day of next next month
    const lastDayOfNextNextMonth = new Date(
      firstDayOfNextNextMonth.getTime() - 1
    );

    return lastDayOfNextNextMonth;
  }

  /**
   * 入力した日付の翌々月10日を返す
   * @param date
   * @returns
   */
  public static async getNextNextMonth10th(date: Date): Promise<Date> {
    // Get the year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new date object for the 10th day of the month after next next
    const nextNextMonth10th = new Date(year, month + 3, 10);

    return nextNextMonth10th;
  }

  /**
   * 2つのHH:mm形式の文字列を受け取り、時間としての単純検証と、
   * 開始 < 終了である事の複合検証を行う。
   * 時間は、MD様の基準で考え、00:00～04:59までは、翌日として取り扱う。
   * 開始=終了はfalseなので注意。
   */
  public static async validateTimes(
    startTimeTmp: string | null,
    endTimeTmp: string | null
  ): Promise<boolean> {
    if (startTimeTmp == null || endTimeTmp == null) {
      return false;
    }

    const toDate = (s: string) => new Date(`1970-01-01T${s}Z`);
    let startTime = toDate(startTimeTmp).getTime();
    let endTime = toDate(endTimeTmp).getTime();

    //00:00:00から4:59:59の間であれば、次の日補正
    if (startTime < 5 * 60 * 60 * 1000) {
      startTime += 24 * 60 * 60 * 1000; // add 24 hours
    }
    if (endTime < 5 * 60 * 60 * 1000) {
      endTime += 24 * 60 * 60 * 1000; // add 24 hours
    }

    let workTime = endTime - startTime;

    if (workTime <= 0) {
      return false;
    }

    return true;
  }

  /**
   * UTCでの日付を取得し、日本時間に変換して文字列で返す。
   * フォーマットはyyyy/MM/dd HH:mm:ss
   * @param date
   * @returns
   */
  static cnvUTC2JST4DateTimeStr(date: Date): string {
    let dateTmp = new Date(date);
    dateTmp = this.cnvUTC2JST(dateTmp); // JSTに変換

    let res = dateTmp.toISOString();
    res = res.replace("-", "/");
    res = res.replace("-", "/");
    res = res.replace("T", " ");
    res = res.replace(/[.].*$/, "");

    return res;
  }

  /**
   * UTCのDateを受け取り、JSTにして返す。
   * Date型はTimeZoneを認識しないので、単純に時差を調整するだけ。
   * @param date
   * @returns
   */
  public static cnvUTC2JST(date: Date): Date {
    let res = date;
    res.setHours(res.getHours() + 9);
    return res;
  }

  /**
   * 日付型を受け取り、yyyy-MM-dd HH:mm:ss形式で返す
   * @param date
   * @returns
   */
  static cnvDate2Str(date: Date): string {
    const pad = (num: number): string => num.toString().padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // JavaScriptの月は0から始まるので、1を足す
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  /**
   * 渡された日付をyyyy-MM-ddの文字列型で返す
   * @param date
   * @returns
   */
  static formatDateString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  /**
   * 渡された日付の末日をyyyy-MM-ddの文字列型で返す
   * @param date
   * @returns
   */
  static getLastDayOfMonth_str(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    return `${year}-${month.toString().padStart(2, "0")}-${lastDay
      .toString()
      .padStart(2, "0")}`;
  }
  /**
   * 渡された日付の末日をyyyy-MMの文字列型で返す
   * @param date
   * @returns
   */
  static getLastDayOfMonth_str4Dtp(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return `${year}-${month.toString().padStart(2, "0")}`;
  }

  /**
   * 渡された日付をyyyy年MM月dd日の文字列型で返す
   * @param date
   * @returns
   */
  static formatDateString_chc(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}年${month}月${day}日`;
  }

  /**
   * 渡された日付の末日をyyyy年MM月dd日の文字列型で返す
   * @param date
   * @returns
   */
  static getLastDayOfMonth_chc(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    return `${year}年${month.toString().padStart(2, "0")}月${lastDay
      .toString()
      .padStart(2, "0")}日`;
  }

  /**
   * 渡された日付をyyyy/MM/ddの文字列型で返す
   * @param date
   * @returns
   */
  static formatDateString_sl(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  /**
   * 渡された日付をyyyy/MMの文字列型で返す
   * @param date
   * @returns
   */
  static formatDateString_ym(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}`;
  }

  /**
   * 日付を文字列に変換する。
   * フォーマットはyyyy/MM/dd HH:mm:ss
   * @param date
   * @returns
   */
  static cnvDate2Str4DateTime(date: Date): string {
    let dateTmp = new Date(date);

    let res = dateTmp.toISOString();
    res = res.replace("-", "/");
    res = res.replace("-", "/");
    res = res.replace("T", " ");
    res = res.replace(/[.].*$/, "");

    return res;
  }

  /**
   * 渡された日付の当月1日0時0分0秒を返す。
   * @param date
   * @returns
   */
  static resetToFirstDayOfMonth(date: Date): Date {
    // 新しいDateオブジェクトを作成し、同じ年月日を設定
    let firstDayOfMonth = new Date(date);

    // 日付を1日に設定し、時分秒を0に設定
    firstDayOfMonth.setDate(1);
    firstDayOfMonth.setHours(0, 0, 0, 0);

    return firstDayOfMonth;
  }

  /**
   * 2つの日付を受け取り、その間の毎月1日0時0分0秒の日付配列を返します。
   *
   * @param {Date} startDate - 開始日
   * @param {Date} endDate - 終了日
   * @returns {Date[]} 開始日と終了日の間の毎月1日0時0分0秒の日付配列
   */
  static getFirstDaysBetweenDates(startDate: Date, endDate: Date): Date[] {
    let dates: Date[] = [];

    // 開始日と終了日を同じ時間に設定
    let start = new Date(startDate);
    let end = new Date(endDate);

    // 時分秒を0に設定
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    // 開始日が終了日より後の場合、入れ替える
    if (start > end) {
      [start, end] = [end, start];
    }

    // 現在の年月を設定
    let current = new Date(start.getFullYear(), start.getMonth(), 1);

    // 現在の日付が終了日以前の間、毎月1日を配列に追加
    while (current <= end) {
      dates.push(new Date(current));
      current.setMonth(current.getMonth() + 1);
    }

    return dates;
  }

  /**
   * 2つの日付配列を受け取り、一致している日付があるかを返します。
   * 日付は0時0分0秒にリセットされてから比較されます。
   *
   * @param {Date[]} dates1 - 1つ目の日付配列
   * @param {Date[]} dates2 - 2つ目の日付配列
   * @returns {boolean} 一致している日付があるかどうか
   */
  static hasCommonDate(dates1: Date[], dates2: Date[]): boolean {
    const resetTime = (date: Date): Date => {
      const resetDate = new Date(date.getTime());
      resetDate.setHours(0, 0, 0, 0);
      return resetDate;
    };

    // dates1をタイムスタンプのSetに変換
    const datesSet1 = new Set(dates1.map((date) => resetTime(date).getTime()));

    // dates2をタイムスタンプにリセットしてから、datesSet1と比較
    for (let date of dates2) {
      if (datesSet1.has(resetTime(date).getTime())) {
        return true; // 一致する日付が見つかった
      }
    }

    return false; // 一致する日付がない
  }

  static formatDate_ymdh(inputDate: Date): string {
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: "Asia/Tokyo",
    }).format(inputDate);

    return formattedDate;
  }

  /**
   * 2つの日付を受け取り、その間の毎月1日0時0分0秒の日付配列を返します。
   * @param dateStart
   * @param dateEnd
   */
  static cnvDateMonth(dateStart: Date, dateEnd: Date): Date[] {
    let dates: Date[] = [];
    let current = new Date(dateStart);
    while (current <= dateEnd) {
      dates.push(new Date(current));
      current.setMonth(current.getMonth() + 1);
    }
    return dates;
  }

  /**
   * -------------------------------------------------
   * ビジネス期間の関係
   * -------------------------------------------------
   */
  /**
   * 日付を受け取り、その日付が所属するビジネス期間を返す。
   * Business期間は毎年、4月から翌年3月までで、
   * 返される配列は、開始日（インデックス0）と終了日（インデックス1）の
   * 2つのDate型です。
   * @param inputDate
   */
  static getBusinessDate(inputDate: Date): Date[] {
    const year = inputDate.getFullYear();

    // 引数の日付が1月1日から3月31日までの場合、その年のビジネス期間は前年の4月1日からその年の3月31日まで
    if (inputDate.getMonth() < 3) {
      // 1月から3月の場合
      const start = new Date(year - 1, 3, 1); // 前年の4月1日
      const end = new Date(year, 2, 31); // その年の3月31日
      return [start, end];
    } else {
      // それ以外の場合（4月から12月の場合）
      const start = new Date(year, 3, 1); // その年の4月1日
      const end = new Date(year + 1, 2, 31); // 翌年の3月31日
      return [start, end];
    }
  }

  /**
   * 日付を受け取り、その日付が所属するビジネス期間を返す。
   * Business期間は毎年、4月から翌年3月までで、
   * 返される配列は、開始月から翌年の終了月までの月毎の1日を示す
   * Date型の配列で返す。
   * @param inputDate
   */
  static getBusinessDate4month(inputDate: Date): Date[] {
    const businessDate = this.getBusinessDate(inputDate);
    const start = businessDate[0];
    const end = businessDate[1];

    const dates: Date[] = [];
    let current = new Date(start);

    while (current <= end) {
      // 日本時間に調整（+9時間）
      const adjustedDate = new Date(current.getTime() + 9 * 60 * 60 * 1000);
      dates.push(adjustedDate);
      current.setMonth(current.getMonth() + 1);
    }

    return dates;
  }
}
