import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoGoalsGetReqImple } from "mediadept-common/src/common/dto/DtoGoalsGetReqImple";
import { DtoGoalsGetResImple } from "mediadept-common/src/common/dto/DtoGoalsGetResImple";
import { DtoGoalsPostReq } from "mediadept-common/src/common/dto/abst/DtoGoalsPostReq";
import { DtoGoalsPostRes } from "mediadept-common/src/common/dto/abst/DtoGoalsPostRes";
import { DtoGoalsPutReq } from "mediadept-common/src/common/dto/abst/DtoGoalsPutReq";
import { DtoGoalsPutRes } from "mediadept-common/src/common/dto/abst/DtoGoalsPutRes";
import { DtoGoalsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoGoalsDeleteReq";
import { DtoGoalsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoGoalsDeleteRes";

export class Cl_goal {
  public static async getList(
    dto: DtoGoalsGetReqImple
  ): Promise<DtoGoalsGetResImple> {
    return Object.assign(
      new DtoGoalsGetResImple(),
      await CallAPI.getRequest(
        "/goals?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoGoalsGetReqImple
  ): Promise<DtoGoalsGetResImple> {
    return Object.assign(
      new DtoGoalsGetResImple(),
      await CallAPI.getRequest("/goals/" + dto.c_goal_id)
    );
  }

  public static async post(dto: DtoGoalsPostReq): Promise<DtoGoalsPostRes> {
    return Object.assign(
      new DtoGoalsPostRes(),
      await CallAPI.postRequest("/goals", dto)
    );
  }

  public static async put(dto: DtoGoalsPutReq): Promise<DtoGoalsPutRes> {
    return Object.assign(
      new DtoGoalsPutRes(),
      await CallAPI.putRequest("/goals/" + dto.c_goal_id, dto)
    );
  }

  public async del(dto: DtoGoalsDeleteReq): Promise<DtoGoalsDeleteRes> {
    return Object.assign(
      new DtoGoalsDeleteRes(),
      await CallAPI.deleteRequest("/goals?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
