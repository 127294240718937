
import { Options, Vue } from "vue-class-component";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import Mo_modal_n from "@/components/molecules/Mo_modal_n.vue";
import Mo_modal_s from "@/components/molecules/Mo_modal_s.vue";
import Mo_modal_left_s from "@/components/molecules/Mo_modal_left_s.vue";
import Mo_modal_left_l from "@/components/molecules/Mo_modal_left_l.vue";
import Mo_modal_left_n from "@/components/molecules/Mo_modal_left_n.vue";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    Mo_modal_l,
    Mo_modal_n,
    Mo_modal_s,
    Mo_modal_left_s,
    Mo_modal_left_l,
    Mo_modal_left_n,
    Mo_modal_alert,
  },
})
export default class Modal extends Vue {
  // Mo_modal_l
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_l;
    modalComp.showModal();
  }
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_l;
    modalComp.closeModal();
  }
  async save(): Promise<void> {
    alert("save");
  }
  //------------------------
  // Mo_modal_n
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal_n(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_n;
    modalComp.showModal();
  }
  async closeModal_n(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_n;
    modalComp.closeModal();
  }
  async save_n(): Promise<void> {
    alert("save_n");
  }
  //------------------------
  // Mo_modal_s
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal_s(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_s;
    modalComp.showModal();
  }
  async closeModal_s(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_s;
    modalComp.closeModal();
  }
  async save_s(): Promise<void> {
    alert("save_s");
  }
  //------------------------
  //------------------------
  // Mo_modal_left_s
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal_left_s(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_left_s;
    modalComp.showModal();
  }
  async closeModal_left_s(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_left_s;
    modalComp.closeModal();
  }
  async save_left_s(): Promise<void> {
    alert("save_s");
  }
  //------------------------
  //------------------------
  // Mo_modal_left_l
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal_left(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_left_l;
    modalComp.showModal();
  }
  async closeModal_left(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_left_l;
    modalComp.closeModal();
  }
  async save_left(): Promise<void> {
    alert("save_l");
  }
  //------------------------
  //------------------------
  // Mo_modal_left_n
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal_left_n(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_left_n;
    modalComp.showModal();
  }
  async closeModal_left_n(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_left_n;
    modalComp.closeModal();
  }
  async save_left_n(): Promise<void> {
    alert("save_n");
  }
  //------------------------
  //------------------------
  // Mo_modal_alert
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal_alert(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_alert;
    modalComp.showModal();
  }
  async closeModal_alert(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_alert;
    modalComp.closeModal();
  }
  async save_alert(): Promise<void> {
    alert("save_alert");
  }
  //------------------------
  //------------------------
  // Mo_modal_alert2
  //------------------------
  /**
   * vue-property-decoratorを入れて、propを使用したが、うまく動かなかった。
   * refsでコンポーネント内のメソッドを操作する方向で使用する。
   */
  async showModal_alert2(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_alert2;
    modalComp.showModal();
  }
  async closeModal_alert2(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_alert2;
    modalComp.closeModal();
  }
  async save_alert2(): Promise<void> {
    alert("save_alert");
  }
  //------------------------
}
