import { EntityConditionImple } from "../entity/EntityConditionImple";
import { EntityVariationImple } from "../entity/EntityVariationImple";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoItemsGetRes } from "./abst/DtoItemsGetRes";

export class DtoItemsGetResImple extends DtoItemsGetRes {
  public pager = new DtoApiPager();

  //画像用
  //-----------------------
  public imgaUrl4S3: string | null = null;
  //詳細用
  //-----------------------
  public variations: EntityVariationImple[] = [];
  // public conditions: EntityConditionImple[][] = [];
}
