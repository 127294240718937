import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityClient } from "../../entity/EntityClient";

export class DtoClientsPutReq extends EntityClient {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_client_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
