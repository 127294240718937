
//※コメントセット
//----------------------
//バリデーション
//----------------------
//----------------------
//DOM連携用プロパティ
//----------------------
//----------------------
//DOM連携用メソッド
//----------------------
//----------------------
//セレクトボックス用プロパティ
//----------------------
//----------------------
//モーダル用プロパティ
//----------------------
//----------------------
//制御用メソッド
//----------------------
//----------------------
//基本操作用メソッド
//----------------------
//----------------------
//モーダル用メソッド
//----------------------
//----------------------
//その他のメソッド（画面独自のもの等）
//----------------------

import { Options, Vue } from "vue-class-component";
import { Cl_notifications, toasterType } from "@/functions/Cl_notifications";
// import { Cl_dataTables } from "@/functions/Cl_dataTables";

import "vue-good-table-next/dist/vue-good-table-next.css";
//import VueGoodTable from "vue-good-table-next";
import { VueGoodTable } from "vue-good-table-next";

import axios from "axios";
import { AxiosError } from "axios";
import { CONST_BASE_IS_DEBAG } from "@/constants/constant";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// 参考：https://pgmemo.tokyo/data/archives/2239.html
import { defineProps, toRef } from "vue";
import { useForm, useField, Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { setLocale } from "yup";
import { suggestive } from "yup-locale-ja";
setLocale(suggestive);

import { reactive } from "vue"; // "from '@vue/composition-api'" if you are using Vue <2.7
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  integer,
  maxLength,
  minLength,
} from "@vuelidate/validators";
import { DtoMessagesGetResImple } from "mediadept-common/src/common/dto/DtoMessagesGetResImple";

@Options({
  components: {
    VueGoodTable,
    Swal,
    vSelect,
    Field,
    Form,
    ErrorMessage,
  },
})
export default class Test extends Vue {
  c_client_id = "";

  password = "";
  email = "";
  schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required().min(8),
  });

  async onSubmit(values: any) {
    console.log(values);
  }

  async fb() {
    await this.schema.validate({ email: this.email, password: this.password });
  }

  async fa() {
    try {
      await this.schema.validate({
        email: this.email,
        password: this.password,
      });
      console.log("どちらもOK");
    } catch (e) {
      console.log("何かがNG");
    } finally {
      console.log("finally");
    }
  }

  async updStoreUpd() {
    this.$store.commit("clearStore");
  }
  async updStoreName() {
    this.$store.commit("updName", this.c_client_id);
  }
  async updStoreid() {
    this.$store.commit("updToken_id", this.c_client_id);
  }
  async getName() {
    alert(this.$store.state.name);
  }

  /**
   *
   * メソッドのコピペ用
   */
  async example() {
    //ボタン無効＋ローダー表示
    //-----------------
    // this.btnDisable = true;
    // const panel = document.getElementById("panel-editers");
    // panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      // Cl_common.logger("処理実行");
      //準備
      //-------
      // let dto = new DtoClientsGetReqImple();
      // dto.c_client_id = Number(id);
      //API実行
      //-------
      // this.delegate = await Cl_client.get(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      // Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      // this.btnDisable = false;
      // panel?.classList.remove("enable-loader");
    }
  }

  /*columns =
    '[{"label":"Name","field":"name"},{"label":"Age","field":"age","type":"number"},{"label":"CreatedOn","field":"createdAt","type":"date","dateInputFormat":"yyyy-MM-dd","dateOutputFormat":"MMMdoyy"},{"label":"Percent","field":"score","type":"percentage"}]';
  rows =
    '[{"id":1,"name":"John","age":20,"createdAt":"","score":0.03343},{"id":2,"name":"Jane","age":24,"createdAt":"2011-10-31","score":0.03343},{"id":3,"name":"Susan","age":16,"createdAt":"2011-10-30","score":0.03343},{"id":4,"name":"Chris","age":55,"createdAt":"2011-10-11","score":0.03343},{"id":5,"name":"Dan","age":40,"createdAt":"2011-10-21","score":0.03343},{"id":6,"name":"John","age":20,"createdAt":"2011-10-31","score":0.03343}]';*/
  /*columns = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Age",
      field: "age",
      type: "number",
    },
    {
      label: "Created On",
      field: "createdAt",
      type: "date",
      dateInputFormat: "yyyy-MM-dd",
      dateOutputFormat: "MMM do yy",
    },
    {
      label: "Percent",
      field: "score",
      type: "percentage",
    },
  ];*/
  columns = [
    {
      label: "code",
      field: "c_series_code",
      type: "number",
    },
    {
      label: "name",
      field: "c_series_name",
    },
    {
      label: "name pt",
      field: "c_series_name_pt",
    },
  ];
  rows = [
    //{ id: 1, name: "John", age: 20, createdAt: "", score: 0.03343 },
    //{ id: 2, name: "Jane", age: 24, createdAt: "2011-10-31", score: 0.03343 },
    //{ id: 3, name: "Susan", age: 16, createdAt: "2011-10-30", score: 0.03343 },
    //{ id: 4, name: "Chris", age: 55, createdAt: "2011-10-11", score: 0.03343 },
    //{ id: 5, name: "Dan", age: 40, createdAt: "2011-10-21", score: 0.03343 },
    //{ id: 6, name: "John", age: 20, createdAt: "2011-10-31", score: 0.03343 },
  ];
  totalRecords = 26;

  public selected = "";
  public optionsOp = [
    { label: "One", code: "A" },
    { label: "Two", code: "B" },
    { label: "Three", code: "C" },
  ];

  public mounted() {
    // Cl_dataTables.getTable("#dt-basic-example");

    this.getData(1, 5);
  }

  public async onPageChange(params: any) {
    console.log("onPageChange");
    console.log(params);

    this.getData(params.currentPage, params.currentPerPage);
  }

  public async getData(cursor: number, count: number) {
    const url =
      "https://yuusacpcf4.execute-api.ap-northeast-1.amazonaws.com/prod/items" +
      "?keyword=ヨウ&cursor=" +
      cursor +
      "&sort=new&search=onsale&count=" +
      count;

    try {
      const response = await axios.get(url, {
        headers: {
          "x-api-key": "YS3nItKwiL52notBlZVrF4ET0WVtx4YM6rT0rrSM",
        },
      });

      console.log(response.data);
      this.rows = response.data.items;
      this.totalRecords = response.data.maxcount;
    } catch (error: any) {
      if (this.isAxiosError(error)) {
        if (CONST_BASE_IS_DEBAG) {
          console.log((error as AxiosError).name);
          console.log((error as AxiosError).message);
          console.log((error as AxiosError).stack);
          console.log((error as AxiosError).response);
        }
      }
    }
  }

  public isAxiosError(error: any): error is AxiosError {
    return !!error.isAxiosError;
  }

  public onSortChange(params: any) {
    console.log(params);
    console.log(params[0].field);
    console.log(params[0].type);
  }

  async showNotification(): Promise<void> {
    Cl_notifications.showToastr(toasterType.success, "success");
    Cl_notifications.showToastr(toasterType.error, "error");
    Cl_notifications.showToastr(toasterType.warning, "warning");
    Cl_notifications.showToastr(toasterType.info, "info");
  }

  public showAlert(type: number) {
    // 下記のページのコードをそのまま使用可能。
    // https://sweetalert2.github.io
    switch (type) {
      case 0:
        Swal.fire("Hello Vue world!!!");
        break;
      case 1:
        Swal.fire("The Internet?", "That thing is still around?", "warning");
        break;
      case 2:
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                "Cancelled",
                "Your imaginary file is safe :)",
                "error"
              );
            }
          });
        break;
      case 3:
        Swal.fire({
          title: "Submit your Github username",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Look up",
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            return fetch(`//api.github.com/users/${login}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: `${result.value.login}'s avatar`,
              imageUrl: result.value.avatar_url,
            });
          }
        });
        break;
      case 4:
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        break;
    }
  }
}
