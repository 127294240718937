import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityHolisticlock } from "../../entity/EntityHolisticlock";
import { DaoHolisticlock } from "../../dao/DaoHolisticlock";

import {
  e_t_claim,
  e_t_holisticlock,
  e_t_sales,
  e_t_supply,
  e_tables,
} from "../../constant/ConstantsEntity";
import { CommonDate4MD } from "../../function/CommonDate4MD";
import { DaoSales } from "../../dao/DaoSales";
import {
  CONST_CLAIMSTATUS_MISYOUNINMISYUTURYOKU,
  CONST_CLAIMSTATUS_SASIMODOSIZUMIMISYOUNIN,
  CONST_CLAIMSTATUS_SYOUNINIRAIZUMIMISYOUNIN,
} from "../../constant/Constants";
import { DaoSupply } from "../../dao/DaoSupply";

export class DtoHolisticlocksPostReq extends EntityHolisticlock {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
  /**
   * 日付の重複チェック。
   * 自身のc_holisticlock_dateに入力されている日付が、登録済みかのチェックを行う。
   * 問題なければ空文字を返す。
   */
  public async valid4DupCheck(
    daoHolisticlock: DaoHolisticlock
  ): Promise<string> {
    if (
      (await daoHolisticlock.count(
        e_t_holisticlock[e_t_holisticlock.c_holisticlock_date] +
          " = '" +
          CommonDate4MD.cnvDate2Str(new Date(this.c_holisticlock_date!)) +
          "'"
      )) > 0
    ) {
      return "dapleicate date.";
    } else {
      return "";
    }
  }
  /**
   * ロック対象日付内に、未承認の請求書が存在するかのチェック。
   * 存在する場合、対象の請求書Noを返す。
   * 最大3件として、それ以上の場合は、"他3"というようにサマリを返す。
   * ぞんざいしない場合は、空文字を返す。
   */
  public async valid4NotAplyClaim(
    daoSales: DaoSales,
    daoSupply: DaoSupply
  ): Promise<string> {
    let res: string = "";
    let cnt: number = 0;
    const subQuery =
      "SELECT " +
      e_t_claim[e_t_claim.c_claim_id] +
      " FROM " +
      e_tables[e_tables.t_claim] +
      " WHERE " +
      e_t_claim[e_t_claim.c_claim_flag_del] +
      "=0 AND " +
      e_t_claim[e_t_claim.c_claim_flag_sales] +
      "=1 AND " +
      e_t_claim[e_t_claim.c_claim_status] +
      " IN (" +
      CONST_CLAIMSTATUS_MISYOUNINMISYUTURYOKU +
      "," +
      CONST_CLAIMSTATUS_SYOUNINIRAIZUMIMISYOUNIN +
      "," +
      CONST_CLAIMSTATUS_SASIMODOSIZUMIMISYOUNIN +
      ")";

    let sales = await daoSales.select(
      e_t_sales[e_t_sales.c_sales_month] +
        " = '" +
        CommonDate4MD.cnvDate2Str(new Date(this.c_holisticlock_date!)) +
        "' AND " +
        e_t_sales[e_t_sales.c_sales_claimnum_tmp] +
        " IS NOT NULL AND " +
        e_t_sales[e_t_sales.c_claim_id] +
        " IN (" +
        subQuery +
        ")"
    );
    let supply = await daoSupply.select(
      e_t_supply[e_t_supply.c_supply_month] +
        " = '" +
        CommonDate4MD.cnvDate2Str(new Date(this.c_holisticlock_date!)) +
        "' AND " +
        e_t_supply[e_t_supply.c_supply_claimnum_tmp] +
        " IS NOT NULL AND " +
        e_t_supply[e_t_supply.c_claim_id] +
        " IN (" +
        subQuery +
        ")"
    );

    //2つの配列をマージ
    //-------
    let claimIds: number[] = [];
    for (let i = 0; i < sales.length; i++) {
      //すでに登録されている請求書は除外
      if (
        sales[i].c_sales_claimnum_tmp == null ||
        claimIds.includes(sales[i].c_sales_claimnum_tmp!)
      ) {
        continue;
      }

      claimIds.push(sales[i].c_sales_claimnum_tmp!);
    }
    for (let i = 0; i < supply.length; i++) {
      //すでに登録されている請求書は除外
      if (
        supply[i].c_supply_claimnum_tmp == null ||
        claimIds.includes(supply[i].c_supply_claimnum_tmp!)
      ) {
        continue;
      }

      claimIds.push(supply[i].c_supply_claimnum_tmp!);
    }

    //エラーメッセージ作成
    //-------

    for (let i = 0; i < claimIds.length; i++) {
      if (cnt < 3) {
        res += claimIds[i] + ",";
      }
      cnt++;
    }
    if (cnt > 0) {
      res = res.slice(0, -1);
      if (cnt > 3) {
        res += " 他" + (cnt - 3) + "件";
      }
    }
    return res;
  }
}
