import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestAddchgsGetReqImple } from "mediadept-common/src/common/dto/DtoRequestAddchgsGetReqImple";
import { DtoRequestAddchgsGetResImple } from "mediadept-common/src/common/dto/DtoRequestAddchgsGetResImple";
import { DtoRequestAddchgsPostReq } from "mediadept-common/src/common/dto/abst/DtoRequestAddchgsPostReq";
import { DtoRequestAddchgsPostRes } from "mediadept-common/src/common/dto/abst/DtoRequestAddchgsPostRes";
import { DtoRequestAddchgsPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestAddchgsPutReq";
import { DtoRequestAddchgsPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestAddchgsPutRes";
import { DtoRequestAddchgsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoRequestAddchgsDeleteReq";
import { DtoRequestAddchgsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoRequestAddchgsDeleteRes";

export class Cl_requestaddchg {
  public static async getList(
    dto: DtoRequestAddchgsGetReqImple
  ): Promise<DtoRequestAddchgsGetResImple> {
    return Object.assign(
      new DtoRequestAddchgsGetResImple(),
      await CallAPI.getRequest(
        "/requestaddchgs?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestAddchgsGetReqImple
  ): Promise<DtoRequestAddchgsGetResImple> {
    return Object.assign(
      new DtoRequestAddchgsGetResImple(),
      await CallAPI.getRequest("/requestaddchgs/" + dto.c_request_addchg_id)
    );
  }

  public static async post(
    dto: DtoRequestAddchgsPostReq
  ): Promise<DtoRequestAddchgsPostRes> {
    return Object.assign(
      new DtoRequestAddchgsPostRes(),
      await CallAPI.postRequest("/requestaddchgs", dto)
    );
  }

  public static async put(
    dto: DtoRequestAddchgsPutReq
  ): Promise<DtoRequestAddchgsPutRes> {
    return Object.assign(
      new DtoRequestAddchgsPutRes(),
      await CallAPI.putRequest(
        "/requestaddchgs/" + dto.c_request_addchg_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestAddchgsDeleteReq
  ): Promise<DtoRequestAddchgsDeleteRes> {
    return Object.assign(
      new DtoRequestAddchgsDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestaddchgs?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
