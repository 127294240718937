export class EntityVariation {
public c_variation_id:number | null = null;
public c_item_id:number | null = null;
public c_variation_manag_name:string | null = null;
public c_variation_name:string | null = null;
public c_variation_size:string | null = null;
public c_variation_specification:string | null = null;
public c_variation_type:string | null = null;
public c_variation_time_start:string | null = null;
public c_variation_time_end:string | null = null;
public c_variation_term:number | null = null;
public c_variation_term_type:number | null = null;
public c_variation_price:number | null = null;
public c_variation_price_type:number | null = null;
public c_variation_margin_sales:number | null = null;
public c_variation_margin_supply:number | null = null;
public c_variation_remarks:string | null = null;
public c_variation_flag_del:number | null = null;
public c_variation_reg_user:string | null = null;
public c_variation_reg_at:Date | null = null;
public c_variation_upd_user:string | null = null;
public c_variation_upd_at:Date | null = null;
}