export class EntityCondition {
public c_condition_id:number | null = null;
public c_variation_id:number | null = null;
public c_supplier_id:number | null = null;
public c_condition_name:string | null = null;
public c_condition_type:string | null = null;
public c_condition_price:number | null = null;
public c_condition_flag_del:number | null = null;
public c_condition_remarks:string | null = null;
public c_condition_reg_user:string | null = null;
public c_condition_reg_at:Date | null = null;
public c_condition_upd_user:string | null = null;
public c_condition_upd_at:Date | null = null;
}