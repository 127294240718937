
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DtoClientsGetReqImple } from "mediadept-common/src/common/dto/DtoClientsGetReqImple";
import { DtoClientsGetResImple } from "mediadept-common/src/common/dto/DtoClientsGetResImple";
import { DtoClientsPutReq } from "mediadept-common/src/common/dto/abst/DtoClientsPutReq";
import { DtoClientsPutRes } from "mediadept-common/src/common/dto/abst/DtoClientsPutRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoClientsPostReq } from "mediadept-common/src/common/dto/abst/DtoClientsPostReq";
import { DtoClientsPostRes } from "mediadept-common/src/common/dto/abst/DtoClientsPostRes";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class Itemedit4exmedia extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClientsGetResImple();
  btnDisable = false;
  isEditMode = true;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4CompanyType = [
    { label: "指定なし", code: 1 },
    { label: "株式", code: 2 },
    { label: "有限", code: 3 },
  ];
  op4SignagePosi = [
    { label: "前", code: 1 },
    { label: "後", code: 2 },
  ];
  optionsOp = [
    { label: "テスト" },
    { label: "アイエイチ・ラブ" },
    { label: "アイビーエス" },
    { label: "アクスル" },
    { label: "アクティス" },
    { label: "ACOZ" },
    { label: "アサツーディ・ケイ" },
    { label: "朝日電装" },
    { label: "アテンポライフ" },
    { label: "アドベル" },
    { label: "アド・トゥモロー" },
    { label: "アートシステム" },
    { label: "五十嵐商事" },
    { label: "伊勢屋テック" },
    { label: "茨城ロボッツ・スポーツエンターテイメント" },
    { label: "インフィニティ・コミュニケーションズ" },
    { label: "INFORICH" },
    { label: "エイディ・アンド・エム" },
    { label: "エスピーエスエス" },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
}
