import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoDocsPostReq } from "./abst/DtoDocsPostReq";

export class DtoDocsPostReqImple extends DtoDocsPostReq {
  //画像用
  //-------------------
  public filename: string | null = null;
  public filetype: string | null = null;
  public filesize: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    //ページャー用入力値チェック
    res = res.concat(super.IsValid());

    return res;
  }
}
