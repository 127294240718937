
import { Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";

import Mo_chart_doughnut from "@/components/molecules/Mo_chart_doughnut.vue";
import Mo_chart_barline from "@/components/molecules/Mo_chart_barline.vue";
import { ChartOptions } from "chart.js";
import { ref } from "vue";
import { DtoAggregatesGetRes } from "mediadept-common/src/common/dto/DtoAggregatesGetRes";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import { CommonDate4MD } from "mediadept-common/src/common/function/CommonDate4MD";
import { Cl_common } from "@/functions/Cl_common";
import {
  DtoAggregatesGetReq,
  e_aggTypes,
} from "mediadept-common/src/common/dto/DtoAggregatesGetReq";
import { Cl_aggregates } from "@/functions/Cl_aggregates";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { get } from "jquery";
import { EntityAgg4pro } from "mediadept-common/src/common/entity/EntityAgg4pro";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_chart_doughnut,
    Mo_chart_barline,
    Mo_modal_alert,
  },
})
export default class AnalyprofitPrsonal extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoAggregatesGetRes();
  btnDisable = false;

  columns = [
    {
      thClass: "text-center",
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      thClass: "text-center",
      label: "案件番号",
      field: "c_project_number",
    },
    {
      thClass: "text-center",
      label: "案件名",
      field: "c_project_name",
    },
    {
      thClass: "text-center",
      tdClass: "text-center",
      label: "対象期間",
      field: "c_agg_pro_year",
    },
    {
      thClass: "text-center",
      label: "広告主",
      field: "c_project_sponsor_name",
    },
    {
      thClass: "text-center",
      tdClass: "text-right",
      label: "受注売上額",
      field: "c_agg_pro_amount_sales",
    },
    {
      thClass: "text-center",
      tdClass: "text-right",
      label: "受注仕入額",
      field: "c_agg_pro_amount_supply",
    },
    {
      thClass: "text-center",
      tdClass: "text-right",
      label: "受注粗利額",
      field: "c_agg_pro_amount_profit",
    },
    {
      thClass: "text-center",
      tdClass: "text-right",
      label: "受注利益率",
      field: "c_agg_pro_ratio_profit",
    },
  ];

  //----------------------
  //DOM連携用メソッド
  //----------------------
  domMonth_start = new DtoMdDtp();
  domMonth_end = new DtoMdDtp();
  domBisYear = 0;
  staffId = -1;
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //期指定UI用
    await this.setParam();

    //画面更新
    this.get4All();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  @Watch("domBisYear")
  async get4All() {
    this.get4batch_2000();
    this.get4graph_summary_2100();
    // this.get4graph_genre_item_2200();
    this.get4graph_client_2300();
    this.get4list_2400();
  }

  /**
   * バッジ更新用_2000
   */
  async get4batch_2000() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    //ローダーはパネル毎の独立制御の為、バッチは処理しない。
    // const panel = document.getElementsByClassName("panel-container");
    // Array.from(panel).map((element: Element) => {
    //   element.classList.add("enable-loader");
    // });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.staffid = this.staffId;
      dto.aggType = e_aggTypes.staff_badge_2000;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);

      this.delegate.dataStaff = response.dataStaff;

      this.delegate.badge_20001_sales_order = response.badge_20001_sales_order;
      this.delegate.badge_20002_supply_order =
        response.badge_20002_supply_order;
      this.delegate.badge_20003_profit_order =
        response.badge_20003_profit_order;
      this.delegate.badge_20004_ratio_profit_order =
        response.badge_20004_ratio_profit_order;
      this.delegate.badge_20005_sales = response.badge_20005_sales;
      this.delegate.badge_20006_supply = response.badge_20006_supply;
      this.delegate.badge_20007_profit = response.badge_20007_profit;
      this.delegate.badge_20008_ratio_profit =
        response.badge_20008_ratio_profit;

      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      //ローダーはパネル毎の独立制御の為、バッチは処理しない。
      // Array.from(panel).map((element: Element) => {
      //   element.classList.remove("enable-loader");
      // });
    }
  }
  /**
   * 売上詳細グラフ更新用_2100
   */
  async get4graph_summary_2100() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-2100_summary-graph");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.staffid = this.staffId;
      dto.aggType = e_aggTypes.staff_summary_graph_2100;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);

      this.delegate.summary_graph_2100_chartData =
        response.summary_graph_2100_chartData;

      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  /**
   * 売上先別売上額グラフ用_2300
   */
  async get4graph_client_2300() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("2300_client_graph");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.staffid = this.staffId;
      dto.aggType = e_aggTypes.staff_ranking_client_2300;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);

      this.delegate.client_graph_23001_chartData =
        response.client_graph_23001_chartData;

      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  /**
   * 案件一覧_2400
   */
  async get4list_2400() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-2400_list");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.staffid = this.staffId;
      dto.aggType = e_aggTypes.staff_project_list_2400;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);

      this.delegate.project_list_2400 = response.project_list_2400;

      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }

  /**
   * -------------------------------------------------
   * 汎用メソッド。
   * -------------------------------------------------
   */
  /**
  /**
   * 期指定UIの指定値を、期間指定UI用に変換する。
   * 基本的に、DTOで使用するのは期間指定となる。
   */
  async cnvSelect4Term() {
    //指定年の4月1日を取得
    const startDate = new Date(this.domBisYear, 3, 1);

    //期間に変換
    const bd = CommonDate4MD.getBusinessDate(startDate);
    this.domMonth_start.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(bd[0]);
    this.domMonth_end.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(bd[1]);
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  /**
   * 一覧の日付を年月に変換する。
   * @param item
   */
  getYearMonth(item: EntityAgg4pro) {
    return item.c_agg_pro_year + "年" + item.c_agg_pro_month + "月";
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * エラーモーダルを閉じる。
   */
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  //----------------------
  //その他のメソッド（画面独自のもの等）
  //この画面では、グラフのオプション等もここに記述する。
  //----------------------

  /**
   * パラメータから変数に値をセットする。
   */
  async setParam() {
    //年度をセットする
    let sdate = Cl_common.getParam("sdate");
    const bd = CommonDate4MD.getBusinessDate(new Date(sdate + "-01"));
    this.domBisYear = bd[0].getFullYear();

    //社員ID
    this.staffId = Number(Cl_common.getParam("sid"));
  }

  /**
   * -------------------------------------------------
   * 以下、モック時のコード
   * -------------------------------------------------
   */

  /**
   * 400当期業種別売上棒グラフ用オプション
   */
  chartOptions4GenreGraph400 = ref<ChartOptions<"bar">>({
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        // stacked: true,
      },
      yAxis: {
        axis: "y",
        // stacked: true,
      },
    },
  });

  optionsOp = [
    {
      label: "メディアサービス",
    },
    {
      label: "Officeroom",
    },
    {
      label: "フラッグ",
    },
    {
      label: "クラフトワールドワイド",
    },
    {
      label: "ジーニー",
    },
    {
      label: "東映エージエンシー ",
    },
    {
      label: "サニーサイドアップ",
    },
    {
      label: "日本中央競馬会",
    },
    {
      label: "TATRAS INTERNATIONAL",
    },
    {
      label: "Nomad Tokyo合同会社",
    },
    {
      label: "シグナル",
    },
    {
      label: "ゴラク",
    },
  ];
  optionsOp2 = [
    {
      label: "田中真哉",
    },
    {
      label: "國部卓爾",
    },
    {
      label: "江島",
    },
    {
      label: "務",
    },
    {
      label: "神野秀二郎",
    },
    {
      label: "石川幸",
    },
    {
      label: "大竹",
    },
    {
      label: "辻本",
    },
    {
      label: "光田圭吾",
    },
    {
      label: "小針有貴",
    },
    {
      label: "高橋三千夫",
    },
    {
      label: "岩崎",
    },
    {
      label: "山本晋治",
    },
    {
      label: "岩崎",
    },
    {
      label: "村瀬智史",
    },
    {
      label: "折原慎吾",
    },
    {
      label: "藤井伽奈",
    },
    {
      label: "山本り央",
    },
    {
      label: "北島麻琉",
    },
    {
      label: "伊澤　暁",
    },
    {
      label: "高橋ほたる",
    },
    {
      label: "川嶋　康照",
    },
    {
      label: "小泉　圭祐",
    },
    {
      label: "長塚　未来",
    },
    {
      label: "テスト",
    },
  ];
  optionsOp3 = [
    {
      label: "代理店",
    },
    {
      label: "直クライアント",
    },
    {
      label: "その他",
    },
  ];
  optionsOp4 = [
    { label: "広告" },
    { label: "製造/製作" },
    { label: "印刷" },
    { label: "イベント" },
    { label: "通信/放送サービス" },
    { label: "ファッション" },
    { label: "美容" },
    { label: "化粧品" },
    { label: "端末" },
    { label: "家電" },
    { label: "自動車/バイク" },
    { label: "音楽" },
    { label: "スポーツ" },
    { label: "旅行" },
    { label: "ゲーム" },
    { label: "書籍" },
    { label: "映画" },
    { label: "食品" },
    { label: "飲料" },
    { label: "外食" },
    { label: "運輸・観光" },
    { label: "教育" },
    { label: "医療/医薬" },
    { label: "証券" },
    { label: "不動産" },
    { label: "金融/保険" },
    { label: "消費者金融" },
    { label: "官庁" },
    { label: "政治/宗教" },
    { label: "マッチング" },
    { label: "ギャンブル" },
    { label: "その他" },
  ];
  optionsOp5 = [
    { label: "ビルボード" },
    { label: "ポスタージャック" },
    { label: "商業施設" },
    { label: "デジタルビルボード" },
    { label: "交通" },
    { label: "その他メディア" },
    { label: "その他" },
  ];

  //-------------------------------------------
  //円グラフのデータ。
  chartData4d1 = {
    labels: [
      "ビルボード",
      "ポスタージャック",
      "商業施設",
      "デジタルビルボード",
      "交通",
      "その他メディア",
      "その他",
    ],
    datasets: [
      {
        data: [30, 20, 15, 10, 5, 15, 5],
        backgroundColor: [
          "#77CEFF",
          "#0079AF",
          "#123E6B",
          "#97B0C4",
          "#A5C8ED",
        ],
      },
    ],
  };
  //円グラフのデータ2。
  chartData4d3 = {
    labels: [
      "広告",
      "製造/製作",
      "印刷",
      "イベント",
      "通信/放送サービス",
      "ファッション",
      "美容",
      "化粧品",
      "端末",
      "家電",
      "自動車/バイク",
      "音楽",
      "スポーツ",
      "旅行",
      "ゲーム",
      "書籍",
      "映画",
      "食品",
      "飲料",
      "運輸・観光",
      "教育",
      "外食",
      "医療/医薬",
      "証券",
      "金融/保険",
      "不動産",
      "消費者金融",
      "官庁",
      "政治/宗教",
      "マッチング",
      "ギャンブル",
      "その他",
    ],
    datasets: [
      {
        data: [
          3, 2, 4, 5, 6, 4, 2, 3, 4, 4, 2, 2, 2, 3, 4, 4, 5, 2, 1, 2, 2, 2, 2,
          3, 4, 5, 4, 5, 3, 3, 2, 1,
        ],
        backgroundColor: [
          "#77CEFF",
          "#0079AF",
          "#123E6B",
          "#97B0C4",
          "#A5C8ED",
        ],
      },
    ],
  };

  //円グラフのオプション。
  chartOptions4d1 = ref<ChartOptions<"doughnut">>({
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  });

  reRender = true;

  //折れ線＋棒グラフのデータ2。
  chartData4d4 = {
    labels: [
      "2023/1",
      "2023/2",
      "2023/3",
      "2023/4",
      "2023/5",
      "2023/6",
      "2023/7",
      "2023/8",
      "2023/9",
      "2023/10",
      "2023/11",
      "2023/12",
    ],
    datasets: [
      {
        type: "line",
        data: [
          203000000, 519000000, 835000000, 1251000000, 1667000000, 2083000000,
          2499000000, 2915000000, 3331000000, 3747000000, 4163000000,
          4579000000,
        ],
        label: "ビルボード",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 1,
        backgroundColor: "rgba(26,179,148,0.5)",
        borderColor: "rgba(26,179,148,0.7)",
      },
      {
        type: "line",
        data: [
          200000000, 400000000, 600000000, 900000000, 1200000000, 1500000000,
          1800000000, 2100000000, 2400000000, 2700000000, 3000000000,
          3300000000,
        ],
        label: "ポスタージャック",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 1,
        backgroundColor: "rgba(26,179,148,0.5)",
        borderColor: "rgba(26,179,148,0.7)",
      },
      {
        type: "line",
        data: [
          197000000, 281000000, 365000000, 549000000, 733000000, 917000000,
          1101000000, 1285000000, 1469000000, 1653000000, 1837000000,
          2021000000,
        ],
        label: "商業施設",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#8297b3",
        borderColor: "#8297b3",
      },
      {
        type: "line",
        data: [
          194000000, 162000000, 130000000, 198000000, 266000000, 334000000,
          402000000, 470000000, 538000000, 606000000, 674000000, 742000000,
        ],
        label: "デジタルビルボード",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
      {
        type: "line",
        data: [
          212000000, 876000000, 1540000000, 2304000000, 3068000000, 3832000000,
          4596000000, 5360000000, 6124000000, 6888000000, 7652000000,
          8416000000,
        ],
        label: "交通",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
      {
        type: "line",
        data: [
          209000000, 757000000, 1305000000, 1953000000, 2601000000, 3249000000,
          3897000000, 4545000000, 5193000000, 5841000000, 6489000000,
          7137000000,
        ],
        label: "その他メディア",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
      {
        type: "line",
        data: [
          206000000, 638000000, 1070000000, 1602000000, 2134000000, 2666000000,
          3198000000, 3730000000, 4262000000, 4794000000, 5326000000,
          5858000000,
        ],
        label: "その他",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
    ],
  };
  //折れ線＋棒グラフのオプション。
  chartOptions4d2 = ref<ChartOptions<"bar">>({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        // stacked: true,
      },
      "y-axis-1": {
        // stacked: true,
        title: {
          display: true,
          text: "月別売上額(円)",
        },
      },
      "y-axis-2": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "累積売上額(円)",
        },
        ticks: {
          display: true,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  });
  //折れ線＋棒グラフのオプション2。
  chartOptions4d4 = ref<ChartOptions<"bar">>({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        // stacked: true,
      },
    },
  });
}
