import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoHistorysGetReqImple } from "mediadept-common/src/common/dto/DtoHistorysGetReqImple";
import { DtoHistorysGetResImple } from "mediadept-common/src/common/dto/DtoHistorysGetResImple";
import { DtoHistorysPostReq } from "mediadept-common/src/common/dto/abst/DtoHistorysPostReq";
import { DtoHistorysPostRes } from "mediadept-common/src/common/dto/abst/DtoHistorysPostRes";
import { DtoHistorysPutReq } from "mediadept-common/src/common/dto/abst/DtoHistorysPutReq";
import { DtoHistorysPutRes } from "mediadept-common/src/common/dto/abst/DtoHistorysPutRes";
import { DtoHistorysDeleteReq } from "mediadept-common/src/common/dto/abst/DtoHistorysDeleteReq";
import { DtoHistorysDeleteRes } from "mediadept-common/src/common/dto/abst/DtoHistorysDeleteRes";

export class Cl_history {
  public static async getList(
    dto: DtoHistorysGetReqImple
  ): Promise<DtoHistorysGetResImple> {
    return Object.assign(
      new DtoHistorysGetResImple(),
      await CallAPI.getRequest(
        "/historys?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoHistorysGetReqImple
  ): Promise<DtoHistorysGetResImple> {
    return Object.assign(
      new DtoHistorysGetResImple(),
      await CallAPI.getRequest("/historys/" + dto.c_history_id)
    );
  }

  public static async post(
    dto: DtoHistorysPostReq
  ): Promise<DtoHistorysPostRes> {
    return Object.assign(
      new DtoHistorysPostRes(),
      await CallAPI.postRequest("/historys", dto)
    );
  }

  public static async put(dto: DtoHistorysPutReq): Promise<DtoHistorysPutRes> {
    return Object.assign(
      new DtoHistorysPutRes(),
      await CallAPI.putRequest("/historys/" + dto.c_history_id, dto)
    );
  }

  public async del(dto: DtoHistorysDeleteReq): Promise<DtoHistorysDeleteRes> {
    return Object.assign(
      new DtoHistorysDeleteRes(),
      await CallAPI.deleteRequest("/historys?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
