import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoFileReportsGetReq } from "./abst/DtoFileReportsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoSendMailsPostReq {
  /**
   * メールの送信処理をルーティングする為の文字列
   * CONST_MAILTYPE_xxxxxxの定数を指定
   */
  public mailType: string | null = null;

  //-------------------
  /**
   * 送信対象のstaffidの配列
   */
  public sendStaffIds: number[] = [];
  /**
   * メール本文
   */
  public mailBody: string | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
