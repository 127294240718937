import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityRequestRemote } from "../../entity/EntityRequestRemote";

export class DtoRequestRemotesPostReq extends EntityRequestRemote {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
