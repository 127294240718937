export class EntityGoal {
public c_goal_id:number | null = null;
public c_goal_year:number | null = null;
public c_goal_earnings_1:number | null = null;
public c_goal_gross_1:number | null = null;
public c_goal_earnings_2:number | null = null;
public c_goal_gross_2:number | null = null;
public c_goal_earnings_3:number | null = null;
public c_goal_gross_3:number | null = null;
public c_goal_earnings_4:number | null = null;
public c_goal_gross_4:number | null = null;
public c_goal_earnings_5:number | null = null;
public c_goal_gross_5:number | null = null;
public c_goal_earnings_6:number | null = null;
public c_goal_gross_6:number | null = null;
public c_goal_earnings_7:number | null = null;
public c_goal_gross_7:number | null = null;
public c_goal_earnings_8:number | null = null;
public c_goal_gross_8:number | null = null;
public c_goal_earnings_9:number | null = null;
public c_goal_gross_9:number | null = null;
public c_goal_earnings_10:number | null = null;
public c_goal_gross_10:number | null = null;
public c_goal_earnings_11:number | null = null;
public c_goal_gross_11:number | null = null;
public c_goal_earnings_12:number | null = null;
public c_goal_gross_12:number | null = null;
public c_goal_flag_del:number | null = null;
public c_goal_reg_user:string | null = null;
public c_goal_reg_at:Date | null = null;
public c_goal_upd_user:string | null = null;
public c_goal_upd_at:Date | null = null;
}