export class EntityAttendance {
public c_attendance_id:number | null = null;
public c_staff_id:number | null = null;
public c_attendance_date:Date | null = null;
public c_attendance_time_work:string | null = null;
public c_attendance_lat_work:string | null = null;
public c_attendance_lng_work:string | null = null;
public c_attendance_time_goout_start:string | null = null;
public c_attendance_lat_goout_start:string | null = null;
public c_attendance_lng_goout_start:string | null = null;
public c_attendance_time_goout_end:string | null = null;
public c_attendance_lat_goout_end:string | null = null;
public c_attendance_lng_goout_end:string | null = null;
public c_attendance_time_leaving:string | null = null;
public c_attendance_lat_leaving:string | null = null;
public c_attendance_lng_leaving:string | null = null;
public c_attendance_ot1_aftertime:string | null = null;
public c_attendance_ot2_nighttime:string | null = null;
public c_attendance_ot3_hd_aftertime:string | null = null;
public c_attendance_ot4_hd_nighttime:string | null = null;
public c_attendance_ot5_sp_aftertime:string | null = null;
public c_attendance_ot6_sp_nighttime:string | null = null;
public c_attendance_remarks:string | null = null;
public c_attendance_correct_time_work:string | null = null;
public c_attendance_correct_time_goout_start:string | null = null;
public c_attendance_correct_time_goout_end:string | null = null;
public c_attendance_correct_time_leaving:string | null = null;
public c_attendance_correct_time_rest:string | null = null;
public c_attendance_flag_upd_work:number | null = null;
public c_attendance_flag_upd_goout_start:number | null = null;
public c_attendance_flag_upd_goout_end:number | null = null;
public c_attendance_flag_upd_leaving:number | null = null;
public c_attendance_flag_upd_aftertime:number | null = null;
public c_attendance_flag_upd_nighttime:number | null = null;
public c_attendance_flag_upd_hd_aftertime:number | null = null;
public c_attendance_flag_upd_hd_nighttime:number | null = null;
public c_attendance_flag_upd_sp_aftertime:number | null = null;
public c_attendance_flag_upd_sp_nighttime:number | null = null;
public c_attendance_reg_user:string | null = null;
public c_attendance_reg_at:Date | null = null;
public c_attendance_upd_user:string | null = null;
public c_attendance_upd_at:Date | null = null;
}