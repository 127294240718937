export class EntityBank {
public c_bank_id:number | null = null;
public c_bank_name:string | null = null;
public c_bank_branch_name:string | null = null;
public c_bank_type:number | null = null;
public c_bank_number:string | null = null;
public c_bank_flag_del:number | null = null;
public c_bank_reg_user:string | null = null;
public c_bank_reg_at:Date | null = null;
public c_bank_upd_user:string | null = null;
public c_bank_upd_at:Date | null = null;
}