export class EntityDocfile {
public c_docfile_id:number | null = null;
public c_doc_id:number | null = null;
public c_staff_id:number | null = null;
public c_docfile_ver:string | null = null;
public c_docfile_imgpath:string | null = null;
public c_docfile_filename:string | null = null;
public c_docfile_filetype:string | null = null;
public c_docfile_size:number | null = null;
public c_docfile_dig:string | null = null;
public c_docfile_ocrtxt:string | null = null;
public c_docfile_flag_del:number | null = null;
public c_docfile_reg_user:string | null = null;
public c_docfile_reg_at:Date | null = null;
public c_docfile_upd_user:string | null = null;
public c_docfile_upd_at:Date | null = null;
}