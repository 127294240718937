
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_goal } from "@/functions/Cl_goal";
import { DtoGoalsGetReqImple } from "mediadept-common/src/common/dto/DtoGoalsGetReqImple";
import { DtoGoalsGetResImple } from "mediadept-common/src/common/dto/DtoGoalsGetResImple";
import { DtoGoalsPutReq } from "mediadept-common/src/common/dto/abst/DtoGoalsPutReq";
import { DtoGoalsPutRes } from "mediadept-common/src/common/dto/abst/DtoGoalsPutRes";
import { DtoGoalsPostReq } from "mediadept-common/src/common/dto/abst/DtoGoalsPostReq";
import { DtoGoalsPostRes } from "mediadept-common/src/common/dto/abst/DtoGoalsPostRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    Mo_modal_alert,
    vSelect,
  },
})
export default class Salestargetedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    goalYear: Yup.string().required("年度を入力してください。"),
    goalEarnings1: Yup.string().required(
      "売上目標額(期首1ヶ月)を入力してください。"
    ),
    goalEarnings2: Yup.string().required(
      "売上目標額(期首2ヶ月)を入力してください。"
    ),
    goalEarnings3: Yup.string().required(
      "売上目標額(期首3ヶ月)を入力してください。"
    ),
    goalEarnings4: Yup.string().required(
      "売上目標額(期首4ヶ月)を入力してください。"
    ),
    goalEarnings5: Yup.string().required(
      "売上目標額(期首5ヶ月)を入力してください。"
    ),
    goalEarnings6: Yup.string().required(
      "売上目標額(期首6ヶ月)を入力してください。"
    ),
    goalEarnings7: Yup.string().required(
      "売上目標額(期首7ヶ月)を入力してください。"
    ),
    goalEarnings8: Yup.string().required(
      "売上目標額(期首8ヶ月)を入力してください。"
    ),
    goalEarnings9: Yup.string().required(
      "売上目標額(期首9ヶ月)を入力してください。"
    ),
    goalEarnings10: Yup.string().required(
      "売上目標額(期首10ヶ月)を入力してください。"
    ),
    goalEarnings11: Yup.string().required(
      "売上目標額(期首11ヶ月)を入力してください。"
    ),
    goalEarnings12: Yup.string().required(
      "売上目標額(期首12ヶ月)を入力してください。"
    ),
    goalGross1: Yup.string().required(
      "粗利目標額(期首1ヶ月)を入力してください。"
    ),
    goalGross2: Yup.string().required(
      "粗利目標額(期首2ヶ月)を入力してください。"
    ),
    goalGross3: Yup.string().required(
      "粗利目標額(期首3ヶ月)を入力してください。"
    ),
    goalGross4: Yup.string().required(
      "粗利目標額(期首4ヶ月)を入力してください。"
    ),
    goalGross5: Yup.string().required(
      "粗利目標額(期首5ヶ月)を入力してください。"
    ),
    goalGross6: Yup.string().required(
      "粗利目標額(期首6ヶ月)を入力してください。"
    ),
    goalGross7: Yup.string().required(
      "粗利目標額(期首7ヶ月)を入力してください。"
    ),
    goalGross8: Yup.string().required(
      "粗利目標額(期首8ヶ月)を入力してください。"
    ),
    goalGross9: Yup.string().required(
      "粗利目標額(期首9ヶ月)を入力してください。"
    ),
    goalGross10: Yup.string().required(
      "粗利目標額(期首10ヶ月)を入力してください。"
    ),
    goalGross11: Yup.string().required(
      "粗利目標額(期首11ヶ月)を入力してください。"
    ),
    goalGross12: Yup.string().required(
      "粗利目標額(期首12ヶ月)を入力してください。"
    ),
  });
  schemaUpd = Yup.object().shape({
    goalYear: Yup.string().required("年度を入力してください。"),
    goalEarnings1: Yup.string().required(
      "売上目標額(期首1ヶ月)を入力してください。"
    ),
    goalEarnings2: Yup.string().required(
      "売上目標額(期首2ヶ月)を入力してください。"
    ),
    goalEarnings3: Yup.string().required(
      "売上目標額(期首3ヶ月)を入力してください。"
    ),
    goalEarnings4: Yup.string().required(
      "売上目標額(期首4ヶ月)を入力してください。"
    ),
    goalEarnings5: Yup.string().required(
      "売上目標額(期首5ヶ月)を入力してください。"
    ),
    goalEarnings6: Yup.string().required(
      "売上目標額(期首6ヶ月)を入力してください。"
    ),
    goalEarnings7: Yup.string().required(
      "売上目標額(期首7ヶ月)を入力してください。"
    ),
    goalEarnings8: Yup.string().required(
      "売上目標額(期首8ヶ月)を入力してください。"
    ),
    goalEarnings9: Yup.string().required(
      "売上目標額(期首9ヶ月)を入力してください。"
    ),
    goalEarnings10: Yup.string().required(
      "売上目標額(期首10ヶ月)を入力してください。"
    ),
    goalEarnings11: Yup.string().required(
      "売上目標額(期首11ヶ月)を入力してください。"
    ),
    goalEarnings12: Yup.string().required(
      "売上目標額(期首12ヶ月)を入力してください。"
    ),
    goalGross1: Yup.string().required(
      "粗利目標額(期首1ヶ月)を入力してください。"
    ),
    goalGross2: Yup.string().required(
      "粗利目標額(期首2ヶ月)を入力してください。"
    ),
    goalGross3: Yup.string().required(
      "粗利目標額(期首3ヶ月)を入力してください。"
    ),
    goalGross4: Yup.string().required(
      "粗利目標額(期首4ヶ月)を入力してください。"
    ),
    goalGross5: Yup.string().required(
      "粗利目標額(期首5ヶ月)を入力してください。"
    ),
    goalGross6: Yup.string().required(
      "粗利目標額(期首6ヶ月)を入力してください。"
    ),
    goalGross7: Yup.string().required(
      "粗利目標額(期首7ヶ月)を入力してください。"
    ),
    goalGross8: Yup.string().required(
      "粗利目標額(期首8ヶ月)を入力してください。"
    ),
    goalGross9: Yup.string().required(
      "粗利目標額(期首9ヶ月)を入力してください。"
    ),
    goalGross10: Yup.string().required(
      "粗利目標額(期首10ヶ月)を入力してください。"
    ),
    goalGross11: Yup.string().required(
      "粗利目標額(期首11ヶ月)を入力してください。"
    ),
    goalGross12: Yup.string().required(
      "粗利目標額(期首12ヶ月)を入力してください。"
    ),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoGoalsGetResImple();
  btnDisable = false;
  isEditMode = true;

  domearnings_1 = "";
  domearnings_2 = "";
  domearnings_3 = "";
  domearnings_4 = "";
  domearnings_5 = "";
  domearnings_6 = "";
  domearnings_7 = "";
  domearnings_8 = "";
  domearnings_9 = "";
  domearnings_10 = "";
  domearnings_11 = "";
  domearnings_12 = "";

  domgoal_gross_1 = "";
  domgoal_gross_2 = "";
  domgoal_gross_3 = "";
  domgoal_gross_4 = "";
  domgoal_gross_5 = "";
  domgoal_gross_6 = "";
  domgoal_gross_7 = "";
  domgoal_gross_8 = "";
  domgoal_gross_9 = "";
  domgoal_gross_10 = "";
  domgoal_gross_11 = "";
  domgoal_gross_12 = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        //this.delegate.c_client_company_type = 1;
        //this.delegate.c_client_flag_company_signage_posi = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoGoalsGetReqImple();

      dto.c_goal_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_goal.get(dto);

      //レスポンス処理
      //-------
      if (this.delegate.c_goal_earnings_1 != null) {
        this.domearnings_1 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_1.toString()
        );
      }

      if (this.delegate.c_goal_earnings_2 != null) {
        this.domearnings_2 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_2.toString()
        );
      }

      if (this.delegate.c_goal_earnings_3 != null) {
        this.domearnings_3 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_3.toString()
        );
      }

      if (this.delegate.c_goal_earnings_4 != null) {
        this.domearnings_4 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_4.toString()
        );
      }

      if (this.delegate.c_goal_earnings_5 != null) {
        this.domearnings_5 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_5.toString()
        );
      }

      if (this.delegate.c_goal_earnings_6 != null) {
        this.domearnings_6 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_6.toString()
        );
      }

      if (this.delegate.c_goal_earnings_7 != null) {
        this.domearnings_7 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_7.toString()
        );
      }

      if (this.delegate.c_goal_earnings_8 != null) {
        this.domearnings_8 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_8.toString()
        );
      }

      if (this.delegate.c_goal_earnings_9 != null) {
        this.domearnings_9 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_9.toString()
        );
      }

      if (this.delegate.c_goal_earnings_10 != null) {
        this.domearnings_10 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_10.toString()
        );
      }

      if (this.delegate.c_goal_earnings_11 != null) {
        this.domearnings_11 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_11.toString()
        );
      }

      if (this.delegate.c_goal_earnings_12 != null) {
        this.domearnings_12 = await this.kanmaIns_get(
          this.delegate.c_goal_earnings_12.toString()
        );
      }

      if (this.delegate.c_goal_gross_1 != null) {
        this.domgoal_gross_1 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_1.toString()
        );
      }

      if (this.delegate.c_goal_gross_2 != null) {
        this.domgoal_gross_2 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_2.toString()
        );
      }

      if (this.delegate.c_goal_gross_3 != null) {
        this.domgoal_gross_3 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_3.toString()
        );
      }

      if (this.delegate.c_goal_gross_4 != null) {
        this.domgoal_gross_4 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_4.toString()
        );
      }

      if (this.delegate.c_goal_gross_5 != null) {
        this.domgoal_gross_5 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_5.toString()
        );
      }

      if (this.delegate.c_goal_gross_6 != null) {
        this.domgoal_gross_6 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_6.toString()
        );
      }

      if (this.delegate.c_goal_gross_7 != null) {
        this.domgoal_gross_7 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_7.toString()
        );
      }

      if (this.delegate.c_goal_gross_8 != null) {
        this.domgoal_gross_8 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_8.toString()
        );
      }

      if (this.delegate.c_goal_gross_9 != null) {
        this.domgoal_gross_9 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_9.toString()
        );
      }

      if (this.delegate.c_goal_gross_10 != null) {
        this.domgoal_gross_10 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_10.toString()
        );
      }

      if (this.delegate.c_goal_gross_11 != null) {
        this.domgoal_gross_11 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_11.toString()
        );
      }

      if (this.delegate.c_goal_gross_12 != null) {
        this.domgoal_gross_12 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_12.toString()
        );
      }

      if (this.delegate.c_goal_gross_1 != null) {
        this.domgoal_gross_1 = await this.kanmaIns_get(
          this.delegate.c_goal_gross_1.toString()
        );
      }

      //IDがnullなら一覧へ遷移
      if (this.delegate.c_goal_id == null) {
        this.$router.push("/salestargetlist");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoGoalsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_goal_id = this.delegate.c_goal_id;
        dto.c_goal_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;

        // this.schemaUpd.validateSync({
        //   goalYear: this.delegate.c_goal_year,
        //   goalEarnings1: this.delegate.c_goal_earnings_1,
        //   goalEarnings2: this.delegate.c_goal_earnings_2,
        //   goalEarnings3: this.delegate.c_goal_earnings_3,
        //   goalEarnings4: this.delegate.c_goal_earnings_4,
        //   goalEarnings5: this.delegate.c_goal_earnings_5,
        //   goalEarnings6: this.delegate.c_goal_earnings_6,
        //   goalEarnings7: this.delegate.c_goal_earnings_7,
        //   goalEarnings8: this.delegate.c_goal_earnings_8,
        //   goalEarnings9: this.delegate.c_goal_earnings_9,
        //   goalEarnings10: this.delegate.c_goal_earnings_10,
        //   goalEarnings11: this.delegate.c_goal_earnings_11,
        //   goalEarnings12: this.delegate.c_goal_earnings_12,
        //   goalGross1: this.delegate.c_goal_gross_1,
        //   goalGross2: this.delegate.c_goal_gross_2,
        //   goalGross3: this.delegate.c_goal_gross_3,
        //   goalGross4: this.delegate.c_goal_gross_4,
        //   goalGross5: this.delegate.c_goal_gross_5,
        //   goalGross6: this.delegate.c_goal_gross_6,
        //   goalGross7: this.delegate.c_goal_gross_7,
        //   goalGross8: this.delegate.c_goal_gross_8,
        //   goalGross9: this.delegate.c_goal_gross_9,
        //   goalGross10: this.delegate.c_goal_gross_10,
        //   goalGross11: this.delegate.c_goal_gross_11,
        //   goalGross12: this.delegate.c_goal_gross_12,
        // });

        this.schemaAdd.validateSync({
          goalYear: this.delegate.c_goal_year,
          goalEarnings1: this.domearnings_1,
          goalEarnings2: this.domearnings_2,
          goalEarnings3: this.domearnings_3,
          goalEarnings4: this.domearnings_4,
          goalEarnings5: this.domearnings_5,
          goalEarnings6: this.domearnings_6,
          goalEarnings7: this.domearnings_7,
          goalEarnings8: this.domearnings_8,
          goalEarnings9: this.domearnings_9,
          goalEarnings10: this.domearnings_10,
          goalEarnings11: this.domearnings_11,
          goalEarnings12: this.domearnings_12,
          goalGross1: this.domgoal_gross_1,
          goalGross2: this.domgoal_gross_2,
          goalGross3: this.domgoal_gross_3,
          goalGross4: this.domgoal_gross_4,
          goalGross5: this.domgoal_gross_5,
          goalGross6: this.domgoal_gross_6,
          goalGross7: this.domgoal_gross_7,
          goalGross8: this.domgoal_gross_8,
          goalGross9: this.domgoal_gross_9,
          goalGross10: this.domgoal_gross_10,
          goalGross11: this.domgoal_gross_11,
          goalGross12: this.domgoal_gross_12,
        });

        await this.cnvData4Add();

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoGoalsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_goal.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "データを削除しました。";
      } else {
        //更新処理：
        this.message4ModalSuccess = "データを更新しました。";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;

      // this.schemaAdd.validateSync({
      //   goalYear: this.delegate.c_goal_year,
      //   goalEarnings1: this.delegate.c_goal_earnings_1,
      //   goalEarnings2: this.delegate.c_goal_earnings_2,
      //   goalEarnings3: this.delegate.c_goal_earnings_3,
      //   goalEarnings4: this.delegate.c_goal_earnings_4,
      //   goalEarnings5: this.delegate.c_goal_earnings_5,
      //   goalEarnings6: this.delegate.c_goal_earnings_6,
      //   goalEarnings7: this.delegate.c_goal_earnings_7,
      //   goalEarnings8: this.delegate.c_goal_earnings_8,
      //   goalEarnings9: this.delegate.c_goal_earnings_9,
      //   goalEarnings10: this.delegate.c_goal_earnings_10,
      //   goalEarnings11: this.delegate.c_goal_earnings_11,
      //   goalEarnings12: this.delegate.c_goal_earnings_12,
      //   goalGross1: this.delegate.c_goal_gross_1,
      //   goalGross2: this.delegate.c_goal_gross_2,
      //   goalGross3: this.delegate.c_goal_gross_3,
      //   goalGross4: this.delegate.c_goal_gross_4,
      //   goalGross5: this.delegate.c_goal_gross_5,
      //   goalGross6: this.delegate.c_goal_gross_6,
      //   goalGross7: this.delegate.c_goal_gross_7,
      //   goalGross8: this.delegate.c_goal_gross_8,
      //   goalGross9: this.delegate.c_goal_gross_9,
      //   goalGross10: this.delegate.c_goal_gross_10,
      //   goalGross11: this.delegate.c_goal_gross_11,
      //   goalGross12: this.delegate.c_goal_gross_12,
      // });

      this.schemaAdd.validateSync({
        goalYear: this.delegate.c_goal_year,
        goalEarnings1: this.domearnings_1,
        goalEarnings2: this.domearnings_2,
        goalEarnings3: this.domearnings_3,
        goalEarnings4: this.domearnings_4,
        goalEarnings5: this.domearnings_5,
        goalEarnings6: this.domearnings_6,
        goalEarnings7: this.domearnings_7,
        goalEarnings8: this.domearnings_8,
        goalEarnings9: this.domearnings_9,
        goalEarnings10: this.domearnings_10,
        goalEarnings11: this.domearnings_11,
        goalEarnings12: this.domearnings_12,
        goalGross1: this.domgoal_gross_1,
        goalGross2: this.domgoal_gross_2,
        goalGross3: this.domgoal_gross_3,
        goalGross4: this.domgoal_gross_4,
        goalGross5: this.domgoal_gross_5,
        goalGross6: this.domgoal_gross_6,
        goalGross7: this.domgoal_gross_7,
        goalGross8: this.domgoal_gross_8,
        goalGross9: this.domgoal_gross_9,
        goalGross10: this.domgoal_gross_10,
        goalGross11: this.domgoal_gross_11,
        goalGross12: this.domgoal_gross_12,
      });

      //準備
      //-------
      await this.cnvData4Add();

      let dto = Object.assign(new DtoGoalsPostReq(), this.delegate);
      dto.c_goal_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_goal.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/salestargetedit",
        query: { id: res.c_goal_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　ADD用。
   */
  async cnvData4Add() {
    if (this.domearnings_1 != null) {
      let domearnings_1Tmp = await this.kanmaDel_get(this.domearnings_1);
      this.delegate.c_goal_earnings_1 = Number(domearnings_1Tmp);
    }

    if (this.domearnings_2 != null) {
      let domearnings_2Tmp = await this.kanmaDel_get(this.domearnings_2);
      this.delegate.c_goal_earnings_2 = Number(domearnings_2Tmp);
    }

    if (this.domearnings_3 != null) {
      let domearnings_3Tmp = await this.kanmaDel_get(this.domearnings_3);
      this.delegate.c_goal_earnings_3 = Number(domearnings_3Tmp);
    }

    if (this.domearnings_4 != null) {
      let domearnings_4Tmp = await this.kanmaDel_get(this.domearnings_4);
      this.delegate.c_goal_earnings_4 = Number(domearnings_4Tmp);
    }

    if (this.domearnings_5 != null) {
      let domearnings_5Tmp = await this.kanmaDel_get(this.domearnings_5);
      this.delegate.c_goal_earnings_5 = Number(domearnings_5Tmp);
    }

    if (this.domearnings_6 != null) {
      let domearnings_6Tmp = await this.kanmaDel_get(this.domearnings_6);
      this.delegate.c_goal_earnings_6 = Number(domearnings_6Tmp);
    }

    if (this.domearnings_7 != null) {
      let domearnings_7Tmp = await this.kanmaDel_get(this.domearnings_7);
      this.delegate.c_goal_earnings_7 = Number(domearnings_7Tmp);
    }

    if (this.domearnings_8 != null) {
      let domearnings_8Tmp = await this.kanmaDel_get(this.domearnings_8);
      this.delegate.c_goal_earnings_8 = Number(domearnings_8Tmp);
    }

    if (this.domearnings_9 != null) {
      let domearnings_9Tmp = await this.kanmaDel_get(this.domearnings_9);
      this.delegate.c_goal_earnings_9 = Number(domearnings_9Tmp);
    }

    if (this.domearnings_10 != null) {
      let domearnings_10Tmp = await this.kanmaDel_get(this.domearnings_10);
      this.delegate.c_goal_earnings_10 = Number(domearnings_10Tmp);
    }

    if (this.domearnings_11 != null) {
      let domearnings_11Tmp = await this.kanmaDel_get(this.domearnings_11);
      this.delegate.c_goal_earnings_11 = Number(domearnings_11Tmp);
    }

    if (this.domearnings_12 != null) {
      let domearnings_12Tmp = await this.kanmaDel_get(this.domearnings_12);
      this.delegate.c_goal_earnings_12 = Number(domearnings_12Tmp);
    }

    if (this.domgoal_gross_1 != null) {
      let domgoal_gross_1Tmp = await this.kanmaDel_get(this.domgoal_gross_1);
      this.delegate.c_goal_gross_1 = Number(domgoal_gross_1Tmp);
    }

    if (this.domgoal_gross_2 != null) {
      let domgoal_gross_2Tmp = await this.kanmaDel_get(this.domgoal_gross_2);
      this.delegate.c_goal_gross_2 = Number(domgoal_gross_2Tmp);
    }

    if (this.domgoal_gross_3 != null) {
      let domgoal_gross_3Tmp = await this.kanmaDel_get(this.domgoal_gross_3);
      this.delegate.c_goal_gross_3 = Number(domgoal_gross_3Tmp);
    }

    if (this.domgoal_gross_4 != null) {
      let domgoal_gross_4Tmp = await this.kanmaDel_get(this.domgoal_gross_4);
      this.delegate.c_goal_gross_4 = Number(domgoal_gross_4Tmp);
    }

    if (this.domgoal_gross_5 != null) {
      let domgoal_gross_5Tmp = await this.kanmaDel_get(this.domgoal_gross_5);
      this.delegate.c_goal_gross_5 = Number(domgoal_gross_5Tmp);
    }

    if (this.domgoal_gross_6 != null) {
      let domgoal_gross_6Tmp = await this.kanmaDel_get(this.domgoal_gross_6);
      this.delegate.c_goal_gross_6 = Number(domgoal_gross_6Tmp);
    }

    if (this.domgoal_gross_7 != null) {
      let domgoal_gross_7Tmp = await this.kanmaDel_get(this.domgoal_gross_7);
      this.delegate.c_goal_gross_7 = Number(domgoal_gross_7Tmp);
    }

    if (this.domgoal_gross_8 != null) {
      let domgoal_gross_8Tmp = await this.kanmaDel_get(this.domgoal_gross_8);
      this.delegate.c_goal_gross_8 = Number(domgoal_gross_8Tmp);
    }

    if (this.domgoal_gross_9 != null) {
      let domgoal_gross_9Tmp = await this.kanmaDel_get(this.domgoal_gross_9);
      this.delegate.c_goal_gross_9 = Number(domgoal_gross_9Tmp);
    }

    if (this.domgoal_gross_10 != null) {
      let domgoal_gross_10Tmp = await this.kanmaDel_get(this.domgoal_gross_10);
      this.delegate.c_goal_gross_10 = Number(domgoal_gross_10Tmp);
    }

    if (this.domgoal_gross_11 != null) {
      let domgoal_gross_11Tmp = await this.kanmaDel_get(this.domgoal_gross_11);
      this.delegate.c_goal_gross_11 = Number(domgoal_gross_11Tmp);
    }

    if (this.domgoal_gross_12 != null) {
      let domgoal_gross_12Tmp = await this.kanmaDel_get(this.domgoal_gross_12);
      this.delegate.c_goal_gross_12 = Number(domgoal_gross_12Tmp);
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_goal_id +
      "】" +
      this.delegate.c_goal_year +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  /**
   * 検索時の処理
   * カンマを消す
   */
  async kanmaDel_get(formData: string): Promise<string> {
    let res = "";

    res = formData.replace(/,/g, "");

    console.log("res");
    console.log(res);

    return res;
  }

  /**
   * 読み込み時の処理
   * カンマをつける
   */
  async kanmaIns_get(formData: string): Promise<string> {
    let res = "";

    let val: string = formData;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    // this.item.cnvPrice = num;

    res = num;

    console.log("res");
    console.log(res);

    return res;
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any, formName: string) {
    // カンマを消す
    if (formName === "domearnings_1") {
      this.domearnings_1 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_2") {
      this.domearnings_2 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_3") {
      this.domearnings_3 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_4") {
      this.domearnings_4 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_5") {
      this.domearnings_5 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_6") {
      this.domearnings_6 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_7") {
      this.domearnings_7 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_8") {
      this.domearnings_8 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_9") {
      this.domearnings_9 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_10") {
      this.domearnings_10 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_11") {
      this.domearnings_11 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domearnings_12") {
      this.domearnings_12 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_1") {
      this.domgoal_gross_1 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_2") {
      this.domgoal_gross_2 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_3") {
      this.domgoal_gross_3 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_4") {
      this.domgoal_gross_4 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_5") {
      this.domgoal_gross_5 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_6") {
      this.domgoal_gross_6 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_7") {
      this.domgoal_gross_7 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_8") {
      this.domgoal_gross_8 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_9") {
      this.domgoal_gross_9 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_10") {
      this.domgoal_gross_10 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_11") {
      this.domgoal_gross_11 = obj.target.value.replace(/,/g, "");
    } else if (formName === "domgoal_gross_12") {
      this.domgoal_gross_12 = obj.target.value.replace(/,/g, "");
    }
    // this.item.cnvPrice = obj.target.value.replace(/,/g, "");
    // if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any, formName: string) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    // this.item.cnvPrice = num;

    if (formName === "domearnings_1") {
      this.domearnings_1 = num;
    } else if (formName === "domearnings_2") {
      this.domearnings_2 = num;
    } else if (formName === "domearnings_3") {
      this.domearnings_3 = num;
    } else if (formName === "domearnings_4") {
      this.domearnings_4 = num;
    } else if (formName === "domearnings_5") {
      this.domearnings_5 = num;
    } else if (formName === "domearnings_6") {
      this.domearnings_6 = num;
    } else if (formName === "domearnings_7") {
      this.domearnings_7 = num;
    } else if (formName === "domearnings_8") {
      this.domearnings_8 = num;
    } else if (formName === "domearnings_9") {
      this.domearnings_9 = num;
    } else if (formName === "domearnings_10") {
      this.domearnings_10 = num;
    } else if (formName === "domearnings_11") {
      this.domearnings_11 = num;
    } else if (formName === "domearnings_12") {
      this.domearnings_12 = num;
    }

    if (formName === "domgoal_gross_1") {
      this.domgoal_gross_1 = num;
    } else if (formName === "domgoal_gross_2") {
      this.domgoal_gross_2 = num;
    } else if (formName === "domgoal_gross_3") {
      this.domgoal_gross_3 = num;
    } else if (formName === "domgoal_gross_4") {
      this.domgoal_gross_4 = num;
    } else if (formName === "domgoal_gross_5") {
      this.domgoal_gross_5 = num;
    } else if (formName === "domgoal_gross_6") {
      this.domgoal_gross_6 = num;
    } else if (formName === "domgoal_gross_7") {
      this.domgoal_gross_7 = num;
    } else if (formName === "domgoal_gross_8") {
      this.domgoal_gross_8 = num;
    } else if (formName === "domgoal_gross_9") {
      this.domgoal_gross_9 = num;
    } else if (formName === "domgoal_gross_10") {
      this.domgoal_gross_10 = num;
    } else if (formName === "domgoal_gross_11") {
      this.domgoal_gross_11 = num;
    } else if (formName === "domgoal_gross_12") {
      this.domgoal_gross_12 = num;
    }
  }
}
