
import { Prop, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import Mo_modal_n from "@/components/molecules/Mo_modal_n.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { Cl_common } from "@/functions/Cl_common";

import { CONST_MAILTYPE_INSURANCE } from "mediadept-common/src/common/constant/Constants";
import { DtoProjectsGetResImple } from "mediadept-common/src/common/dto/DtoProjectsGetResImple";
import { DtoSendMailsPostReq } from "mediadept-common/src/common/dto/DtoSendMailsPostReq";

//masters用
import { Cl_master } from "@/functions/Cl_master";
import { Cl_sendMail } from "@/functions/Cl_sendMail";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    Mo_modal_l,
    Mo_modal_n,
    vSelect,
  },
})
export default class Or_insurance extends Vue {
  @Prop({ required: true })
  projectDataInsura: DtoProjectsGetResImple;

  /**
   * 保険申請担当者
   */
  delegate4list = new DtoMastersGetRes();
  /**
   * メール送信対象者の配列
   */
  selectedIds: number[] = [];
  /**
   * メール本文
   */
  mailbody = "";

  //----------------------
  //DOM連携用プロパティ
  //----------------------

  //--------

  //ボタン制御用
  btnDisable = false;

  //----------------------
  //バリデーション
  //----------------------
  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    sendTo: Yup.array()
      .of(Yup.number())
      .min(1, "送信先を選択してください") // 選択された要素が1つ以上であることを検証
      .required("送信先を選択してください"),
  });

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //コンポーネントが表示されていない、読み込み時に呼ばれるので注意。
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-body");
    panel?.classList.add("enable-loader");

    try {
      //本文作成
      //-----------------
      let count = await this.setMailbody();

      //対象が0なら終了
      if (count == 0) {
        await this.closeModal();
        //処理対象なしイベント発生
        this.$emit("insura_none");
        return;
      }

      //処理
      //-----------------
      Cl_common.logger("処理実行 保険申請用担当者取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4insurance = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      Cl_common.logger(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  /**
   * メール本文を作成
   */
  async setMailbody(): Promise<number> {
    let count = 0;
    let mailBodyTmp = "担当者様\n\n";
    mailBodyTmp += "お疲れ様です。\n\n";
    mailBodyTmp += "下記案件につきまして保険加入をお願い致します。\n\n";
    mailBodyTmp += "------------------------------------\n";
    for (let i = 0; i < this.projectDataInsura.supplys.length; ++i) {
      console.log(this.projectDataInsura.supplys[i].c_supply_flag_insurance);
      if (
        this.projectDataInsura.supplys[i].c_variation_id == null ||
        this.projectDataInsura.supplys[i].c_supply_flag_insurance == 1
      ) {
        continue;
      }

      let sponsor_name = "";
      let variation_size = "";
      let item_name = "";
      let DateStart = "";
      let DateEnd = "";

      if (this.projectDataInsura.c_project_sponsor_name != null) {
        sponsor_name = this.projectDataInsura.c_project_sponsor_name;
      }

      if (this.projectDataInsura.supplys[i].c_variation_size != null) {
        variation_size = this.projectDataInsura.supplys[i].c_variation_size!;
      }

      if (this.projectDataInsura.supplys[i].c_item_name != null) {
        item_name = this.projectDataInsura.supplys[i].c_item_name!;
      }

      if (
        this.projectDataInsura.supplys[i].cnvDateStart.date != null &&
        this.projectDataInsura.supplys[i].cnvDateStart.date != undefined
      ) {
        DateStart = this.projectDataInsura.supplys[
          i
        ].cnvDateStart.date!.replace(/-/g, "/");
      }

      if (
        this.projectDataInsura.supplys[i].cnvDateEnd.date != null &&
        this.projectDataInsura.supplys[i].cnvDateEnd.date != undefined
      ) {
        DateEnd = this.projectDataInsura.supplys[i].cnvDateEnd.date!.replace(
          /-/g,
          "/"
        );
      }

      mailBodyTmp +=
        "案件番号：" +
        this.projectDataInsura.c_project_number +
        "\n" +
        "広告主：" +
        sponsor_name +
        "\n" +
        "媒体名：" +
        item_name +
        "\n" +
        "サイズ：" +
        variation_size +
        "\n" +
        "期間：" +
        DateStart +
        " ～ " +
        DateEnd +
        "\n" +
        "------------------------------------ \n";
      count++;
    }
    mailBodyTmp += "以上\n\n";
    mailBodyTmp += "ご手配のほど宜しくお願い申し上げます。\n\n";

    this.mailbody = mailBodyTmp;

    return count;
  }
  /**
   * メール送信＋フラグ書き換え処理
   */
  async sendMail() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-body");
    panel?.classList.add("enable-loader");

    try {
      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        sendTo: this.selectedIds,
      });
      //処理
      //-----------------
      Cl_common.logger("処理実行 保険申請送信");

      //準備
      //-------
      let dto = new DtoSendMailsPostReq();

      dto.mailType = CONST_MAILTYPE_INSURANCE;
      dto.mailBody = this.mailbody;
      dto.sendStaffIds = this.selectedIds;

      //API実行
      //-------
      const res = await Cl_sendMail.post(dto);
      Cl_common.logger(this.delegate4list);
      if (res.message == "success") {
        //レスポンス処理
        //-------
        //送信済みフラグ更新処理
        //-------
        for (let i = 0; i < this.projectDataInsura.supplys.length; ++i) {
          if (
            this.projectDataInsura.supplys[i].c_variation_id == null ||
            this.projectDataInsura.supplys[i].c_supply_flag_insurance == 1
          ) {
            continue;
          }
          this.projectDataInsura.supplys[i].c_supply_flag_insurance = 1;
        }
        //処理完了イベント発生
        this.$emit("insura_comp");
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.errors = error;
      } else {
        //それ以外
        //---------------
      }

      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      this.getMastersList();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }

  //------------------------------------
  //詳細データ変換系メソッド
  //------------------------------------

  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal(): Promise<void> {
    this.errors = null;
    this.selectedIds = [];
    const modalComp: any = this.$refs.modal_insurance;
    await modalComp.showModal();

    //起動時に送信対象を読み込み
    await this.getMastersList();
  }
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.modal_insurance;
    modalComp.closeModal();
  }
  // --------
}
