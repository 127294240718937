export class EntityRequestCaruse {
public c_request_caruse_id:number | null = null;
public c_request_id:number | null = null;
public c_request_caruse_lic_date:Date | null = null;
public c_request_caruse_start:Date | null = null;
public c_request_caruse_end:Date | null = null;
public c_request_caruse_pax:number | null = null;
public c_request_caruse_pax_1_name:string | null = null;
public c_request_caruse_pax_1_com:string | null = null;
public c_request_caruse_pax_2_name:string | null = null;
public c_request_caruse_pax_2_com:string | null = null;
public c_request_caruse_pax_3_name:string | null = null;
public c_request_caruse_pax_3_com:string | null = null;
public c_request_caruse_pax_4_name:string | null = null;
public c_request_caruse_pax_4_com:string | null = null;
public c_request_caruse_dest:string | null = null;
public c_request_caruse_cont:string | null = null;
public c_request_caruse_reg_user:string | null = null;
public c_request_caruse_reg_at:Date | null = null;
public c_request_caruse_upd_user:string | null = null;
public c_request_caruse_upd_at:Date | null = null;
}