import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoPro2docsPutReq } from "./abst/DtoPro2docsPutReq";

export class DtoPro2docsPutReqImple extends DtoPro2docsPutReq {
  //対象となる仕入ID
  public supply_id: number | null = null;
  //対象となるプロジェクトID（Pro2DocはAPI側で取得）
  public project_id: number | null = null;
  //0で解除、1で設定
  public linkon: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    return res;
  }
}
