
import { EntitySupplyImple } from "mediadept-common/src/common/entity/EntitySupplyImple";
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  CONST_OP_SUPPLY_TYPE,
  CONST_OP_TAXRATE,
  CONST_AUTH_PAYCHECK,
  CONST_AUTHORITY_STAFF_NYURYOKUSYA,
  CONST_OP_SUBJECT,
} from "mediadept-common/src/common/constant/Constants";
//masters用
import { EntitySupplier } from "mediadept-common/src/common/entity/EntitySupplier";
import { item } from "@/dto/DtoItemsGetRes";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";

import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import "@vuepic/vue-datepicker/dist/main.css";
import { isXMLDoc } from "jquery";
import { DtoDocsGetResImple } from "mediadept-common/src/common/dto/DtoDocsGetResImple";
import { EntityHolisticlock } from "mediadept-common/src/common/entity/EntityHolisticlock";

import { DtoPro2docsPutReqImple } from "mediadept-common/src/common/dto/DtoPro2docsPutReqImple";
import { DtoPro2docsPutResImple } from "mediadept-common/src/common/dto/DtoPro2docsPutResImple";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_pro2doc } from "@/functions/Cl_pro2doc";
import { EntityPro2doc } from "mediadept-common/src/common/entity/EntityPro2doc";

import { DtoApiError } from "mediadept-common/src/common/dto/abst/DtoApiError";
import { AxiosError } from "axios";

@Options({
  components: {
    vSelect,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class Mo_tableJyutu_row_supply extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntitySupplyImple;

  // @Prop({ required: true })
  // items4Supplyers: EntitySupplier[];

  @Prop({ required: true })
  mastersData: DtoMastersGetRes;

  @Prop({ required: true })
  supplyInfo: {
    isLinkMode: boolean;
    doc: DtoDocsGetResImple | null;
    pro2Doc: EntityPro2doc | null;
  };
  @Prop({ required: true })
  disabledDates: EntityHolisticlock[] | null;

  authPayCheck = CONST_AUTH_PAYCHECK;
  authtypeNyuryokusya = CONST_AUTHORITY_STAFF_NYURYOKUSYA;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opSupplyType = CONST_OP_SUPPLY_TYPE;
  opSubject = CONST_OP_SUBJECT;
  opTaxtate = CONST_OP_TAXRATE;
  opSuppliers: EntitySupplier[] = [];

  //ボタン制御用
  btnDisable = false;

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //仕入先の選択肢作成
    this.opSuppliers = this.mastersData.getSupplier4NoneDelete([
      this.item.c_supplier_id!,
    ]);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 日付の最小値を取得
   */
  getMinDate(): Date | undefined {
    //制限は入力者のみ
    //-------
    if (this.$store.state.authType != CONST_AUTHORITY_STAFF_NYURYOKUSYA) {
      return undefined;
    }
    //前々月以前は選択不可
    //-------
    let dt = new Date();
    // let dt = new Date("2024/08/10 17:48:00");//テスト用

    dt.setMonth(dt.getMonth() - 1);

    const limitYear = 2024;
    const limitMonth = 3; // 3月はJavaScriptではインデックスが2、0からカウントされるため1を引く

    // 2024年3月以前であれば2024年3月をセット
    if (
      dt.getFullYear() < limitYear ||
      (dt.getFullYear() === limitYear && dt.getMonth() < limitMonth)
    ) {
      dt = new Date(limitYear, limitMonth); // 新たにDateオブジェクトを作成し、2024年3月を設定
    }

    return dt;
  }
  /**
   * ロックされた日付を設定
   */
  getDisabledDates(): Date[] | undefined {
    if (this.disabledDates == null) {
      return undefined;
    }

    if (this.disabledDates.length == 0) {
      return undefined;
    }

    return this.disabledDates.map(
      (item) => new Date(item.c_holisticlock_date!)
    );
  }
  /**
   * 統合ロックされるべき行かを判定する
   */
  isHolisticlock(): boolean {
    if (this.item.cnvDateMonth.date == null) {
      return false;
    }
    if (this.disabledDates == null) {
      return false;
    }
    if (this.disabledDates.length == 0) {
      return false;
    }

    const isDateMatching = (
      data: EntityHolisticlock[],
      targetDate: Date
    ): boolean => {
      return data.some(
        (item) =>
          new Date(item.c_holisticlock_date!).getTime() === targetDate.getTime()
      );
    };

    return isDateMatching(
      this.disabledDates,
      new Date(this.item.cnvDateMonth.date)
    );
  }
  /**
   * クリック時の選択処理
   */
  async selectRow() {
    if (
      //ロック機能による編集不可
      (this.item.c_supply_flag_lock == 1 &&
        this.$store.state.authType == this.authtypeNyuryokusya) ||
      //リンクモード時は編集不可
      this.supplyInfo.isLinkMode ||
      //支払確定済みによる編集不可
      (this.item.c_supply_flag_paid == 1 &&
        this.$store.state.authType == this.authtypeNyuryokusya) ||
      //統合ロックによる編集不可
      this.isHolisticlock()
    ) {
      return;
    }

    this.item.isSelect = true;
    this.$emit("selected", this.item.c_supply_id);
  }
  /**
   * 商品選択時のイベント処理
   */
  async selectItem() {
    this.$emit("itemSelectSupply", this.item.c_supply_id);
  }
  /**
   * 仕入先選択時のイベント処理
   */
  async selectSupplier() {
    this.$emit("supplierSelectSupply", this.item.c_supply_id);
  }

  /**
   * 選択解除処理
   */
  async noneSelectRow() {
    this.item.isSelect = false;
  }

  //------------------
  async linkDoc(event?: MouseEvent) {
    if (this.item.c_doc_id == null) {
      return;
    }
    Cl_common.goTo("/docedit?id=" + this.item.c_doc_id, this.$router, event);
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // カンマを消す
    this.item.cnvPrice = obj.target.value.replace(/,/g, "");
    // if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    this.item.cnvPrice = num;
  }

  /**
   * 金額再計算イベント発生
   */
  calcProfit() {
    //再計算イベント発生
    //-----------------------
    this.$emit("reCalc");
  }

  //------------------

  //-----------------
  //文書機能関連の処理
  //-----------------
  /**
   * 仕入明細と文書を紐付ける
   */
  async upd4Doc(linkOn: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoPro2docsPutReqImple();

      dto.c_pro2doc_id = 1;
      dto.supply_id = this.item.c_supply_id;
      dto.project_id = this.item.c_project_id;
      dto.c_doc_id = this.supplyInfo.doc!.c_doc_id;
      if (linkOn) {
        dto.linkon = 1;
      } else {
        dto.linkon = 0;
      }

      //API実行
      //-------
      const response = await Cl_pro2doc.put(dto);
      // Cl_common.logger("koko");
      Cl_common.logger("response");
      Cl_common.logger(response);

      //結果をセット
      if (linkOn) {
        this.item.c_pro2doc_id = response.c_pro2doc_id;
      } else {
        this.item.c_pro2doc_id = null;
      }
    } catch (error) {
      // //モーダルのメッセージを設定
      // this.message4ModalErrorConf = "エラーが発生しました";
      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          case "already linked.":
            //データ整合エラー
            this.$emit("alreadyLinked");
            break;
          default:
            // this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
      }

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * AxiosErrorかどうかを返す。
   * @param error
   * @returns
   */
  isAxiosError(error: any): error is AxiosError {
    return !!error.isAxiosError;
  }
  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
