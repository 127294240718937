
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_staff } from "@/functions/Cl_staff";
import { DtoStaffsGetReqImple } from "mediadept-common/src/common/dto/DtoStaffsGetReqImple";
import { DtoStaffsGetResImple } from "mediadept-common/src/common/dto/DtoStaffsGetResImple";
import { DtoStaffsPutReq } from "mediadept-common/src/common/dto/abst/DtoStaffsPutReq";
import { DtoStaffsPutRes } from "mediadept-common/src/common/dto/abst/DtoStaffsPutRes";
import { DtoStaffsPostReq } from "mediadept-common/src/common/dto/abst/DtoStaffsPostReq";
import { DtoStaffsPostRes } from "mediadept-common/src/common/dto/abst/DtoStaffsPostRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";

// バリデーション
import * as Yup from "yup";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";

@Options({
  components: {
    Mo_modal_alert,
    vSelect,
  },
})
export default class Syainedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    staffName: Yup.string().required("社員名を入力してください。"),
    furigana: Yup.string()
      .required("ふりがなを入力してください。")
      .matches(
        /^([\u3040-\u309F―ー‐－0-9]|\s|\p{P}|\p{S})+$/,
        "ふりがなはひらがなで入力してください。"
      ),
    staffLoginid: Yup.string()
      .required("ログインIDを入力してください。")
      .matches(
        /^[0-9a-zA-Z.]{4,}$/,
        "ログインIDは半角英数およびドット（.）4桁以上で入力してください。"
      ),
    staffPassNew: Yup.string()
      .required("パスワードを入力してください。")
      .matches(
        /^[0-9a-zA-Z]{6,}$/,
        "パスワードは半角英数6桁以上で入力してください。"
      ),
    email: Yup.string()
      .email("有効なメールアドレスを入力してください。")
      .required("メールアドレスを入力してください。"),
    elderUser: Yup.string().required("上長を最低一人選択してください。"),
  });
  schemaUpd = Yup.object().shape({
    staffName: Yup.string().required("社員名を入力してください。"),
    showOrder: Yup.string().required("表示順を入力してください。"),
    showOrder4Attendance:
      Yup.string().required("勤怠機能用表示順を入力してください。"),
    furigana: Yup.string()
      .required("ふりがなを入力してください。")
      .matches(
        /^([\u3040-\u309F―ー‐－0-9]|\s|\p{P}|\p{S})+$/,
        "ふりがなはひらがなで入力してください。"
      ),
    staffLoginid: Yup.string()
      .required("ログインIDを入力してください。")
      .matches(
        /^[0-9a-zA-Z.]{4,}$/,
        "ログインIDは半角英数およびドット（.）4桁以上で入力してください。"
      ),
    staffPassUpd: Yup.string()
      .nullable()
      .matches(
        /^([0-9a-zA-Z]{6,}|)$/,
        "パスワードは半角英数6桁以上で入力してください。"
      ),
    email: Yup.string()
      .email("有効なメールアドレスを入力してください。")
      .required("メールアドレスを入力してください。"),
    elderUser: Yup.string().required("上長を最低一人選択してください。"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoStaffsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isEditMode = true;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4BranchId = [
    { label: "", code: 0 },
    { label: "東京", code: 1 },
    // { label: "大阪" },
    // { label: "名古屋" },
  ];
  op4StaffDepart = [
    { label: "営業部", code: 1 },
    { label: "営業推進部", code: 2 },
  ];

  op4StaffUser = [{ label: "", code: 0 }];
  op4AuthType = [
    { label: "入力者", code: 1 },
    { label: "集計者", code: 2 },
    { label: "権限者", code: 3 },
    { label: "管理者", code: 4 },
  ];
  op4AuthClaim = [
    { label: "必要", code: 1 },
    { label: "不要", code: 2 },
  ];
  op4AuthReport = [
    { label: "ON", code: 1 },
    { label: "OFF", code: 2 },
  ];
  op4ShowOrder = [{ label: "" }];
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(No: number): EntityStaff[] | undefined {
    if (1 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_elder_user_id_1!,
      ]);
    }
    if (2 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_elder_user_id_2!,
      ]);
    }
    if (3 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_elder_user_id_3!,
      ]);
    }
    if (4 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_elder_user_id_4!,
      ]);
    }
    if (5 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_elder_user_id_5!,
      ]);
    }
    // #4388 承認者編集項目追加は詳細調整までコメントアウト。
    if (6 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_application_staff_id_1!,
      ]);
    }
    if (7 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_application_staff_id_2!,
      ]);
    }
    if (8 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_application_staff_id_3!,
      ]);
    }
    if (9 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_individual_staff_id_1!,
      ]);
    }
    if (10 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_individual_staff_id_2!,
      ]);
    }
    if (11 == No) {
      return this.delegate4list.getStaffSortNum4NoneDelete([
        this.delegate.c_staff_individual_staff_id_3!,
      ]);
    }
    return undefined;
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 0;
      //表示用
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        this.delegate.c_staff_depart = 1;
        this.delegate.c_branch_id = 1;
        this.delegate.c_staff_show_order = 1;
        this.delegate.c_staff_auth_type = 1;
        this.delegate.c_staff_flag_auth_claim = 1;
        this.delegate.c_staff_flag_del = 0;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoStaffsGetReqImple();

      dto.c_staff_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_staff.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_staff_id == null) {
        this.$router.push("/syainlist");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoStaffsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_staff_id = this.delegate.c_staff_id;
        dto.c_staff_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;
        const isInputedEldUser = await this.isElderUserInp();

        this.schemaUpd.validateSync({
          staffName: this.delegate.c_staff_name,
          furigana: this.delegate.c_staff_name_pt,
          email: this.delegate.c_staff_mail,
          staffLoginid: this.delegate.c_staff_login_id,
          staffPassUpd: this.delegate.c_staff_login_pass,
          elderUser: isInputedEldUser,
          showOrder: this.delegate.c_staff_show_order,
          showOrder4Attendance: this.delegate.c_staff_show_order_attendandce,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoStaffsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_staff.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "データを削除しました。";
      } else {
        //更新処理：
        this.message4ModalSuccess = "データを更新しました。";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      const isInputedEldUser = await this.isElderUserInp();

      this.schemaAdd.validateSync({
        staffName: this.delegate.c_staff_name,
        furigana: this.delegate.c_staff_name_pt,
        email: this.delegate.c_staff_mail,
        staffLoginid: this.delegate.c_staff_login_id,
        staffPassNew: this.delegate.c_staff_login_pass,
        elderUser: isInputedEldUser,
      });

      //準備
      //-------
      let dto = Object.assign(new DtoStaffsPostReq(), this.delegate);
      dto.c_staff_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_staff.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/syainedit",
        query: { id: res.c_staff_id },
      });

      //this.start = res.c_message_start?.toString().split("T")[0];
      //this.end = res.c_message_end?.toString().split("T")[0];

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  /**
   * 上長入力欄に入力されている入力値を繋げて文字列にして返す
   */
  async isElderUserInp(): Promise<string> {
    let elderUsers = "";

    //c_staff_elder_user_id_1が、nullでなければelderUsersに入力値を追記
    if (this.delegate.c_staff_elder_user_id_1 != null) {
      //number入ってる
      elderUsers += this.delegate.c_staff_elder_user_id_1.toString();
    }
    //c_staff_elder_user_id_2が、nullでなければelderUsersに入力値を追記
    if (this.delegate.c_staff_elder_user_id_2 != null) {
      //number入ってる
      elderUsers += this.delegate.c_staff_elder_user_id_2.toString();
    }
    //c_staff_elder_user_id_3が、nullでなければelderUsersに入力値を追記
    if (this.delegate.c_staff_elder_user_id_3 != null) {
      //number入ってる
      elderUsers += this.delegate.c_staff_elder_user_id_3.toString();
    }
    //c_staff_elder_user_id_4が、nullでなければelderUsersに入力値を追記
    if (this.delegate.c_staff_elder_user_id_4 != null) {
      //number入ってる
      elderUsers += this.delegate.c_staff_elder_user_id_4.toString();
    }
    //c_staff_elder_user_id_5が、nullでなければelderUsersに入力値を追記
    if (this.delegate.c_staff_elder_user_id_5 != null) {
      //number入ってる
      elderUsers += this.delegate.c_staff_elder_user_id_5.toString();
    }
    return elderUsers;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * データを削除する
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf = "削除してもよろしいでしょうか。";

    //モーダルを表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }

  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }

  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
