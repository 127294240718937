
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import Mo_modal_max_force from "@/components/molecules/Mo_modal_max_force.vue";
import { Cl_common } from "@/functions/Cl_common";

import { EntityProject } from "mediadept-common/src/common/entity/EntityProject";
import { DtoProjectsGetReqImple } from "mediadept-common/src/common/dto/DtoProjectsGetReqImple";
import { Cl_project } from "@/functions/Cl_project";
import { DtoProjectsGetResImple } from "mediadept-common/src/common/dto/DtoProjectsGetResImple";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import { commonRegx } from "xcommon-node/src/common/commonRegx";
import Mo_tableJyutu_row_supply from "@/components/molecules/Mo_tableJyutu_row_supply.vue";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoDocsGetResImple } from "mediadept-common/src/common/dto/DtoDocsGetResImple";
import { EntityPro2doc } from "mediadept-common/src/common/entity/EntityPro2doc";

@Options({
  components: {
    vSelect,
    Mt_dtp,
    Mt_dtp4month,
    Mo_modal_max_force,
    Mo_tableJyutu_row_supply,
  },
})
export default class Or_selectSupply extends Vue {
  // @Prop({ required: true })
  // evDatas: {
  //   selectedItem: EntityClient;
  //   isSelected: boolean;
  // };

  // ----------
  @Prop({ required: true })
  docData: DtoDocsGetResImple;
  @Prop({ required: true })
  proData: EntityProject;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoProjectsGetResImple();
  delegate4list = new DtoMastersGetRes();

  /**
   * データ不整合エラーメッセージ制御用
   */
  message4dataError = false;

  //ボタン制御用
  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      // dtoMaster.getStaff4sortNum = 1;
      // dtoMaster.delFlag4staffSortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;
      // dtoMaster.getClients = 1;
      // dtoMaster.delFlag4clients = 1;
      dtoMaster.getSuppliers = 1;
      dtoMaster.delFlag4suppliers = 0;

      //休日用準備
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      Cl_common.logger(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async getlist() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = this.proData.c_project_number;

      //準備
      //-------
      let dto = new DtoProjectsGetReqImple();

      dto.c_project_id = Number(id); //APIのURL構成の関係で、案件番号を受注IDに入れているので注意

      //API実行
      //-------
      this.delegate = await Cl_project.get(dto);
      // Cl_common.logger("koko");
      Cl_common.logger("this.delegate");
      Cl_common.logger(this.delegate);

      //売上明細、仕入明細の日付等展開
      await this.cnvDetails4Get();

      //データ整合性エラーの表記をリセット。
      this.message4dataError = false;
    } catch (error) {
      // //モーダルのメッセージを設定
      // this.message4ModalErrorConf = "エラーが発生しました";
      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      Cl_common.logger(error);
      this.delegate = new DtoProjectsGetResImple();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 選択されているPro2Docを返す
   */
  getSelectedPro2Doc(): EntityPro2doc {
    return this.docData.pro2Doc.find(
      (data) => data.c_project_id == this.proData.c_project_id
    )!;
  }

  /**
   * 明細内のデータを調整
   * APIのデータをgetした時用。
   */
  async cnvDetails4Get() {
    let tmp = new DtoMdDtp();

    //仕入明細
    //---------------------
    for (let i = 0; i < this.delegate.supplys.length; ++i) {
      //日付
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_month != null) {
        tmp.date = commonRegx.maches4GroupName(
          this.delegate.supplys[i].c_supply_month!.toString(),
          "(?<gn>^\\d+[-]\\d+)[-]",
          commonRegx._stdOption
        );
      }
      this.delegate.supplys[i].cnvDateMonth = tmp;
      // ----
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_date_start != null) {
        tmp.date = this.delegate.supplys[i]
          .c_supply_date_start!.toString()
          .split("T")[0];
      }

      this.delegate.supplys[i].cnvDateStart = tmp;
      // ----
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_date_end != null) {
        tmp.date = this.delegate.supplys[i]
          .c_supply_date_end!.toString()
          .split("T")[0];
      }
      this.delegate.supplys[i].cnvDateEnd = tmp;
      // ----
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_date_exp_payment != null) {
        tmp.date = this.delegate.supplys[i]
          .c_supply_date_exp_payment!.toString()
          .split("T")[0];
      }
      this.delegate.supplys[i].cnvDateExpPayment = tmp;
      // ----

      //項目選択
      // if (
      //   this.delegate.supplys[i].c_supply_item != null &&
      //   this.delegate.supplys[i].c_supply_item != ""
      // ) {
      //   this.delegate.supplys[i].cnvItemType =
      //     await Cl_projectImple.cnvStrArray2NumArray(
      //       this.delegate.supplys[i].c_supply_item!.split(",")
      //     );
      // }

      //請求書番号
      if (this.delegate.supplys[i].c_supply_claimnum_tmp != null) {
        this.delegate.supplys[i].cnvClaimnumTmp =
          this.delegate.supplys[i].c_supply_claimnum_tmp!.toString();
      } else {
        this.delegate.supplys[i].cnvClaimnumTmp = "";
      }

      //金額
      if (this.delegate.supplys[i].c_supply_price == null) {
        this.delegate.supplys[i].cnvPrice = "0";
      } else {
        this.delegate.supplys[i].cnvPrice =
          this.delegate.supplys[i].c_supply_price!.toString();
        //カンマ補完
        while (
          this.delegate.supplys[i].cnvPrice !=
          (this.delegate.supplys[i].cnvPrice = this.delegate.supplys[
            i
          ].cnvPrice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
        );
      }

      //商品名
      if (this.delegate.supplys[i].c_variation_id != null) {
        this.delegate.supplys[i].cnvItemName =
          this.delegate.supplys[i].c_item_name +
          " " +
          this.delegate.supplys[i].c_variation_name;
      } else {
        this.delegate.supplys[i].cnvItemName = "商品を選択してください";
      }
    }
  }
  /**
   * 仕入明細でデータ不整合エラーイベント発生時の処理
   */
  alreadyLinkedError() {
    this.message4dataError = true;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    await modalComp.showModal();

    //起動時に読み込み
    await this.getMastersList();
    await this.getlist();
  }

  async closeModalSupply(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    modalComp.closeModal();
    this.$emit("modalclosed");
  }
}
