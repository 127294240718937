
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DtoItemsGetReqImple } from "mediadept-common/src/common/dto/DtoItemsGetReqImple";
import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";
import { DtoItemImagePostReq } from "mediadept-common/src/common/dto/abst/DtoItemImagePostReq";
import { DtoItemImagePostRes } from "mediadept-common/src/common/dto/abst/DtoItemImagePostRes";
import { DtoItemImageDeleteReq } from "mediadept-common/src/common/dto/abst/DtoItemImageDeleteReq";
import { DtoItemImageDeleteRes } from "mediadept-common/src/common/dto/abst/DtoItemImageDeleteRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_item } from "@/functions/Cl_item";
import { Cl_itemimage } from "@/functions/Cl_itemimage";

import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import { DtoItemsPutReq } from "mediadept-common/src/common/dto/abst/DtoItemsPutReq";

import Or_variation from "@/components/organisms/Or_variation.vue";

import axios from "axios";
import { AxiosError } from "axios";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { tSMethodSignature } from "@babel/types";
import { DtoItemsPostReq } from "mediadept-common/src/common/dto/abst/DtoItemsPostReq";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mo_modal_l,
    VueGoodTable,
    Or_variation,
  },
})
export default class Itemedit4billbord extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaUpd = Yup.object().shape({
    itemName: Yup.string().required("商品名を入力してください。"),
    furigana: Yup.string()
      .required("ふりがなを入力してください。")
      .matches(
        /^([\u3040-\u309F―ー‐－0-9]|\s|\p{P}|\p{S})+$/,
        "ふりがなはひらがなで入力してください。"
      ),
  });
  schemaArea = Yup.object().shape({
    itemArea: Yup.string().required("エリアを入力してください。"),
  });
  schemaRoute = Yup.object().shape({
    itemRoute: Yup.string().required("路線を入力してください。"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoItemsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isEditMode = true;

  //商品写真
  srcPhoto = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";
  message4ModalCopyConf = "";

  //----------------------
  //制御用メソッド
  //----------------------

  async mounted() {
    await this.getMastersList();
    await this.get();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------

  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行仕入先マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getSuppliers = 1;
      dtoMaster.delFlag4suppliers = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("読み込み処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");

      //準備
      //-------
      let dto = new DtoItemsGetReqImple();

      dto.c_item_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_item.get(dto);
      Cl_common.logger(this.delegate);

      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_item_id == null) {
        this.$router.push("/syouhinlist");
      }

      //写真
      //------
      if (
        this.delegate.c_item_url_photo != null &&
        this.delegate.c_item_url_photo != ""
      ) {
        this.srcPhoto = this.delegate.imgaUrl4S3!;
      } else {
        this.srcPhoto = "/img/slide_img01_02_pc.png";
      }

      //バリエーション・コンディション調整
      //------
      for (let i = 0; i < this.delegate.variations.length; i++) {
        if (
          null != this.delegate.variations[i].c_variation_specification &&
          "" != this.delegate.variations[i].c_variation_specification
        ) {
          this.delegate.variations[i].cnvSpecification =
            await this.cnvStrArray2NumArray(
              this.delegate.variations[i].c_variation_specification!.split(",")
            );
        }
        if (
          null != this.delegate.variations[i].c_variation_type &&
          "" != this.delegate.variations[i].c_variation_type
        ) {
          // this.delegate.variations[i].cnvType = await this.cnvStrArray2NumArray(
          //   this.delegate.variations[i].c_variation_type!.split(",")
          // );
          this.delegate.variations[i].cnvType = [
            Number(this.delegate.variations[i].c_variation_type),
          ];
        }
        //金額
        if (this.delegate.variations[i].c_variation_price == null) {
          this.delegate.variations[i].cnvPrice = "0";
        } else {
          this.delegate.variations[i].cnvPrice =
            this.delegate.variations[i].c_variation_price!.toString();
          //カンマ補完
          while (
            this.delegate.variations[i].cnvPrice !=
            (this.delegate.variations[i].cnvPrice = this.delegate.variations[
              i
            ].cnvPrice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
          );
        }

        //TODO:API仕様変更影響箇所
        // for (let j = 0; j < this.delegate.conditions[i].length; j++) {
        //   if (
        //     null != this.delegate.conditions[i][j].c_condition_type &&
        //     "" != this.delegate.conditions[i][j].c_condition_type
        //   ) {
        //     this.delegate.conditions[i][j].cnvType =
        //       await this.cnvStrArray2NumArray(
        //         this.delegate.conditions[i][j].c_condition_type!.split(",")
        //       );
        //   }
        // }
        for (
          let j = 0;
          j < this.delegate.variations[i].conditions.length;
          j++
        ) {
          if (
            null !=
              this.delegate.variations[i].conditions[j].c_condition_type &&
            "" != this.delegate.variations[i].conditions[j].c_condition_type
          ) {
            this.delegate.variations[i].conditions[j].cnvType = [
              Number(
                this.delegate.variations[i].conditions[j].c_condition_type
              ),
            ];
          }
        }
      }

      // 子のメソッドを呼ぶ
      // const fnc: any = this.$refs.Or_variation;
      // setTimeout(fnc.setTime, 3000);
      if (4 == this.delegate.c_item_type) {
        for (let i = 0; i < this.delegate.variations.length; i++) {
          if (
            null != this.delegate.variations[i].c_variation_time_start &&
            null != this.delegate.variations[i].c_variation_time_end &&
            0 < this.delegate.variations[i].c_variation_time_start!.length &&
            0 < this.delegate.variations[i].c_variation_time_end!.length
          ) {
            let s =
              this.delegate.variations[i].c_variation_time_start?.split(":");
            let e =
              this.delegate.variations[i].c_variation_time_end?.split(":");
            let s2 = Number(s![0]) * 60 + Number(s![1]);
            let e2 = Number(e![0]) * 60 + Number(e![1]);

            this.delegate.variations[i].hour = Math.floor((e2 - s2) / 60);
            this.delegate.variations[i].min = Math.floor((e2 - s2) % 60);
          }
        }
      }

      if (5 == this.delegate.c_item_type) {
        document.getElementById("remarks")!.classList.add("remarksHeight118");
      } else if (6 == this.delegate.c_item_type) {
        document.getElementById("remarks")!.classList.add("remarksHeight194");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
      this.delegate = new DtoItemsGetResImple();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isShowModal = true) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("更新処理実行");

      //準備
      //-------
      let dto = new DtoItemsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_item_id = this.delegate.c_item_id;
        dto.c_item_flag_del = 1;
      } else {
        for (let i = 0; i < this.delegate.variations.length; i++) {
          if (undefined != this.delegate.variations[i].cnvSpecification) {
            this.delegate.variations[i].c_variation_specification =
              this.delegate.variations[i].cnvSpecification.join(",");
          }
          // if (undefined != this.delegate.variations[i].cnvType) {
          //   this.delegate.variations[i].c_variation_type =
          //     this.delegate.variations[i].cnvType.join(",");
          // }

          //項目選択
          if (
            this.delegate.variations[i].cnvType[0] != undefined &&
            this.delegate.variations[i].cnvType[0] != null
          ) {
            this.delegate.variations[i].c_variation_type = String(
              this.delegate.variations[i].cnvType[0]
            );
          }

          //金額
          if (
            this.delegate.variations[i].cnvPrice == null ||
            this.delegate.variations[i].cnvPrice == undefined
          ) {
            this.delegate.variations[i].c_variation_price = 0;
          } else {
            this.delegate.variations[i].c_variation_price = Number(
              this.delegate.variations[i].cnvPrice.replaceAll(",", "")
            );
          }

          //TODO:API仕様変更影響箇所
          // for (let j = 0; j < this.delegate.conditions[i].length; j++) {
          //   if (undefined != this.delegate.conditions[i][j].cnvType) {
          //     this.delegate.conditions[i][j].c_condition_type =
          //       this.delegate.conditions[i][j].cnvType.join(",");
          //   }
          // }
          for (
            let j = 0;
            j < this.delegate.variations[i].conditions.length;
            j++
          ) {
            // if (
            //   undefined != this.delegate.variations[i].conditions[j].cnvType
            // ) {
            //   this.delegate.variations[i].conditions[j].c_condition_type =
            //     this.delegate.variations[i].conditions[j].cnvType.join(",");
            // }
            if (
              this.delegate.variations[i].conditions[j].cnvType[0] !=
                undefined &&
              this.delegate.variations[i].conditions[j].cnvType[0] != null
            ) {
              this.delegate.variations[i].conditions[j].c_condition_type =
                String(this.delegate.variations[i].conditions[j].cnvType[0]);
            }
          }
        }

        //バリデーション分岐
        //---------------------------
        this.errors = null;
        //全商品、「商品名」「ふりがな」あり
        if (this.delegate.c_item_type != null) {
          this.schemaUpd.validateSync({
            itemName: this.delegate.c_item_name,
            furigana: this.delegate.c_item_name_pt,
          });
          //1:ビルボード～4:デジタルビルボードまで、「エリア」あり
          if (4 >= this.delegate.c_item_type) {
            this.schemaArea.validateSync({
              itemArea: this.delegate.c_item_area,
            });
          }
          //5:交通は、「路線」あり
          if (5 == this.delegate.c_item_type) {
            this.schemaRoute.validateSync({
              itemRoute: this.delegate.c_item_route,
            });
          }
          //6:その他は、「エリア」「路線」なし
        }
        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoItemsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_item.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      if (isShowModal) {
        //モーダルのメッセージを設定
        if (isDel) {
          //削除処理：
          this.message4ModalSuccess = "正常に削除されました";
        } else {
          //更新処理：
          this.message4ModalSuccess = "正常に変更されました";
        }

        //モーダルを表示
        const modal: any = this.$refs.Mo_modal_success;
        modal.showModal();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを複製する
   */
  async cpyItem() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("コピー処理実行");

      // 先に保存する
      this.upd(false, false);

      //準備
      //-------
      let dto = new DtoItemsPostReq();
      dto.c_item_id = this.delegate.c_item_id;

      //API実行
      //-------
      let res = await Cl_item.post(dto);
      //レスポンスの処理
      //-------

      location.href = "syouhinedit?id=" + res.c_item_id;

      this.message4ModalSuccess = "正常にコピーされました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルアップロードボタン押下時の処理
   */
  async clickImageUpl() {
    const element: any = this.$refs.uploadFile;
    element.click();
  }
  /**
   * 画像ファイルアップロード処理
   */
  async postImage() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      const element: any = this.$refs.uploadFile;
      const uploadFile = element.files[0];

      if (uploadFile != undefined) {
        let dtoItemImagePostReq: DtoItemImagePostReq =
          new DtoItemImagePostReq();

        //マップ画像保存。
        //------------------
        //要素からファイル情報取得。
        const exix = uploadFile.name.split(".").pop();

        if (
          exix != "JPEG" &&
          exix != "jpeg" &&
          exix != "JPG" &&
          exix != "jpg" &&
          exix != "PNG" &&
          exix != "png" &&
          exix != "BMP" &&
          exix != "bmp" &&
          exix != "GIF" &&
          exix != "gif"
        ) {
          this.message4ModalErrorConf =
            "アップロードできるのは、Jpeg/PNG/BMP/GIFのみです";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        }

        //アップロードURL取得API実行
        dtoItemImagePostReq.itemId = this.delegate.c_item_id;
        dtoItemImagePostReq.fileType = exix;
        const res = await Cl_itemimage.post(dtoItemImagePostReq);
        let uploadUrl = res.url;

        if (uploadUrl == null || uploadUrl === "") {
          this.message4ModalErrorConf = "ファイルのアップロードに失敗しました";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();
          return;
        } else {
          await axios.put(uploadUrl!, uploadFile);

          this.delegate.c_item_url_photo =
            "itemimgs/" + this.delegate.c_item_id + "/img." + exix;
          await this.upd(false);
        }
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 画像削除処理
   */
  async deleteImage() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      if (
        this.delegate.c_item_url_photo == null ||
        this.delegate.c_item_url_photo == ""
      ) {
        return;
      }

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoItemImageDeleteReq();

      dto.itemId = this.delegate.c_item_id;

      //API実行
      //-------
      const res = await Cl_itemimage.del(dto);
      //レスポンスの処理
      //-------
      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に削除されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalCpyConf = "";

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async closeModal4CopyConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_copyConf;
    modal.closeModal();
  }
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  /**
   * 商品選択用モーダル表示
   */
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_itemSelect;
    modalComp.showModal();
  }
  /**
   * 商品選択用モーダル閉じる
   */
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_itemSelect;
    modalComp.closeModal();
  }

  async cnvStrArray2NumArray(strArray: string[]): Promise<number[]> {
    let res: number[] = [];

    for (let i = 0; i < strArray.length; ++i) {
      res.push(Number(strArray[i]));
    }

    return res;
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_item_id +
      "】" +
      this.delegate.c_item_name +
      "削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  /**
   * データ複製用のモーダルを表示
   */
  async cpyConf() {
    //モーダルのメッセージを設定
    this.message4ModalCpyConf =
      "【" +
      this.delegate.c_item_id +
      "】" +
      this.delegate.c_item_name +
      "複製します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_copyConf;
    modal.showModal();
  }
  /**
   * データを複製する
   */
  async cpy() {
    this.closeModal4CopyConf();
    this.cpyItem();
  }
}
