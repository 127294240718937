import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoDatadiagnosisGetReqImple {
  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    return res;
  }
}
