import { DtoRequestsPutReq } from "./abst/DtoRequestsPutReq";

export class DtoRequestsPutReqImple extends DtoRequestsPutReq {
  //承認・差戻用
  //-----------------------
  //1で承認、0で差戻。nullで通常のput処理(現状、put処理なし)。
  public isApproval: number | null = null;

  // 一時保存・編集が可能な申請書用の承認申請開始処理用フラグ
  //1で承認申請開始。それ以外はnullである事。
  //-----------------------
  public isApprovalRequest: number | null = null;
}
