import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntitySearchClaimNo } from "../entity/EntitySearchClaimNo";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoSearchClaimNoGetReq extends EntitySearchClaimNo {

    //パラメーター
    //検索条件
    public ClaimNo: number | null = null;
    

    public pager = new DtoApiPager();

    public IsValidImple(chkPager: boolean): string[] {
        let res: string[] = new Array();
        //ページャー用入力値チェック
        if (chkPager) {
            this.pager.IsValid4Pager(res);
        } else {
            res = res.concat(this.IsValid());
        }

        return res;
    }

    public IsValid(): string[] {
        let res: string[] = new Array();
        //単項目チェック
        //--------------
        if (!commonRegx.isMatch(this.c_claim_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
            res.push("type error");
        }

        return res;
    }


}
