export class EntityRequestRwdacct {
public c_request_rwdacct_id:number | null = null;
public c_request_id:number | null = null;
public c_request_rwdacct_type:number | null = null;
public c_request_rwdacct_bank_name:string | null = null;
public c_request_rwdacct_bank_type:number | null = null;
public c_request_rwdacct_bank_branch_name:string | null = null;
public c_request_rwdacct_bank_number:string | null = null;
public c_request_rwdacct_bank_account_name:string | null = null;
public c_request_rwdacct_reg_user:string | null = null;
public c_request_rwdacct_reg_at:Date | null = null;
public c_request_rwdacct_upd_user:string | null = null;
public c_request_rwdacct_upd_at:Date | null = null;
}