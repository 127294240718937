import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestAprvsGetReqImple } from "mediadept-common/src/common/dto/DtoRequestAprvsGetReqImple";
import { DtoRequestAprvsGetResImple } from "mediadept-common/src/common/dto/DtoRequestAprvsGetResImple";
import { DtoRequestAprvsPostReq } from "mediadept-common/src/common/dto/abst/DtoRequestAprvsPostReq";
import { DtoRequestAprvsPostRes } from "mediadept-common/src/common/dto/abst/DtoRequestAprvsPostRes";
import { DtoRequestAprvsPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestAprvsPutReq";
import { DtoRequestAprvsPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestAprvsPutRes";
import { DtoRequestAprvsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoRequestAprvsDeleteReq";
import { DtoRequestAprvsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoRequestAprvsDeleteRes";

export class Cl_requestaprv {
  public static async getList(
    dto: DtoRequestAprvsGetReqImple
  ): Promise<DtoRequestAprvsGetResImple> {
    return Object.assign(
      new DtoRequestAprvsGetResImple(),
      await CallAPI.getRequest(
        "/requestaprvs?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestAprvsGetReqImple
  ): Promise<DtoRequestAprvsGetResImple> {
    return Object.assign(
      new DtoRequestAprvsGetResImple(),
      await CallAPI.getRequest("/requestaprvs/" + dto.c_request_aprv_id)
    );
  }

  public static async post(
    dto: DtoRequestAprvsPostReq
  ): Promise<DtoRequestAprvsPostRes> {
    return Object.assign(
      new DtoRequestAprvsPostRes(),
      await CallAPI.postRequest("/requestaprvs", dto)
    );
  }

  public static async put(
    dto: DtoRequestAprvsPutReq
  ): Promise<DtoRequestAprvsPutRes> {
    return Object.assign(
      new DtoRequestAprvsPutRes(),
      await CallAPI.putRequest("/requestaprvs/" + dto.c_request_aprv_id, dto)
    );
  }

  public static async del(
    dto: DtoRequestAprvsDeleteReq
  ): Promise<DtoRequestAprvsDeleteRes> {
    return Object.assign(
      new DtoRequestAprvsDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestaprvs?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
