
import { Prop, Options, Vue } from "vue-property-decorator";
import { Cl_Authorization_menu } from "../../constants/Cl_Authorization_menu";
import { Cl_common } from "@/functions/Cl_common";
import { isProdEnv } from "../../../../mediadept-common/src/common/constant/Constants";

@Options({})
export default class Tm_leftnavi extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  showFlags = new Cl_Authorization_menu();
  @Prop({ required: true })
  alertCount: { alertCount: number };

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //設定された権限からメニューの表示非表示を制御
    this.showFlags.getAuthorization(this.$store);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * Menuクリック時の処理
   * @param event
   */
  async selectMenu(event: any) {
    // console.log(event);
    //開閉Menuかの判断
    if (event.target.closest("li").classList.contains("open")) {
      // console.log("toggle");
      event.target.closest("li").classList.toggle("active");
    } else {
      //ルーターの処理に移動
      // // console.log("add");
      // // 1.他のactiveを削除
      // let elements = document.getElementsByTagName("li");
      // Array.prototype.forEach.call(elements, function (element) {
      //   if (!element.classList.contains("open")) {
      //     element.classList.remove("active");
      //   }
      // });
      // //2.自身のactivを付ける
      // event.target.closest("li").classList.add("active");
    }
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  isProd(): boolean {
    return isProdEnv();
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
}
