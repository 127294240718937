import { EntityConditionImple } from "./EntityConditionImple";
import { EntityVariation } from "./EntityVariation";

export class EntityVariationImple extends EntityVariation {
  //項目選択
  public cnvSpecification: number[] = [];
  public cnvType: number[] = [];

  // 時間用
  public hour: number | null = null;
  public min: number | null = null;

  //フロントでの金額変換用
  public cnvPrice: string = "";

  //コンディション
  public conditions: EntityConditionImple[] = [];
}
