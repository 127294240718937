
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_staff } from "@/functions/Cl_staff";
import { DtoStaffsGetReqImple } from "mediadept-common/src/common/dto/DtoStaffsGetReqImple";
import { DtoStaffsGetResImple } from "mediadept-common/src/common/dto/DtoStaffsGetResImple";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class Attendancelist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoStaffsGetResImple();
  domStaffNo_start = "";
  domStaffNo_end = "";
  domStaffName = "";
  domBranch = { code: 0, label: "　" };
  domDateMonth = "";
  //ボタン制御用
  btnDisable = false;

  optionsOp = [
    { code: 0, label: "　" },
    { code: 1, label: "東京" },
  ];
  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "社員番号",
      field: "c_staff_number",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "氏名",
      field: "c_staff_name",
    },
    // {
    //   label: "ステータス",
    //   field: "c_branch_id",
    //   thClass: "text-center",
    //   tdClass: "text-center",
    // },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //日付の初期値入力
    //---------------
    // this.domDateMonth = this.formatDate(new Date());

    const date = new Date();
    const year = date.getFullYear();
    let month: string;
    if (21 <= date.getDate()) {
      month = ("00" + (date.getMonth() + 2)).slice(-2);
    } else {
      month = ("00" + (date.getMonth() + 1)).slice(-2);
    }

    this.domDateMonth = year + "-" + month;
    //---------------

    await this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoStaffsGetReqImple();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      dto.isAttendant = 1;
      dto.staffNo_start = this.domStaffNo_start;
      dto.staffNo_end = this.domStaffNo_end;
      dto.staffName = this.domStaffName;
      if (this.domBranch.code != 0) dto.c_branch_id = this.domBranch.code;

      //API実行
      //-------
      this.delegate = await Cl_staff.getList(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoStaffsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "AttendanceLitst";
      dto.staffNo_start = this.domStaffNo_start;
      dto.staffNo_end = this.domStaffNo_end;
      dto.staffName = this.domStaffName;
      dto.attendancemo = this.domDateMonth;

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      Cl_common.logger("res.outputqueue_code");
      Cl_common.logger(res.outputqueue_code);

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dtoOutputReq = new DtoOutputqueuesGetReqImple();
      dtoOutputReq.c_outputqueue_code = res.outputqueue_code;
      let resOutputReq = new DtoOutputqueuesGetResImple();
      resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
      while (now < timeLimit) {
        Cl_common.logger("wait request");
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
        //↓社領修正行
        if (resOutputReq.c_outputqueue_flag_end == 1) {
          break;
        }
      }

      //レスポンス処理
      //-------
      Cl_common.logger("resOutputReq");
      Cl_common.logger(resOutputReq);

      //↓社領修正行
      if (resOutputReq.c_outputqueue_flag_success == 1) {
        location.href = resOutputReq.url;
      } else {
        throw new Error("出力失敗");
      }
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push(
      "/attendanceedit?" + "id=" + id + "&mo=" + this.domDateMonth
    );
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  /**
   * 月表示をデクリする
   */
  async prevMonth() {
    let date = new Date(this.domDateMonth);
    date.setMonth(date.getMonth() - 1);
    this.domDateMonth = this.formatDate(date);
  }
  /**
   * 月表示をインクリする
   */
  async nextMonth() {
    let date = new Date(this.domDateMonth);
    date.setMonth(date.getMonth() + 1);
    this.domDateMonth = this.formatDate(date);
  }
  /**
   * 日付型の変数をyyyy-MMフォーマットの文字列に変換する
   * @param date
   */
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("00" + (date.getMonth() + 1)).slice(-2);

    return `${year}-${month}`;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
