
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import {
  CONST_OP_TERM_ITEM,
  CONST_OP_SUPPLY_TYPE,
  CONST_OP_TAXRATE,
  CONST_AUTH_PAYCHECK,
  CONST_AUTHORITY_STAFF_NYURYOKUSYA,
  CONST_OP_SUBJECT,
} from "mediadept-common/src/common/constant/Constants";
import { EntitySalesImple } from "mediadept-common/src/common/entity/EntitySalesImple";

import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import "@vuepic/vue-datepicker/dist/main.css";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import "floating-vue/dist/style.css";
import { EntityHolisticlock } from "mediadept-common/src/common/entity/EntityHolisticlock";

@Options({
  components: {
    vSelect,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class Mo_tableJyutu_row_sales extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: EntitySalesImple;
  @Prop({ required: true })
  mastersData: DtoMastersGetRes;
  @Prop({ required: true })
  disabledDates: EntityHolisticlock[] | null;

  authPayCheck = CONST_AUTH_PAYCHECK;
  authtypeNyuryokusya = CONST_AUTHORITY_STAFF_NYURYOKUSYA;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opTermItem = CONST_OP_TERM_ITEM;
  opSubject = CONST_OP_SUBJECT;
  opSupplyType = CONST_OP_SUPPLY_TYPE;
  opTaxtate = CONST_OP_TAXRATE;

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 日付の最小値を取得
   */
  getMinDate(): Date | undefined {
    //制限は入力者のみ
    //-------
    if (this.$store.state.authType != CONST_AUTHORITY_STAFF_NYURYOKUSYA) {
      return undefined;
    }

    //前々月以前は選択不可
    //-------
    let dt = new Date();
    // let dt = new Date("2024/08/10 17:48:00");//テスト用

    dt.setMonth(dt.getMonth() - 1);

    const limitYear = 2024;
    const limitMonth = 3; // 3月はJavaScriptではインデックスが2、0からカウントされるため1を引く

    // 2024年3月以前であれば2024年3月をセット
    if (
      dt.getFullYear() < limitYear ||
      (dt.getFullYear() === limitYear && dt.getMonth() < limitMonth)
    ) {
      dt = new Date(limitYear, limitMonth); // 新たにDateオブジェクトを作成し、2024年3月を設定
    }

    return dt;
  }
  /**
   * ロックされた日付を設定
   */
  getDisabledDates(): Date[] | undefined {
    if (this.disabledDates == null) {
      return undefined;
    }

    if (this.disabledDates.length == 0) {
      return undefined;
    }

    return this.disabledDates.map(
      (item) => new Date(item.c_holisticlock_date!)
    );
  }
  /**
   * 統合ロックされるべき行かを判定する
   */
  isHolisticlock(): boolean {
    if (this.item.cnvDateMonth.date == null) {
      return false;
    }
    if (this.disabledDates == null) {
      return false;
    }
    if (this.disabledDates.length == 0) {
      return false;
    }

    const isDateMatching = (
      data: EntityHolisticlock[],
      targetDate: Date
    ): boolean => {
      return data.some(
        (item) =>
          new Date(item.c_holisticlock_date!).getTime() === targetDate.getTime()
      );
    };

    return isDateMatching(
      this.disabledDates,
      new Date(this.item.cnvDateMonth.date)
    );
  }

  /**
   * クリック時の選択処理
   */
  async selectRow() {
    if (
      //申請中の請求書の場合は選択不可
      (this.item.isApprovedClaim == 1 &&
        this.$store.state.authType == CONST_AUTHORITY_STAFF_NYURYOKUSYA) ||
      //統合ロックによる編集不可
      this.isHolisticlock()
    ) {
      return;
    }

    this.item.isSelect = true;
    this.$emit("selected", this.item.c_sales_id);
  }
  /**
   * 商品選択時のイベント処理
   */
  async selectItem() {
    this.$emit("itemSelectSales", this.item.c_sales_id, this.item);
  }

  /**
   * 選択解除処理
   */
  async noneSelectRow() {
    this.item.isSelect = false;
  }

  //------------------
  cnvDateFmt(dateDtr: string | null): string {
    if (dateDtr == null) {
      return "";
    }
    return dateDtr.replaceAll("-", "/");
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // カンマを消す
    this.item.cnvPrice = obj.target.value.replace(/,/g, "");
    // if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    this.item.cnvPrice = num;
  }

  /**
   * 金額再計算イベント発生
   */
  calcProfit() {
    //再計算イベント発生
    //-----------------------
    this.$emit("reCalc");
  }

  async linkClaim(event?: MouseEvent) {
    if (this.item.c_sales_claimnum_tmp == null) {
      return;
    }
    Cl_common.goTo(
      "/seikyusyocreate?claimnum=" + this.item.c_sales_claimnum_tmp,
      this.$router,
      event
    );
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
