import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityRequestOvertime } from "../../entity/EntityRequestOvertime";

export class DtoRequestOvertimesPostReq extends EntityRequestOvertime {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
