import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityClaim } from "../entity/EntityClaim";
import { EntityClaimDet } from "../entity/EntityClaimDet";
import { EntityProject } from "../entity/EntityProject";
import { EntityStaff } from "../entity/EntityStaff";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoClaimsGetRes } from "./abst/DtoClaimsGetRes";
import { EntityClaimImple } from "../entity/EntityClaimImple";

export class DtoClaimsGetResImple extends DtoClaimsGetRes {
  //一覧用
  //-----------------------
  public pager = new DtoApiPager();
  public exitems: Exitem[] = [];
  public itemsImple: EntityClaimImple[] = [];

  //詳細用
  //-----------------------
  public details: EntityClaimDet[] = [];
  public project = new EntityProject();
  public sumSales: number | null = null;
  public claimNumberPrev: number | null = null;
  public claimNumberNext: number | null = null;

  /**
   * 統合ロックの対象となる明細に紐付いているかどうか。
   * 売上、仕入まとめて。
   * 0: 紐付いていない、 1: 紐付いている
   */
  public isHolisticlock: number = 0;

  //承認者のstaffidリスト。c_claim_statusが1の時だけ入力される
  public elderStaffIds: number[] = [];

  //メソッド
  //-----------------------
  public async setEximtes(
    clientmap: Map<number, string>,
    staffmap: Map<number, string>
  ) {
    let tmp: Exitem;
    for (let index = 0; index < this.itemsImple.length; index++) {
      tmp = new Exitem();

      tmp.c_claim_number = this.itemsImple[index].c_claim_number;
      tmp.c_claim_datetime = this.itemsImple[index].c_claim_datetime;
      tmp.c_claim_sponsor_name = this.itemsImple[index].c_claim_sponsor_name;
      tmp.c_claim_id = this.itemsImple[index].c_claim_id;
      tmp.c_claim_title_1 = this.itemsImple[index].c_claim_title_1;
      tmp.c_claim_subtotal = this.itemsImple[index].c_claim_subtotal;
      tmp.c_claim_total = this.itemsImple[index].c_claim_total;
      tmp.c_claim_flag_sales = this.itemsImple[index].c_claim_flag_sales;
      tmp.c_project_number = this.itemsImple[index].c_project_number;
      tmp.c_sales_total_mismatch =
        this.itemsImple[index].c_sales_total_mismatch;

      tmp.c_client_name = clientmap.get(this.itemsImple[index].c_client_id!)!;
      tmp.c_staff_name = staffmap.get(this.itemsImple[index].c_staff_id!)!;

      if (tmp.c_claim_datetime != null) {
        tmp.c_claim_datetime_short = tmp.c_claim_datetime.toLocaleDateString();
        // console.log("tmp.c_claim_datetime_short");
        // console.log(tmp.c_claim_datetime_short);
      }

      this.exitems.push(tmp);
    }
  }
}

export class Exitem extends EntityClaimImple {
  public c_client_name: string | null = null;
  public c_staff_name: string | null = null;
  public c_claim_datetime_short: string | null = null;
}
