
import { Options, Vue } from "vue-class-component";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoSearchClaimNoGetRes } from "mediadept-common/src/common/dto/DtoSearchClaimNoGetRes";
import { DtoSearchClaimNoGetReq } from "mediadept-common/src/common/dto/DtoSearchClaimNoGetReq";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_searchClaimNo } from "@/functions/Cl_searchClaimNo";

import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

import { Cl_filereport } from "@/functions/Cl_filereport";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";

@Options({
  components: {
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class Seikyunolist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSearchClaimNoGetRes();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domClaimNo = "";

  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "案件番号",
      field: "c_project_number",
    },
    {
      label: "案件名",
      field: "c_project_name",
    },
    {
      label: "請求No",
      field: "c_claim_number",
    },
    {
      label: "売上先",
      field: "c_client_name",
    },
    // {
    //   label: "項目",
    //   field: "c_sales_item_name",
    // },
    // {
    //   label: "科目",
    //   field: "c_sales_subject_name",
    // },
    // {
    //   label: "内訳",
    //   field: "c_sales_breakdown_name",
    // },
    {
      label: "金額",
      field: "c_sales_price",
    },
  ];
  // rows = [
  //   {
  //     ANKEN_NO: 1393,
  //     ANKEN_NM: "Crew Bus 6台 撤去作業費",
  //     KOUKOKU_NM: "デルタ航空",
  //     USER_NM: "大安",
  //   },
  //   {
  //     ANKEN_NO: 1562,
  //     ANKEN_NM: "表参道ビル",
  //     KOUKOKU_NM: "ADORE",
  //     USER_NM: "石原岳人",
  //   },
  //   {
  //     ANKEN_NO: 1610,
  //     ANKEN_NM: "【ハウスドゥ様】錦町MKビル 年間掲出料 2014",
  //     KOUKOKU_NM: "ハウスドゥ",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1681,
  //     ANKEN_NM: "ＴＤＲマジカルファンタジー号/JALPAK50周年ラッピングバス",
  //     KOUKOKU_NM: "JALPAK",
  //     USER_NM: "佐藤和博",
  //   },
  //   {
  //     ANKEN_NO: 1710,
  //     ANKEN_NM: "表参道Nビル",
  //     KOUKOKU_NM: "COACH",
  //     USER_NM: "石原岳人",
  //   },
  //   {
  //     ANKEN_NO: 1772,
  //     ANKEN_NM: "【BEN&JERRY’S】倉庫保管料金 2015",
  //     KOUKOKU_NM: "BEN&JERRY’S",
  //     USER_NM: "大安",
  //   },
  //   {
  //     ANKEN_NO: 1774,
  //     ANKEN_NM: "【BEN&JERRY’S】スクープショップ プロモーション 2015",
  //     KOUKOKU_NM: "BEN&JERRY’S",
  //     USER_NM: "大安",
  //   },
  //   {
  //     ANKEN_NO: 1776,
  //     ANKEN_NM: "【BEN&JERRY’S】カウカー2号車（シボレー P30 GRUMMAN）",
  //     KOUKOKU_NM: "BEN&JERRY’S",
  //     USER_NM: "大安",
  //   },
  //   {
  //     ANKEN_NO: 1782,
  //     ANKEN_NM: "清水ビル　キッズプロモーション　2015",
  //     KOUKOKU_NM: "PRADA",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1785,
  //     ANKEN_NM: "ＴＡＫＡＴＡ様　ビルボード　エクセル天王",
  //     KOUKOKU_NM: "TAKATA",
  //     USER_NM: "石原岳人",
  //   },
  //   {
  //     ANKEN_NO: 1787,
  //     ANKEN_NM: "【グロップ】大型ビジョン放映料",
  //     KOUKOKU_NM: "グロップ",
  //     USER_NM: "葛谷健一",
  //   },
  //   {
  //     ANKEN_NO: 1788,
  //     ANKEN_NM: "錦町MKビル　年間掲出料　2015",
  //     KOUKOKU_NM: "ハウスドゥ",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1789,
  //     ANKEN_NM: "【Mercedes-Benz Connection】葵ビル 年間掲出料 2015",
  //     KOUKOKU_NM: "メルセデス",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1790,
  //     ANKEN_NM: "ライオンズマンション小川恵比寿",
  //     KOUKOKU_NM: "住友不動産",
  //     USER_NM: "石原岳人",
  //   },
  //   {
  //     ANKEN_NO: 1791,
  //     ANKEN_NM: "【リクルート・SUMO】大型ビジョン放映料",
  //     KOUKOKU_NM: "リクルート",
  //     USER_NM: "葛谷健一",
  //   },
  //   {
  //     ANKEN_NO: 1792,
  //     ANKEN_NM: "【エースコック】大型ビジョン放映料",
  //     KOUKOKU_NM: "エースコック",
  //     USER_NM: "葛谷健一",
  //   },
  //   {
  //     ANKEN_NO: 1793,
  //     ANKEN_NM: "GIN BOARD 2015(個別仕入情報:堂島大山ビル)",
  //     KOUKOKU_NM: "Apple",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1794,
  //     ANKEN_NM: "GIN BOARD 2015(高井ビル:9年目)",
  //     KOUKOKU_NM: "Apple",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1795,
  //     ANKEN_NM: "表参道ヴァンテベールビル壁面　意匠変更費",
  //     KOUKOKU_NM: "タグホイヤー",
  //     USER_NM: "石原岳人",
  //   },
  //   {
  //     ANKEN_NO: 1796,
  //     ANKEN_NM: "APPLEボード東阪名（長期）①媒体料（はる木ビル）2015",
  //     KOUKOKU_NM: "Apple",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1797,
  //     ANKEN_NM: "APPLEボード東阪名（長期）①媒体料（藤田万年堂） 2015",
  //     KOUKOKU_NM: "Apple",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1798,
  //     ANKEN_NM: "【大型ビジョン放映料】JYJプロモーション",
  //     KOUKOKU_NM: "JYJプロモーション",
  //     USER_NM: "葛谷健一",
  //   },
  //   {
  //     ANKEN_NO: 1799,
  //     ANKEN_NM: "陽光セントラルビル　年間掲出料",
  //     KOUKOKU_NM: "バカルディジャパン",
  //     USER_NM: "石原岳人",
  //   },
  //   {
  //     ANKEN_NO: 1802,
  //     ANKEN_NM: "SPP1～4個別　2014",
  //     KOUKOKU_NM: "コンバース",
  //     USER_NM: "成澤壮一郎",
  //   },
  //   {
  //     ANKEN_NO: 1803,
  //     ANKEN_NM: "APPLEボード東阪名（長期）防犯システム 2014-2015",
  //     KOUKOKU_NM: "Apple",
  //     USER_NM: "田中真哉",
  //   },
  //   {
  //     ANKEN_NO: 1804,
  //     ANKEN_NM: "【DMM】秋葉原・高和ビル/外神田YSビル 掲出料 2015",
  //     KOUKOKU_NM: "DMM",
  //     USER_NM: "田中真哉",
  //   },
  // ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSearchClaimNoGetReq();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domClaimNo != "") dto.ClaimNo = Number(this.domClaimNo);

      Cl_common.logger("domClaimNo");
      Cl_common.logger(this.domClaimNo);

      //API実行
      //-------
      this.delegate = await Cl_searchClaimNo.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchClaimNoGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "SeikyuNoList";

      if (this.domClaimNo != "") dto.ClaimNo = Number(this.domClaimNo);

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      const panel = document.getElementsByClassName("panel-container");
      Array.from(panel).map((element: Element) => {
        element.classList.add("enable-loader");
      });

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/AnkenInput?id=" + id);
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }

  cnvDetails(price: any) {
    let cnvPrice;

    //金額
    if (price != null) {
      cnvPrice = price!.toLocaleString("ja-JP");
    } else {
      cnvPrice = "";
    }
    return cnvPrice;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
