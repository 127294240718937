import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestTmppaysGetReqImple } from "mediadept-common/src/common/dto/DtoRequestTmppaysGetReqImple";
import { DtoRequestTmppaysGetResImple } from "mediadept-common/src/common/dto/DtoRequestTmppaysGetResImple";
import { DtoRequestTmppaysPostReq } from "mediadept-common/src/common/dto/abst/DtoRequestTmppaysPostReq";
import { DtoRequestTmppaysPostRes } from "mediadept-common/src/common/dto/abst/DtoRequestTmppaysPostRes";
import { DtoRequestTmppaysPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestTmppaysPutReq";
import { DtoRequestTmppaysPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestTmppaysPutRes";
import { DtoRequestTmppaysDeleteReq } from "mediadept-common/src/common/dto/abst/DtoRequestTmppaysDeleteReq";
import { DtoRequestTmppaysDeleteRes } from "mediadept-common/src/common/dto/abst/DtoRequestTmppaysDeleteRes";

export class Cl_requesttmppay {
  public static async getList(
    dto: DtoRequestTmppaysGetReqImple
  ): Promise<DtoRequestTmppaysGetResImple> {
    return Object.assign(
      new DtoRequestTmppaysGetResImple(),
      await CallAPI.getRequest(
        "/requesttmppays?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestTmppaysGetReqImple
  ): Promise<DtoRequestTmppaysGetResImple> {
    return Object.assign(
      new DtoRequestTmppaysGetResImple(),
      await CallAPI.getRequest("/requesttmppays/" + dto.c_request_tmppay_id)
    );
  }

  public static async post(
    dto: DtoRequestTmppaysPostReq
  ): Promise<DtoRequestTmppaysPostRes> {
    return Object.assign(
      new DtoRequestTmppaysPostRes(),
      await CallAPI.postRequest("/requesttmppays", dto)
    );
  }

  public static async put(
    dto: DtoRequestTmppaysPutReq
  ): Promise<DtoRequestTmppaysPutRes> {
    return Object.assign(
      new DtoRequestTmppaysPutRes(),
      await CallAPI.putRequest(
        "/requesttmppays/" + dto.c_request_tmppay_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestTmppaysDeleteReq
  ): Promise<DtoRequestTmppaysDeleteRes> {
    return Object.assign(
      new DtoRequestTmppaysDeleteRes(),
      await CallAPI.deleteRequest(
        "/requesttmppays?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
