import { CONST_BREAKDOWNS, CONST_SUBJECTS } from "../constant/Constants";
import { EntityPayment } from "../entity/EntityPayment";
import { DtoMdDtp } from "./DtoMdDtp";

export class DtoPaymentGetRes {

    public items: EntityPayment[] = [];
    public exitems: Exitem[] = [];

    //メソッド
    //-----------------------
    public async setEximtes(clientmap: Map<number, string>, staffmap: Map<number, string>, Projectmap: Map<number, number>) {
        let tmp: Exitem;
        let tmpCmpDate = new DtoMdDtp();
        for (let index = 0; index < this.items.length; index++) {
            tmp = new Exitem();
            tmpCmpDate = new DtoMdDtp();

            tmp.c_claim_id = this.items[index].c_claim_id;
            tmp.c_project_id = this.items[index].c_project_id;
            tmp.c_claim_number = this.items[index].c_claim_number;
            tmp.c_client_id = this.items[index].c_client_id;
            tmp.c_claim_title_1 = this.items[index].c_claim_title_1;
            tmp.c_claim_subtotal = this.items[index].c_claim_subtotal;
            tmp.c_claim_total = this.items[index].c_claim_total;
            tmp.c_staff_id = this.items[index].c_staff_id;
            tmp.c_sales_id = this.items[index].c_sales_id;
            tmp.c_sales_issue = this.items[index].c_sales_issue;
            // tmp.c_sales_item = this.items[index].c_sales_item;
            // tmp.c_sales_subject = this.items[index].c_sales_subject;
            // tmp.c_sales_breakdown = this.items[index].c_sales_breakdown;
            tmp.c_sales_price = this.items[index].c_sales_price;
            tmp.c_sales_date_exp_payment = this.items[index].c_sales_date_exp_payment;
            tmp.c_sales_date_cmp_payment = this.items[index].c_sales_date_cmp_payment;

            tmp.c_client_name = clientmap.get(this.items[index].c_client_id!)!;
            tmp.c_staff_name = staffmap.get(this.items[index].c_staff_id!)!;
            tmp.c_project_number = Projectmap.get(this.items[index].c_project_id!)!;

            // if (tmp.c_sales_subject != null) {
            //     tmp.c_sales_subject_name = CONST_SUBJECTS[tmp.c_sales_subject];
            // } else {
            //     tmp.c_sales_subject_name = "";
            // }

            // if (tmp.c_sales_breakdown != null) {
            //     tmp.c_sales_breakdown_name = CONST_BREAKDOWNS[tmp.c_sales_breakdown];
            // } else {
            //     tmp.c_sales_breakdown_name = "";
            // }

            if (tmp.c_sales_issue != null) {
                tmp.c_sales_issue_short = tmp.c_sales_issue.toLocaleDateString();
            }

            if (tmp.c_sales_date_exp_payment != null) {
                tmp.c_sales_date_exp_payment_short = tmp.c_sales_date_exp_payment.toLocaleDateString();
            }

            if (tmp.c_sales_date_cmp_payment != null) {
                tmp.c_sales_date_cmp_payment_short = tmp.c_sales_date_cmp_payment.toISOString().split('T')[0];
                // tmp.c_sales_date_cmp_payment_short = tmp.c_sales_date_cmp_payment_short.replace(/\//g,'-');
                console.log(tmp.c_sales_date_cmp_payment_short);
                tmpCmpDate.date = tmp.c_sales_date_cmp_payment_short;
            }
            
            tmp.cnvDatecmp_payment = tmpCmpDate;
            console.log("cnvDatecmp_payment");
            console.log(tmp.cnvDatecmp_payment.date);

            this.exitems.push(tmp);
        }
    }

}

export class Exitem extends EntityPayment {
    public c_client_name: string | null = null;
    public c_staff_name: string | null = null;
    public c_project_number: number | null = null;
    public c_sales_issue_short: string | null = null;
    public c_sales_date_exp_payment_short: string | null = null;
    public c_sales_date_cmp_payment_short: string | null = null;
    // public c_sales_subject_name: string | null = null;
    // public c_sales_breakdown_name: string | null = null;
}