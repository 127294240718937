import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoHolisticlocksGetReqImple } from "mediadept-common/src/common/dto/DtoHolisticlocksGetReqImple";
import { DtoHolisticlocksGetResImple } from "mediadept-common/src/common/dto/DtoHolisticlocksGetResImple";
import { DtoHolisticlocksPostReq } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPostReq";
import { DtoHolisticlocksPostRes } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPostRes";
import { DtoHolisticlocksPutReq } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPutReq";
import { DtoHolisticlocksPutRes } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPutRes";
import { DtoHolisticlocksDeleteReq } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksDeleteReq";
import { DtoHolisticlocksDeleteRes } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksDeleteRes";

export class Cl_holisticlock {
  public static async getList(
    dto: DtoHolisticlocksGetReqImple
  ): Promise<DtoHolisticlocksGetResImple> {
    return Object.assign(
      new DtoHolisticlocksGetResImple(),
      await CallAPI.getRequest(
        "/holisticlocks?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoHolisticlocksGetReqImple
  ): Promise<DtoHolisticlocksGetResImple> {
    return Object.assign(
      new DtoHolisticlocksGetResImple(),
      await CallAPI.getRequest("/holisticlocks/" + dto.c_holisticlock_id)
    );
  }

  public static async post(
    dto: DtoHolisticlocksPostReq
  ): Promise<DtoHolisticlocksPostRes> {
    return Object.assign(
      new DtoHolisticlocksPostRes(),
      await CallAPI.postRequest("/holisticlocks", dto)
    );
  }

  public static async put(
    dto: DtoHolisticlocksPutReq
  ): Promise<DtoHolisticlocksPutRes> {
    return Object.assign(
      new DtoHolisticlocksPutRes(),
      await CallAPI.putRequest("/holisticlocks/" + dto.c_holisticlock_id, dto)
    );
  }

  public static async del(
    dto: DtoHolisticlocksDeleteReq
  ): Promise<DtoHolisticlocksDeleteRes> {
    return Object.assign(
      new DtoHolisticlocksDeleteRes(),
      await CallAPI.deleteRequest("/holisticlocks/" + dto.c_holisticlock_id)
    );
  }
}
