import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityRequestHoliday } from "../../entity/EntityRequestHoliday";

export class DtoRequestHolidaysPostReq extends EntityRequestHoliday {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
