
import { Ref, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import {
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
} from "mediadept-common/src/common/constant/Constants";
// masters
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
//残業時間
import { Cl_requestovertime } from "@/functions/Cl_requestovertime";
import { DtoRequestOvertimesGetReqImple } from "mediadept-common/src/common/dto/DtoRequestOvertimesGetReqImple";
import { DtoRequestOvertimesGetResImple } from "mediadept-common/src/common/dto/DtoRequestOvertimesGetResImple";
import { DtoRequestOvertimesPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestOvertimesPutReq";
import { DtoRequestOvertimesPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestOvertimesPutRes";
import { DtoRequestOvertimesPostReqImple } from "mediadept-common/src/common/dto/DtoRequestOvertimesPostReqImple";
import { DtoRequestOvertimesPostResImple } from "mediadept-common/src/common/dto/DtoRequestOvertimesPostResImple";
//申請ヘッダ
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsPutReqImple } from "mediadept-common/src/common/dto/DtoRequestsPutReqImple";
import { DtoRequestsPutResImple } from "mediadept-common/src/common/dto/DtoRequestsPutResImple";
//store
import store from "@/store";
import { CommonStaff } from "mediadept-common/src/common/service/CommonStaff";
// バリデーション
import * as Yup from "yup";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";
import { CommonDate4MD } from "mediadept-common/src/common/function/CommonDate4MD";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";
//時間入力テキストボックス
import Mt_timeTxtBox from "@/components/atoms/Mt_timeTxtBox.vue";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mt_timeTxtBox,
  },
})
export default class shinsei_Zangyoedit extends Vue {
  @Ref("timeTxtBox") timeTxtBoxRef!: Mt_timeTxtBox;

  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    workDate: Yup.string().required("出勤日を入力してください"),
    workPlace: Yup.string().required("勤務地を入力してください"),
    workCont: Yup.string().required("業務内容を入力してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoRequestOvertimesGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isAdmin = false;
  isMine = false;
  isEditMode = true;

  domApplicantName = "";
  domContent = "";

  //日付関係
  domDateZangyou: string | undefined = ""; //delegate.c_request_overtime_ot_date
  domDateSyukin: string | undefined = ""; //delegate.c_request_overtime_hol_date
  domDateDaikyuSyujitu: string | undefined = ""; //delegate.c_request_overtime_compday_all
  domDateDaikyuBubun: string | undefined = ""; //delegate.c_request_overtime_compday_part

  // domAuthorizer1="";
  // domAuthorizer2="";
  // domAuthorizer3="";
  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConfEx = false;
  fmShowSasimodosi = false;
  fmShowTorisage = false;

  //時間入力テキストボックス用
  domStart_exp: {
    contTimeValue: string;
  } = {
    contTimeValue: "",
  };

  domEnd_exp: {
    contTimeValue: string;
  } = {
    contTimeValue: "",
  };

  //----------------------
  //DOM連携用メソッド
  //----------------------
  convDateTime(val: Date | null): string {
    let res = "";

    if (val == null) {
      res = "";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  /**
   * 承認者の順序を入力して、承認者名を得る
   * @param num
   */
  getAuthoriser(num: number): string {
    let res = "-";

    if (this.delegate4list.items4staffs == null) {
      return "-";
    }

    switch (num) {
      case 1:
        if (this.delegate.request.c_request_1_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_1_approval
          );
        }

        break;
      case 2:
        if (this.delegate.request.c_request_2_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_2_approval
          );
        }

        break;
      case 3:
        if (this.delegate.request.c_request_3_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_3_approval
          );
        }

        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 承認日時を受け取り、表示用の文字列にして返す
   * @param val
   */
  getAuthDate(authorizerId: number | null, val: Date | null): string {
    let res = "";

    if (authorizerId == null) {
      return "-";
    }

    if (val == null) {
      res = "未承認";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.request.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalDisableConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 1;
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      store.commit("revStore");
      const authType = store.state.authType;
      // Cl_common.logger(authType);
      this.isAdmin = authType == 4; //管理者判定

      if (id == "") {
        //新規追加
        this.isEditMode = false;

        //初期値設定
        this.delegate.request.c_staff_id = store.state.id;
        this.delegate.c_request_overtime_type = 1;
        this.fmShowConf = false;
        this.fmShowConfEx = false;

        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoRequestOvertimesGetReqImple();

      //※fanctions内のクラスの修正コスト軽減の為、c_request_overtime_idに入れているが、
      //  API側ではrequestIDとして扱われるので注意
      dto.c_request_overtime_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_requestovertime.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_request_overtime_id == null) {
        this.$router.push("/shinseisyolist");
      }

      //UI連携用変数に入力
      //---
      await this.cnvData4Get();

      //フラグ更新
      //---
      store.commit("revStore");
      const loginStaffId = store.state.id;
      this.isMine = loginStaffId == this.delegate.request.c_staff_id!; //申請者判定

      //承認関係の表記
      //---
      await this.chgAuthCont();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　GET用。
   */
  async cnvData4Get() {
    //業務内容
    if (this.delegate.c_request_overtime_cont != null) {
      this.domContent = this.delegate.c_request_overtime_cont;
    }

    //申請者
    this.domApplicantName = CommonStaff.cnvStaffNum2Name(
      this.delegate4list.items4staffs,
      this.delegate.request.c_staff_id
    );

    //残業日
    if (this.delegate.c_request_overtime_ot_date != null) {
      this.domDateZangyou = this.delegate
        .c_request_overtime_ot_date!.toString()
        .split("T")[0];
    }
    //出勤日
    if (this.delegate.c_request_overtime_hol_date != null) {
      this.domDateSyukin = this.delegate
        .c_request_overtime_hol_date!.toString()
        .split("T")[0];
    }

    //代休（終日
    if (this.delegate.c_request_overtime_compday_all != null) {
      this.domDateDaikyuSyujitu = this.delegate
        .c_request_overtime_compday_all!.toString()
        .split("T")[0];
    }
    //代休（部分）
    if (this.delegate.c_request_overtime_compday_part != null) {
      this.domDateDaikyuBubun = this.delegate
        .c_request_overtime_compday_part!.toString()
        .split("T")[0];
    }

    // //予定時間 開始
    // if (this.delegate.c_request_overtime_hol_datetime_start_exp != null) {
    //   this.domStart_exp.contTimeValue = this.timeTxtBoxRef.set(
    //     this.delegate.c_request_overtime_hol_datetime_start_exp!
    //   );
    // }

    // //予定時間 終了
    // if (this.delegate.c_request_overtime_hol_datetime_end_exp != null) {
    //   this.domEnd_exp.contTimeValue = this.timeTxtBoxRef.set(
    //     this.delegate.c_request_overtime_hol_datetime_end_exp!
    //   );
    // }
  }

  /**
   * 承認に関するコンテンツを制御する
   */
  async chgAuthCont() {
    let loginStaffId = -1;

    switch (this.delegate.request.c_request_status) {
      case CONST_REQUEST_STATUS_SINSEITYU:
        //申請中
        //-----------
        store.commit("revStore");
        loginStaffId = store.state.id;
        if (
          (loginStaffId == this.delegate.request.c_request_1_approval &&
            this.delegate.request.c_request_1_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_2_approval &&
            this.delegate.request.c_request_2_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_3_approval &&
            this.delegate.request.c_request_3_approval_datetime == null)
        ) {
          //自身が承認者で未承認
          //--------
          this.fmShowConf = true;
          this.fmShowConfEx = false;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        } else {
          //自身が承認者でない、もしくは承認済み
          //--------
          this.fmShowConf = false;
          this.fmShowConfEx = true;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        }

        break;
      case CONST_REQUEST_STATUS_SASIMODOSI:
        //差戻
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = true;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_SYOUNINZUMI:
        //承認済
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_TORISAGE:
        //取下
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = true;

        break;
      default:
        throw new Error("不正なstatus");
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isDisable: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_request_flag_del = 1;
      }
      if (isDisable) {
        //差戻処理：ステータス変更。
        dto.c_request_status = 4;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      // //準備
      // //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isApproval) {
        //承認
        dto.isApproval = 1;
      } else {
        //差戻
        dto.isApproval = 0;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //バリデーション
      //-----------------
      this.errors = null;
      //独自の時間検証
      if (
        !(await CommonDate4MD.validateTimes(
          this.delegate.c_request_overtime_hol_datetime_start_exp,
          this.delegate.c_request_overtime_hol_datetime_end_exp
        ))
        // !(await CommonDate4MD.validateTimes(
        //   this.domStart_exp.contTimeValue.replace(/^(-?\d+)(\d{2})/, "$1:$2"),
        //   this.domEnd_exp.contTimeValue.replace(/^(-?\d+)(\d{2})/, "$1:$2")
        // ))
      ) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "予定時間が不正です";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      this.schemaAdd.validateSync({
        workDate: this.domDateSyukin,
        workPlace: this.delegate.c_request_overtime_hol_location,
        workCont: this.domContent,
      });

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---
      await this.cnvData4Add();

      let dto = Object.assign(
        new DtoRequestOvertimesPostReqImple(),
        this.delegate
      );

      //API実行
      //-------
      const res = await Cl_requestovertime.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/shinsei_Zangyoedit",
        query: { id: res.c_request_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　ADD用。
   */
  async cnvData4Add() {
    //業務内容
    if (this.domContent != null && this.domContent != undefined) {
      this.delegate.c_request_overtime_cont = this.domContent;
    }

    //残業日
    if (this.domDateZangyou != undefined) {
      this.delegate.c_request_overtime_ot_date = new Date(this.domDateZangyou);
    }
    //出勤日
    if (this.domDateSyukin != undefined) {
      this.delegate.c_request_overtime_hol_date = new Date(this.domDateSyukin);
    }

    //代休（終日
    if (this.domDateDaikyuSyujitu != undefined) {
      this.delegate.c_request_overtime_compday_all = new Date(
        this.domDateDaikyuSyujitu
      );
    }

    //代休（部分）
    if (this.domDateDaikyuBubun != undefined) {
      this.delegate.c_request_overtime_compday_part = new Date(
        this.domDateDaikyuBubun
      );
    }

    //予定時間 開始
    // if (this.domStart_exp.contTimeValue != undefined) {
    //   this.delegate.c_request_overtime_hol_datetime_start_exp =
    //     this.timeTxtBoxRef.add(this.domStart_exp.contTimeValue);
    // }

    //予定時間 終了
    // if (this.domEnd_exp.contTimeValue != undefined) {
    //   this.delegate.c_request_overtime_hol_datetime_end_exp =
    //     this.timeTxtBoxRef.add(this.domEnd_exp.contTimeValue);
    // }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * 取り下げ用のモーダルを表示
   */
  async disableConf() {
    //モーダルのメッセージを設定
    this.message4ModalDisableConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を取り下げます。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }
  /**
   * データを取り下げる
   */
  async disab() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4disableConf();
    this.upd(false, true);
  }

  /**
   * 時間外残業と、休日勤務の切り替え
   */
  async chgType() {
    Cl_common.logger(this.delegate.c_request_overtime_type);
    if (this.delegate.c_request_overtime_type == 1) {
      //時間外残業
      Cl_common.logger("時間外残業");
    } else {
      //休日勤務
      Cl_common.logger("休日勤務");
    }
  }

  /**
   * 代休の終日が入力されたか、部分が入力されたかによって
   * 反対の入力値を初期化する処理
   * 1:終日変化
   * 2:部分変化
   */
  async cngDaikyu(val: number) {
    if (val == 1) {
      //終日変化
      Cl_common.logger("終日変化");
      this.delegate.c_request_overtime_compday_part = null;
      this.delegate.c_request_overtime_compday_part_start_time = null;
      this.delegate.c_request_overtime_compday_part_end_time = null;
    } else {
      //部分変化
      Cl_common.logger("部分変化");
      this.delegate.c_request_overtime_compday_all = null;
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "Zangyo";
      let id = Cl_common.getParam("id");
      dto.requestId = Number(id);
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4disableConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
