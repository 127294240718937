import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-587062a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "koumoku claimCont cwC_1" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "suuryou claimCont cwC_2" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "tanka claimCont cwC_3" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "kingaku claimCont cwC_4" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "syouhizei claimCont cwC_5 selectedClear selectBorderNone disableWhite" }
const _hoisted_10 = { class: "zeikomi claimCont cwC_6" }
const _hoisted_11 = { class: "keisan claimCont cwC_7 selectBorderNone disableWhite" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vSelect = _resolveComponent("vSelect")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.c_claim_det_item) = $event)),
        disabled: _ctx.btnDisable
      }, null, 8, _hoisted_2), [
        [_vModelText, _ctx.item.c_claim_det_item]
      ])
    ]),
    _createElementVNode("td", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        style: {"text-align":"right"},
        class: "",
        disabled: _ctx.btnDisable,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.c_claim_det_quantity) = $event))
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.item.c_claim_det_quantity]
      ])
    ]),
    _createElementVNode("td", _hoisted_5, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        style: {"text-align":"right"},
        class: "",
        disabled: _ctx.btnDisable,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.c_claim_det_unitprice) = $event))
      }, null, 8, _hoisted_6), [
        [_vModelText, _ctx.item.c_claim_det_unitprice]
      ])
    ]),
    _createElementVNode("td", _hoisted_7, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        style: {"text-align":"right"},
        maxlength: "50",
        class: "",
        onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.kanmaDel($event))),
        onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.kanmaIns($event))),
        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.calcTax())),
        disabled: _ctx.btnDisable,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.c_claim_det_price) = $event))
      }, null, 40, _hoisted_8), [
        [_vModelText, _ctx.item.c_claim_det_price]
      ])
    ]),
    _createElementVNode("td", _hoisted_9, [
      _createVNode(_component_vSelect, {
        disabled: _ctx.btnDisable,
        selectable: _ctx.calcTax(),
        options: _ctx.opTaxtate,
        modelValue: _ctx.item.c_claim_det_taxrate,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.item.c_claim_det_taxrate) = $event)),
        reduce: (label) => label.code
      }, null, 8, ["disabled", "selectable", "options", "modelValue", "reduce"])
    ]),
    _createElementVNode("td", _hoisted_10, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        style: {"text-align":"right"},
        class: "",
        disabled: "true",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.item.c_claim_det_price_sum) = $event))
      }, null, 512), [
        [_vModelText, _ctx.item.c_claim_det_price_sum]
      ])
    ]),
    _createElementVNode("td", _hoisted_11, [
      _createVNode(_component_vSelect, {
        disabled: _ctx.btnDisable,
        options: _ctx.opRouding,
        modelValue: _ctx.item.c_claim_rounding,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.item.c_claim_rounding) = $event)),
        reduce: (label) => label.code
      }, null, 8, ["disabled", "options", "modelValue", "reduce"])
    ])
  ]))
}