
import { useDropzone } from "vue3-dropzone";
import { defineComponent, PropType, ref } from "vue";
import { Cl_common } from "@/functions/Cl_common";

// fileInfo オブジェクトの型を定義します
interface FileInfo {
  path: string;
  name: string;
  size: number;
  type: string;
  lastModified: number;
  data: any;
  isExecute: boolean;
  isRejected: boolean;
}

export default {
  name: "Or_fileupload",

  props: {
    fileInfo: {
      type: Object as PropType<FileInfo>,
      required: true,
    },
    updateFileInfo: {
      type: Function as PropType<(file: FileInfo) => void>,
      required: true,
    },
    isMinMode: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  setup(props: any) {
    const isRejected = ref(false);

    function onDrop(acceptFiles: any, rejectReasons: any) {
      props.updateFileInfo({
        path: "",
        name: "",
        size: -1,
        type: "",
        lastModified: "",
        data: null,
        isExecute: false,
      });

      Cl_common.logger("acceptFiles");
      Cl_common.logger(acceptFiles);
      Cl_common.logger("rejectReasons");
      Cl_common.logger(rejectReasons);
      isRejected.value = false;

      if (acceptFiles.length != 1 || rejectReasons.length != 0) {
        isRejected.value = true;
        return;
      }

      const newFileInfo = {
        path: acceptFiles[0].path,
        name: acceptFiles[0].name,
        size: acceptFiles[0].size,
        type: acceptFiles[0].type,
        lastModified: acceptFiles[0].lastModified,
        data: acceptFiles[0],
        isExecute: true,
      };

      // 親コンポーネントのメソッドを使って fileInfo を更新
      props.updateFileInfo(newFileInfo);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      maxFiles: 1,
      maxSize: 3000000,
      accept: ".pdf",
    });

    return {
      getRootProps,
      getInputProps,
      isRejected,
      ...rest,
    };
  },
};
