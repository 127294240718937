export class EntityRequestTmppay {
public c_request_tmppay_id:number | null = null;
public c_request_id:number | null = null;
public c_request_tmppay_price:number | null = null;
public c_request_tmppay_date:Date | null = null;
public c_request_tmppay_date_exp_payment:Date | null = null;
public c_request_tmppay_cont:string | null = null;
public c_request_tmppay_reg_user:string | null = null;
public c_request_tmppay_reg_at:Date | null = null;
public c_request_tmppay_upd_user:string | null = null;
public c_request_tmppay_upd_at:Date | null = null;
}