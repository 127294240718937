import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoLocksGetReqImple } from "mediadept-common/src/common/dto/DtoLocksGetReqImple";
import { DtoLocksGetResImple } from "mediadept-common/src/common/dto/DtoLocksGetResImple";
import { DtoLocksPostReq } from "mediadept-common/src/common/dto/abst/DtoLocksPostReq";
import { DtoLocksPostRes } from "mediadept-common/src/common/dto/abst/DtoLocksPostRes";
import { DtoLocksPutReq } from "mediadept-common/src/common/dto/abst/DtoLocksPutReq";
import { DtoLocksPutRes } from "mediadept-common/src/common/dto/abst/DtoLocksPutRes";
import { DtoLocksDeleteReq } from "mediadept-common/src/common/dto/abst/DtoLocksDeleteReq";
import { DtoLocksDeleteRes } from "mediadept-common/src/common/dto/abst/DtoLocksDeleteRes";

export class Cl_lock {
  public static async getList(
    dto: DtoLocksGetReqImple
  ): Promise<DtoLocksGetResImple> {
    return Object.assign(
      new DtoLocksGetResImple(),
      await CallAPI.getRequest(
        "/locks?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoLocksGetReqImple
  ): Promise<DtoLocksGetResImple> {
    return Object.assign(
      new DtoLocksGetResImple(),
      await CallAPI.getRequest("/locks/" + dto.c_lock_id)
    );
  }

  public static async post(dto: DtoLocksPostReq): Promise<DtoLocksPostRes> {
    return Object.assign(
      new DtoLocksPostRes(),
      await CallAPI.postRequest("/locks", dto)
    );
  }

  public static async put(dto: DtoLocksPutReq): Promise<DtoLocksPutRes> {
    return Object.assign(
      new DtoLocksPutRes(),
      await CallAPI.putRequest("/locks/" + dto.c_lock_id, dto)
    );
  }

  public async del(dto: DtoLocksDeleteReq): Promise<DtoLocksDeleteRes> {
    return Object.assign(
      new DtoLocksDeleteRes(),
      await CallAPI.deleteRequest("/locks?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
