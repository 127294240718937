import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuePdf = _resolveComponent("VuePdf")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numOfPages, (page) => {
    return (_openBlock(), _createBlock(_component_VuePdf, {
      key: page,
      src: _ctx.pdfSrc,
      page: page,
      enableTextSelection: false
    }, null, 8, ["src", "page"]))
  }), 128))
}