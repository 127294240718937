
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoPaymentGetReq } from "mediadept-common/src/common/dto/DtoPaymentGetReq";
import { DtoPaymentGetRes } from "mediadept-common/src/common/dto/DtoPaymentGetRes";
import { Cl_payment } from "@/functions/Cl_payment";
import { DtoSalessPutReq } from "mediadept-common/src/common/dto/abst/DtoSalessPutReq";
import { Cl_sales } from "@/functions/Cl_sales";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";

import Or_selectClient from "@/components/organisms/Or_selectClient.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";
import { Watch } from "vue-property-decorator/lib/decorators/Watch";

import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Or_selectClient,
    Mt_dtp,
  },
})
export default class Nyukinyoteilist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoPaymentGetRes();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domClaimNo_start = "";
  domClaimNo_end = "";
  // domClaimDate_start: Date | null = null;
  domClaimDate_start = new DtoMdDtp();
  // domClaimDate_end: Date | null = null;
  domClaimDate_end = new DtoMdDtp();
  domClientid: number | null = null;
  domClaimTitle = "";
  domClaimSponsorName = "";
  // domClaimPayLimit_start: Date | null = null;
  domClaimPayLimit_start = new DtoMdDtp();
  // domClaimPayLimit_end: Date | null = null;
  domClaimPayLimit_end = new DtoMdDtp();
  domStaffId = "";
  domPaymentStatus = "";

  optionsOp3 = [
    { label: "未入金のみ", code: 1 },
    { label: "入金のみ", code: 2 },
  ];
  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "案件番号",
      field: "c_project_number",
    },
    {
      label: "請求No",
      field: "c_claim_number",
    },
    {
      label: "発行日",
      field: "c_sales_issue_short",
      type: "date",
      dateInputFormat: "MM/dd/yyyy",
      dateOutputFormat: "yyyy/MM/dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "請求先",
      field: "c_client_name",
    },
    {
      label: "案件名",
      field: "c_claim_title_1",
    },
    {
      label: "税抜金額",
      field: "c_claim_subtotal",
    },
    {
      label: "請求合計",
      field: "c_claim_total",
    },
    // {
    //   label: "科目",
    //   field: "c_sales_subject_name",
    // },
    // {
    //   label: "内訳",
    //   field: "c_sales_breakdown_name",
    // },
    {
      label: "売上金額",
      field: "c_sales_price",
    },
    {
      label: "担当者",
      field: "c_staff_name",
    },
    {
      label: "入金予定日",
      field: "c_sales_date_exp_payment_short",
      type: "date",
      dateInputFormat: "MM/dd/yyyy",
      dateOutputFormat: "yyyy/MM/dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "入金日",
      field: "c_sales_date_cmp_payment_short",
      type: "date",
      dateInputFormat: "yyyy-MM-dd",
      dateOutputFormat: "yyyy-MM-dd",
      thClass: "text-center",
      tdClass: "text-center dtpPaddeing",
    },
  ];

  // //請求先選択用
  evDatas: {
    selectedItem: EntityClient | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
    await this.getStaffList();
    await this.getClientList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoPaymentGetReq();

      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      // dto.pager.cursor = currentPage;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.ClaimDate_start = this.domClaimDate_start;

      if (this.domClaimDate_start.date != null) {
        dto.ClaimDate_start = new Date(this.domClaimDate_start.date);
      }

      // dto.ClaimDate_end = this.domClaimDate_end;

      if (this.domClaimDate_end.date != null) {
        dto.ClaimDate_end = new Date(this.domClaimDate_end.date);
      }

      if (this.domClientid != null) dto.Clientid = Number(this.domClientid);

      dto.ClaimTitle = this.domClaimTitle;

      dto.ClaimSponsorName = this.domClaimSponsorName;

      // dto.ClaimPayLimit_start = this.domClaimPayLimit_start;

      if (this.domClaimPayLimit_start.date != null) {
        dto.ClaimPayLimit_start = new Date(this.domClaimPayLimit_start.date);
      }

      // dto.ClaimPayLimit_end = this.domClaimPayLimit_end;

      if (this.domClaimPayLimit_end.date != null) {
        dto.ClaimPayLimit_end = new Date(this.domClaimPayLimit_end.date);
      }

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.StaffId = Number(this.domStaffId);

      if (this.domPaymentStatus != "" && this.domPaymentStatus != null)
        dto.PaymentStatus = Number(this.domPaymentStatus);

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      Cl_common.logger("API実行");
      this.delegate = await Cl_payment.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoPaymentGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "NyukinKakuteiList";

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.ClaimDate_start = this.domClaimDate_start;

      if (this.domClaimDate_start.date != null) {
        dto.ClaimDate_start = new Date(this.domClaimDate_start.date);
      }

      // dto.ClaimDate_end = this.domClaimDate_end;

      if (this.domClaimDate_end.date != null) {
        dto.ClaimDate_end = new Date(this.domClaimDate_end.date);
      }

      if (this.domClientid != null) dto.Clientid = Number(this.domClientid);

      dto.ClaimTitle = this.domClaimTitle;

      dto.ClaimSponsorName = this.domClaimSponsorName;

      // dto.ClaimPayLimit_start = this.domClaimPayLimit_start;

      if (this.domClaimPayLimit_start.date != null) {
        dto.ClaimPayLimit_start = new Date(this.domClaimPayLimit_start.date);
      }

      // dto.ClaimPayLimit_end = this.domClaimPayLimit_end;

      if (this.domClaimPayLimit_end.date != null) {
        dto.ClaimPayLimit_end = new Date(this.domClaimPayLimit_end.date);
      }

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.StaffId = Number(this.domStaffId);

      if (this.domPaymentStatus != "" && this.domPaymentStatus != null)
        dto.PaymentStatus = Number(this.domPaymentStatus);

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async upd_date() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    //準備
    //-------
    let dto = new DtoSalessPutReq();

    try {
      const table = document.getElementsByClassName("dateRow_id");

      let tmpDate;
      let tmpSalesId;
      for (var i = 0, len = table.length | 0; i < len; i = (i + 1) | 0) {
        // tmp = <HTMLInputElement>table[i];
        // tmpDate = table[i].getElementsByClassName("dateInput");
        tmpDate = table[i].getElementsByClassName("dp__input");
        tmpSalesId = table[i].getElementsByClassName("salesId");

        Cl_common.logger("tmpSalesId");
        Cl_common.logger(tmpSalesId[0].innerHTML);

        if (
          (tmpDate[0] as HTMLInputElement).value != null &&
          (tmpDate[0] as HTMLInputElement).value != ""
        ) {
          Cl_common.logger("tmpDate");
          Cl_common.logger((tmpDate[0] as HTMLInputElement).value);

          dto.c_sales_id = Number(tmpSalesId[0].innerHTML);

          dto.c_sales_date_cmp_payment = new Date(
            (tmpDate[0] as HTMLInputElement).value
          );
          //API実行
          //-------
          dto.isUpd_date = 1;
          await Cl_sales.put(dto);
        } else {
          Cl_common.logger("tmpDate");
          Cl_common.logger((tmpDate[0] as HTMLInputElement).value);

          dto.c_sales_id = Number(tmpSalesId[0].innerHTML);

          dto.c_sales_date_cmp_payment = null;
          //API実行
          //-------
          dto.isUpd_date = 1;
          await Cl_sales.put(dto);
        }
      }

      //更新処理：
      this.message4ModalSuccess = "正常に変更されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *社員プルダウン取得用
   */
  async getStaffList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //TODO:#4134
      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 1;
      //表示用
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegateStaff = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateStaff = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *顧客プルダウン取得用
   */
  async getClientList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行顧客マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;

      //API実行
      //-------
      this.delegateClient = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateClient);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateClient = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 顧客選択時のイベント
   * watch用
   */
  @Watch("evDatas.isSelected")
  async selectClient() {
    if (!this.evDatas.isSelected) {
      return;
    }
    this.domClientid = this.evDatas.selectedItem!.c_client_id!;
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/AnkenInput?id=" + id);
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }

  cnvDetails(price: any) {
    let cnvPrice;

    //金額
    if (price != null) {
      cnvPrice = price!.toLocaleString("ja-JP");
    } else {
      cnvPrice = "";
    }
    return cnvPrice;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  /**
   * 請求先選択用モーダル
   */
  async showModalclaim(): Promise<void> {
    this.evDatas.selectedItem = null;
    this.evDatas.isSelected = false;

    const modalClient: any = this.$refs.Mo_modal_selectType;
    await modalClient.showModal();
  }
  async closeModalclaim(): Promise<void> {
    const modalClient: any = this.$refs.Mo_modal_selectType;
    modalClient.closeModal();
  }
}
