import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoDocsPutReq } from "./abst/DtoDocsPutReq";

export class DtoDocsPutReqImple extends DtoDocsPutReq {
  //画像用
  //-------------------
  public filename: string | null = null;
  public filetype: string | null = null;
  public filesize: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    res = res.concat(super.IsValid());

    return res;
  }
}
