
import { Prop, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EntityClaimDet } from "mediadept-common/src/common/entity/EntityClaimDet";

import Mo_claimlist_row from "../molecules/Mo_claimlist_row.vue";

@Options({
  components: {
    vSelect,
    Mo_claimlist_row,
  },
})
export default class Or_tableClaim extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  items: EntityClaimDet[];

  sum = "0";
  sumTax = "0";

  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }

  /**
   * buttonの有効無効を制御
   */
  eventAdjButton(btnDisableTmp: boolean) {
    this.btnDisable = btnDisableTmp;
  }
  //----------------------
  //基本操作用メソッド
  //----------------------

  delRow() {
    this.items.pop();
    this.sumCalc();
  }
  addRow() {
    if (17 <= this.items.length) {
      return;
    }

    let tmp = new EntityClaimDet();

    tmp.c_claim_det_id = -1;
    tmp.c_claim_rownum = this.items.length + 1;
    tmp.c_claim_det_taxrate = 3;
    tmp.c_claim_rounding = 0;

    this.items.push(tmp);
  }

  /**
   * 小計、請求金額の再計算
   */
  sumCalc() {
    let tmpSum = 0;
    let tmpSumTax = 0;

    for (const item of this.items) {
      if (item.c_claim_det_price != null && item.c_claim_det_price != "") {
        tmpSum += Number(item.c_claim_det_price.replace(/,/g, ""));
      }
      if (
        item.c_claim_det_price_sum != null &&
        item.c_claim_det_price_sum != ""
      ) {
        tmpSumTax += Number(item.c_claim_det_price_sum!.replace(/,/g, ""));
      }
    }

    this.sum = tmpSum.toString();
    this.sumTax = tmpSumTax.toString();

    while (
      this.sum != (this.sum = this.sum.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
    );
    while (
      this.sumTax !=
      (this.sumTax = this.sumTax.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
    );
  }
  getSum(): string[] {
    let res: string[] = [];
    this.sumCalc();
    res.push(this.sum);
    res.push(this.sumTax);
    return res;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
