
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Mo_modal_s extends Vue {
  /**
   * 表示用フラグ
   */
  public showFlag = false;

  async showModal(): Promise<void> {
    this.showFlag = true;
  }
  async closeModal(): Promise<void> {
    this.showFlag = false;
  }
}
