import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestLoasGetReqImple } from "mediadept-common/src/common/dto/DtoRequestLoasGetReqImple";
import { DtoRequestLoasGetResImple } from "mediadept-common/src/common/dto/DtoRequestLoasGetResImple";
import { DtoRequestLoasPostReq } from "mediadept-common/src/common/dto/abst/DtoRequestLoasPostReq";
import { DtoRequestLoasPostRes } from "mediadept-common/src/common/dto/abst/DtoRequestLoasPostRes";
import { DtoRequestLoasPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestLoasPutReq";
import { DtoRequestLoasPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestLoasPutRes";
import { DtoRequestLoasDeleteReq } from "mediadept-common/src/common/dto/abst/DtoRequestLoasDeleteReq";
import { DtoRequestLoasDeleteRes } from "mediadept-common/src/common/dto/abst/DtoRequestLoasDeleteRes";

export class Cl_requestloa {
  public static async getList(
    dto: DtoRequestLoasGetReqImple
  ): Promise<DtoRequestLoasGetResImple> {
    return Object.assign(
      new DtoRequestLoasGetResImple(),
      await CallAPI.getRequest(
        "/requestloas?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestLoasGetReqImple
  ): Promise<DtoRequestLoasGetResImple> {
    return Object.assign(
      new DtoRequestLoasGetResImple(),
      await CallAPI.getRequest("/requestloas/" + dto.c_request_loa_id)
    );
  }

  public static async post(
    dto: DtoRequestLoasPostReq
  ): Promise<DtoRequestLoasPostRes> {
    return Object.assign(
      new DtoRequestLoasPostRes(),
      await CallAPI.postRequest("/requestloas", dto)
    );
  }

  public static async put(
    dto: DtoRequestLoasPutReq
  ): Promise<DtoRequestLoasPutRes> {
    return Object.assign(
      new DtoRequestLoasPutRes(),
      await CallAPI.putRequest("/requestloas/" + dto.c_request_loa_id, dto)
    );
  }

  public static async del(
    dto: DtoRequestLoasDeleteReq
  ): Promise<DtoRequestLoasDeleteRes> {
    return Object.assign(
      new DtoRequestLoasDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestloas?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
