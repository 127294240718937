import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityDoc4det } from "../../entity/EntityDoc4det";

export class DtoDoc4detsGetReq extends EntityDoc4det {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_doc_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
