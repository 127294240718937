import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoClaimsGetReqImple } from "mediadept-common/src/common/dto/DtoClaimsGetReqImple";
import { DtoClaimsGetResImple } from "mediadept-common/src/common/dto/DtoClaimsGetResImple";
import { DtoClaimsPostReq } from "mediadept-common/src/common/dto/abst/DtoClaimsPostReq";
import { DtoClaimsPostRes } from "mediadept-common/src/common/dto/abst/DtoClaimsPostRes";
import { DtoClaimsPutReqImple } from "mediadept-common/src/common/dto/DtoClaimsPutReqImple";
import { DtoClaimsPutResImple } from "mediadept-common/src/common/dto/DtoClaimsPutResImple";
import { DtoClaimsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoClaimsDeleteReq";
import { DtoClaimsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoClaimsDeleteRes";
import { DtoClaimsPostReqImple } from "mediadept-common/src/common/dto/DtoClaimsPostReqImple";
import { DtoClaimsPostResImple } from "mediadept-common/src/common/dto/DtoClaimsPostResImple";

export class Cl_claim {
  public static async getList(
    dto: DtoClaimsGetReqImple
  ): Promise<DtoClaimsGetResImple> {
    return Object.assign(
      new DtoClaimsGetResImple(),
      await CallAPI.getRequest(
        "/claims?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoClaimsGetReqImple
  ): Promise<DtoClaimsGetResImple> {
    return Object.assign(
      new DtoClaimsGetResImple(),
      await CallAPI.getRequest(
        "/claims/" + dto.c_claim_id + "?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async post(
    dto: DtoClaimsPostReqImple
  ): Promise<DtoClaimsPostResImple> {
    return Object.assign(
      new DtoClaimsPostResImple(),
      await CallAPI.postRequest("/claims", dto)
    );
  }

  public static async put(
    dto: DtoClaimsPutReqImple
  ): Promise<DtoClaimsPutResImple> {
    return Object.assign(
      new DtoClaimsPutResImple(),
      await CallAPI.putRequest("/claims/" + dto.c_claim_id, dto)
    );
  }

  public async del(dto: DtoClaimsDeleteReq): Promise<DtoClaimsDeleteRes> {
    return Object.assign(
      new DtoClaimsDeleteRes(),
      await CallAPI.deleteRequest("/claims?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
