import { DtoRequestTmppaysPostReq } from "./abst/DtoRequestTmppaysPostReq";
import { EntityRequest } from "../entity/EntityRequest";

export class DtoRequestTmppaysPostReqImple extends DtoRequestTmppaysPostReq {
  public request = new EntityRequest();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック

    return res;
  }
}
