import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoDocfilesGetRes } from "./abst/DtoDocfilesGetRes";

export class DtoDocfilesGetResImple extends DtoDocfilesGetRes {
  /**
   * ファイルダウンロード用URL
   * getメソッドの戻り値として常にダウンロード用URLが返される。
   */
  public url: string | null = null;

  public pager = new DtoApiPager();
}
