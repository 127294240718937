export class EntityAlert {
public c_alert_id:number | null = null;
public c_staff_id:number | null = null;
public c_alert_type:number | null = null;
public c_alert_cont:string | null = null;
public c_alert_url_link:string | null = null;
public c_alert_limit:Date | null = null;
public c_alert_flag_read:number | null = null;
public c_alert_flag_mail:number | null = null;
public c_alert_marker:string | null = null;
public c_alert_reg_user:string | null = null;
public c_alert_reg_at:Date | null = null;
public c_alert_upd_user:string | null = null;
public c_alert_upd_at:Date | null = null;
}