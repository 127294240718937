
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import {
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
} from "mediadept-common/src/common/constant/Constants";
// masters
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
//残業時間
import { Cl_requestaprv } from "@/functions/Cl_requestaprv";
import { DtoRequestAprvsGetReqImple } from "mediadept-common/src/common/dto/DtoRequestAprvsGetReqImple";
import { DtoRequestAprvsGetResImple } from "mediadept-common/src/common/dto/DtoRequestAprvsGetResImple";
import { DtoRequestOvertimesPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestOvertimesPutReq";
import { DtoRequestOvertimesPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestOvertimesPutRes";
import { DtoRequestAprvsPostReqImple } from "mediadept-common/src/common/dto/DtoRequestAprvsPostReqImple";
import { DtoRequestOvertimesPostResImple } from "mediadept-common/src/common/dto/DtoRequestOvertimesPostResImple";
//申請ヘッダ
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsPutReqImple } from "mediadept-common/src/common/dto/DtoRequestsPutReqImple";
import { DtoRequestsPutResImple } from "mediadept-common/src/common/dto/DtoRequestsPutResImple";
//store
import store from "@/store";
import { CommonStaff } from "mediadept-common/src/common/service/CommonStaff";
// バリデーション
import * as Yup from "yup";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class shinsei_Ringishoedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    // Deadline: Yup.string().required("決裁期限を入力してください"),
    title: Yup.string().required("件名を入力してください"),
    email: Yup.string().email("有効なメールアドレスを入力してください"),
    reason: Yup.string().required("内容・目的・理由を入力してください"),
  });
  schemaAdd_price = Yup.object().shape({
    Price: Yup.number()
      .nullable()
      .required("支払金額（税込）を半角数字で入力してください")
      .typeError("支払金額（税込）を半角数字で入力してください"),
  });
  schema_result = Yup.object().shape({
    aprvResult: Yup.string().required("決済結果を選択してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoRequestAprvsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isAdmin = false;
  isMine = false;
  isEditMode = true;

  domApplicantName = "";
  domContent = "";
  domAddress = "";
  domTitle = "";
  domPrice = "";
  domCont = "";

  //※稟議書独自
  domComment_1 = "";
  domComment_2 = "";
  domComment_3 = "";
  isDisableComment1 = true;
  isDisableComment2 = true;
  isDisableComment3 = true;
  isDisableResult = true;

  //日付関係
  domDeadline: string | undefined = ""; //delegate.c_request_aprv_deadline
  // domDateSyukin: string | undefined = ""; //delegate.c_request_overtime_hol_date
  // domDateDaikyuSyujitu: string | undefined = ""; //delegate.c_request_overtime_compday_all
  // domDateDaikyuBubun: string | undefined = ""; //delegate.c_request_overtime_compday_part

  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConfEx = false;
  fmShowSasimodosi = false;
  fmShowTorisage = false;

  // optionsOp = [
  //   { label: "ユーザー1", code: 1 },
  //   { label: "ユーザー2", code: 2 },
  //   { label: "ユーザー3", code: 3 },
  // ];

  // optionsOp2 = [
  //   { label: "◯◯部◯◯課", code: 1 },
  //   { label: "△△部△△課", code: 2 },
  // ];

  // optionsOp3 = [
  //   { label: "なし", code: 1 },
  //   { label: "課長", code: 2 },
  //   { label: "部長", code: 3 },
  // ];

  optionsOp4 = [
    { label: "可決", code: 1 },
    { label: "条件付可決", code: 2 },
    { label: "保留", code: 3 },
    { label: "否決", code: 4 },
  ];

  //----------------------
  //DOM連携用メソッド
  //----------------------
  convDateTime(val: Date | null): string {
    let res = "";

    if (val == null) {
      res = "";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  /**
   * 承認者の順序を入力して、承認者名を得る
   * @param num
   */
  getAuthoriser(num: number): string {
    let res = "-";

    if (this.delegate4list.items4staffs == null) {
      return "-";
    }

    switch (num) {
      case 1:
        if (this.delegate.request.c_request_1_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_1_approval
          );
        }

        break;
      case 2:
        if (this.delegate.request.c_request_2_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_2_approval
          );
        }

        break;
      case 3:
        if (this.delegate.request.c_request_3_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_3_approval
          );
        }

        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 承認日時を受け取り、表示用の文字列にして返す
   * @param val
   */
  getAuthDate(authorizerId: number | null, val: Date | null): string {
    let res = "";

    if (authorizerId == null) {
      return "-";
    }

    if (val == null) {
      res = "未承認";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4BankType = [
    { label: "当座預金", code: 1 },
    { label: "普通預金", code: 2 },
  ];
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.request.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalDisableConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 1;
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      store.commit("revStore");
      const authType = store.state.authType;
      // Cl_common.logger(authType);
      this.isAdmin = authType == 4; //管理者判定

      if (id == "") {
        //新規追加
        this.isEditMode = false;

        //初期値設定
        this.delegate.request.c_staff_id = store.state.id;
        // this.delegate.c_request_overtime_type = 1;
        this.fmShowConf = false;
        this.fmShowConfEx = false;

        this.isDisableComment1 = true;
        this.isDisableComment2 = true;
        this.isDisableComment3 = true;
        this.isDisableResult = true;

        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoRequestAprvsGetReqImple();

      //※fanctions内のクラスの修正コスト軽減の為、c_request_overtime_idに入れているが、
      //  API側ではrequestIDとして扱われるので注意
      dto.c_request_aprv_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_requestaprv.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_request_aprv_id == null) {
        this.$router.push("/shinseisyolist");
      }

      //UI連携用変数に入力
      //---
      await this.cnvData4Get();

      //フラグ更新
      //---
      store.commit("revStore");
      const loginStaffId = store.state.id;
      this.isMine = loginStaffId == this.delegate.request.c_staff_id!; //申請者判定

      //承認関係の表記
      //---
      await this.chgAuthCont();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　GET用。
   */
  async cnvData4Get() {
    //申請者
    this.domApplicantName = CommonStaff.cnvStaffNum2Name(
      this.delegate4list.items4staffs,
      this.delegate.request.c_staff_id
    );

    //決裁期限
    if (this.delegate.c_request_aprv_deadline != null) {
      this.domDeadline = this.delegate
        .c_request_aprv_deadline!.toString()
        .split("T")[0];
    }

    //E-mail
    if (this.delegate.c_request_aprv_address_result != null) {
      this.domAddress = this.delegate.c_request_aprv_address_result;
    }

    //件名
    if (this.delegate.c_request_aprv_name != null) {
      this.domTitle = this.delegate.c_request_aprv_name;
    }

    //内容・目的・理由
    if (this.delegate.c_request_aprv_cont != null) {
      this.domCont = this.delegate.c_request_aprv_cont;
    }
    //支払金額
    // if (this.delegate.c_request_aprv_payment != null) {
    //   this.domPrice = this.delegate.c_request_aprv_payment.toString();
    // }

    if (this.delegate.c_request_aprv_payment != null) {
      this.domPrice = await this.kanmaIns_get(
        this.delegate.c_request_aprv_payment.toString()
      );
    }

    //コメント系
    if (this.delegate.c_request_aprv_1_cont != null) {
      this.domComment_1 = this.delegate.c_request_aprv_1_cont;
    }
    if (this.delegate.c_request_aprv_2_cont != null) {
      this.domComment_2 = this.delegate.c_request_aprv_2_cont;
    }
    if (this.delegate.c_request_aprv_3_cont != null) {
      this.domComment_3 = this.delegate.c_request_aprv_3_cont;
    }
  }

  /**
   * 承認に関するコンテンツを制御する
   */
  async chgAuthCont() {
    let loginStaffId = -1;

    //初期化
    this.isDisableComment1 = true;
    this.isDisableComment2 = true;
    this.isDisableComment3 = true;
    this.isDisableResult = true;

    switch (this.delegate.request.c_request_status) {
      case CONST_REQUEST_STATUS_SINSEITYU:
        //申請中
        //-----------
        store.commit("revStore");
        loginStaffId = store.state.id;
        if (
          (loginStaffId == this.delegate.request.c_request_1_approval &&
            this.delegate.request.c_request_1_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_2_approval &&
            this.delegate.request.c_request_2_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_3_approval &&
            this.delegate.request.c_request_3_approval_datetime == null)
        ) {
          //自身が承認者で未承認
          //--------
          this.fmShowConf = true;
          this.fmShowConfEx = false;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;

          //++++++++++++++++++++++++++++++
          //稟議書の独自制御
          //※稟議書独自
          //++++++++++++++++++++++++++++++
          //自身が何番目の承認権者か判定指定UI制御
          if (
            loginStaffId == this.delegate.request.c_request_1_approval &&
            this.delegate.request.c_request_1_approval_datetime == null
          ) {
            //第1承認者
            this.isDisableComment1 = false;
            this.isDisableComment2 = true;
            this.isDisableComment3 = true;
            if (
              this.delegate.request.c_request_2_approval == null &&
              this.delegate.request.c_request_3_approval == null
            ) {
              this.isDisableResult = false;
            } else {
              this.isDisableResult = true;
            }
          }
          if (
            loginStaffId == this.delegate.request.c_request_2_approval &&
            this.delegate.request.c_request_2_approval_datetime == null
          ) {
            //第2承認者
            this.isDisableComment1 = true;
            this.isDisableComment2 = false;
            this.isDisableComment3 = true;
            if (this.delegate.request.c_request_3_approval == null) {
              this.isDisableResult = false;
            } else {
              this.isDisableResult = true;
            }
          }
          if (
            loginStaffId == this.delegate.request.c_request_3_approval &&
            this.delegate.request.c_request_3_approval_datetime == null
          ) {
            //第3承認者
            this.isDisableComment1 = true;
            this.isDisableComment2 = true;
            this.isDisableComment3 = false;
            this.isDisableResult = false;
          }

          //++++++++++++++++++++++++++++++
        } else {
          //自身が承認者でない、もしくは承認済み
          //--------
          this.fmShowConf = false;
          this.fmShowConfEx = true;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        }

        break;
      case CONST_REQUEST_STATUS_SASIMODOSI:
        //差戻
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = true;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_SYOUNINZUMI:
        //承認済
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_TORISAGE:
        //取下
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = true;

        break;
      default:
        throw new Error("不正なstatus");
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isDisable: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_request_flag_del = 1;
      }
      if (isDisable) {
        //差戻処理：ステータス変更。
        dto.c_request_status = 4;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      if (!this.isDisableResult) {
        this.errors = null;
        this.schema_result.validateSync({
          aprvResult: this.delegate.c_request_aprv_result,
        });
      }

      // //準備
      // //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isApproval) {
        //承認
        dto.isApproval = 1;
      } else {
        //差戻
        dto.isApproval = 0;
      }

      //※稟議書独自
      if (!this.isDisableComment1) {
        dto.comment = this.domComment_1;
      }
      if (!this.isDisableComment2) {
        dto.comment = this.domComment_2;
      }
      if (!this.isDisableComment3) {
        dto.comment = this.domComment_3;
      }
      if (!this.isDisableResult) {
        dto.aprvResult = this.delegate.c_request_aprv_result;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //ここでカンマを消さないとバリデーションでエラーになる
      if (this.domPrice != null) {
        this.domPrice = await this.kanmaDel_get(this.domPrice);
      }
      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        title: this.domTitle,
        reason: this.domCont,
        email: this.domAddress,
      });
      this.schemaAdd_price.validateSync({
        Price: this.domPrice,
      });

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---
      await this.cnvData4Add();

      let dto = Object.assign(new DtoRequestAprvsPostReqImple(), this.delegate);

      //API実行
      //-------
      const res = await Cl_requestaprv.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/shinsei_Ringishoedit",
        query: { id: res.c_request_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "Ringisho";
      let id = Cl_common.getParam("id");
      dto.requestId = Number(id);
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　ADD用。
   */
  async cnvData4Add() {
    //決裁期限
    if (this.domDeadline != undefined) {
      this.delegate.c_request_aprv_deadline = new Date(this.domDeadline);
    }
    //E-mail
    if (this.domAddress != null && this.domAddress != undefined) {
      this.delegate.c_request_aprv_address_result = this.domAddress;
    }
    //件名
    if (this.domTitle != null && this.domTitle != undefined) {
      this.delegate.c_request_aprv_name = this.domTitle;
    }
    //内容・目的・理由
    if (this.domCont != null && this.domCont != undefined) {
      this.delegate.c_request_aprv_cont = this.domCont;
    }
    //支払金額
    // if (this.domPrice != null && this.domPrice != undefined) {
    //   this.delegate.c_request_aprv_payment = Number(this.domPrice);
    // }

    if (this.domPrice != null && this.domPrice != undefined) {
      let domPriceTmp = await this.kanmaDel_get(this.domPrice);
      this.delegate.c_request_aprv_payment = Number(domPriceTmp);
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * 取り下げ用のモーダルを表示
   */
  async disableConf() {
    //モーダルのメッセージを設定
    this.message4ModalDisableConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を取り下げます。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.showModal();
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }
  /**
   * データを取り下げる
   */
  async disab() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4disableConf();
    this.upd(false, true);
  }

  /**
   * 読み込み時の処理
   * カンマをつける
   */
  async kanmaIns_get(formData: string): Promise<string> {
    let res = "";

    let val: string = formData;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    // this.item.cnvPrice = num;

    res = num;

    console.log("res");
    console.log(res);

    return res;
  }

  /**
   * 保存時の処理
   * カンマを消す
   */
  async kanmaDel_get(formData: string): Promise<string> {
    let res = "";

    res = formData.replace(/,/g, "");

    console.log("res");
    console.log(res);

    return res;
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // カンマを消す
    this.domPrice = obj.target.value.replace(/,/g, "");
  }

  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    // this.item.cnvPrice = num;

    this.domPrice = num;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async closeModal4disableConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.closeModal();
  }
}
