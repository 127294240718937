import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoPro2docsDeleteReq } from "./abst/DtoPro2docsDeleteReq";

export class DtoPro2docsDeleteReqImple extends DtoPro2docsDeleteReq {
  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    return res;
  }
}
