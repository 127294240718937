export class DtoClaimNumsPostReq {
  /**
   * 発行する請求Noの個数
   */
  public issueCount: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();

    return res;
  }
}
