
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_supplier } from "@/functions/Cl_supplier";
import { DtoSuppliersGetReqImple } from "mediadept-common/src/common/dto/DtoSuppliersGetReqImple";
import { DtoSuppliersGetResImple } from "mediadept-common/src/common/dto/DtoSuppliersGetResImple";
import { DtoSuppliersPutReq } from "mediadept-common/src/common/dto/abst/DtoSuppliersPutReq";
import { DtoSuppliersPutRes } from "mediadept-common/src/common/dto/abst/DtoSuppliersPutRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class profit_Shiiresakiedit extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSuppliersGetResImple();
  btnDisable = false;
  isEditMode = true;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4CompanyType = [
    { label: "指定なし", code: 1 },
    { label: "株式", code: 2 },
    { label: "有限", code: 3 },
  ];
  op4SignagePosi = [
    { label: "前", code: 1 },
    { label: "後", code: 2 },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        this.delegate.c_supplier_company_type = 1;
        this.delegate.c_supplier_flag_company_signage_posi = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoSuppliersGetReqImple();

      dto.c_supplier_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_supplier.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_supplier_id == null) {
        this.$router.push("/shiiresakilist");
      }
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSuppliersPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_supplier_id = this.delegate.c_supplier_id;
        dto.c_supplier_flag_del = 1;
      } else {
        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoSuppliersPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_supplier.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = Object.assign(new DtoSuppliersPutReq(), this.delegate);
      dto.c_supplier_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_supplier.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/shiiresakiedit",
        query: { id: res.c_supplier_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_supplier_id +
      "】" +
      this.delegate.c_supplier_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
}
