export class EntityProject {
public c_project_id:number | null = null;
public c_client_id:number | null = null;
public c_staff_id:number | null = null;
public c_project_number:number | null = null;
public c_project_name:string | null = null;
public c_project_cont:string | null = null;
public c_project_alert:number | null = null;
public c_project_type:number | null = null;
public c_project_sponsor_name:string | null = null;
public c_project_probability:number | null = null;
public c_project_billing_type:number | null = null;
public c_project_remarks:string | null = null;
public c_project_flag_del:number | null = null;
public c_project_reg_user:string | null = null;
public c_project_reg_at:Date | null = null;
public c_project_upd_user:string | null = null;
public c_project_upd_at:Date | null = null;
}