import { commonRegx } from "xcommon-node/src/common/commonRegx";
/**
 * 経費精算書の一覧用のエンティティ
 * Jsonにして扱う
 */
export class EntityRequestAddchgList {
  public c_addchg_rownum: number | null = null;
  public c_addchg_section_start: string | null = null;
  public c_addchg_section_end: string | null = null;
  public c_addchg_transport: string | null = null;
  public c_addchg_fare: number | null = null;
  public c_addchg_pass: number | null = null;
  

  //DOM連携用
  //-------------------
  public cnvExpensesDate: string | undefined = "";
  public cnvFare: string = "";
  public cnvPass: string = "";

  /**
   * データからDOM連携用の変数にデータを入力する
   */
  public async cnvData2dom() {
    //日付
    //-------------
    // if (this.c_expenses_date != null) {
    //   this.cnvExpensesDate = commonRegx.maches4GroupName(
    //     this.c_expenses_date.toString(),
    //     "(?<gn>^\\d+[-]\\d+)[-]",
    //     commonRegx._stdOption
    //   );
    // }

    //金額
    //-------------
    if (this.c_addchg_fare == null) {
      // this.cnvPrice = "0";
    } else {
      this.cnvFare = this.c_addchg_fare.toString();
      //カンマ補完
      while (
        this.cnvFare !=
        (this.cnvFare = this.cnvFare!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
      );
    }
    if (this.c_addchg_pass == null) {
      // this.cnvPrice = "0";
    } else {
      this.cnvPass = this.c_addchg_pass.toString();
      //カンマ補完
      while (
        this.cnvPass !=
        (this.cnvPass = this.cnvPass!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
      );
    }
  }
  /**
   * DOM連携用の変数からデータ用の変数に入力する
   */
  public async cnvDom2data() {
    //日付
    //-------------
    // if (this.cnvExpensesDate != undefined) {
    //   this.c_expenses_date = new Date(this.cnvExpensesDate);
    // }

    //金額
    //-------------
    if (this.cnvFare == null || this.cnvFare == undefined) {
      // this.c_expenses_price = 0;
    } else {
      this.c_addchg_fare = Number(this.cnvFare.replace(/,/g, ""));
    }

    if (this.cnvPass == null || this.cnvPass == undefined) {
      // this.c_expenses_price = 0;
    } else {
      this.c_addchg_pass = Number(this.cnvPass.replace(/,/g, ""));
    }
  }
}
