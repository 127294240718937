import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityVariationImple } from "../entity/EntityVariationImple";

export class DtoVariationsPostReqImple extends EntityVariationImple {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
