import { EntitySalesImple } from "../entity/EntitySalesImple";
import { EntitySupplyImple } from "../entity/EntitySupplyImple";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoProjectsGetRes } from "./abst/DtoProjectsGetRes";

export class DtoClaimNumsPostRes {
  /**
   * 発行された請求書No
   */
  public claimNums: number[] = [];
}
