import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityAlert } from "../../entity/EntityAlert";

export class DtoSalesInfosGetReq extends EntityAlert {
  public year: number | null = null;
  public type: number | null = null;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
