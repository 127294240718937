
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { DtoHolisticlocksGetReqImple } from "mediadept-common/src/common/dto/DtoHolisticlocksGetReqImple";
import { DtoHolisticlocksGetResImple } from "mediadept-common/src/common/dto/DtoHolisticlocksGetResImple";
import { DtoHolisticlocksPutReq } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPutReq";
import { DtoHolisticlocksPutRes } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPutRes";
import { DtoHolisticlocksPostReq } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPostReq";
import { DtoHolisticlocksPostRes } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksPostRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import { Cl_holisticlock } from "@/functions/Cl_holisticlock";

//masters用
import { Cl_master } from "@/functions/Cl_master";
import { Cl_sendMail } from "@/functions/Cl_sendMail";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";

// バリデーション
import * as Yup from "yup";
import { DtoHolisticlocksDeleteReq } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksDeleteReq";
import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoApiError } from "mediadept-common/src/common/dto/abst/DtoApiError";
import { AxiosError } from "axios";

@Options({
  components: {
    Mo_modal_alert,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class Datalockedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    datalockDate: Yup.string().required("年月を選択してください。"),
  });
  schemaUpd = Yup.object().shape({
    datalockDate: Yup.string().required("年月を選択してください。"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoHolisticlocksGetResImple();
  delegateMasters = new DtoMastersGetRes();
  domDate = new DtoMdDtp();
  btnDisable = false;
  isEditMode = true;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    this.getMastersList();
    this.get();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container4selectPro");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegateMasters = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      // //モーダルのメッセージを設定
      // this.message4ModalErrorConf = "エラーが発生しました";
      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      this.delegateMasters = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        //this.delegate.c_client_company_type = 1;
        //this.delegate.c_client_flag_company_signage_posi = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoHolisticlocksGetReqImple();

      dto.c_holisticlock_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_holisticlock.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_holisticlock_id == null) {
        this.$router.push("/datalocklist");
      }

      //日付変換
      let tmp = new DtoMdDtp();
      if (this.delegate.c_holisticlock_date != null) {
        tmp.date = commonRegx.maches4GroupName(
          this.delegate.c_holisticlock_date!.toString(),
          "(?<gn>^\\d+[-]\\d+)[-]",
          commonRegx._stdOption
        );
      }
      this.domDate = tmp;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoHolisticlocksPutReq();
      if (isDel) {
        //削除処理
        dto.c_holisticlock_id = this.delegate.c_holisticlock_id;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;

        this.schemaUpd.validateSync({
          datalockDate: this.domDate.date,
        });

        //日付
        if (this.domDate != null && this.domDate.date != null) {
          this.delegate.c_holisticlock_date = new Date(this.domDate.date!);
        } else {
          this.delegate.c_holisticlock_date = null;
        }
      }

      //更新処理：delegateからdtoにデータ投入。
      dto = Object.assign(new DtoHolisticlocksPutReq(), this.delegate);

      //API実行
      //-------
      await Cl_holisticlock.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        datalockDate: this.domDate.date,
      });

      //準備
      //-------
      //日付をdelegateに戻す
      if (this.domDate != null && this.domDate.date != null) {
        this.delegate.c_holisticlock_date = new Date(this.domDate.date);
      } else {
        this.delegate.c_holisticlock_date = null;
      }

      let dto = Object.assign(new DtoHolisticlocksPostReq(), this.delegate);

      //API実行
      //-------
      const res = await Cl_holisticlock.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/datalockedit",
        query: { id: res.c_holisticlock_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "データを更新しました。";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      }
      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          //ロック日付の重複
          case "dapleicate date.":
            this.message4ModalErrorConf =
              "指定された月は、既にロックされています";
            break;
          case "be not apply claim.":
            this.message4ModalErrorConf =
              "ロック対象範囲に承認されていない請求書が存在します。　請求No：" +
              response.info +
              "　請求書が承認されていないため、ロックできません。";
            break;

          default:
            this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }

      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを削除する
   */
  async del() {
    //削除確認モーダル表示。
    this.closeModal4deleteConf();

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoHolisticlocksDeleteReq();
      //削除処理
      dto.c_holisticlock_id = this.delegate.c_holisticlock_id;

      //API実行
      //-------
      await Cl_holisticlock.del(dto);
      //レスポンスの処理
      //-------

      //モーダルのメッセージを設定
      //削除処理：
      this.message4ModalSuccess = "正常に削除されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //---------------
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを削除する
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf = "削除してもよろしいでしょうか。";

    //モーダルを表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * ロック者を取得する
   */
  getLockStaff(): string {
    if (this.delegate.c_staff_id != null) {
      let staff = this.delegateMasters.items4staffs.find(
        (x) => x.c_staff_id == this.delegate.c_staff_id
      );
      if (staff != null) {
        return staff.c_staff_name!;
      }
    }
    return "";
  }
  /**
   * ロック日時を取得する
   */
  getLockDate(): string {
    if (this.delegate.c_holisticlock_reg_at != null) {
      return this.delegate
        .c_holisticlock_reg_at!.toString()
        .replace("T", " ")
        .replace(".000Z", "");
    } else {
      return "";
    }
  }
  /**
   * AxiosErrorかどうかを返す。
   * @param error
   * @returns
   */
  isAxiosError(error: any): error is AxiosError {
    return !!error.isAxiosError;
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
