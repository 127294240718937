import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoAlertsGetReqImple } from "mediadept-common/src/common/dto/DtoAlertsGetReqImple";
import { DtoAlertsGetResImple } from "mediadept-common/src/common/dto/DtoAlertsGetResImple";
import { DtoAlertsPutReq } from "mediadept-common/src/common/dto/abst/DtoAlertsPutReq";
import { DtoAlertsPutRes } from "mediadept-common/src/common/dto/abst/DtoAlertsPutRes";
export class Cl_alert {
  public static async getList(
    dto: DtoAlertsGetReqImple
  ): Promise<DtoAlertsGetResImple> {
    return Object.assign(
      new DtoAlertsGetResImple(),
      await CallAPI.getRequest(
        "/alert?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
  public static async put(dto: DtoAlertsPutReq): Promise<DtoAlertsPutRes> {
    return Object.assign(
      new DtoAlertsPutRes(),
      await CallAPI.putRequest("/alert/" + dto.c_alert_id, dto)
    );
  }
}
