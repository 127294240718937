import { commonRegx } from "xcommon-node/src/common/commonRegx";

export class DtoSalesInfosGetRes {
    public type1: number = 0;
    public type2: number = 0;
    public type3: number = 0;
    public type4: number = 0;
    public type5: number = 0;
    public type6: number = 0;
    public type7: number = 0;

    public salesTotal: number = 0;
    public budget: number = 0;
}
