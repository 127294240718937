import Cl_api from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoItemsGetReq } from "@/dto/DtoItemsGetReq";
import { DtoItemsGetRes } from "@/dto/DtoItemsGetRes";

export default class Cl_items {
  public async getList(dto: DtoItemsGetReq): Promise<DtoItemsGetRes> {
    return Object.assign(
      new DtoItemsGetRes(),
      await Cl_api.getRequest("/items?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
