import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoDoc4detsGetReq } from "./abst/DtoDoc4detsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoDoc4detsGetReqImple extends DtoDoc4detsGetReq {
  public pager = new DtoApiPager();

  // 検索条件
  //-------------------
  /**
   * 文書番号開始
   */
  public no_start: number | null = null;
  /**
   * 文書番号終了
   */
  public no_end: number | null = null;
  /**
   * 文書種別
   */
  public manag_type: number | null = null;
  /**
   * 文書種類
   */
  public type: number | null = null;
  /**
   * 金額開始
   */
  public price_start: number | null = null;
  /**
   * 金額終了
   */
  public price_end: number | null = null;
  /**
   * 取引年月日開始
   */
  public date_start: string | null = null;
  /**
   * 取引年月日終了
   */
  public date_end: string | null = null;
  /**
   * 取引先
   */
  public customer: string | null = null;
  /**
   * 作成者
   */
  public staff_id: number | null = null;
  /**
   * 案件担当者
   */
  public staff_id4project: number | null = null;

  /**
   * 無効表示フラグ
   */
  public flag_enab: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
