
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EntityVariationImple } from "mediadept-common/src/common/entity/EntityVariationImple";
import { EntityConditionImple } from "mediadept-common/src/common/entity/EntityConditionImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Or_selectItem from "@/components/organisms/Or_selectItem.vue";

import Mo_condition from "@/components/molecules/Mo_condition.vue";
import { DtoVariationsPostReqImple } from "mediadept-common/src/common/dto/DtoVariationsPostReqImple";
import { DtoVariationsPutReq } from "mediadept-common/src/common/dto/abst/DtoVariationsPutReq";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_variation } from "@/functions/Cl_variation";
import { EntitySupplier } from "mediadept-common/src/common/entity/EntitySupplier";
import { CONST_OP_SUPPLY_TYPE } from "mediadept-common/src/common/constant/Constants";
import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";

@Options({
  components: {
    vSelect,
    Mo_condition,
    Mo_modal_alert,
    Or_selectItem,
  },
})
export default class Or_variation extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  variations: EntityVariationImple[];
  @Prop({ required: true })
  conditions: EntityConditionImple[][];
  @Prop({ required: true })
  c_item_id: number;
  @Prop({ required: true })
  c_item_type: number;
  @Prop({ required: true })
  items4Supplyers: EntitySupplier[];
  @Prop({ required: true })
  itemName: string;

  btnDisable = false;
  activeTab = 0;
  className = "";
  delIndex = 0;
  a = 1;

  //コンディションの商品選択用情報
  //------------------

  /**
   * 押下された商品選択ボタンのID。売上idか仕入idが入る。
   */
  callId = 0;
  evDatas4item: {
    selectVariation: EntityVariationImple;
    data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
    isItemOnly: boolean;
  } = {
    selectVariation: new EntityVariationImple(),
    data: new DtoItemsGetResImple(),
    isSelected: false,
    detStartDate: "",
    detCount: -1,
    isItemOnly: true,
  };

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opSupplyType = CONST_OP_SUPPLY_TYPE;

  // optionsOp8 = [
  //   { label: "媒体料", code: 1 },
  //   { label: "製作・施工（取付撤去）", code: 2 },
  //   { label: "製作・取付", code: 3 },
  //   { label: "取付", code: 4 },
  //   { label: "撤去", code: 5 },
  //   { label: "撤去（前受）", code: 6 },
  //   { label: "プリント", code: 7 },
  //   { label: "工事（資産）", code: 8 },
  //   { label: "電気代", code: 9 },
  //   { label: "申請", code: 10 },
  //   { label: "その他", code: 11 },
  // ];
  optionsOp9 = [
    { label: "外照式", code: 1 },
    { label: "内照式", code: 2 },
    { label: "照明なし", code: 3 },
    { label: "自発光（LEDなど）", code: 4 },
    { label: "ターポリン", code: 5 },
    { label: "FFシート", code: 6 },
    { label: "粘着シート", code: 7 },
    { label: "懸垂幕", code: 8 },
    { label: "ポスター", code: 9 },
    { label: "屋上", code: 10 },
    { label: "壁面", code: 11 },
    { label: "自立", code: 12 },
    { label: "直貼り", code: 13 },
    { label: "その他", code: 14 },
  ];
  optionsOp10 = [
    { label: "日間", code: 1 },
    { label: "週間", code: 2 },
    { label: "ヵ月", code: 3 },
    { label: "年間", code: 4 },
  ];

  //----------------------
  //制御用メソッド
  //----------------------

  // async mounted() {
  // }

  async addVariation() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    const panel2 = document.getElementById("panel-editers4det");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("バリエーション追加処理実行");

      //準備
      //-------
      let dto = new DtoVariationsPostReqImple();
      dto.c_item_id = this.c_item_id;
      dto.c_variation_name = "新規バリエーション";
      dto.c_variation_price_type = 1;
      dto.c_variation_flag_del = 0;
      //API実行
      //-------
      let res = await Cl_variation.post(dto);
      //レスポンスの処理
      //-------
      this.variations.push(res);
      // リフレッシュしない
      //this.$emit("get");
      this.$emit("upd", false, false);
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");

      this.activeTab = this.variations.length - 1;
    }
  }

  async delVariation() {
    this.closeModal4deleteConf();

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    const panel2 = document.getElementById("panel-editers4det");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoVariationsPutReq();
      dto.c_variation_id = this.variations[this.delIndex].c_variation_id;
      dto.c_variation_flag_del = 1;
      //API実行
      //-------
      let res = await Cl_variation.put(dto);
      console.log(res);
      //レスポンスの処理
      //-------
      this.variations.splice(this.delIndex, 1);
      this.message4ModalSuccess = "正常に削除されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success_no_get;
      modal.showModal();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");

      this.activeTab = 0;
    }
  }

  async cpyVariation(index: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    const panel2 = document.getElementById("panel-editers4det");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("コピー処理実行");

      // 先に保存する
      this.upd(false, false);

      //準備
      //-------
      let dto = new DtoVariationsPostReqImple();
      dto.c_item_id = this.variations[index].c_item_id;
      dto.c_variation_margin_sales =
        this.variations[index].c_variation_margin_sales;
      dto.c_variation_margin_supply =
        this.variations[index].c_variation_margin_supply;
      dto.c_variation_name =
        "【コピー】" + this.variations[index].c_variation_name;

      dto.c_variation_price = this.variations[index].c_variation_price;
      // dto.cnvPrice = this.variations[index].cnvPrice;
      // if (
      //   this.variations[index].cnvPrice == null ||
      //   this.variations[index].cnvPrice == undefined
      // ) {
      //   dto.c_variation_price = 0;
      // } else {
      //   dto.c_variation_price = Number(
      //     this.variations[index].cnvPrice.replaceAll(",", "")
      //   );
      // }

      dto.c_variation_price_type =
        this.variations[index].c_variation_price_type;
      dto.c_variation_remarks = this.variations[index].c_variation_remarks;
      dto.c_variation_size = this.variations[index].c_variation_size;
      dto.c_variation_specification =
        this.variations[index].c_variation_specification;
      dto.c_variation_term = this.variations[index].c_variation_term;
      dto.c_variation_term_type = this.variations[index].c_variation_term_type;
      dto.c_variation_time_end = this.variations[index].c_variation_time_end;
      dto.c_variation_time_start =
        this.variations[index].c_variation_time_start;
      dto.c_variation_flag_del = 0;

      dto.c_variation_type = this.variations[index].c_variation_type;
      // dto.cnvType = this.variations[index].cnvType;
      // if (
      //   this.variations[index].cnvType[0] != undefined &&
      //   this.variations[index].cnvType[0] != null
      // ) {
      //   dto.c_variation_type = String(this.variations[index].cnvType[0]);
      // }
      // dto.cnvType = this.variations[index].cnvType;

      dto.cnvSpecification = this.variations[index].cnvSpecification;
      // dto.c_variation_specification =
      //   this.variations[index].c_variation_specification;
      // if (undefined != this.variations[index].cnvSpecification) {
      //   dto.c_variation_specification =
      //     this.variations[index].cnvSpecification.join(",");
      // }

      for (let i = 0; i < this.variations[index].conditions.length; i++) {
        let tmp = new EntityConditionImple();
        tmp = new EntityConditionImple();

        tmp.c_condition_flag_del = 0;
        tmp.c_supplier_id = this.variations[index].conditions[i].c_supplier_id;
        tmp.c_condition_name =
          this.variations[index].conditions[i].c_condition_name;
        tmp.c_condition_price =
          this.variations[index].conditions[i].c_condition_price;
        tmp.c_condition_remarks =
          this.variations[index].conditions[i].c_condition_remarks;

        tmp.c_condition_type =
          this.variations[index].conditions[i].c_condition_type;
        // if (
        //   this.variations[index].conditions[i].cnvType[0] != undefined &&
        //   this.variations[index].conditions[i].cnvType[0] != null
        // ) {
        //   tmp.c_condition_type = String(
        //     this.variations[index].conditions[i].cnvType[0]
        //   );
        // }

        dto.conditions.push(tmp);
      }
      console.log(dto);

      //API実行
      //-------
      let res = await Cl_variation.post(dto);
      //レスポンスの処理
      //-------
      this.variations.push(res);
      // this.$emit("get");

      this.message4ModalSuccess = "正常にコピーされました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");

      this.activeTab = this.variations.length - 1;
    }
  }

  async setActiveTab(index: number) {
    this.activeTab = index;
    console.log("this.activeTab = " + this.activeTab);
  }

  // public async setTime() {
  //   for (let i = 0; i < this.variations.length; i++) {
  //     this.timeChange(i);
  //   }
  // }

  async timeChange(index: number) {
    if (
      null != this.variations[index].c_variation_time_start &&
      null != this.variations[index].c_variation_time_end &&
      0 < this.variations[index].c_variation_time_start!.length &&
      0 < this.variations[index].c_variation_time_end!.length
    ) {
      let s = this.variations[index].c_variation_time_start?.split(":");
      let e = this.variations[index].c_variation_time_end?.split(":");
      let s2 = Number(s![0]) * 60 + Number(s![1]);
      let e2 = Number(e![0]) * 60 + Number(e![1]);

      this.variations[index].hour = Math.floor((e2 - s2) / 60);
      this.variations[index].min = Math.floor((e2 - s2) % 60);
    }
  }

  async get() {
    this.$emit("get");
  }

  async upd(isDel: boolean, isShowModal = true) {
    this.$emit("upd", isDel, isShowModal);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * コンディションの商品選択起動
   */
  async itemSelectCondi(ev: number) {
    this.callId = 0;
    // console.log("商品選択起動" + ev);
    this.callId = ev;
    this.showModalItem4condition();
  }
  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    if (obj.target.value == "0") obj.target.value = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * conditionの商品選択時のイベント
   * watch用
   */
  @Watch("evDatas4item.isSelected")
  async selectItem4item() {
    if (!this.evDatas4item.isSelected) {
      return;
    }
    //対象のconditionを検索
    //-------------------
    for (let indexVari = 0; indexVari < this.variations.length; indexVari++) {
      for (
        let indexCondi = 0;
        indexCondi < this.variations[indexVari].conditions.length;
        indexCondi++
      ) {
        if (
          this.variations[indexVari].conditions[indexCondi].c_condition_id ==
          this.callId
        ) {
          //商品名入力
          //-------------------
          console.log(this.evDatas4item.data.c_item_name);
          this.variations[indexVari].conditions[indexCondi].c_condition_name =
            this.evDatas4item.data.c_item_name;
          return;
        }
      }
    }
  }
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf(index: number) {
    this.delIndex = index;
    //モーダルのメッセージを設定
    this.message4ModalDelConf = "削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  async showModalItem4condition(): Promise<void> {
    this.evDatas4item = {
      selectVariation: new EntityVariationImple(),
      data: new DtoItemsGetResImple(),
      isSelected: false,
      detStartDate: "",
      detCount: -1,
      isItemOnly: true,
    };

    const modalComp: any = this.$refs.comp_itemSelect4condition;
    modalComp.showModal4Item();
  }
}
