import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoAggregatesGetReq } from "mediadept-common/src/common/dto/DtoAggregatesGetReq";
import { DtoAggregatesGetRes } from "mediadept-common/src/common/dto/DtoAggregatesGetRes";

export class Cl_aggregates {
  public static async get(
    dto: DtoAggregatesGetReq
  ): Promise<DtoAggregatesGetRes> {
    return Object.assign(
      new DtoAggregatesGetReq(),
      await CallAPI.getRequest(
        "/aggregates" + "?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
