import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoProjectsGetRes } from "./abst/DtoProjectsGetRes";
import { DtoClaimsPostRes } from "./abst/DtoClaimsPostRes";

export class DtoClaimsPostResImple extends DtoClaimsPostRes {
  /**
   * アクセスすべきURL
   */
  public claimUrl: string | null = null;
}
