import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityLock } from "../entity/EntityLock";

export class DtoLocksPostReqImple extends EntityLock {
  public isUnlock = false;

  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
