
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { DtoSearchProjectGetRes } from "mediadept-common/src/common/dto/DtoSearchProjectGetRes";
import { DtoSearchProjectGetReq } from "mediadept-common/src/common/dto/DtoSearchProjectGetReq";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_searchProject } from "@/functions/Cl_searchProject";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";

import Or_selectClient from "@/components/organisms/Or_selectClient.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";
import { Watch } from "vue-property-decorator/lib/decorators/Watch";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";

// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    // VueDatePicker,
    Mt_dtp,
    Mt_dtp4month,
    Or_selectClient,
  },
})
export default class Ankenlist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSearchProjectGetRes();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domProjectNo_start = "";
  domProjectNo_end = "";
  domProjectName = "";
  domProClientid: number | null = null;
  domProjectSponsorName = "";
  domProtype = "";
  domProStaffId = "";
  domSalesIssueDate_start = new DtoMdDtp();
  domSalesIssueDate_end = new DtoMdDtp();
  domSalesMonth_start = new DtoMdDtp();
  domSalesMonth_end = new DtoMdDtp();
  domSupplyMonth_start = new DtoMdDtp();
  domSupplyMonth_end = new DtoMdDtp();
  domsalePrice_start = "";
  domsalePrice_end = "";
  domSupplyPrice_start = "";
  domSupplyPrice_end = "";
  domProRemarks = "";
  domProFreeWord = "";
  domItemName = "";

  optionsOp2 = [
    { label: "仕入", code: 1 },
    { label: "売上", code: 2 },
    { label: "仕掛", code: 3 },
    { label: "その他", code: 4 },
  ];

  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "案件番号",
      field: "c_project_number",
    },
    {
      label: "案件名",
      field: "c_project_name",
    },
    {
      label: "請求先",
      field: "c_client_name",
    },
    {
      label: "広告主",
      field: "c_project_sponsor_name",
    },
    {
      label: "担当者",
      field: "c_staff_name",
    },
    // {
    //   label: "商品名",
    //   field: "c_item_name",
    // },
  ];

  // //請求先選択用
  evDatas: {
    selectedItem: EntityClient | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegateStaff.getStaffSortNum4NoneDeleteAndDelete();
  }

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    this.getStaffList();
    this.getClientList();
    // await this.getlist(1);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSearchProjectGetReq();
      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProtype != "" && this.domProtype != null)
        dto.projectType = Number(this.domProtype);

      if (this.domProStaffId != "" && this.domProStaffId != null)
        dto.staffId = Number(this.domProStaffId);

      if (this.domSalesIssueDate_start.date != null) {
        dto.salesIssueDate_start = new Date(this.domSalesIssueDate_start.date);
      }
      if (this.domSalesIssueDate_end.date != null) {
        dto.salesIssueDate_end = new Date(this.domSalesIssueDate_end.date);
      }
      if (this.domSalesMonth_start.date != null) {
        dto.salesMonth_start = new Date(this.domSalesMonth_start.date);
      }
      if (this.domSalesMonth_end.date != null) {
        dto.salesMonth_end = new Date(this.domSalesMonth_end.date);
      }

      if (this.domSupplyMonth_start.date != null) {
        dto.supplyMonth_start = new Date(this.domSupplyMonth_start.date);
      }
      if (this.domSupplyMonth_end.date != null) {
        dto.supplyMonth_end = new Date(this.domSupplyMonth_end.date);
      }

      if (this.domsalePrice_start != "") {
        let domsalePrice_startTmp = await this.kanmaDel_get(
          this.domsalePrice_start
        );
        dto.salesPrice_start = Number(domsalePrice_startTmp);
      }

      if (this.domsalePrice_end != "") {
        let domsalePrice_endTmp = await this.kanmaDel_get(
          this.domsalePrice_end
        );
        dto.salesPrice_end = Number(domsalePrice_endTmp);
      }

      if (this.domSupplyPrice_start != "") {
        let domSupplyPrice_startTmp = await this.kanmaDel_get(
          this.domSupplyPrice_start
        );
        dto.supplyPrice_start = Number(domSupplyPrice_startTmp);
      }

      if (this.domSupplyPrice_end != "") {
        let domSupplyPrice_endTmp = await this.kanmaDel_get(
          this.domSupplyPrice_end
        );
        dto.supplyPrice_end = Number(domSupplyPrice_endTmp);
      }

      dto.proRemarks = this.domProRemarks;

      dto.proFreeWord = this.domProFreeWord;

      dto.itemName = this.domItemName;

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      this.delegate = await Cl_searchProject.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchProjectGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "AnkenList";

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProtype != "" && this.domProtype != null)
        dto.projectType = Number(this.domProtype);

      if (this.domProStaffId != "" && this.domProStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domProStaffId;

      if (this.domSalesIssueDate_start.date != null) {
        dto.salesIssueDate_start = new Date(this.domSalesIssueDate_start.date);
      }
      if (this.domSalesIssueDate_end.date != null) {
        dto.salesIssueDate_end = new Date(this.domSalesIssueDate_end.date);
      }
      if (this.domSalesMonth_start.date != null) {
        dto.salesMonth_start = new Date(this.domSalesMonth_start.date);
      }
      if (this.domSalesMonth_end.date != null) {
        dto.salesMonth_end = new Date(this.domSalesMonth_end.date);
      }
      if (this.domSupplyMonth_start.date != null) {
        dto.supplyMonth_start = new Date(this.domSupplyMonth_start.date);
      }
      if (this.domSupplyMonth_end.date != null) {
        dto.supplyMonth_end = new Date(this.domSupplyMonth_end.date);
      }

      // if (this.domsalePrice_start != "")
      //   dto.salesPrice_start = Number(this.domsalePrice_start);

      // if (this.domsalePrice_end != "")
      //   dto.salesPrice_end = Number(this.domsalePrice_end);

      // if (this.domSupplyPrice_start != "")
      //   dto.supplyPrice_start = Number(this.domSupplyPrice_start);

      // if (this.domSupplyPrice_end != "")
      //   dto.supplyPrice_end = Number(this.domSupplyPrice_end);

      if (this.domsalePrice_start != "") {
        let domsalePrice_startTmp = await this.kanmaDel_get(
          this.domsalePrice_start
        );
        dto.salesPrice_start = Number(domsalePrice_startTmp);
      }

      if (this.domsalePrice_end != "") {
        let domsalePrice_endTmp = await this.kanmaDel_get(
          this.domsalePrice_end
        );
        dto.salesPrice_end = Number(domsalePrice_endTmp);
      }

      if (this.domSupplyPrice_start != "") {
        let domSupplyPrice_startTmp = await this.kanmaDel_get(
          this.domSupplyPrice_start
        );
        dto.supplyPrice_start = Number(domSupplyPrice_startTmp);
      }

      if (this.domSupplyPrice_end != "") {
        let domSupplyPrice_endTmp = await this.kanmaDel_get(
          this.domSupplyPrice_end
        );
        dto.supplyPrice_end = Number(domSupplyPrice_endTmp);
      }

      dto.proRemarks = this.domProRemarks;

      dto.proFreeWord = this.domProFreeWord;

      dto.itemName = this.domItemName;

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *社員プルダウン取得用
   */
  async getStaffList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //TODO:#4134
      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 0;
      //表示用
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegateStaff = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateStaff = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *顧客プルダウン取得用
   */
  async getClientList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行顧客マスタ取得＋休日取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;

      //休日用準備
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegateClient = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateClient);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateClient = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 顧客選択時のイベント
   * watch用
   */
  @Watch("evDatas.isSelected")
  async selectClient() {
    if (!this.evDatas.isSelected) {
      return;
    }
    this.domProClientid = this.evDatas.selectedItem!.c_client_id!;
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/jyutyuinput?record_id=" + id);
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  /**
   * 請求先選択用モーダル
   */
  async showModalclaim(): Promise<void> {
    this.evDatas.selectedItem = null;
    this.evDatas.isSelected = false;

    const modalClient: any = this.$refs.Mo_modal_selectType;
    await modalClient.showModal();
  }
  async closeModalclaim(): Promise<void> {
    const modalClient: any = this.$refs.Mo_modal_selectType;
    modalClient.closeModal();
  }

  /**
   * 検索時の処理
   * カンマを消す
   */
  async kanmaDel_get(formData: string): Promise<string> {
    let res = "";

    res = formData.replace(/,/g, "");

    console.log("res");
    console.log(res);

    return res;
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any, formName: string) {
    // カンマを消す
    if (formName == "salePrice_start") {
      this.domsalePrice_start = obj.target.value.replace(/,/g, "");
    } else if (formName == "salePrice_end") {
      this.domsalePrice_end = obj.target.value.replace(/,/g, "");
    } else if (formName == "SupplyPrice_start") {
      this.domSupplyPrice_start = obj.target.value.replace(/,/g, "");
    } else if (formName == "SupplyPrice_end") {
      this.domSupplyPrice_end = obj.target.value.replace(/,/g, "");
    }

    // this.item.cnvPrice = obj.target.value.replace(/,/g, "");
    // if (this.item.cnvPrice == "0") this.item.cnvPrice = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any, formName: string) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    // this.item.cnvPrice = num;

    if (formName == "salePrice_start") {
      this.domsalePrice_start = num;
    } else if (formName == "salePrice_end") {
      this.domsalePrice_end = num;
    } else if (formName == "SupplyPrice_start") {
      this.domSupplyPrice_start = num;
    } else if (formName == "SupplyPrice_end") {
      this.domSupplyPrice_end = num;
    }
  }
}
