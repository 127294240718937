import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityPro2doc } from "../../entity/EntityPro2doc";

export class DtoPro2docsPutReq extends EntityPro2doc {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_pro2doc_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
