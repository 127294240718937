import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSearchClaimNoGetReq } from "mediadept-common/src/common/dto/DtoSearchClaimNoGetReq";
import { DtoSearchClaimNoGetRes } from "mediadept-common/src/common/dto/DtoSearchClaimNoGetRes";

export class Cl_searchClaimNo {
  public static async getList(
    dto: DtoSearchClaimNoGetReq
  ): Promise<DtoSearchClaimNoGetRes> {
    return Object.assign(
      new DtoSearchClaimNoGetRes(),
      await CallAPI.getRequest(
        "/searchclaimno?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
