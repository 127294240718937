import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityGoal } from "../../entity/EntityGoal";

export class DtoGoalsPutReq extends EntityGoal {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_goal_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
