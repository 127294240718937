
import { Prop, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import Mo_modal_n from "@/components/molecules/Mo_modal_n.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_tableJyutu_row_sales from "@/components/molecules/Mo_tableJyutu_row_sales.vue";
import { Cl_common } from "@/functions/Cl_common";
import {
  CONST_OP_ITEMTYPE,
  CONST_OP_OWNER,
  CONST_OP_TERM_ITEM,
  CONST_OP_TERM_TYPE,
} from "mediadept-common/src/common/constant/Constants";

import { DtoItemsGetReqImple } from "mediadept-common/src/common/dto/DtoItemsGetReqImple";
import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_item } from "@/functions/Cl_item";
import { EntityVariationImple } from "mediadept-common/src/common/entity/EntityVariationImple";
import { number } from "yup";

@Options({
  components: {
    vSelect,
    Mo_modal_l,
    Mo_modal_n,
    VueGoodTable,
    Mo_tableJyutu_row_sales,
  },
})
export default class Or_selectItem extends Vue {
  // selectedVariation: EntityVariationImple | null = null;
  // selectItem: DtoItemsGetResImple | null = null;
  @Prop({ required: true })
  evDatas: {
    selectVariation: EntityVariationImple;
    data: DtoItemsGetResImple;
    isSelected: boolean;
    detStartDate: string;
    detCount: number;
    isItemOnly: boolean;
  };
  @Prop({ required: false })
  selectedItem: {
    itemId: number | null;
    selectedName: string;
  };

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate4list = new DtoItemsGetResImple();
  delegate4det = new DtoItemsGetResImple();
  domItemManagName = "";
  domItemName = "";
  domItemType = [];
  domOwner = [];
  domArea = "";
  domTerm = "";

  //--------
  detNote = "";
  detRemarks = "";
  detInfo = "";
  detItempageLink = "";
  detItempageLink4selected = "";

  detStartDate = "";
  detCount = 1;

  //ボタン制御用
  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opItemtype = CONST_OP_ITEMTYPE;
  opOwner = CONST_OP_OWNER;
  opTerm = CONST_OP_TERM_ITEM;

  strTypes = [
    "",
    "ビルボード",
    "ポスタージャック",
    "商業施設",
    "デジタルビルボード",
    "交通",
    "その他",
  ];
  strOwner = ["", "自社", "他社", "交通", "リムジン", "その他"];
  columns = [
    {
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      label: "商品管理番号",
      field: "c_item_manag_name",
    },
    {
      label: "商品名",
      field: "c_item_name",
    },
    {
      label: "商品種別",
      field: "c_item_type",
    },
    {
      label: "所属",
      field: "c_item_owner",
    },
    {
      label: "エリア",
      field: "c_item_area",
    },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //コンポーネントが表示されていない、読み込み時に呼ばれるので注意。
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-list");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoItemsGetReqImple();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      dto.itemManagName = this.domItemManagName;
      dto.itemName = this.domItemName;
      if (0 < this.domItemType.length) {
        dto.itemType = this.domItemType.join(",");
      }
      if (0 < this.domOwner.length) {
        dto.itemOwner = this.domOwner.join(",");
      }
      dto.itemArea = this.domArea;
      if (null != this.domTerm && "" != this.domTerm) {
        dto.itemTerm = this.domTerm;
      }

      //API実行
      //-------
      this.delegate4list = await Cl_item.getList(dto);
      //レスポンス処理
      //-------
    } catch (error) {
      //エラー発生で一覧を初期化
      this.delegate4list = new DtoItemsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  /**
   * 商品詳細を読み込み
   * @param itemCode
   */
  async getDet(id: string) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-itemdet");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoItemsGetReqImple();

      dto.c_item_id = Number(id);

      //API実行
      //-------
      this.delegate4det = await Cl_item.get(dto);
      Cl_common.logger(this.delegate4det);
      //レスポンス処理
      //-------
      this.detNote = this.delegate4det.c_item_note!;
      this.detRemarks = this.delegate4det.c_item_remarks!;
      this.detInfo = this.delegate4det.c_item_info!;
      this.detItempageLink = "/syouhinedit?id=" + this.delegate4det.c_item_id!;
    } catch (error) {
      //エラー発生で一覧を初期化
      this.delegate4det = new DtoItemsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }

  /**
   * バリエーションの情報を集約して文字列で返す
   * @param variation
   */
  getVariationInfo(variation: EntityVariationImple): string {
    // "内訳：媒体費・期間3ヵ月・金額1,000,000"
    let res = "";
    if (variation.c_variation_size != null) {
      res += "・サイズ：" + variation.c_variation_size;
    }
    if (variation.c_variation_price != null) {
      res += "・金額：" + variation.c_variation_price;
    }
    if (
      variation.c_variation_term != null &&
      variation.c_variation_term_type != null
    ) {
      var found = CONST_OP_TERM_TYPE.find(
        (e) => e.code === variation.c_variation_term_type
      );
      res += "・期間：" + variation.c_variation_term + found?.label;
    }

    return res;
  }

  /**
   * バリエーションが選択された際の処理
   * @param variation
   */
  selectVariation(variation: EntityVariationImple) {
    //選択イベント発生
    //-----------------------
    // const res: {
    //   selectVariation: EntityVariationImple;
    //   data: DtoItemsGetResImple;
    // } = { selectVariation: variation, data: this.delegate4det };

    // this.$emit("selectedItem", res);

    //イベントを発生させないパターン
    //-----------------------
    this.evDatas.selectVariation = variation;
    this.evDatas.data = this.delegate4det;
    this.evDatas.isSelected = true;
    this.evDatas.detStartDate = this.detStartDate;
    this.evDatas.detCount = this.detCount;

    //モーダルを閉じる
    //-----------------------
    this.closeModal4variation();
    this.closeModal4Item();
  }

  //------------------------------------
  //詳細データ変換系メソッド
  //------------------------------------
  /**
   * 商品種別用
   */
  getItemType(itemType: number): string {
    var found = CONST_OP_ITEMTYPE.find((e) => e.code === itemType);

    if (found != undefined) {
      return found!.label;
    } else {
      return "";
    }
  }
  /**
   * 商品期間用
   */
  getItemTerm(itemTerm: number): string {
    var found = CONST_OP_TERM_ITEM.find((e) => e.code === itemTerm);

    if (found != undefined) {
      return found!.label;
    } else {
      return "";
    }
  }
  /**
   * 所有用
   */
  getItemOwner(itemOwn: number): string {
    var found = CONST_OP_OWNER.find((e) => e.code === itemOwn);

    if (found != undefined) {
      return found!.label;
    } else {
      return "";
    }
  }

  /**
   * selectedItem.item_idを元に、遷移すべき商品詳細ページのURLを返す。
   */
  getLink4selected(): string {
    if (!this.selectedItem) {
      return "";
    }
    return "/syouhinedit?id=" + this.selectedItem.itemId!.toString();
  }

  //------------------------------------

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }

  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }

  async showDet(id: string) {
    //商品選択モードの場合、商品情報を返して終了
    //----------------
    if (this.evDatas.isItemOnly == true) {
      await this.getDet(id);

      this.evDatas.data = this.delegate4det;
      this.evDatas.isSelected = true;

      //モーダルを閉じる
      //-----------------------
      this.closeModal4Item();
      return;
    }

    //詳細モーダル表示
    //----------------
    const modalComp: any = this.$refs.modal_variationSelect;
    await modalComp.showModal();

    //データ取得
    //----------------

    let date = new Date();
    let yyyy = date.getFullYear();
    let mm = ("0" + (date.getMonth() + 1)).slice(-2);
    let dd = ("0" + date.getDate()).slice(-2);

    this.detCount = 1;
    this.detStartDate = yyyy + "-" + mm + "-" + dd;
    await this.getDet(id);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal4Item(): Promise<void> {
    //起動時に読み込み
    this.getlist(1);

    const modalComp: any = this.$refs.modal_itemSelect;
    modalComp.showModal();
  }
  async closeModal4Item(): Promise<void> {
    const modalComp: any = this.$refs.modal_itemSelect;
    modalComp.closeModal();
  }
  // --------
  async closeModal4variation(): Promise<void> {
    const modalComp: any = this.$refs.modal_variationSelect;
    modalComp.closeModal();
  }
}
