export class EntityStaff4attendance {
public c_staff_id:number | null = null;
public c_staff_number:string | null = null;
public c_staff_name:string | null = null;
public c_staff_show_order_attendandce:number | null = null;
public c_branch_id:number | null = null;
public c_staff_depart:number | null = null;
public c_attendance_id:number | null = null;
public c_attendance_date:Date | null = null;
public c_attendance_time_work:string | null = null;
public c_attendance_time_leaving:string | null = null;
public c_attendance_time_goout_start:string | null = null;
public c_attendance_time_goout_end:string | null = null;
public c_attendance_reg_user:string | null = null;
public c_attendance_reg_at:Date | null = null;
public c_attendance_upd_user:string | null = null;
public c_attendance_upd_at:Date | null = null;
}