
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DtoClientsGetReqImple } from "mediadept-common/src/common/dto/DtoClientsGetReqImple";
import { DtoClientsGetResImple } from "mediadept-common/src/common/dto/DtoClientsGetResImple";
import { DtoClientsPutReq } from "mediadept-common/src/common/dto/abst/DtoClientsPutReq";
import { DtoClientsPutRes } from "mediadept-common/src/common/dto/abst/DtoClientsPutRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoClientsPostReq } from "mediadept-common/src/common/dto/abst/DtoClientsPostReq";
import { DtoClientsPostRes } from "mediadept-common/src/common/dto/abst/DtoClientsPostRes";

import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mo_modal_l,
    VueGoodTable,
  },
})
export default class Itemedit4billbord extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClientsGetResImple();
  btnDisable = false;
  isEditMode = true;

  columns = [
    {
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      label: "商品番号",
      field: "ANKEN_NO",
    },
    {
      label: "商品名",
      field: "ANKEN_NM",
    },
    {
      label: "商品種別",
      field: "KOUKOKU_NM",
    },
    {
      label: "所属",
      field: "SHOZOKU_NM",
    },
    {
      label: "エリア",
      field: "AREA_NM",
    },
  ];
  rows = [
    {
      ANKEN_NO: 1010,
      ANKEN_NM: "xxxxxx広告ビルボード",
      KOUKOKU_NM: "ビルボード",
      SHOZOKU_NM: "自社",
      AREA_NM: "xxxxxx",
      link: "/itemedit4billbord",
    },
    {
      ANKEN_NO: 1009,
      ANKEN_NM: "xxxxxx広告ポスタージャック",
      KOUKOKU_NM: "ポスタージャック",
      SHOZOKU_NM: "他社",
      AREA_NM: "xxxxxx",
      link: "/itemedit4posterjack",
    },
    {
      ANKEN_NO: 1008,
      ANKEN_NM: "xxxxxx広告商業施設",
      KOUKOKU_NM: "商業施設",
      SHOZOKU_NM: "交通",
      AREA_NM: "xxxxxx",
      link: "/itemedit4commercialfacility",
    },
    {
      ANKEN_NO: 1007,
      ANKEN_NM: "xxxxxx広告デジタルビルボード",
      KOUKOKU_NM: "デジタルビルボード",
      SHOZOKU_NM: "他社",
      AREA_NM: "xxxxxx",
      link: "/itemedit4digitalbillbord",
    },
    {
      ANKEN_NO: 1006,
      ANKEN_NM: "xxxxxx広告交通",
      KOUKOKU_NM: "交通",
      SHOZOKU_NM: "交通",
      AREA_NM: "xxxxxx",
      link: "/itemedit4koutu",
    },
    {
      ANKEN_NO: 1003,
      ANKEN_NM: "xxxxxx広告その他",
      KOUKOKU_NM: "その他",
      SHOZOKU_NM: "リムジン",
      AREA_NM: "xxxxxx",
      link: "/itemedit4sonota",
    },
    {
      ANKEN_NO: 1002,
      ANKEN_NM: "xxxxxx広告その他",
      KOUKOKU_NM: "その他",
      SHOZOKU_NM: "その他",
      AREA_NM: "xxxxxx",
      link: "",
    },
    {
      ANKEN_NO: 1001,
      ANKEN_NM: "xxxxxx広告その他",
      KOUKOKU_NM: "その他",
      SHOZOKU_NM: "その他",
      AREA_NM: "xxxxxx",
      link: "",
    },
  ];
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4CompanyType = [
    { label: "指定なし", code: 1 },
    { label: "株式", code: 2 },
    { label: "有限", code: 3 },
  ];
  op4SignagePosi = [
    { label: "前", code: 1 },
    { label: "後", code: 2 },
  ];
  optionsOp = [
    { label: "テスト" },
    { label: "アイエイチ・ラブ" },
    { label: "アイビーエス" },
    { label: "アクスル" },
    { label: "アクティス" },
    { label: "ACOZ" },
    { label: "アサツーディ・ケイ" },
    { label: "朝日電装" },
    { label: "アテンポライフ" },
    { label: "アドベル" },
    { label: "アド・トゥモロー" },
    { label: "アートシステム" },
    { label: "五十嵐商事" },
    { label: "伊勢屋テック" },
    { label: "茨城ロボッツ・スポーツエンターテイメント" },
    { label: "インフィニティ・コミュニケーションズ" },
    { label: "INFORICH" },
    { label: "エイディ・アンド・エム" },
    { label: "エスピーエスエス" },
  ];
  optionsOp2 = [
    { label: "ビルボード" },
    { label: "ポスタージャック" },
    { label: "商業施設" },
    { label: "デジタルビルボード" },
    { label: "交通" },
    { label: "その他メディア" },
    { label: "その他" },
  ];
  optionsOp8 = [
    { label: "媒体料" },
    { label: "製作・施工（取付撤去）" },
    { label: "製作・取付" },
    { label: "取付" },
    { label: "撤去" },
    { label: "撤去（前受）" },
    { label: "プリント" },
    { label: "工事（資産）" },
    { label: "電気代" },
    { label: "申請" },
    { label: "その他" },
  ];
  optionsOp9 = [
    { label: "外照式" },
    { label: "内照式" },
    { label: "照明なし" },
    { label: "自発光（LEDなど）" },
    { label: "ターポリン" },
    { label: "FFシート" },
    { label: "粘着シート" },
    { label: "懸垂幕" },
    { label: "ポスター" },
    { label: "屋上" },
    { label: "壁面" },
    { label: "自立" },
    { label: "直貼り" },
    { label: "その他" },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  /**
   * 商品選択用モーダル表示
   */
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_itemSelect;
    modalComp.showModal();
  }
  /**
   * 商品選択用モーダル閉じる
   */
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_itemSelect;
    modalComp.closeModal();
  }
}
