import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoClaimsGetReq } from "./abst/DtoClaimsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoClaimsGetReqImple extends DtoClaimsGetReq {
  public pager = new DtoApiPager();

  //検索条件
  public claimNo_start: number | null = null;
  public claimNo_end: number | null = null;
  public ClaimDate_start: Date | null = null;
  public ClaimDate_end: Date | null = null;
  public Clientid: number | null = null;
  public ClaimTitle: string | null = null;
  public ClaimSponsorName: string | null = null;
  public ClaimPayLimit_start: Date | null = null;
  public ClaimPayLimit_end: Date | null = null;
  public StaffId: number | null = null;
  public SalesMonth_start: Date | null = null;
  public SalesMonth_end: Date | null = null;
  /**
   * 　1:未発行 2:発行済み 3:未承認
   */
  public ClaimStatus: string | null = null;
  /**
   * 0:売上なし;1:売上あり;
   */
  public flagSales: number | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
