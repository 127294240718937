import { EntityStaff } from "../entity/EntityStaff";
import { EntityClient } from "../entity/EntityClient";
import { EntitySupplier } from "../entity/EntitySupplier";
import { EntityBankImple } from "../entity/EntityBankImple";
import { EntityHoliday } from "../entity/EntityHoliday";

export class DtoMastersGetRes {
  /**
   * 社員マスタ用
   */
  public items4staffs: EntityStaff[] = [];
  /**
   * 社員マスタ表示順入力済み＋削除フラグOFF＋表示順ソート用
   * 受注情報入力、請求書、請求書（売上なし）用
   */
  public items4staffsSortNum: EntityStaff[] = [];
  /**
   * 社員マスタ　勤怠管理用
   */
  public items4AttendanceStaffs: EntityStaff[] = [];
  /**
   * 社員マスタ保険申請担当者用
   */
  public items4staffInsurance: EntityStaff[] = [];
  /**
   * 売上先マスタ用
   */
  public items4Client: EntityClient[] = [];
  /**
   * 仕入先マスタ用
   */
  public items4Suplier: EntitySupplier[] = [];
  /**
   * 口座マスタ用
   */
  public items4Bank: EntityBankImple[] = [];
  /**
   * 休日管理用
   */
  public items4Holiday: EntityHoliday[] = [];

  /**
   * 削除フラグが立っていないサプライヤーと、渡されたIDのサプライヤーで別配列を作成し返す。
   * 削除された選択肢を含む可能性のあるMasterを処理する用。
   */
  getSupplier4NoneDelete(supplierIds: number[] | null): EntitySupplier[] {
    let res: EntitySupplier[] = [];

    //削除フラグを除外。
    //-----------------
    for (let index = 0; index < this.items4Suplier.length; index++) {
      if (this.items4Suplier[index].c_supplier_flag_del == 0) {
        res.push(this.items4Suplier[index]);
      }
    }
    //指定IDのデータを追加。
    //-----------------
    if (supplierIds != null) {
      for (let index = 0; index < supplierIds.length; index++) {
        //検索
        const result = res.find(
          (item) => item.c_supplier_id === supplierIds[index]
        );
        if (!result) {
          //存在しないので入力
          const exactMatches = this.items4Suplier.filter(
            (supplier) => supplier.c_supplier_id === supplierIds[index]
          );
          res = exactMatches.concat(res);
        }
      }
    }

    return res;
  }

  /**
   * 削除フラグが立っていない社員と、渡されたIDの社員で別配列を作成し返す。
   * 削除された選択肢を含む可能性のあるMasterを処理する用。
   * items4staffsSortNum用。
   */
  getStaffSortNum4NoneDelete(staffIds: number[] | null): EntityStaff[] {
    let res: EntityStaff[] = [];

    //削除フラグを除外。
    //-----------------
    for (let index = 0; index < this.items4staffsSortNum.length; index++) {
      if (this.items4staffsSortNum[index].c_staff_flag_del == 0) {
        res.push(this.items4staffsSortNum[index]);
      }
    }
    //指定IDのデータを追加。
    //-----------------
    if (staffIds != null) {
      for (let index = 0; index < staffIds.length; index++) {
        //検索
        const result = res.find(
          (item) => item.c_staff_id === staffIds[index]
        );
        if (!result) {
          //存在しないので入力
          const exactMatches = this.items4staffsSortNum.filter(
            (staff) => staff.c_staff_id === staffIds[index]
          );
          res = exactMatches.concat(res);
        }
      }
    }

    return res;
  }

  getStaffSortNum4NoneDeleteAndDelete(): EntityStaff[] {
    let res: EntityStaff[] = [];

    //削除フラグが立ってないデータを追加
    //-----------------
    for (let index = 0; index < this.items4staffsSortNum.length; index++) {
      if (this.items4staffsSortNum[index].c_staff_flag_del == 0) {
        res.push(this.items4staffsSortNum[index]);
      }
    }

    console.log("res1");
    console.log(res);

    //削除フラグが立ってないデータを追加
    //-----------------
    for (let index = 0; index < this.items4staffsSortNum.length; index++) {
      if (this.items4staffsSortNum[index].c_staff_flag_del == 1) {
        res.push(this.items4staffsSortNum[index]);
      }
    }

    console.log("res2");
    console.log(res);

    return res;
  }


}
