export class DtoApiPager {
  //ページャー用パラメータ
  /**
   * 取得数。ページ内の件数。（リクエストの値をそのまま返す）
   */
  public count: number | null = null;
  /**
   * 相対位置。何ページ目を取得するか。1から始まる。（リクエストの値をそのまま返す）
   */
  public cursor: number | null = null;
  /**
   * 全件数。対象となるアイテム数
   * レスポンス時のみに使用。
   */
  public maxcount: number | null = null;
  /**
   * 絶対位置。基本的には、対象となるテーブルのIdを指定する。
   * ※リクエスト時に指定された場合はそのまま返す。
   * ※複数のテーブルを使用する場合はカンマで区切って返す。
   * */
  public maxid: number | null = null;

  public IsValid4Pager(res: string[]) {
    //単項目チェック
    if (this.count == null || this.count <= 0) {
      res.push("パラメータcountが不正です");
    }
    if (this.cursor == null || this.cursor <= 0) {
      res.push("パラメータcursorが不正です");
    }

    //相関チェック

    // return true;
  }
}
