import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";

export class Cl_master {
  public static async get(dto: DtoMastersgetReq): Promise<DtoMastersGetRes> {
    return Object.assign(
      new DtoMastersGetRes(),
      await CallAPI.getRequest("/masters?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
