import { DtoMdDtp } from "../dto/DtoMdDtp";

export class EntityPayment {
    public c_claim_id: number | null = null;
    public c_project_id: number | null = null;
    public c_claim_number: number | null = null;
    public c_client_id: number | null = null;
    public c_claim_title_1: string | null = null;
    public c_claim_subtotal: number | null = null;
    public c_claim_total: number | null = null;
    public c_staff_id: number | null = null;
    public c_sales_id: number | null = null;
    public c_sales_issue: Date | null = null;
    public c_sales_item: number | null = null;
    public c_sales_subject: number | null = null;
    public c_sales_breakdown: number | null = null;
    public c_sales_price: number | null = null;
    public c_sales_date_exp_payment: Date | null = null;
    public c_sales_date_cmp_payment: Date | null = null;

    //フロントでの日付変換用
    public cnvDatecmp_payment: DtoMdDtp = new DtoMdDtp();

}