export class EntityRequestRemote {
public c_request_remote_id:number | null = null;
public c_request_id:number | null = null;
public c_request_remote_date:Date | null = null;
public c_request_remote_datetime_start_exp:string | null = null;
public c_request_remote_datetime_end_exp:string | null = null;
public c_request_remote_location:string | null = null;
public c_request_remote_reason:string | null = null;
public c_request_remote_cont:string | null = null;
public c_request_remote_reg_user:string | null = null;
public c_request_remote_reg_at:Date | null = null;
public c_request_remote_upd_user:string | null = null;
public c_request_remote_upd_at:Date | null = null;
}