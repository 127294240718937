export class EntityRequestLoa {
public c_request_loa_id:number | null = null;
public c_request_id:number | null = null;
public c_request_loa_date_start:Date | null = null;
public c_request_loa_date_end:Date | null = null;
public c_request_loa_cont:string | null = null;
public c_request_loa_tel:string | null = null;
public c_request_loa_reg_user:string | null = null;
public c_request_loa_reg_at:Date | null = null;
public c_request_loa_upd_user:string | null = null;
public c_request_loa_upd_at:Date | null = null;
}