import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSalessGetReqImple } from "mediadept-common/src/common/dto/DtoSalessGetReqImple";
import { DtoSalessGetResImple } from "mediadept-common/src/common/dto/DtoSalessGetResImple";
import { DtoSalessPostReq } from "mediadept-common/src/common/dto/abst/DtoSalessPostReq";
import { DtoSalessPostRes } from "mediadept-common/src/common/dto/abst/DtoSalessPostRes";
import { DtoSalessPutReq } from "mediadept-common/src/common/dto/abst/DtoSalessPutReq";
import { DtoSalessPutRes } from "mediadept-common/src/common/dto/abst/DtoSalessPutRes";
import { DtoSalessDeleteReq } from "mediadept-common/src/common/dto/abst/DtoSalessDeleteReq";
import { DtoSalessDeleteRes } from "mediadept-common/src/common/dto/abst/DtoSalessDeleteRes";

export class Cl_sales {
  public static async getList(
    dto: DtoSalessGetReqImple
  ): Promise<DtoSalessGetResImple> {
    return Object.assign(
      new DtoSalessGetResImple(),
      await CallAPI.getRequest(
        "/saless?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoSalessGetReqImple
  ): Promise<DtoSalessGetResImple> {
    return Object.assign(
      new DtoSalessGetResImple(),
      await CallAPI.getRequest("/saless/" + dto.c_sales_id)
    );
  }

  public static async post(dto: DtoSalessPostReq): Promise<DtoSalessPostRes> {
    return Object.assign(
      new DtoSalessPostRes(),
      await CallAPI.postRequest("/saless", dto)
    );
  }

  public static async put(dto: DtoSalessPutReq): Promise<DtoSalessPutRes> {
    return Object.assign(
      new DtoSalessPutRes(),
      await CallAPI.putRequest("/saless/" + dto.c_sales_id, dto)
    );
  }

  public async del(dto: DtoSalessDeleteReq): Promise<DtoSalessDeleteRes> {
    return Object.assign(
      new DtoSalessDeleteRes(),
      await CallAPI.deleteRequest("/saless?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
