import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoAttendancesGetReqImple } from "mediadept-common/src/common/dto/DtoAttendancesGetReqImple";
import { DtoAttendancesGetResImple } from "mediadept-common/src/common/dto/DtoAttendancesGetResImple";
import { DtoAttendancesPostReq } from "mediadept-common/src/common/dto/abst/DtoAttendancesPostReq";
import { DtoAttendancesPostRes } from "mediadept-common/src/common/dto/abst/DtoAttendancesPostRes";
import { DtoAttendancesPutReq } from "mediadept-common/src/common/dto/abst/DtoAttendancesPutReq";
import { DtoAttendancesPutRes } from "mediadept-common/src/common/dto/abst/DtoAttendancesPutRes";
import { DtoAttendancesDeleteReq } from "mediadept-common/src/common/dto/abst/DtoAttendancesDeleteReq";
import { DtoAttendancesDeleteRes } from "mediadept-common/src/common/dto/abst/DtoAttendancesDeleteRes";

export class Cl_attendance {
  public static async getList(
    dto: DtoAttendancesGetReqImple
  ): Promise<DtoAttendancesGetResImple> {
    return Object.assign(
      new DtoAttendancesGetResImple(),
      await CallAPI.getRequest(
        "/attendances?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoAttendancesGetReqImple
  ): Promise<DtoAttendancesGetResImple> {
    return Object.assign(
      new DtoAttendancesGetResImple(),
      await CallAPI.getRequest("/attendances/" + dto.c_attendance_id)
    );
  }

  public static async post(
    dto: DtoAttendancesPostReq
  ): Promise<DtoAttendancesPostRes> {
    return Object.assign(
      new DtoAttendancesPostRes(),
      await CallAPI.postRequest("/attendances", dto)
    );
  }

  public static async put(
    dto: DtoAttendancesPutReq
  ): Promise<DtoAttendancesPutRes> {
    return Object.assign(
      new DtoAttendancesPutRes(),
      await CallAPI.putRequest("/attendances/" + dto.c_attendance_id, dto)
    );
  }

  public static async del(
    dto: DtoAttendancesDeleteReq
  ): Promise<DtoAttendancesDeleteRes> {
    return Object.assign(
      new DtoAttendancesDeleteRes(),
      await CallAPI.deleteRequest(
        "/attendances?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
