import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoDoc4detsGetReqImple } from "mediadept-common/src/common/dto/DtoDoc4detsGetReqImple";
import { DtoDoc4detsGetResImple } from "mediadept-common/src/common/dto/DtoDoc4detsGetResImple";
import { DtoDoc4detsPostReq } from "mediadept-common/src/common/dto/abst/DtoDoc4detsPostReq";
import { DtoDoc4detsPostRes } from "mediadept-common/src/common/dto/abst/DtoDoc4detsPostRes";
import { DtoDoc4detsPutReq } from "mediadept-common/src/common/dto/abst/DtoDoc4detsPutReq";
import { DtoDoc4detsPutRes } from "mediadept-common/src/common/dto/abst/DtoDoc4detsPutRes";
import { DtoDoc4detsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoDoc4detsDeleteReq";
import { DtoDoc4detsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoDoc4detsDeleteRes";

export class Cl_doc4det {
  public static async getList(
    dto: DtoDoc4detsGetReqImple
  ): Promise<DtoDoc4detsGetResImple> {
    return Object.assign(
      new DtoDoc4detsGetResImple(),
      await CallAPI.getRequest(
        "/doc4dets?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoDoc4detsGetReqImple
  ): Promise<DtoDoc4detsGetResImple> {
    return Object.assign(
      new DtoDoc4detsGetResImple(),
      await CallAPI.getRequest("/doc4dets/" + dto.c_doc_id)
    );
  }

  public static async post(
    dto: DtoDoc4detsPostReq
  ): Promise<DtoDoc4detsPostRes> {
    return Object.assign(
      new DtoDoc4detsPostRes(),
      await CallAPI.postRequest("/doc4dets", dto)
    );
  }

  public static async put(dto: DtoDoc4detsPutReq): Promise<DtoDoc4detsPutRes> {
    return Object.assign(
      new DtoDoc4detsPutRes(),
      await CallAPI.putRequest("/doc4dets/" + dto.c_doc_id, dto)
    );
  }

  public static async del(
    dto: DtoDoc4detsDeleteReq
  ): Promise<DtoDoc4detsDeleteRes> {
    return Object.assign(
      new DtoDoc4detsDeleteRes(),
      await CallAPI.deleteRequest("/doc4dets/" + dto.c_doc_id)
    );
  }
}
