
import { Options, Vue } from "vue-class-component";
import Tm_leftnavi from "@/components/template/Tm_leftnavi.vue";
import Tm_header from "@/components/template/Tm_header.vue";
import Tm_footer from "@/components/template/Tm_footer.vue";
import { Cl_common } from "./functions/Cl_common";
import { DtoAlertsGetReqImple } from "mediadept-common/src/common/dto/DtoAlertsGetReqImple";
import { Cl_alert } from "./functions/Cl_alert";
import { CONST_REGULARLY_ALERTCOUNT } from "./constants/constant";
import { Cl_staffImple } from "./functions/Cl_staffImple";
import Store from "@/store/index";

@Options({
  components: {
    Tm_leftnavi,
    Tm_header,
    Tm_footer,
  },
  props: {
    msg: String,
  },
})
export default class App extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  msg!: string;
  updateTimer = 0;
  alertCount: { alertCount: number } = { alertCount: 0 };

  //----------------------
  //DOM連携用メソッド
  //----------------------
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //---------------------------------
    // 定期処理
    //---------------------------------
    //新着情報のカウント更新
    // console.log(this.$route.path);
    /**
     * 20230809 以下、数値のリアルタイムの変更ができないため一旦コメントアウト
     *
     */
    // const token = JSON.parse(localStorage.getItem("mediadept")!).token_id;
    // // console.log(token);
    // if (token != null && token != "") {
    //   this.alertCount.alertCount = await this.get4Alert();
    // }
    // if (this.updateTimer == 0) {
    //   this.updateTimer = setInterval(async () => {
    //     // console.log(this.$route.path);
    //     if (this.$route.path != "/" && document.visibilityState === "visible") {
    //       // console.log(this.$route.path);
    //       // this.alertCount.alertCount++;
    //       this.alertCount.alertCount = await this.get4Alert();
    //     }
    //   }, CONST_REGULARLY_ALERTCOUNT);
    // }
    //---------------------------------
  }
  //----------------------
  //基本操作用メソッド
  //----------------------

  /**
   * アラートのデータを読み込む
   */
  async get4Alert(): Promise<number> {
    //ボタン無効＋ローダー表示
    //-----------------
    let countAlert = 0;
    try {
      //処理
      //-----------------
      // Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoAlertsGetReqImple();

      //検索条件設定
      const nowdate = new Date();
      dto.date = new Date(
        nowdate.getFullYear(),
        nowdate.getMonth(),
        nowdate.getDate()
      )
        .toLocaleDateString()
        .replaceAll("/", "-");

      dto.isDashboard = 1;

      //API実行
      //-------
      countAlert = (await Cl_alert.getList(dto)).items.length;
    } catch (error) {
      Cl_common.logger(error);
      countAlert = 0;
    }

    return countAlert;
  }

  public isMypage(): boolean {
    // console.log(this.$route.path);
    return this.$route.path !== "/";
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
}
