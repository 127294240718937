import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityItem } from "../../entity/EntityItem";

export class DtoItemsPostReq extends EntityItem {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
