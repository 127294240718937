
import { Options, Vue } from "vue-class-component";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";

//API用
import { DtoItemsGetReq } from "@/dto/DtoItemsGetReq";
import Cl_items from "@/functions/Cl_items";
import { item } from "@/dto/DtoItemsGetRes";

@Options({
  components: {
    vSelect,
  },
})
export default class Vueselect extends Vue {
  //変数セット 通常用
  //---------------------------
  selected: { label: string; code: string } = { label: "", code: "" };
  optionsOp = [
    { label: "One", code: "A" },
    { label: "Two", code: "B" },
    { label: "Three", code: "C" },
  ];

  //変数セット2 multi用
  //---------------------------
  /**
   * シングルの時のようにオブジェクトにすると、初期で何かを選択している感じの挙動になるので
   * multiの時は、文字列で受けたほうが良さそう。
   */
  selected2: { label: string; code: string }[] = [];
  optionsOp2 = [
    { label: "One", code: "A" },
    { label: "Two", code: "B" },
    { label: "Three", code: "C" },
    { label: "fore", code: "D" },
    { label: "five", code: "E" },
    { label: "six", code: "F" },
  ];

  //変数セット3 APIからSELECT構築用
  //---------------------------
  selected3: item[] = []; //あれ？型を定義すれば初期状態で変な選択をしている状態にはならない？
  optionsOp3: item[] = [];

  async showSelect1(): Promise<void> {
    Cl_common.logger(this.selected);
    Cl_common.logger(this.selected.code);
    Cl_common.logger(this.selected.label);
  }
  async showSelect2(): Promise<void> {
    // Cl_common.logger(this.selected2);
    // Cl_common.logger((this.selected2 as any)[0].code);
    // Cl_common.logger((this.selected2 as any)[2].label);

    for await (const iterator of this.selected2) {
      Cl_common.logger("code:" + iterator.code);
      Cl_common.logger("label:" + iterator.label);
      Cl_common.logger("--------------");
    }

    // for (let index = 0; index < (this.selected2 as any); index++) {
    //   Cl_common.logger("code:" + (this.selected2 as any)[index].code);
    //   Cl_common.logger("label:" + (this.selected2 as any)[index].label);
    //   Cl_common.logger("--------------");
    // }
  }

  async creSelect(): Promise<void> {
    //ローダー表示※必ずしもボタンからたどる必要はない。
    //-----------------
    const panel = document.getElementById("btn")?.closest(".panel-container");
    panel?.classList.add("enable-loader");

    try {
      //処理

      //DTO作成
      //---------------------
      let dtoItemsGetReq = new DtoItemsGetReq();

      dtoItemsGetReq.count = 10;
      dtoItemsGetReq.cursor = 1;
      dtoItemsGetReq.keyword = "異世界";
      dtoItemsGetReq.sort = "new";
      dtoItemsGetReq.search = "onsale";

      //API実行
      //---------------------
      let itemsClient = new Cl_items();

      let response = await itemsClient.getList(dtoItemsGetReq);
      Cl_common.logger(response);

      //データ入力
      //---------------------
      this.optionsOp3 = response.items!;
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
    }
  }

  async showSelect3(): Promise<void> {
    // for await (const iterator of this.selected3 as any) {
    //   Cl_common.logger("c_series_code:" + iterator.c_series_code);
    //   Cl_common.logger("c_series_name:" + iterator.c_series_name);
    //   Cl_common.logger("--------------");
    // }

    for (let index = 0; index < this.selected3.length; index++) {
      Cl_common.logger("c_series_code:" + this.selected3[index].c_series_code);
      Cl_common.logger("c_series_name:" + this.selected3[index].c_series_name);
      Cl_common.logger("--------------");
    }
  }
}
