
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_holiday } from "@/functions/Cl_holiday";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoHolisticlocksGetReqImple } from "mediadept-common/src/common/dto/DtoHolisticlocksGetReqImple";
import { DtoHolisticlocksGetResImple } from "mediadept-common/src/common/dto/DtoHolisticlocksGetResImple";
import { Cl_holisticlock } from "@/functions/Cl_holisticlock";
import { DtoHolisticlocksGetRes } from "mediadept-common/src/common/dto/abst/DtoHolisticlocksGetRes";
import { CommonDate4MD } from "mediadept-common/src/common/function/CommonDate4MD";

//masters用
import { Cl_master } from "@/functions/Cl_master";
import { Cl_sendMail } from "@/functions/Cl_sendMail";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";

@Options({
  components: {
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class Datalocklist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoHolisticlocksGetResImple();
  delegateMasters = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;
  //検索条件dom
  domDate_start = new DtoMdDtp();
  domDate_end = new DtoMdDtp();

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "ロック年月",
      field: "c_holisticlock_date",
      thClass: "text-center",
      tdClass: "text-center",
      width: "300px",
    },
    {
      label: "備考",
      field: "c_holisticlock_remarks",
    },
    {
      label: "ロック者",
      field: "c_staff_id",
      width: "300px",
    },
    {
      label: "ロック日時",
      field: "c_holisticlock_upd_at",
      width: "300px",
    },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    this.getlist(1);
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container4selectPro");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegateMasters = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      // //モーダルのメッセージを設定
      // this.message4ModalErrorConf = "エラーが発生しました";
      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      this.delegateMasters = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------

      let dto = new DtoHolisticlocksGetReqImple();

      //検索条件設定
      dto.pager.count = 9999;
      dto.pager.cursor = currentPage;

      //API実行
      //-------
      this.delegate = await Cl_holisticlock.getList(dto);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoHolisticlocksGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 日付をyyyy/MM形式の文字列に変換する
   * @param date
   */
  cnvDate2Str4m(date: Date | null): string {
    if (date == null) {
      return "日時が設定されていません";
    }

    return CommonDate4MD.formatDateString_ym(new Date(date));
  }
  /**
   * 日付をyyyy/MM/dd HH:mm:ss形式の文字列に変換する
   * @param date
   */
  cnvDate2Str4dt(date: Date | null): string {
    if (date == null) {
      return "日時が設定されていません";
    }

    return CommonDate4MD.cnvDate2Str4DateTime(new Date(date));
  }
  /**
   *　社員IDを社員名に変換する
   * @param staffId
   */
  cnvStaffId2Name(staffId: number | null): string {
    if (staffId == null) {
      return "社員が設定されていません";
    }

    const staff = this.delegateMasters.items4staffs.find(
      (staff) => staff.c_staff_id == staffId
    );

    if (staff == null) {
      return "社員が見つかりません";
    }

    return staff.c_staff_name!;
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/datalockedit");
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
