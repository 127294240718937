export class EntityRequestAprv {
public c_request_aprv_id:number | null = null;
public c_request_id:number | null = null;
public c_request_aprv_deadline:Date | null = null;
public c_request_aprv_address_result:string | null = null;
public c_request_aprv_name:string | null = null;
public c_request_aprv_cont:string | null = null;
public c_request_aprv_payment:number | null = null;
public c_request_aprv_1_cont:string | null = null;
public c_request_aprv_2_cont:string | null = null;
public c_request_aprv_3_cont:string | null = null;
public c_request_aprv_result:number | null = null;
public c_request_aprv_fin_date:Date | null = null;
public c_request_aprv_number:number | null = null;
public c_request_aprv_reg_user:string | null = null;
public c_request_aprv_reg_at:Date | null = null;
public c_request_aprv_upd_user:string | null = null;
public c_request_aprv_upd_at:Date | null = null;
}