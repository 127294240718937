
import { Prop, Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DtoApiError } from "mediadept-common/src/common/dto/abst/DtoApiError";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import Or_tableJyutu from "@/components/organisms/Or_tableJyutu.vue";
import Or_insurance from "@/components/organisms/Or_insurance.vue";
import { Cl_common } from "@/functions/Cl_common";
import { CONST_OP_ALERT_CONTRACT } from "mediadept-common/src/common/constant/Constants";
import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { Cl_projectImple } from "@/functions/Cl_projectImple";

//project用
import { Cl_project } from "@/functions/Cl_project";
import { DtoProjectsGetReqImple } from "mediadept-common/src/common/dto/DtoProjectsGetReqImple";
import { DtoProjectsGetResImple } from "mediadept-common/src/common/dto/DtoProjectsGetResImple";
import { DtoProjectsPutReq } from "mediadept-common/src/common/dto/abst/DtoProjectsPutReq";
import { DtoProjectsPostReq } from "mediadept-common/src/common/dto/abst/DtoProjectsPostReq";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";

//masters用
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";

//claim用
import { Cl_claim } from "@/functions/Cl_claim";
import { DtoClaimsPostReqImple } from "mediadept-common/src/common/dto/DtoClaimsPostReqImple";
import { DtoClaimsPostResImple } from "mediadept-common/src/common/dto/DtoClaimsPostResImple";
import { EntitySalesImple } from "mediadept-common/src/common/entity/EntitySalesImple";

import Or_selectClient from "@/components/organisms/Or_selectClient.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";

// バリデーション
import * as Yup from "yup";
import { AxiosError } from "axios";
import { Cl_filemaster } from "@/functions/Cl_filemaster";

import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mo_modal_l,
    Or_tableJyutu,
    Or_insurance,
    Or_selectClient,
  },
})
export default class profit_jyutyuinput extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    pjName: Yup.string().required("案件名を入力してください。"),
  });
  schemaUpd = Yup.object().shape({
    pjName: Yup.string().required("案件名を入力してください。"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoProjectsGetResImple();
  delegate4list = new DtoMastersGetRes();

  btnDisable = false;
  isEditMode = true;

  //請求先選択用
  evDatas: {
    selectedItem: EntityClient | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  //収益計算データ用
  profitDatas: {
    sumSales: string;
    sumSupplys: string;
    profit: string;
    profitRatio: string;
  } = {
    sumSales: "0",
    sumSupplys: "0",
    profit: "0",
    profitRatio: "0",
  };

  //調整用
  jyutyuNumber = "";
  remarks = "";

  /**
   * 既存データが読み込まれているかのフラグ。
   * 新規登録と既存更新の切り分けに使用。
   */
  isLoaded = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opAlertContract = CONST_OP_ALERT_CONTRACT;
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;
      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;
      dtoMaster.getSuppliers = 1;
      dtoMaster.delFlag4suppliers = 0;

      //休日用準備
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);
      Cl_common.logger(this.delegate4list);

      //レスポンス処理
      //-------
    } catch (error) {
      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      this.isLoaded = false;

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("record_id");

      //準備
      //-------
      let dto = new DtoProjectsGetReqImple();

      dto.c_project_id = Number(id); //APIのURL構成の関係で、案件番号を受注IDに入れているので注意

      //API実行
      //-------
      this.delegate = await Cl_project.get(dto);
      // Cl_common.logger("koko");
      Cl_common.logger(this.delegate);

      //IDがnullなら新規追加モード
      if (this.delegate.c_project_id == null) {
        const delegateTmp = this.delegate;

        //新しいdelegateを作成し、新規追加モードにする
        this.delegate = new DtoProjectsGetResImple();
        this.delegate.jyutyuNumberPrev = delegateTmp.jyutyuNumberPrev;
        this.delegate.jyutyuNumberNext = delegateTmp.jyutyuNumberNext;
        this.delegate.lockDates = JSON.parse(
          JSON.stringify(delegateTmp.lockDates)
        );
        this.delegate.c_project_type = 2;
        this.jyutyuNumber = "";
        this.isEditMode = false;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //備考展開
      if (this.delegate.c_project_remarks != null) {
        this.remarks = this.delegate.c_project_remarks;
      }

      //案件番号展開
      this.jyutyuNumber = this.delegate.c_project_number!.toString();

      //売上明細、仕入明細の日付等展開
      await this.cnvDetails4Get();

      //読み込み完了フラグをON
      this.isLoaded = true;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
      this.delegate = new DtoProjectsGetResImple();
      this.isLoaded = false;
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });

      //収益計算
      //-----------------
      const tableJyutu: any = this.$refs.or_tableJyutu;
      await tableJyutu.profitCalc();
    }
  }
  /**
   * データを更新する
   * isNoModalをtrueにすると処理後のModal表示を抑制する
   */
  async upd(isDel: boolean, isNoModal: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------

    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    if (!isNoModal) {
      Array.from(panel).map((element: Element) => {
        element.classList.add("enable-loader");
      });
    }

    try {
      //入力確認　TODO：専用のモジュールに置き換えたい
      //-----------------
      if (!(await this.isVaild())) {
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoProjectsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_project_id = this.delegate.c_project_id;
        dto.c_project_flag_del = 1;
      } else {
        //日付をdelegateに戻す
        this.cnvDetails4Upd();

        //備考欄を戻す
        if (this.remarks != null) {
          this.delegate.c_project_remarks = this.remarks;
        }

        //バリデーション
        //-----------------
        this.errors = null;

        this.schemaUpd.validateSync({
          pjName: this.delegate.c_project_name,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoProjectsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_project.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      if (!isNoModal) {
        const modal: any = this.$refs.Mo_modal_success;
        modal.showModal();
      }
    } catch (error) {
      Cl_common.logger(error);
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          case "deduplicationClaim":
            this.message4ModalErrorConf =
              "請求No：" + response.info + "は、既に使用されています。";
            break;
          //#4594
          case "delete sales data is not allowed.":
            this.message4ModalErrorConf =
              "請求書が存在する為、案件を削除できません、請求書の再編集または、削除を行ってください";
            break;
          case "delete sales data is not allowed supply.":
            this.message4ModalErrorConf =
              "支払確定している仕入明細がある為、案件を削除できません。支払確定している仕入明細を確認してください。";
            break;
          //#4595
          case "approvedClaimNotUse":
            this.message4ModalErrorConf =
              "請求No：" +
              response.info +
              "は承認済みにより、入力者では変更できません。　請求No：" +
              response.info +
              "の承認を解除した後に入力するか、集計者以上の権限を持つ方にご依頼ください。";
            break;
          //#4599
          case "salesmonthNotAuthority":
            this.message4ModalErrorConf =
              "権限が入力者のため、売上月に前々月を指定できません。指定するには、集計者以上の権限を持つ方にご依頼ください";
            break;
          case "update sales data is not allowed.for approved claim number.":
            this.message4ModalErrorConf =
              "請求No：" +
              response.info +
              "は既に承認されているため、入力者では変更できません。請求No：" +
              response.info +
              "の承認を解除した後に入力するか、集計者以上の権限を持つ方にご依頼ください。";
            break;
          case "Cannot be deleted because there are sales details that are subject to the lock function.":
            this.message4ModalErrorConf =
              "ロック機能の対象となる売上明細が存在する為、削除できません";
            break;
          case "Cannot be deleted because there are purchase details that are subject to the lock function.":
            this.message4ModalErrorConf =
              "ロック機能の対象となる仕入明細が存在する為、削除できません";
            break;
          default:
            this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        // isNoModalがtrueの場合は、エラーを投げる
        if (isNoModal) {
          throw error;
        }

        return;
      }

      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      if (!isNoModal) {
        this.btnDisable = false;
        Array.from(panel).map((element: Element) => {
          element.classList.remove("enable-loader");
        });
      }
    }
  }
  /**
   * データを追加する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //入力確認　TODO：専用のモジュールに置き換えたい
      //-----------------
      if (!(await this.isVaild())) {
        //モーダル表示
        // Cl_common.logger(this.message4ModalErrorConf);
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;

      this.schemaAdd.validateSync({
        pjName: this.delegate.c_project_name,
      });

      //準備
      //-------
      let dto = new DtoProjectsPostReq();

      //日付をdelegateに戻す
      this.cnvDetails4Upd();
      dto.c_project_flag_del = 0;

      //備考欄を戻す
      if (this.remarks != null) {
        this.delegate.c_project_remarks = this.remarks;
      }

      //delegateからdtoにデータ投入。
      dto = Object.assign(new DtoProjectsPostReq(), this.delegate);

      //API実行
      //-------
      const res = await Cl_project.post(dto);
      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/jyutyuinput",
        query: { record_id: res.c_project_number },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に登録されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      Cl_common.logger(error);
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }
      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          case "deduplicationClaim":
            this.message4ModalErrorConf =
              "請求No[" + response.info + "]は、既に使用されています。";
            break;
          //#4599
          case "salesmonthNotAuthority":
            this.message4ModalErrorConf =
              "権限が入力者のため、売上月に前々月を指定できません。指定するには、集計者以上の権限を持つ方にご依頼ください";
            break;
          default:
            this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 明細内のデータを調整
   * APIのデータをgetした時用。
   */
  async cnvDetails4Get() {
    //売上明細
    //---------------------
    let tmp = new DtoMdDtp();
    for (let i = 0; i < this.delegate.sales.length; ++i) {
      //日付
      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_issue != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_issue!.toString()
          .split("T")[0];
      }
      this.delegate.sales[i].cnvDateIssue = tmp;
      // ----

      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_month != null) {
        tmp.date = commonRegx.maches4GroupName(
          this.delegate.sales[i].c_sales_month!.toString(),
          "(?<gn>^\\d+[-]\\d+)[-]",
          commonRegx._stdOption
        );
      }
      this.delegate.sales[i].cnvDateMonth = tmp;
      // ----

      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_date_start != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_date_start!.toString()
          .split("T")[0];
      }
      this.delegate.sales[i].cnvDateStart = tmp;
      // ----

      tmp = new DtoMdDtp();

      if (this.delegate.sales[i].c_sales_date_end != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_date_end!.toString()
          .split("T")[0];
      }

      this.delegate.sales[i].cnvDateEnd = tmp;
      // ----

      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_date_exp_payment != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_date_exp_payment!.toString()
          .split("T")[0];
      }
      this.delegate.sales[i].cnvDateExpPayment = tmp;
      // ----

      tmp = new DtoMdDtp();
      if (this.delegate.sales[i].c_sales_date_cmp_payment != null) {
        tmp.date = this.delegate.sales[i]
          .c_sales_date_cmp_payment!.toString()
          .split("T")[0];
      }
      this.delegate.sales[i].cnvDateCmpPayment = tmp;

      //項目選択
      // if (
      //   this.delegate.sales[i].c_sales_item != null &&
      //   this.delegate.sales[i].c_sales_item != ""
      // ) {
      //   this.delegate.sales[i].cnvItemType =
      //     await Cl_projectImple.cnvStrArray2NumArray(
      //       this.delegate.sales[i].c_sales_item!.split(",")
      //     );
      // }

      //請求書番号
      if (this.delegate.sales[i].c_sales_claimnum_tmp != null) {
        this.delegate.sales[i].cnvClaimnumTmp =
          this.delegate.sales[i].c_sales_claimnum_tmp!.toString();
      } else {
        this.delegate.sales[i].cnvClaimnumTmp = "";
      }

      //金額
      if (this.delegate.sales[i].c_sales_price == null) {
        this.delegate.sales[i].cnvPrice = "0";
      } else {
        this.delegate.sales[i].cnvPrice =
          this.delegate.sales[i].c_sales_price!.toString();
        //カンマ補完
        while (
          this.delegate.sales[i].cnvPrice !=
          (this.delegate.sales[i].cnvPrice = this.delegate.sales[
            i
          ].cnvPrice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
        );
      }

      //商品名
      if (this.delegate.sales[i].c_variation_id != null) {
        this.delegate.sales[i].cnvItemName =
          this.delegate.sales[i].c_item_name +
          " " +
          this.delegate.sales[i].c_variation_name;
      } else {
        this.delegate.sales[i].cnvItemName = "商品を選択してください";
      }
    }

    //仕入明細
    //---------------------

    for (let i = 0; i < this.delegate.supplys.length; ++i) {
      //日付
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_month != null) {
        tmp.date = commonRegx.maches4GroupName(
          this.delegate.supplys[i].c_supply_month!.toString(),
          "(?<gn>^\\d+[-]\\d+)[-]",
          commonRegx._stdOption
        );
      }
      this.delegate.supplys[i].cnvDateMonth = tmp;
      // ----
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_date_start != null) {
        tmp.date = this.delegate.supplys[i]
          .c_supply_date_start!.toString()
          .split("T")[0];
      }

      this.delegate.supplys[i].cnvDateStart = tmp;
      // ----
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_date_end != null) {
        tmp.date = this.delegate.supplys[i]
          .c_supply_date_end!.toString()
          .split("T")[0];
      }
      this.delegate.supplys[i].cnvDateEnd = tmp;
      // ----
      tmp = new DtoMdDtp();
      if (this.delegate.supplys[i].c_supply_date_exp_payment != null) {
        tmp.date = this.delegate.supplys[i]
          .c_supply_date_exp_payment!.toString()
          .split("T")[0];
      }
      this.delegate.supplys[i].cnvDateExpPayment = tmp;
      // ----

      //項目選択
      // if (
      //   this.delegate.supplys[i].c_supply_item != null &&
      //   this.delegate.supplys[i].c_supply_item != ""
      // ) {
      //   this.delegate.supplys[i].cnvItemType =
      //     await Cl_projectImple.cnvStrArray2NumArray(
      //       this.delegate.supplys[i].c_supply_item!.split(",")
      //     );
      // }

      //請求書番号
      if (this.delegate.supplys[i].c_supply_claimnum_tmp != null) {
        this.delegate.supplys[i].cnvClaimnumTmp =
          this.delegate.supplys[i].c_supply_claimnum_tmp!.toString();
      } else {
        this.delegate.supplys[i].cnvClaimnumTmp = "";
      }

      //金額
      if (this.delegate.supplys[i].c_supply_price == null) {
        this.delegate.supplys[i].cnvPrice = "0";
      } else {
        this.delegate.supplys[i].cnvPrice =
          this.delegate.supplys[i].c_supply_price!.toString();
        //カンマ補完
        while (
          this.delegate.supplys[i].cnvPrice !=
          (this.delegate.supplys[i].cnvPrice = this.delegate.supplys[
            i
          ].cnvPrice!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
        );
      }

      //商品名
      if (this.delegate.supplys[i].c_variation_id != null) {
        this.delegate.supplys[i].cnvItemName =
          this.delegate.supplys[i].c_item_name +
          " " +
          this.delegate.supplys[i].c_variation_name;
      } else {
        this.delegate.supplys[i].cnvItemName = "商品を選択してください";
      }

      //c_doc_idを入力
      if (this.delegate.supplys[i].c_pro2doc_id != null) {
        this.delegate.supplys[i].c_doc_id =
          this.delegate.pro2doc.find(
            (item) => item.c_pro2doc_id == this.delegate.supplys[i].c_pro2doc_id
          )?.c_doc_id ?? null;
      } else {
        this.delegate.supplys[i].c_doc_id = null;
      }
    }
  }
  /**
   * 明細内のデータを調整
   * APIのデータをupdした時用。
   */
  async cnvDetails4Upd() {
    //売上明細
    //---------------------
    for (let i = 0; i < this.delegate.sales.length; ++i) {
      //日付
      if (this.delegate.sales[i].cnvDateIssue.date != null) {
        this.delegate.sales[i].c_sales_issue = new Date(
          this.delegate.sales[i].cnvDateIssue!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_issue = null;
      }

      if (this.delegate.sales[i].cnvDateMonth.date != null) {
        this.delegate.sales[i].c_sales_month = new Date(
          this.delegate.sales[i].cnvDateMonth!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_month = null;
      }

      if (this.delegate.sales[i].cnvDateStart.date != null) {
        this.delegate.sales[i].c_sales_date_start = new Date(
          this.delegate.sales[i].cnvDateStart!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_date_start = null;
      }

      if (this.delegate.sales[i].cnvDateEnd.date != null) {
        this.delegate.sales[i].c_sales_date_end = new Date(
          this.delegate.sales[i].cnvDateEnd!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_date_end = null;
      }

      if (this.delegate.sales[i].cnvDateExpPayment.date != null) {
        this.delegate.sales[i].c_sales_date_exp_payment = new Date(
          this.delegate.sales[i].cnvDateExpPayment!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_date_exp_payment = null;
      }

      if (this.delegate.sales[i].cnvDateCmpPayment.date != null) {
        this.delegate.sales[i].c_sales_date_cmp_payment = new Date(
          this.delegate.sales[i].cnvDateCmpPayment!.date!
        );
      } else {
        this.delegate.sales[i].c_sales_date_cmp_payment = null;
      }

      //項目選択
      // if (this.delegate.sales[i].cnvItemType != undefined) {
      //   this.delegate.sales[i].c_sales_item =
      //     this.delegate.sales[i].cnvItemType.join(",");
      // }

      //請求書番号
      if (
        this.delegate.sales[i].cnvClaimnumTmp != null &&
        this.delegate.sales[i].cnvClaimnumTmp != "" &&
        Number.isInteger(Number(this.delegate.sales[i].cnvClaimnumTmp))
      ) {
        this.delegate.sales[i].c_sales_claimnum_tmp = Number(
          this.delegate.sales[i].cnvClaimnumTmp
        );
      } else {
        this.delegate.sales[i].c_sales_claimnum_tmp = null;
      }

      //金額
      if (
        this.delegate.sales[i].cnvPrice == null ||
        this.delegate.sales[i].cnvPrice == undefined
      ) {
        this.delegate.sales[i].c_sales_price = 0;
      } else {
        this.delegate.sales[i].c_sales_price = Number(
          this.delegate.sales[i].cnvPrice.replaceAll(",", "")
        );
      }
    }

    //仕入明細
    //---------------------
    for (let i = 0; i < this.delegate.supplys.length; ++i) {
      //日付
      if (this.delegate.supplys[i].cnvDateMonth.date != null) {
        this.delegate.supplys[i].c_supply_month = new Date(
          this.delegate.supplys[i].cnvDateMonth!.date!
        );
      } else {
        this.delegate.supplys[i].c_supply_month = null;
      }

      if (this.delegate.supplys[i].cnvDateStart.date != null) {
        this.delegate.supplys[i].c_supply_date_start = new Date(
          this.delegate.supplys[i].cnvDateStart!.date!
        );
      } else {
        this.delegate.supplys[i].c_supply_date_start = null;
      }

      if (this.delegate.supplys[i].cnvDateEnd.date != null) {
        this.delegate.supplys[i].c_supply_date_end = new Date(
          this.delegate.supplys[i].cnvDateEnd!.date!
        );
      } else {
        this.delegate.supplys[i].c_supply_date_end = null;
      }

      if (this.delegate.supplys[i].cnvDateExpPayment.date != null) {
        this.delegate.supplys[i].c_supply_date_exp_payment = new Date(
          this.delegate.supplys[i].cnvDateExpPayment!.date!
        );
      } else {
        this.delegate.supplys[i].c_supply_date_exp_payment = null;
      }

      //項目選択
      // if (this.delegate.supplys[i].cnvItemType != undefined) {
      //   this.delegate.supplys[i].c_supply_item =
      //     this.delegate.supplys[i].cnvItemType.join(",");
      // }
      //請求書番号
      if (
        this.delegate.supplys[i].cnvClaimnumTmp != null &&
        this.delegate.supplys[i].cnvClaimnumTmp != "" &&
        Number.isInteger(Number(this.delegate.supplys[i].cnvClaimnumTmp))
      ) {
        this.delegate.supplys[i].c_supply_claimnum_tmp = Number(
          this.delegate.supplys[i].cnvClaimnumTmp
        );
      } else {
        this.delegate.supplys[i].c_supply_claimnum_tmp = null;
      }
      //金額
      if (
        this.delegate.supplys[i].cnvPrice == null ||
        this.delegate.supplys[i].cnvPrice == undefined
      ) {
        this.delegate.supplys[i].c_supply_price = 0;
      } else {
        this.delegate.supplys[i].c_supply_price = Number(
          this.delegate.supplys[i].cnvPrice.replaceAll(",", "")
        );
      }
    }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "案件番号:" +
      this.delegate.c_project_number +
      "　" +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }

  /**
   * 案件をコピーする
   */
  async cpyProject() {
    if (this.delegate.c_project_id == null) {
      return;
    }

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      // 一旦保存
      //-----------------
      await this.upd(false, true);

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoProjectsPostReq();

      //パラメータ設定
      //-----------------
      dto.isProjectCopy = 1;
      dto.projectid4copy = this.delegate.c_project_id;

      //API実行
      //-------
      const res = await Cl_project.post(dto);
      //レスポンスの処理
      //-------
      await this.$router.push({
        path: "/jyutyuinput",
        query: { record_id: res.c_project_number },
      });
      await this.get();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 前の案件番号に遷移
   */
  async goPrev() {
    if (this.delegate.jyutyuNumberPrev == null) {
      return;
    }

    await this.$router.push(
      "/jyutyuinput?record_id=" + this.delegate.jyutyuNumberPrev
    );
    await this.get();
  }
  /**
   * 次の案件番号に遷移
   */
  async goNext() {
    if (this.delegate.jyutyuNumberNext == null) {
      return;
    }

    await this.$router.push(
      "/jyutyuinput?record_id=" + this.delegate.jyutyuNumberNext
    );
    await this.get();
  }
  //----------------------
  /**
   * 明細行の商品選択時のイベント
   * watch用
   */
  @Watch("evDatas.isSelected")
  async selectClient() {
    if (!this.evDatas.isSelected) {
      return;
    }

    // console.log(this.evDatas.selectedItem?.c_client_name);

    this.delegate.c_client_id = this.evDatas.selectedItem!.c_client_id!;
  }

  //----------------------
  /**
   * 請求書作成処理
   */
  async creClaim(event?: MouseEvent) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行 creClaim");

      //準備
      //-------
      //１．明細行選択なし状態と必須項目未入力で、エラーメッセージを表示
      //　　必須入力事項は、請求Noと金額のみ
      //-----
      //選択なし
      let selectedRow: EntitySalesImple | null = null;
      for (let i = 0; i < this.delegate.sales.length; ++i) {
        if (this.delegate.sales[i].isSelect) {
          selectedRow = this.delegate.sales[i];
          break;
        }
      }

      if (selectedRow == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "請求書を作成するには、対象となる売上明細行を選択してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }
      //必須入力なし
      // Cl_common.logger(selectedRow);
      if (selectedRow.cnvPrice == "" || selectedRow.cnvClaimnumTmp == "") {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "請求書を作成するには、請求Noと金額を入力してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }

      //POST API実行
      //-------
      //現状を保存
      //-----
      Cl_common.logger(selectedRow);
      try {
        await this.upd(false, true);
      } catch (error) {
        return;
      }

      //API実行
      //-----
      let dto = new DtoClaimsPostReqImple();
      dto.sales = selectedRow;
      let res = await Cl_claim.post(dto);
      Cl_common.logger(res);
      //レスポンスの処理
      //-------
      //画面遷移
      //-----

      console.log("res");
      console.log(res);
      if (dto.sales.c_project_id != res.c_project_id) {
        //削除済み
        //-----
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "請求No：" +
          selectedRow.cnvClaimnumTmp +
          "は、既に使用されています。";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }
      //#4595

      //請求書作成画面は売上無しで請求書の作成はできないので、遷移する必要あるか？
      if (res.claimUrl != "") {
        //遷移

        if (event && (event.ctrlKey || event.metaKey)) {
          window.open(res.claimUrl!);
        } else {
          this.$router.push(res.claimUrl!);
        }
      } else {
        if (res.c_claim_flag_del == 1) {
          //削除済み
          //-----
          //モーダルのメッセージを設定
          this.message4ModalErrorConf =
            "請求書No：" +
            selectedRow.cnvClaimnumTmp +
            "は、削除されています。管理者にお問い合わせください。";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();

          return;
        }

        if (res.c_claim_flag_sales == 0) {
          //売上なしで同一の請求書番号あり
          //-----
          //モーダルのメッセージを設定
          this.message4ModalErrorConf =
            "請求書No：" +
            selectedRow.cnvClaimnumTmp +
            "は、売上なし請求書として登録済みです。";
          //モーダル表示
          const modal: any = this.$refs.Mo_modal_errorConf;
          modal.showModal();

          return;
        }
      }
    } catch (error) {
      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          //#4595
          case "approvedClaimNotUse":
            this.message4ModalErrorConf =
              "請求No：" +
              response.info +
              "は承認済みにより、入力者では変更できません。　請求No：" +
              response.info +
              "の承認を解除した後に入力するか、集計者以上の権限を持つ方にご依頼ください。";
            break;
          //#4599
          case "salesmonthNotAuthority":
            this.message4ModalErrorConf =
              "権限が入力者のため、売上月に前々月を指定できません。指定するには、集計者以上の権限を持つ方にご依頼ください";
            break;
          default:
            this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "jyutyuinput";
      let id = Cl_common.getParam("record_id");
      dto.projectId = Number(id);
      dto.projectNumber = Number.parseInt(this.jyutyuNumber);
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async showModal4insurance(): Promise<void> {
    const modal: any = this.$refs.comp_insurance;
    modal.showModal();
  }
  async closeModal4insurance(): Promise<void> {
    const modal: any = this.$refs.comp_insurance;
    modal.closeModal();
  }
  /**
   * 請求先選択用モーダル
   */
  async showModalclaim(): Promise<void> {
    this.evDatas.selectedItem = null;
    this.evDatas.isSelected = false;

    const modalClient: any = this.$refs.Mo_modal_selectType;
    await modalClient.showModal();
  }
  async closeModalclaim(): Promise<void> {
    const modalClient: any = this.$refs.Mo_modal_selectType;
    modalClient.closeModal();
  }

  /**
   * 保険申請時に対象がなかった場合
   */
  async insuraNone() {
    //モーダルのメッセージを設定
    this.message4ModalErrorConf =
      "保険申請依頼が完了していない仕入情報がありません";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.showModal();
  }
  /**
   * 保険申請時の処理が正常に完了した場合
   */
  async insuraComp() {
    this.closeModal4insurance();

    await this.upd(false, true);

    this.message4ModalSuccess = "保険申請依頼メールが正常に送信されました";
    const modal: any = this.$refs.Mo_modal_success;
    modal.showModal();
  }

  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
  /**
   * 保存時のバリデーション処理
   * 　TODO：専用のモジュールに置き換えたい
   */
  async isVaild(): Promise<boolean> {
    //入金方法
    if (this.delegate.c_project_billing_type == null) {
      //モーダルのメッセージを設定　表示はしない
      this.message4ModalErrorConf = "入金方法を設定してください";
      return false;
    }
    //---------------

    return true;
  }

  /**
   * AxiosErrorかどうかを返す。
   * @param error
   * @returns
   */
  isAxiosError(error: any): error is AxiosError {
    return !!error.isAxiosError;
  }
  //モック時のコード
  //---------------------------

  // /**
  //  * 指定時間処理を停止する関数
  //  * @param {number} ms 待機するミリ秒数
  //  */
  // async sleep(ms: number) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
}
