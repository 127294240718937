import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityHoliday } from "../../entity/EntityHoliday";

export class DtoHolidaysPostReq extends EntityHoliday {
  public IsValid(): string[] {
    let res: string[] = new Array();

    // this.c_holiday_date = new Date(Date.parse(this.c_holiday_date!.toString()));

    //単項目チェック
    //--------------
    // if (this.c_holiday_date == null) {
    //   res.push("開始日が不正です");
    // }

    return res;
  }
}
