import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestEntmtsGetReqImple } from "mediadept-common/src/common/dto/DtoRequestEntmtsGetReqImple";
import { DtoRequestEntmtsGetResImple } from "mediadept-common/src/common/dto/DtoRequestEntmtsGetResImple";
import { DtoRequestEntmtsPostReq } from "mediadept-common/src/common/dto/abst/DtoRequestEntmtsPostReq";
import { DtoRequestEntmtsPostRes } from "mediadept-common/src/common/dto/abst/DtoRequestEntmtsPostRes";
import { DtoRequestEntmtsPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestEntmtsPutReq";
import { DtoRequestEntmtsPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestEntmtsPutRes";
import { DtoRequestEntmtsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoRequestEntmtsDeleteReq";
import { DtoRequestEntmtsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoRequestEntmtsDeleteRes";

export class Cl_requestentmt {
  public static async getList(
    dto: DtoRequestEntmtsGetReqImple
  ): Promise<DtoRequestEntmtsGetResImple> {
    return Object.assign(
      new DtoRequestEntmtsGetResImple(),
      await CallAPI.getRequest(
        "/requestentmts?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestEntmtsGetReqImple
  ): Promise<DtoRequestEntmtsGetResImple> {
    return Object.assign(
      new DtoRequestEntmtsGetResImple(),
      await CallAPI.getRequest("/requestentmts/" + dto.c_request_entmt_id)
    );
  }

  public static async post(
    dto: DtoRequestEntmtsPostReq
  ): Promise<DtoRequestEntmtsPostRes> {
    return Object.assign(
      new DtoRequestEntmtsPostRes(),
      await CallAPI.postRequest("/requestentmts", dto)
    );
  }

  public static async put(
    dto: DtoRequestEntmtsPutReq
  ): Promise<DtoRequestEntmtsPutRes> {
    return Object.assign(
      new DtoRequestEntmtsPutRes(),
      await CallAPI.putRequest("/requestentmts/" + dto.c_request_entmt_id, dto)
    );
  }

  public static async del(
    dto: DtoRequestEntmtsDeleteReq
  ): Promise<DtoRequestEntmtsDeleteRes> {
    return Object.assign(
      new DtoRequestEntmtsDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestentmts?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
