export class EntityHistory {
public c_history_id:number | null = null;
public c_staff_id:number | null = null;
public c_doc_id:number | null = null;
public c_history_datetime:Date | null = null;
public c_history_title:string | null = null;
public c_history_det:string | null = null;
public c_history_logstream:string | null = null;
public c_history_eventid:string | null = null;
public c_history_reg_user:string | null = null;
public c_history_reg_at:Date | null = null;
public c_history_upd_user:string | null = null;
public c_history_upd_at:Date | null = null;
}