import {
  CONST_CLAIM_ROUDING_KIRIAGE,
  CONST_CLAIM_ROUDING_KIRISUTE,
  CONST_CLAIM_ROUDING_SISYAGONYU,
} from "../constant/Constants";
import { EntityConditionImple } from "../entity/EntityConditionImple";
import { EntityVariationImple } from "../entity/EntityVariationImple";

/**
 * メディアデプトプロジェクト内に関する金額の処理を担当する
 */
export class CommonPrice4MD {
  /**
   * EntityVariationImpleに設定されている情報から、売上金額を返す。
   * 情報が不足している場合、設定金額をそのまま返す。
   * @param entityClaim
   * @param authData
   * @returns
   */
  public static async cnvVariationPrice(
    itemCount: number,
    variation: EntityVariationImple
  ): Promise<number> {
    //準備
    //---------------
    if (!this.isInteger(variation.c_variation_price)) {
      return 0;
    }
    if (!this.isInteger(variation.c_variation_price_type)) {
      return variation.c_variation_price!;
    }
    if (!Number.isFinite(variation.c_variation_margin_sales)) {
      return variation.c_variation_price!;
    }

    if (itemCount < 1) {
      itemCount = 1;
    }

    //算出
    //---------------
    if (variation.c_variation_price_type == 1) {
      //グロス
      const ratio: number = variation.c_variation_margin_sales! / 100;
      const margin = variation.c_variation_price! * ratio;
      return Math.round(variation.c_variation_price! - margin) * itemCount;
    } else {
      //ネット
      return variation.c_variation_price! * itemCount;
    }
  }
  /**
   * EntityConditionImple設定されている情報から、合計仕入金額を返す。
   * @param entityClaim
   * @param authData
   * @returns
   */
  public static async cnvSupplySumPrice(
    conditions: EntityConditionImple[]
  ): Promise<number> {
    let res = 0;

    for (let index = 0; index < conditions.length; index++) {
      if (
        conditions[index].c_condition_price != null &&
        this.isInteger(conditions[index].c_condition_price)
      ) {
        res += conditions[index].c_condition_price!;
      }
    }

    return res;
  }

  /**
   * 金額と小数点以下の丸め方法（0:四捨五入;1:切り上げ;2:切り捨て;）を受け取って、丸めた金額を返す。
   */
  public static cnvIntPrice(price: number, rounding: number): number {
    switch (rounding) {
      case CONST_CLAIM_ROUDING_SISYAGONYU:
        return Math.round(price);
      case CONST_CLAIM_ROUDING_KIRIAGE:
        return Math.ceil(price);
      case CONST_CLAIM_ROUDING_KIRISUTE:
        return Math.floor(price);

      default:
        throw new Error("roundingの値が不正です。");
    }
  }

  //---------------------------------
  /**
   * 整数かどうかをbool型で返す。
   * @param value
   * @returns
   */
  public static isInteger(value: any): boolean {
    return typeof value === "number" && value % 1 === 0;
  }

  /**
   * 税抜き金額計算して返す。
   * 業務仕様的に、内税以外の税率の場合、入力金額は税抜き金額と同義なので、そのまま返す。
   * 内税の時のみ、入力値を税込み金額として扱い、税抜き金額を計算して返す。
   */
  public static calcExcludTax(
    salesPrice: number,
    taxRate: number,
    rounding: number
  ): string {
    let res = "";

    //開始判定
    //-------------
    if (salesPrice == null) {
      res = "0";
      return res;
    }

    //計算開始
    //-------------
    let sum = 0;
    let sum_str = salesPrice.toString();

    //税込金額計算
    //-------------

    //数字にパース
    sum_str = sum_str.replace(/,/g, "");
    sum = Number(sum_str);
    let sumTmp = "";
    let taxiTmp = 0;

    switch (taxRate) {
      case null:
        //消費税未設定：金額がそのまま税抜金額に入る
        sumTmp = salesPrice.toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      case 1:
        //5%
        sumTmp = salesPrice.toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      case 2:
        //8%
        sumTmp = salesPrice.toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));

        res = sumTmp;
        break;
      case 3:
        //10%
        sumTmp = salesPrice.toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      case 4:
        //内税　計算される
        //-------
        //消費税計算
        taxiTmp = salesPrice - salesPrice / 1.1;
        sumTmp = CommonPrice4MD.cnvIntPrice(
          salesPrice - taxiTmp,
          rounding
        ).toString();

        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      default:
        //消費税未設定：金額がそのまま税込み金額に入る
        sumTmp = salesPrice.toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
    }

    return res;
  }

  /**
   * 税込み金額計算
   */
  public static calcTax(
    salesPrice: number,
    taxRate: number,
    rounding: number
  ): string {
    let res = "";

    //開始判定
    //-------------
    if (salesPrice == null) {
      res = "0";
      return res;
    }

    //計算開始
    //-------------
    let sum = 0;
    let sum_str = salesPrice.toString();

    //税込金額計算
    //-------------

    //数字にパース
    sum_str = sum_str.replace(/,/g, "");
    sum = Number(sum_str);
    let sumTmp = "";

    switch (taxRate) {
      case null:
        //消費税未設定：金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      case 1:
        //5%
        sumTmp = CommonPrice4MD.cnvIntPrice(
          (1 + 5 / 100) * sum,
          rounding!
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      case 2:
        //8%
        sumTmp = CommonPrice4MD.cnvIntPrice(
          (1 + 8 / 100) * sum,
          rounding!
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));

        res = sumTmp;
        break;
      case 3:
        //10%
        sumTmp = CommonPrice4MD.cnvIntPrice(
          (1 + 10 / 100) * sum,
          rounding!
        ).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      case 4:
        //内税　ここでは金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
      default:
        //消費税未設定：金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        while (sumTmp != (sumTmp = sumTmp.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        res = sumTmp;
        break;
    }

    return res;
  }

  /**
   * 税込み金額計算 カンマなし
   */
  public static calcTax_noComma(
    salesPrice: number,
    taxRate: number,
    rounding: number
  ): string {
    let res = "";

    //開始判定
    //-------------
    if (salesPrice == null) {
      res = "0";
      return res;
    }

    //計算開始
    //-------------
    let sum = 0;
    let sum_str = salesPrice.toString();

    //税込金額計算
    //-------------

    //数字にパース
    sum_str = sum_str.replace(/,/g, "");
    sum = Number(sum_str);
    let sumTmp = "";

    switch (taxRate) {
      case null:
        //消費税未設定：金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
      case 1:
        //5%
        sumTmp = CommonPrice4MD.cnvIntPrice(
          (1 + 5 / 100) * sum,
          rounding!
        ).toString();
        res = sumTmp;
        break;
      case 2:
        //8%
        sumTmp = CommonPrice4MD.cnvIntPrice(
          (1 + 8 / 100) * sum,
          rounding!
        ).toString();
        res = sumTmp;
        break;
      case 3:
        //10%
        sumTmp = CommonPrice4MD.cnvIntPrice(
          (1 + 10 / 100) * sum,
          rounding!
        ).toString();
        res = sumTmp;
        break;
      case 4:
        //内税　ここでは金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
      default:
        //消費税未設定：金額がそのまま税込み金額に入る
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
    }

    return res;
  }
  /**
   * 税抜き金額計算 カンマなし
   * 入力は入力金額。
   */
  public static calcTax_noComma_noTax(
    salesPrice: number,
    taxRate: number,
    rounding: number
  ): string {
    let res = "";

    //開始判定
    //-------------
    if (salesPrice == null) {
      res = "0";
      return res;
    }

    //計算開始
    //-------------
    let sum = 0;
    let sum_str = salesPrice.toString();

    //税抜金額計算
    //-------------

    //数字にパース
    sum_str = sum_str.replace(/,/g, "");
    sum = Number(sum_str);
    let sumTmp = "";
    let tax = 0;

    switch (taxRate) {
      case null:
        //消費税未設定：金額がそのまま税抜き金額に入る（非課税扱い）。
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
      case 1:
        //5%　入力金額が税抜金額の為、そのまま返す。
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
      case 2:
        //8%　入力金額が税抜金額の為、そのまま返す。
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
      case 3:
        //10%　入力金額が税抜金額の為、そのまま返す。
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
      case 4:
        //内税　税抜金額にして返す。

        // 内税　請求額 -　(請求額 / 1.1) = 税額 ※ここでは小数点処理を行わない

        tax = sum - sum / 1.1;
        sumTmp += CommonPrice4MD.cnvIntPrice(sum - tax, rounding!).toString();

        res = sumTmp;
        break;
      default:
        //消費税未設定：金額がそのまま税抜き金額に入る（非課税扱い）。
        sumTmp = CommonPrice4MD.cnvIntPrice(sum, rounding!).toString();
        res = sumTmp;
        break;
    }

    return res;
  }

  /**
   *　売上額と仕入額を受け取り、利益率を計算して返す。
   *　マイナス以下は0として扱う。
   * @returns
   */
  public static calcRatioProfit(
    priceSales: number,
    priceSupply: number
  ): number {
    //-------
    let res = 0;
    const profit = priceSales - priceSupply;
    const profitRate = Number(((profit / priceSales) * 100).toFixed(2));

    if (
      !isNaN(profitRate) &&
      profitRate != -Infinity &&
      profitRate != Infinity
    ) {
      res = profitRate;
    } else {
      res = 0;
    }

    return res;
  }
  /**
   * 売上配列から割合配列を計算し、合計が100になるように調整して返す関数。
   *
   * @param sales - 売上額の数値配列。各要素が個別の売上額を示す。
   * @returns 売上額の割合を整数で表した数値配列。合計は100になる。
   *
   * @example
   * ```typescript
   * const sales = [120, 300, 80, 500];
   * const percentages = calculatePercentageArray(sales);
   * console.log(percentages); // [12, 30, 8, 50] など合計100の配列
   * ```
   *
   * @remarks
   * - `sales`の合計を100%とし、各要素がその割合を持つように計算される。
   * - 合計が100に満たない場合、残りの割合を要素に1ずつ分配して合計が100になるように調整。
   * - `sales`が空配列や合計が0の場合は、すべて0の配列を返す。
   */
  public static calculatePercentageArray(sales: number[]): number[] {
    const total = sales.reduce((acc, value) => acc + value, 0); // 売上合計を計算
    if (total === 0) return sales.map(() => 0); // 合計が0の場合はすべて0を返す

    // 小数点以下も含めて計算した割合配列
    const rawPercentages = sales.map((value) => (value / total) * 100);

    // 各割合の小数点以下を切り捨てて整数配列を作成
    const flooredPercentages = rawPercentages.map(Math.floor);
    let percentageSum = flooredPercentages.reduce(
      (acc, value) => acc + value,
      0
    );

    // 合計が100になるように調整
    let remainder = 100 - percentageSum;
    let i = 0;
    while (remainder > 0) {
      flooredPercentages[i]++;
      remainder--;
      i = (i + 1) % flooredPercentages.length;
    }

    return flooredPercentages;
  }
}
