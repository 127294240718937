import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoAlertsGetReq } from "./abst/DtoAlertsGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoAlertsGetReqImple extends DtoAlertsGetReq {

  //検索条件
  public date: string | null = null;

  //Dashboardからの呼び出し
  public isDashboard: number = 0;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    return res;
  }
}
