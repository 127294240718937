export enum toasterType {
  success,
  warning,
  error,
  info,
}

export class Cl_notifications {
  public static async showToastr(
    type: toasterType,
    msg: string
  ): Promise<void> {
    (window as any).toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: true,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
      onclick: null,
      showDuration: 300,
      hideDuration: 100,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };

    switch (type) {
      case toasterType.success:
        (window as any).toastr.success(msg);
        break;
      case toasterType.warning:
        (window as any).toastr.warning(msg);
        break;
      case toasterType.error:
        (window as any).toastr.error(msg);
        break;
      case toasterType.info:
        (window as any).toastr.info(msg);
        break;
      default:
        break;
    }
  }
}
