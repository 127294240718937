
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";

@Options({
  components: {
    vSelect,
    VueGoodTable,
  },
})
export default class profit_itemlist extends Vue {
  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push(id);
  }

  optionsOp2 = [
    { label: "ビルボード" },
    { label: "ポスタージャック" },
    { label: "商業施設" },
    { label: "デジタルビルボード" },
    { label: "交通" },
    { label: "その他" },
  ];
  optionsOp3 = [
    { label: "自社" },
    { label: "他社" },
    { label: "交通" },
    { label: "リムジン" },
    { label: "その他" },
  ];
  columns = [
    {
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      label: "商品番号",
      field: "ANKEN_NO",
    },
    {
      label: "商品名",
      field: "ANKEN_NM",
    },
    {
      label: "商品種別",
      field: "KOUKOKU_NM",
    },
    {
      label: "所属",
      field: "SHOZOKU_NM",
    },
    {
      label: "エリア",
      field: "AREA_NM",
    },
  ];
  rows = [
    {
      ANKEN_NO: 1010,
      ANKEN_NM: "xxxxxx広告ビルボード",
      KOUKOKU_NM: "ビルボード",
      SHOZOKU_NM: "自社",
      AREA_NM: "xxxxxx",
      link: "/itemedit4billbord",
    },
    {
      ANKEN_NO: 1009,
      ANKEN_NM: "xxxxxx広告ポスタージャック",
      KOUKOKU_NM: "ポスタージャック",
      SHOZOKU_NM: "他社",
      AREA_NM: "xxxxxx",
      link: "/itemedit4posterjack",
    },
    {
      ANKEN_NO: 1008,
      ANKEN_NM: "xxxxxx広告商業施設",
      KOUKOKU_NM: "商業施設",
      SHOZOKU_NM: "交通",
      AREA_NM: "xxxxxx",
      link: "/itemedit4commercialfacility",
    },
    {
      ANKEN_NO: 1007,
      ANKEN_NM: "xxxxxx広告デジタルビルボード",
      KOUKOKU_NM: "デジタルビルボード",
      SHOZOKU_NM: "他社",
      AREA_NM: "xxxxxx",
      link: "/itemedit4digitalbillbord",
    },
    {
      ANKEN_NO: 1006,
      ANKEN_NM: "xxxxxx広告交通",
      KOUKOKU_NM: "交通",
      SHOZOKU_NM: "交通",
      AREA_NM: "xxxxxx",
      link: "/itemedit4koutu",
    },
    {
      ANKEN_NO: 1003,
      ANKEN_NM: "xxxxxx広告その他",
      KOUKOKU_NM: "その他",
      SHOZOKU_NM: "リムジン",
      AREA_NM: "xxxxxx",
      link: "/itemedit4sonota",
    },
    {
      ANKEN_NO: 1002,
      ANKEN_NM: "xxxxxx広告その他",
      KOUKOKU_NM: "その他",
      SHOZOKU_NM: "その他",
      AREA_NM: "xxxxxx",
      link: "",
    },
    {
      ANKEN_NO: 1001,
      ANKEN_NM: "xxxxxx広告その他",
      KOUKOKU_NM: "その他",
      SHOZOKU_NM: "その他",
      AREA_NM: "xxxxxx",
      link: "",
    },
  ];
  totalRecords = 10;
}
