import { e_t_pro2doc } from "../constant/ConstantsEntity";
import { DaoPro2doc } from "../dao/DaoPro2doc";
import { EntityHolisticlock } from "../entity/EntityHolisticlock";
import { EntityPro2doc } from "../entity/EntityPro2doc";
import { EntitySalesImple } from "../entity/EntitySalesImple";
import { EntitySupplyImple } from "../entity/EntitySupplyImple";
import { DtoApiPager } from "./abst/DtoApiPager";
import { DtoProjectsGetRes } from "./abst/DtoProjectsGetRes";

export class DtoProjectsGetResImple extends DtoProjectsGetRes {
  //一覧用
  //-----------------------
  public pager = new DtoApiPager();

  //詳細用
  //-----------------------
  /**
   * 売上用
   */
  public sales: EntitySalesImple[] = [];
  /**
   * 仕入用
   */
  public supplys: EntitySupplyImple[] = [];
  /**
   * ロック用
   */
  public lockDates: EntityHolisticlock[] = [];

  /**
   * プロジェクトに紐づくpro2doc
   */
  public pro2doc: EntityPro2doc[] = [];

  //前後の案件番号
  public jyutyuNumberPrev: number | null = null;
  public jyutyuNumberNext: number | null = null;

  // public async getDocId4Supply() {
  //   if (this.supplys.length > 0) {
  //     return;
  //   }

  //   let daoPro2doc = new DaoPro2doc();

  //   try {
  //     await daoPro2doc.connect();

  //     //データ取得。
  //     //-------
  //     this.cnvPro2docId2DocId = await daoPro2doc.getCnvTable(
  //       e_t_pro2doc[e_t_pro2doc.c_pro2doc_id],
  //       e_t_pro2doc[e_t_pro2doc.c_doc_id],
  //       e_t_pro2doc[e_t_pro2doc.c_project_id] +
  //         "=" +
  //         this.supplys[0].c_project_id
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   } finally {
  //     await daoPro2doc.dispose();
  //   }
  // }

  /**
   * supplyに対して、対応するc_doc_idを設定する。
   */
  // public async setDocId4Supply() {
  //   if (this.supplys.length > 0) {
  //     return;
  //   }

  //   let daoPro2doc = new DaoPro2doc();

  //   try {
  //     await daoPro2doc.connect();

  //     //データ取得。
  //     //-------
  //     const cnvPro2docId2DocId = await daoPro2doc.getCnvTable(
  //       e_t_pro2doc[e_t_pro2doc.c_pro2doc_id],
  //       e_t_pro2doc[e_t_pro2doc.c_doc_id],
  //       e_t_pro2doc[e_t_pro2doc.c_project_id] +
  //         "=" +
  //         this.supplys[0].c_project_id
  //     );

  //     //値設定。
  //     //-------
  //     for (let index = 0; index < this.supplys.length; index++) {
  //       if (
  //         this.supplys[index].c_pro2doc_id != null &&
  //         cnvPro2docId2DocId.has(this.supplys[index].c_pro2doc_id!.toString())
  //       ) {
  //         this.supplys[index].c_doc_id = Number(
  //           cnvPro2docId2DocId.get(this.supplys[index].c_pro2doc_id!.toString())
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   } finally {
  //     await daoPro2doc.dispose();
  //   }
  // }
}
