import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";

import { DtoClaimNumsPostReq } from "mediadept-common/src/common/dto/DtoClaimNumsPostReq";
import { DtoClaimNumsPostRes } from "mediadept-common/src/common/dto/DtoClaimNumsPostRes";

export class Cl_claimnum {
  public static async post(
    dto: DtoClaimNumsPostReq
  ): Promise<DtoClaimNumsPostRes> {
    return Object.assign(
      new DtoClaimNumsPostRes(),
      await CallAPI.postRequest("/claimnums", dto)
    );
  }
}
