
export class DtoItemImagePostReq {
    public itemId: number | null = null;
    public fileType: string | null = null;

    public IsValid(): string[] {
        let res: string[] = new Array();
        //単項目チェック
        //--------------

        return res;
    }
}
