import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoSuppliersGetReq } from "./abst/DtoSuppliersGetReq";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoSuppliersGetReqImple extends DtoSuppliersGetReq {

  //仕入れ先マスタ一覧の検索条件
  public SuppliersId_start: number | null = null;
  public SuppliersId_end: number | null = null;
  public SuppliersName: string | null = null;

  public pager = new DtoApiPager();

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();
    //ページャー用入力値チェック
    if (chkPager) {
      this.pager.IsValid4Pager(res);
    } else {
      res = res.concat(super.IsValid());
    }

    // if (this.SuppliersId_start != null && this.SuppliersId_end == null) {
    //   res.push("idの指定が不正です");
    // }
    // if (this.SuppliersId_start == null && this.SuppliersId_end != null) {
    //   res.push("idの指定が不正です");
    // }
    if (this.SuppliersId_start != null && this.SuppliersId_end != null && this.SuppliersId_start > this.SuppliersId_end) {
      res.push("idの指定が不正です");
    }

    return res;
  }
}
