
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoFileReportsGetResImple } from "mediadept-common/src/common/dto/DtoFileReportsGetResImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

@Options({
  components: {
    Mo_modal_alert,
  },
})
export default class Nyukinyoteicreate extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  btnDisable = false;
  domDate = "";

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  //async mounted() {}

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    if (0 == this.domDate.length) {
      alert("入金予定月が未入力です。");
      return;
    }

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "NyukinYotei";
      dto.year = Number(this.domDate.split("-")[0]);
      dto.month = Number(this.domDate.split("-")[1]);

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
