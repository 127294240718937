export class EntityRequestEntmt {
public c_request_entmt_id:number | null = null;
public c_request_id:number | null = null;
public c_request_entmt_date:Date | null = null;
public c_request_entmt_dest:string | null = null;
public c_request_entmt_companion:string | null = null;
public c_request_entmt_bud:string | null = null;
public c_request_entmt_cont:string | null = null;
public c_request_entmt_reg_user:string | null = null;
public c_request_entmt_reg_at:Date | null = null;
public c_request_entmt_upd_user:string | null = null;
public c_request_entmt_upd_at:Date | null = null;
}