import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntityRequestEntmt } from "../../entity/EntityRequestEntmt";

export class DtoRequestEntmtsPostReq extends EntityRequestEntmt {
  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------

    return res;
  }
}
