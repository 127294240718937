
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";

@Options({})
export default class Loader extends Vue {
  /**
   * 基本の処理の形。処理中はボタン押下は無効になる。
   *
   */
  async showLoader(): Promise<void> {
    //ボタンを無効化
    //-----------------
    const btn = document.getElementById("btn1");
    btn?.setAttribute("disabled", "disabled");

    try {
      //処理
      Cl_common.logger("処理実行");
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタンを無効解除
      //-----------------
      btn?.removeAttribute("disabled");
    }
  }
  /**
   * 基本の処理の形2。パネルにローダーを表示。
   * ローダーの入ったパネルである必要があるので注意。
   *
   */
  async showLoader4panel(): Promise<void> {
    //ローダー表示※必ずしもボタンからたどる必要はない。
    //-----------------
    const panel = document.getElementById("btn2")?.closest(".panel-container");
    panel?.classList.add("enable-loader");

    try {
      //処理
      Cl_common.logger("処理実行");
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
    }
  }
}
