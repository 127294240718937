
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import { DtoBanksGetReqImple } from "mediadept-common/src/common/dto/DtoBanksGetReqImple";
import { DtoBanksGetResImple } from "mediadept-common/src/common/dto/DtoBanksGetResImple";
import { DtoBanksPutReq } from "mediadept-common/src/common/dto/abst/DtoBanksPutReq";
import { DtoBanksPutRes } from "mediadept-common/src/common/dto/abst/DtoBanksPutRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoBanksPostReq } from "mediadept-common/src/common/dto/abst/DtoBanksPostReq";
import { Cl_bank } from "@/functions/Cl_bank";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class Kouzaedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    bankName: Yup.string().required("銀行名を入力してください。"),
    branchName: Yup.string().required("支店名を入力してください。"),
    bankNumber: Yup.string().required("口座番号を入力してください。"),
  });
  schemaUpd = Yup.object().shape({
    bankName: Yup.string().required("銀行名を入力してください。"),
    branchName: Yup.string().required("支店名を入力してください。"),
    bankNumber: Yup.string().required("口座番号を入力してください。"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoBanksGetResImple();
  btnDisable = false;
  isEditMode = true;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4Bank = [
    { label: "みずほ銀行", code: 1 },
    { label: "りそな銀行", code: 2 },
    { label: "三井住友銀行", code: 3 },
    { label: "三菱UFJ銀行", code: 4 },
  ];
  op4BankType = [
    { label: "当座預金", code: 1 },
    { label: "普通預金", code: 2 },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");

      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        this.delegate.c_bank_type = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoBanksGetReqImple();

      dto.c_bank_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_bank.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_bank_id == null) {
        this.$router.push("/Kouzalist");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoBanksPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_bank_id = this.delegate.c_bank_id;
        dto.c_bank_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;

        this.schemaUpd.validateSync({
          bankName: this.delegate.c_bank_name,
          branchName: this.delegate.c_bank_branch_name,
          bankNumber: this.delegate.c_bank_number,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoBanksPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_bank.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;

      this.schemaAdd.validateSync({
        bankName: this.delegate.c_bank_name,
        branchName: this.delegate.c_bank_branch_name,
        bankNumber: this.delegate.c_bank_number,
      });

      //準備
      //-------
      let dto = Object.assign(new DtoBanksPostReq(), this.delegate);
      dto.c_bank_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_bank.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/Kouzaedit",
        query: { id: res.c_bank_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_bank_id +
      "】" +
      this.delegate.c_bank_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
