
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import {
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
} from "mediadept-common/src/common/constant/Constants";
// masters
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
//残業時間
import { Cl_requestcostsacct } from "@/functions/Cl_requestcostsacct";
import { DtoRequestCostsacctsGetReqImple } from "mediadept-common/src/common/dto/DtoRequestCostsacctsGetReqImple";
import { DtoRequestCostsacctsGetResImple } from "mediadept-common/src/common/dto/DtoRequestCostsacctsGetResImple";
import { DtoRequestOvertimesPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestOvertimesPutReq";
import { DtoRequestOvertimesPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestOvertimesPutRes";
import { DtoRequestCostsacctsPostReqImple } from "mediadept-common/src/common/dto/DtoRequestCostsacctsPostReqImple";
import { DtoRequestOvertimesPostResImple } from "mediadept-common/src/common/dto/DtoRequestOvertimesPostResImple";
//申請ヘッダ
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsPutReqImple } from "mediadept-common/src/common/dto/DtoRequestsPutReqImple";
import { DtoRequestsPutResImple } from "mediadept-common/src/common/dto/DtoRequestsPutResImple";
//store
import store from "@/store";
import { CommonStaff } from "mediadept-common/src/common/service/CommonStaff";
// バリデーション
import * as Yup from "yup";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class shinsei_Keihikozatorokuedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    Name: Yup.string().required("口座名を入力してください"),
    bankName: Yup.string().required("銀行名を入力してください"),
    branch: Yup.string().required("支店名を入力してください"),
  });

  schemaAddBankType = Yup.object().shape({
    bankType: Yup.string().required("銀行種類を入力してください"),
  });

  schemaAddType = Yup.object().shape({
    type: Yup.string().required("種別を入力してください"),
  });

  schemaAddBankNumber = Yup.object().shape({
    bankNumber: Yup.number()
      .nullable()
      .required("口座番号を半角数字で入力してください")
      .typeError("口座番号を半角数字で入力してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoRequestCostsacctsGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isAdmin = false;
  isMine = false;
  isEditMode = true;

  domApplicantName = "";
  domContent = "";
  domType = "";
  domName = "";
  domBankType = "";

  //日付関係
  domDateZangyou: string | undefined = ""; //delegate.c_request_overtime_ot_date
  domDateSyukin: string | undefined = ""; //delegate.c_request_overtime_hol_date
  domDateDaikyuSyujitu: string | undefined = ""; //delegate.c_request_overtime_compday_all
  domDateDaikyuBubun: string | undefined = ""; //delegate.c_request_overtime_compday_part

  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConfEx = false;
  fmShowSasimodosi = false;
  fmShowTorisage = false;

  // optionsOp = [
  //   { label: "ユーザー1", code: 1 },
  //   { label: "ユーザー2", code: 2 },
  //   { label: "ユーザー3", code: 3 },
  // ];

  // optionsOp2 = [
  //   { label: "営業部", code: 1 },
  //   { label: "営業推進部", code: 2 },
  // ];

  optionsOp3 = [{ label: "新規登録", code: 1 }];

  optionsOp4 = [
    { label: "銀行", code: 1 },
    { label: "信金", code: 2 },
    { label: "信組", code: 3 },
    { label: "農協", code: 4 },
  ];

  //----------------------
  //DOM連携用メソッド
  //----------------------
  convDateTime(val: Date | null): string {
    let res = "";

    if (val == null) {
      res = "";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  /**
   * 承認者の順序を入力して、承認者名を得る
   * @param num
   */
  getAuthoriser(num: number): string {
    let res = "-";

    if (this.delegate4list.items4staffs == null) {
      return "-";
    }

    switch (num) {
      case 1:
        if (this.delegate.request.c_request_1_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_1_approval
          );
        }

        break;
      case 2:
        if (this.delegate.request.c_request_2_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_2_approval
          );
        }

        break;
      case 3:
        if (this.delegate.request.c_request_3_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_3_approval
          );
        }

        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 承認日時を受け取り、表示用の文字列にして返す
   * @param val
   */
  getAuthDate(authorizerId: number | null, val: Date | null): string {
    let res = "";

    if (authorizerId == null) {
      return "-";
    }

    if (val == null) {
      res = "未承認";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4BankType = [
    { label: "当座預金", code: 1 },
    { label: "普通預金", code: 2 },
  ];
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.request.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalDisableConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 1;
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      store.commit("revStore");
      const authType = store.state.authType;
      // Cl_common.logger(authType);
      this.isAdmin = authType == 4; //管理者判定

      if (id == "") {
        //新規追加
        this.isEditMode = false;

        //初期値設定
        this.delegate.request.c_staff_id = store.state.id;
        // this.delegate.c_request_overtime_type = 1;
        this.fmShowConf = false;
        this.fmShowConfEx = false;

        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoRequestCostsacctsGetReqImple();

      //※fanctions内のクラスの修正コスト軽減の為、c_request_overtime_idに入れているが、
      //  API側ではrequestIDとして扱われるので注意
      dto.c_request_costsacct_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_requestcostsacct.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_request_costsacct_id == null) {
        this.$router.push("/shinseisyolist");
      }

      //UI連携用変数に入力
      //---
      await this.cnvData4Get();

      //フラグ更新
      //---
      store.commit("revStore");
      const loginStaffId = store.state.id;
      this.isMine = loginStaffId == this.delegate.request.c_staff_id!; //申請者判定

      //承認関係の表記
      //---
      await this.chgAuthCont();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　GET用。
   */
  async cnvData4Get() {
    //申請者
    this.domApplicantName = CommonStaff.cnvStaffNum2Name(
      this.delegate4list.items4staffs,
      this.delegate.request.c_staff_id
    );

    //口座名義
    if (this.delegate.c_request_costsacct_bank_account_name != null) {
      this.domName = this.delegate.c_request_costsacct_bank_account_name;
    }
  }

  /**
   * 承認に関するコンテンツを制御する
   */
  async chgAuthCont() {
    let loginStaffId = -1;

    switch (this.delegate.request.c_request_status) {
      case CONST_REQUEST_STATUS_SINSEITYU:
        //申請中
        //-----------
        store.commit("revStore");
        loginStaffId = store.state.id;
        if (
          (loginStaffId == this.delegate.request.c_request_1_approval &&
            this.delegate.request.c_request_1_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_2_approval &&
            this.delegate.request.c_request_2_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_3_approval &&
            this.delegate.request.c_request_3_approval_datetime == null)
        ) {
          //自身が承認者で未承認
          //--------
          this.fmShowConf = true;
          this.fmShowConfEx = false;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        } else {
          //自身が承認者でない、もしくは承認済み
          //--------
          this.fmShowConf = false;
          this.fmShowConfEx = true;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        }

        break;
      case CONST_REQUEST_STATUS_SASIMODOSI:
        //差戻
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = true;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_SYOUNINZUMI:
        //承認済
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_TORISAGE:
        //取下
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = true;

        break;
      default:
        throw new Error("不正なstatus");
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isDisable: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_request_flag_del = 1;
      }
      if (isDisable) {
        //差戻処理：ステータス変更。
        dto.c_request_status = 4;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      // //準備
      // //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isApproval) {
        //承認
        dto.isApproval = 1;
      } else {
        //差戻
        dto.isApproval = 0;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd.validateSync({
        type: this.domType,
        Name: this.domName,
        bankName: this.delegate.c_request_costsacct_bank_name,
        bankType: this.domBankType,
        branch: this.delegate.c_request_costsacct_bank_branch_name,
        bankNumber: this.delegate.c_request_costsacct_bank_number,
      });

      this.schemaAddType.validateSync({
        type: this.delegate.c_request_costsacct_type,
      });
      this.schemaAddBankType.validateSync({
        bankType: this.delegate.c_request_costsacct_bank_type,
      });

      this.schemaAddBankNumber.validateSync({
        bankNumber: this.delegate.c_request_costsacct_bank_number,
      });

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---
      await this.cnvData4Add();

      let dto = Object.assign(
        new DtoRequestCostsacctsPostReqImple(),
        this.delegate
      );

      //API実行
      //-------
      const res = await Cl_requestcostsacct.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/shinsei_Keihikozatorokuedit",
        query: { id: res.c_request_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　ADD用。
   */
  async cnvData4Add() {
    //口座名義
    if (this.domName != null) {
      this.delegate.c_request_costsacct_bank_account_name = this.domName;
    }
  }

  /*
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "Keihikozatoroku";
      let id = Cl_common.getParam("id");
      dto.requestId = Number(id);
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);

      //レスポンス処理
      //-------
      location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * 取り下げ用のモーダルを表示
   */
  async disableConf() {
    //モーダルのメッセージを設定
    this.message4ModalDisableConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を取り下げます。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.showModal();
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }
  /**
   * データを取り下げる
   */
  async disab() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4disableConf();
    this.upd(false, true);
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4disableConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
