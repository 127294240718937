
import { defineComponent, ref } from "vue";
import { BarChart, ExtractComponentData } from "vue-chart-3";
import { Chart, ChartOptions, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
  name: "Home",
  components: { BarChart },
  setup() {
    const testData = {
      // labels: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"],
      // datasets: [
      //   {
      //     type: "bar",
      //     data: [10, 30, 50, 70, 90, 20, 25, 25, 80, 95, 10, 5],
      //     label: "人数",
      //     barPercentage: 0.5,
      //     yAxisID: "y-axis-1",
      //     order: 2,
      //   },
      //   {
      //     type: "line",
      //     data: [15, 35, 55, 70, 90, 20, 15, 15, 75, 85, 10, 5],
      //     label: "率",
      //     borderWidth: 1,
      //     fill: false,
      //     lineTension: 0,
      //     yAxisID: "y-axis-2",
      //     order: 1,
      //   },
      // ],
    };

    const bartRef = ref<ExtractComponentData<typeof BarChart>>();
    const options = ref<ChartOptions<"bar">>({
      // responsive: true,
      // plugins: {
      //   legend: {
      //     position: "top",
      //   },
      //   title: {
      //     display: false,
      //   },
      // },
      // scales: {
      //   "y-axis-1": {
      //     stacked: true,
      //   },
      //   "y-axis-2": {
      //     type: "linear",
      //     position: "right",
      //     title: {
      //       display: true,
      //       text: "率(％)",
      //     },
      //     ticks: {
      //       display: true,
      //     },
      //   },
      // },
    });

    return { testData, options, bartRef };
  },
});
