export class EntityRequestHoliday {
public c_request_holiday_id:number | null = null;
public c_request_id:number | null = null;
public c_request_holiday_start:Date | null = null;
public c_request_holiday_end:Date | null = null;
public c_request_holiday_part:Date | null = null;
public c_request_holiday_part_type:number | null = null;
public c_request_holiday_type:number | null = null;
public c_request_holiday_type_oth:string | null = null;
public c_request_holiday_cont:string | null = null;
public c_request_holiday_remarks:string | null = null;
public c_request_holiday_reg_user:string | null = null;
public c_request_holiday_reg_at:Date | null = null;
public c_request_holiday_upd_user:string | null = null;
public c_request_holiday_upd_at:Date | null = null;
}