import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";
import { DtoProjectsGetReqImple } from "mediadept-common/src/common/dto/DtoProjectsGetReqImple";
import { DtoProjectsGetResImple } from "mediadept-common/src/common/dto/DtoProjectsGetResImple";
import { EntitySalesImple } from "mediadept-common/src/common/entity/EntitySalesImple";
import { EntitySupplyImple } from "mediadept-common/src/common/entity/EntitySupplyImple";
import { EntityVariationImple } from "mediadept-common/src/common/entity/EntityVariationImple";
import { Cl_project } from "./Cl_project";

import { CommonProject } from "mediadept-common/src/common/service/CommonProject";
import { CommonDate4MD } from "mediadept-common/src/common/function/CommonDate4MD";
import { CommonPrice4MD } from "mediadept-common/src/common/function/CommonPrice4MD";
import { commonRegx } from "xcommon-node/src/common/commonRegx";

/**
 * 受注、売上、仕入に関する処理を担当する。
 */
export class Cl_projectImple extends Cl_project {
  /**
   * 売上明細の商品選択時の処理
   * @param entitySalesImple
   * @param evDatas4row
   */
  public static async setItemSales4init(
    entitySalesImple: EntitySalesImple,
    supplys: EntitySupplyImple[],
    evDatas4row: {
      selectVariation: EntityVariationImple;
      data: DtoItemsGetResImple;
      isSelected: boolean;
      detStartDate: string;
      detCount: number;
    }
  ): Promise<void> {
    //売上明細
    //--------------------
    //1:行No:自動入力
    //2:請求No:空白
    //3:発行日:空白
    //4:売上月:期間終了の日付の所属する年月。開始日と期間から導出。
    const salseDate = await CommonDate4MD.offsetDate4VariationTerm(
      new Date(evDatas4row.detStartDate),
      evDatas4row.selectVariation
    );

    let dateTmp = "";

    if (salseDate != null) {
      dateTmp = salseDate
        .toLocaleString("ja-JP", { year: "numeric", month: "2-digit" })
        .replaceAll("/", "-");
    } else {
      dateTmp = "";
    }

    if (dateTmp != "") {
      entitySalesImple.cnvDateMonth.date = dateTmp; //"2023-09";
    }

    //5:長短:商品マスタ連動
    entitySalesImple.c_sales_term = evDatas4row.data.c_item_term;

    //6:商品選択:選択した商品名
    entitySalesImple.c_variation_id =
      evDatas4row.selectVariation.c_variation_id;
    entitySalesImple.c_item_id = evDatas4row.selectVariation.c_item_id;
    entitySalesImple.c_variation_name =
      evDatas4row.selectVariation.c_variation_name;
    entitySalesImple.c_item_type = evDatas4row.data.c_item_type;
    entitySalesImple.c_item_name = evDatas4row.data.c_item_name;
    entitySalesImple.cnvItemName =
      evDatas4row.data.c_item_name +
      " " +
      evDatas4row.selectVariation.c_variation_name;

    //7:項目選択:選択した条件に設定されている項目
    if (
      evDatas4row.selectVariation.c_variation_type != null &&
      evDatas4row.selectVariation.c_variation_type != ""
    ) {
      // entitySalesImple.cnvItemType = await this.cnvStrArray2NumArray(
      //   evDatas4row.selectVariation.c_variation_type.split(",")
      // );
      entitySalesImple.c_sales_item =
        evDatas4row.selectVariation.c_variation_type;
    }

    //x:数量:バリエーション選択時に入力した数量を記載
    entitySalesImple.c_sales_count = evDatas4row.detCount;

    //8:期間開始:入力日当日
    entitySalesImple.cnvDateStart.date = evDatas4row.detStartDate;

    //9:期間終了:期間開始から、選択した条件に設定されている期間分ずらした日付
    if (salseDate != null) {
      entitySalesImple.cnvDateEnd.date = salseDate
        .toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", "-");
    } else {
      entitySalesImple.cnvDateEnd.date = "";
    }

    //10:金額:選択した条件に設定されている金額（税抜）の値
    entitySalesImple.cnvPrice = (
      await CommonPrice4MD.cnvVariationPrice(
        evDatas4row.detCount,
        evDatas4row.selectVariation
      )
    ).toString();

    //11:消費税:0.1
    entitySalesImple.c_sales_taxrate = 3;

    //12:入金予定日:空白
    //13:入金完了日:空白

    //14:備考:空白
    entitySalesImple.c_sales_remarks = "";

    //^^^^^^^^^^^^^^^^^^^
    // 売上明細連動の仕入明細入力処理
    //^^^^^^^^^^^^^^^^^^^

    await CommonProject.setItemSupply4init(supplys, evDatas4row, null, null);
  }

  /**
   * 売上明細の商品選択時の処理
   * @param entitySalesImple
   * @param evDatas4row
   */
  public static async setItemSales4upd(
    entitySalesImple: EntitySalesImple,
    evDatas4row: {
      selectVariation: EntityVariationImple;
      data: DtoItemsGetResImple;
      isSelected: boolean;
      detStartDate: string;
      detCount: number;
    }
  ): Promise<void> {
    // console.log("evDatas4row");
    // console.log(evDatas4row);

    entitySalesImple.c_variation_id =
      evDatas4row.selectVariation.c_variation_id;
    entitySalesImple.c_item_id = evDatas4row.selectVariation.c_item_id;
    entitySalesImple.c_variation_name =
      evDatas4row.selectVariation.c_variation_name;
    entitySalesImple.c_item_type = evDatas4row.data.c_item_type;
    entitySalesImple.c_item_name = evDatas4row.data.c_item_name;
    entitySalesImple.cnvItemName =
      evDatas4row.selectVariation.c_variation_name +
      " " +
      evDatas4row.data.c_item_name;
  }
  /**
   * 仕入明細の商品選択時の処理
   * @param entitySalesImple
   * @param evDatas4row
   */
  public static async setItemSupply4upd(
    entitySupplyImple: EntitySupplyImple,
    evDatas4row: {
      selectVariation: EntityVariationImple;
      data: DtoItemsGetResImple;
      isSelected: boolean;
      detStartDate: string;
      detCount: number;
    }
  ): Promise<void> {
    // console.log("row num");
    // console.log(entitySalesImple.c_sales_rownum);

    entitySupplyImple.c_variation_id =
      evDatas4row.selectVariation.c_variation_id;
    entitySupplyImple.c_item_id = evDatas4row.selectVariation.c_item_id;
    entitySupplyImple.c_variation_name =
      evDatas4row.selectVariation.c_variation_name;
    entitySupplyImple.c_item_type = evDatas4row.data.c_item_type;
    entitySupplyImple.c_item_name = evDatas4row.data.c_item_name;
    entitySupplyImple.cnvItemName =
      evDatas4row.data.c_item_name +
      " " +
      evDatas4row.selectVariation.c_variation_name;

    //保険申請依頼済みフラグ  保険申請フラグ1:必要;2:不要;
    if (evDatas4row.data.c_item_insurance == 2) {
      entitySupplyImple.c_supply_flag_insurance = 1; //保険申請フラグが2（不要）なら申請済みとする
    } else {
      entitySupplyImple.c_supply_flag_insurance = 0;
    }
  }

  //--------------
  /**
   * 整数かどうかをbool型で返す。
   * ※Number.isInteger()に置き換えないとダメかも・・
   * @param value
   * @returns
   */
  public static isInteger(value: any): boolean {
    return typeof value === "number" && value % 1 === 0;
  }
  //----------------------
  //共通libraryに移したい
  //----------------------
  /**
   * 文字列型の配列を受け取り、数値型の配列に変換して返す
   * @param strArray
   * @returns
   */
  public static async cnvStrArray2NumArray(
    strArray: string[]
  ): Promise<number[]> {
    const res: number[] = [];

    for (let i = 0; i < strArray.length; ++i) {
      res.push(Number(strArray[i]));
    }

    return res;
  }
}
