export class EntityRequestAddchg {
public c_request_addchg_id:number | null = null;
public c_request_id:number | null = null;
public c_request_addchg_type:number | null = null;
public c_request_addchg_type_oth:string | null = null;
public c_request_addchg_start:Date | null = null;
public c_request_addchg_cr_zip:string | null = null;
public c_request_addchg_cr_add:string | null = null;
public c_request_addchg_cr_tel:string | null = null;
public c_request_addchg_new_zip:string | null = null;
public c_request_addchg_new_add:string | null = null;
public c_request_addchg_new_tel:string | null = null;
public c_request_addchg_route_list:string | null = null;
public c_request_addchg_reg_user:string | null = null;
public c_request_addchg_reg_at:Date | null = null;
public c_request_addchg_upd_user:string | null = null;
public c_request_addchg_upd_at:Date | null = null;
}