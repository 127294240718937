export class EntityItem {
    public c_item_id: number | null = null;
    public c_item_type: number | null = null;
    public c_item_term: number | null = null;
    public c_item_insurance: number | null = null;
    public c_item_manag_name: string | null = null;
    public c_item_name: string | null = null;
    public c_item_name_pt: string | null = null;
    public c_item_owner: number | null = null;
    public c_item_area: string | null = null;
    public c_item_area_type: number | null = null;
    public c_item_address: string | null = null;
    public c_item_route: string | null = null;
    public c_item_note: string | null = null;
    public c_item_remarks: string | null = null;
    public c_item_url_photo: string | null = null;
    public c_item_info: string | null = null;
    public c_item_url_data_1: string | null = null;
    public c_item_url_data_2: string | null = null;
    public c_item_url_data_3: string | null = null;
    public c_item_flag_del: number | null = null;
    public c_item_reg_user: string | null = null;
    public c_item_reg_at: Date | null = null;
    public c_item_upd_user: string | null = null;
    public c_item_upd_at: Date | null = null;
}