import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";

import { DtoSendMailsPostReq } from "mediadept-common/src/common/dto/DtoSendMailsPostReq";
import { DtoSendMailsPostRes } from "mediadept-common/src/common/dto/DtoSendMailsPostRes";

export class Cl_sendMail {
  public static async post(
    dto: DtoSendMailsPostReq
  ): Promise<DtoSendMailsPostRes> {
    return Object.assign(
      new DtoSendMailsPostRes(),
      await CallAPI.postRequest("/sendmails", dto)
    );
  }
}
