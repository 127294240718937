import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoStaffsGetReqImple } from "mediadept-common/src/common/dto/DtoStaffsGetReqImple";
import { DtoStaffsGetResImple } from "mediadept-common/src/common/dto/DtoStaffsGetResImple";
import { DtoStaffsPostReq } from "mediadept-common/src/common/dto/abst/DtoStaffsPostReq";
import { DtoStaffsPostRes } from "mediadept-common/src/common/dto/abst/DtoStaffsPostRes";
import { DtoStaffsPutReq } from "mediadept-common/src/common/dto/abst/DtoStaffsPutReq";
import { DtoStaffsPutRes } from "mediadept-common/src/common/dto/abst/DtoStaffsPutRes";
import { DtoStaffsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoStaffsDeleteReq";
import { DtoStaffsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoStaffsDeleteRes";

export class Cl_staff {
  public static async getList(
    dto: DtoStaffsGetReqImple
  ): Promise<DtoStaffsGetResImple> {
    return Object.assign(
      new DtoStaffsGetResImple(),
      await CallAPI.getRequest(
        "/staffs?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoStaffsGetReqImple
  ): Promise<DtoStaffsGetResImple> {
    return Object.assign(
      new DtoStaffsGetResImple(),
      await CallAPI.getRequest("/staffs/" + dto.c_staff_id)
    );
  }

  public static async post(dto: DtoStaffsPostReq): Promise<DtoStaffsPostRes> {
    return Object.assign(
      new DtoStaffsPostRes(),
      await CallAPI.postRequest("/staffs", dto)
    );
  }

  public static async put(dto: DtoStaffsPutReq): Promise<DtoStaffsPutRes> {
    return Object.assign(
      new DtoStaffsPutRes(),
      await CallAPI.putRequest("/staffs/" + dto.c_staff_id, dto)
    );
  }

  public async del(dto: DtoStaffsDeleteReq): Promise<DtoStaffsDeleteRes> {
    return Object.assign(
      new DtoStaffsDeleteRes(),
      await CallAPI.deleteRequest("/staffs?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
}
