import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoHolidaysGetReqImple } from "mediadept-common/src/common/dto/DtoHolidaysGetReqImple";
import { DtoHolidaysGetResImple } from "mediadept-common/src/common/dto/DtoHolidaysGetResImple";
import { DtoHolidaysPostReq } from "mediadept-common/src/common/dto/abst/DtoHolidaysPostReq";
import { DtoHolidaysPostRes } from "mediadept-common/src/common/dto/abst/DtoHolidaysPostRes";
import { DtoHolidaysPutReq } from "mediadept-common/src/common/dto/abst/DtoHolidaysPutReq";
import { DtoHolidaysPutRes } from "mediadept-common/src/common/dto/abst/DtoHolidaysPutRes";
import { DtoHolidaysDeleteReq } from "mediadept-common/src/common/dto/abst/DtoHolidaysDeleteReq";
import { DtoHolidaysDeleteRes } from "mediadept-common/src/common/dto/abst/DtoHolidaysDeleteRes";

export class Cl_holiday {
  public static async getList(
    dto: DtoHolidaysGetReqImple
  ): Promise<DtoHolidaysGetResImple> {
    return Object.assign(
      new DtoHolidaysGetResImple(),
      await CallAPI.getRequest(
        "/holidays?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoHolidaysGetReqImple
  ): Promise<DtoHolidaysGetResImple> {
    return Object.assign(
      new DtoHolidaysGetResImple(),
      await CallAPI.getRequest("/holidays/" + dto.c_holiday_id)
    );
  }

  public static async post(
    dto: DtoHolidaysPostReq
  ): Promise<DtoHolidaysPostRes> {
    return Object.assign(
      new DtoHolidaysPostRes(),
      await CallAPI.postRequest("/holidays", dto)
    );
  }

  public static async put(dto: DtoHolidaysPutReq): Promise<DtoHolidaysPutRes> {
    return Object.assign(
      new DtoHolidaysPutRes(),
      await CallAPI.putRequest("/holidays/" + dto.c_holiday_id, dto)
    );
  }

  public static async del(
    dto: DtoHolidaysDeleteReq
  ): Promise<DtoHolidaysDeleteRes> {
    return Object.assign(
      new DtoHolidaysDeleteRes(),
      await CallAPI.deleteRequest("/holidays/" + dto.c_holiday_id)
    );
  }
}
