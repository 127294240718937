import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9b2f036"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cleave = _resolveComponent("cleave")!

  return (_ctx.nolimit)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_cleave, {
          ref: "cleaveRef",
          modelValue: _ctx.contTime.contTimeValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contTime.contTimeValue) = $event)),
          options: {
        blocks: [2, 2],
        delimiter: ':',
        numericOnly: true,
      },
          disabled: _ctx.isDisable,
          class: "form-control form-control-sm sinseisyo"
        }, null, 8, ["modelValue", "disabled"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_cleave, {
          ref: "cleaveRef",
          modelValue: _ctx.contTime.contTimeValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contTime.contTimeValue) = $event)),
          options: {
        time: true,
        timePattern: ['h', 'm'],
        timeFormat: '24',
      },
          disabled: _ctx.isDisable,
          class: "form-control form-control-sm sinseisyo"
        }, null, 8, ["modelValue", "disabled"])
      ]))
}