export class EntityClaimDet {
public c_claim_det_id:number | null = null;
public c_claim_id:number | null = null;
public c_claim_rownum:number | null = null;
public c_claim_det_item:string | null = null;
public c_claim_det_quantity:string | null = null;
public c_claim_det_unitprice:string | null = null;
public c_claim_det_price:string | null = null;
public c_claim_det_taxrate:number | null = null;
public c_claim_det_price_sum:string | null = null;
public c_claim_rounding:number | null = null;
public c_claim_det_reg_user:string | null = null;
public c_claim_det_reg_at:Date | null = null;
public c_claim_det_upd_user:string | null = null;
public c_claim_det_upd_at:Date | null = null;
}