import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoSearchProjectGetReq } from "mediadept-common/src/common/dto/DtoSearchProjectGetReq";
import { DtoSearchProjectGetRes } from "mediadept-common/src/common/dto/DtoSearchProjectGetRes";

export class Cl_searchProject {
  public static async getList(
    dto: DtoSearchProjectGetReq
  ): Promise<DtoSearchProjectGetRes> {
    return Object.assign(
      new DtoSearchProjectGetRes(),
      await CallAPI.getRequest(
        "/searchproject?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
