
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";

import Mo_chart_doughnut from "@/components/molecules/Mo_chart_doughnut.vue";
import Mo_chart_barline from "@/components/molecules/Mo_chart_barline.vue";
import { ChartOptions } from "chart.js";
import { ref } from "vue";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_chart_doughnut,
    Mo_chart_barline,
  },
})
export default class Analyprofit extends Vue {
  optionsOp = [
    {
      label: "メディアサービス",
    },
    {
      label: "Officeroom",
    },
    {
      label: "フラッグ",
    },
    {
      label: "クラフトワールドワイド",
    },
    {
      label: "ジーニー",
    },
    {
      label: "東映エージエンシー ",
    },
    {
      label: "サニーサイドアップ",
    },
    {
      label: "日本中央競馬会",
    },
    {
      label: "TATRAS INTERNATIONAL",
    },
    {
      label: "Nomad Tokyo合同会社",
    },
    {
      label: "シグナル",
    },
    {
      label: "ゴラク",
    },
  ];
  optionsOp2 = [
    {
      label: "田中真哉",
    },
    {
      label: "國部卓爾",
    },
    {
      label: "江島",
    },
    {
      label: "務",
    },
    {
      label: "神野秀二郎",
    },
    {
      label: "石川幸",
    },
    {
      label: "大竹",
    },
    {
      label: "辻本",
    },
    {
      label: "光田圭吾",
    },
    {
      label: "小針有貴",
    },
    {
      label: "高橋三千夫",
    },
    {
      label: "岩崎",
    },
    {
      label: "山本晋治",
    },
    {
      label: "岩崎",
    },
    {
      label: "村瀬智史",
    },
    {
      label: "折原慎吾",
    },
    {
      label: "藤井伽奈",
    },
    {
      label: "山本り央",
    },
    {
      label: "北島麻琉",
    },
    {
      label: "伊澤　暁",
    },
    {
      label: "高橋ほたる",
    },
    {
      label: "川嶋　康照",
    },
    {
      label: "小泉　圭祐",
    },
    {
      label: "長塚　未来",
    },
    {
      label: "テスト",
    },
  ];
  optionsOp3 = [
    {
      label: "代理店",
    },
    {
      label: "直クライアント",
    },
    {
      label: "その他",
    },
  ];
  optionsOp4 = [
    { label: "広告" },
    { label: "製造/製作" },
    { label: "印刷" },
    { label: "イベント" },
    { label: "通信/放送サービス" },
    { label: "ファッション" },
    { label: "美容" },
    { label: "化粧品" },
    { label: "端末" },
    { label: "家電" },
    { label: "自動車/バイク" },
    { label: "音楽" },
    { label: "スポーツ" },
    { label: "旅行" },
    { label: "ゲーム" },
    { label: "書籍" },
    { label: "映画" },
    { label: "食品" },
    { label: "飲料" },
    { label: "外食" },
    { label: "運輸・観光" },
    { label: "教育" },
    { label: "医療/医薬" },
    { label: "証券" },
    { label: "不動産" },
    { label: "金融/保険" },
    { label: "消費者金融" },
    { label: "官庁" },
    { label: "政治/宗教" },
    { label: "マッチング" },
    { label: "ギャンブル" },
    { label: "その他" },
  ];
  optionsOp5 = [
    { label: "ビルボード" },
    { label: "ポスタージャック" },
    { label: "商業施設" },
    { label: "デジタルビルボード" },
    { label: "交通" },
    { label: "その他メディア" },
    { label: "その他" },
  ];

  columns = [
    {
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      label: "案件番号",
      field: "ANKEN_NO",
    },
    {
      label: "案件名",
      field: "ANKEN_NM",
    },
    {
      label: "請求先",
      field: "KOUKOKU_NM",
    },
    {
      label: "広告主",
      field: "KOUKOKU_NM",
    },
    {
      label: "担当者",
      field: "USER_NM",
    },
  ];
  rows = [
    {
      ANKEN_NO: 1393,
      ANKEN_NM: "Crew Bus 6台 撤去作業費",
      KOUKOKU_NM: "デルタ航空",
      USER_NM: "大安",
    },
    {
      ANKEN_NO: 1562,
      ANKEN_NM: "表参道ビル",
      KOUKOKU_NM: "ADORE",
      USER_NM: "石原岳人",
    },
    {
      ANKEN_NO: 1610,
      ANKEN_NM: "【ハウスドゥ様】錦町MKビル 年間掲出料 2014",
      KOUKOKU_NM: "ハウスドゥ",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1681,
      ANKEN_NM: "ＴＤＲマジカルファンタジー号/JALPAK50周年ラッピングバス",
      KOUKOKU_NM: "JALPAK",
      USER_NM: "佐藤和博",
    },
    {
      ANKEN_NO: 1710,
      ANKEN_NM: "表参道Nビル",
      KOUKOKU_NM: "COACH",
      USER_NM: "石原岳人",
    },
    {
      ANKEN_NO: 1772,
      ANKEN_NM: "【BEN&JERRY’S】倉庫保管料金 2015",
      KOUKOKU_NM: "BEN&JERRY’S",
      USER_NM: "大安",
    },
    {
      ANKEN_NO: 1774,
      ANKEN_NM: "【BEN&JERRY’S】スクープショップ プロモーション 2015",
      KOUKOKU_NM: "BEN&JERRY’S",
      USER_NM: "大安",
    },
    {
      ANKEN_NO: 1776,
      ANKEN_NM: "【BEN&JERRY’S】カウカー2号車（シボレー P30 GRUMMAN）",
      KOUKOKU_NM: "BEN&JERRY’S",
      USER_NM: "大安",
    },
    {
      ANKEN_NO: 1782,
      ANKEN_NM: "清水ビル　キッズプロモーション　2015",
      KOUKOKU_NM: "PRADA",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1785,
      ANKEN_NM: "ＴＡＫＡＴＡ様　ビルボード　エクセル天王",
      KOUKOKU_NM: "TAKATA",
      USER_NM: "石原岳人",
    },
    {
      ANKEN_NO: 1787,
      ANKEN_NM: "【グロップ】大型ビジョン放映料",
      KOUKOKU_NM: "グロップ",
      USER_NM: "葛谷健一",
    },
    {
      ANKEN_NO: 1788,
      ANKEN_NM: "錦町MKビル　年間掲出料　2015",
      KOUKOKU_NM: "ハウスドゥ",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1789,
      ANKEN_NM: "【Mercedes-Benz Connection】葵ビル 年間掲出料 2015",
      KOUKOKU_NM: "メルセデス",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1790,
      ANKEN_NM: "ライオンズマンション小川恵比寿",
      KOUKOKU_NM: "住友不動産",
      USER_NM: "石原岳人",
    },
    {
      ANKEN_NO: 1791,
      ANKEN_NM: "【リクルート・SUMO】大型ビジョン放映料",
      KOUKOKU_NM: "リクルート",
      USER_NM: "葛谷健一",
    },
    {
      ANKEN_NO: 1792,
      ANKEN_NM: "【エースコック】大型ビジョン放映料",
      KOUKOKU_NM: "エースコック",
      USER_NM: "葛谷健一",
    },
    {
      ANKEN_NO: 1793,
      ANKEN_NM: "GIN BOARD 2015(個別仕入情報:堂島大山ビル)",
      KOUKOKU_NM: "Apple",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1794,
      ANKEN_NM: "GIN BOARD 2015(高井ビル:9年目)",
      KOUKOKU_NM: "Apple",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1795,
      ANKEN_NM: "表参道ヴァンテベールビル壁面　意匠変更費",
      KOUKOKU_NM: "タグホイヤー",
      USER_NM: "石原岳人",
    },
    {
      ANKEN_NO: 1796,
      ANKEN_NM: "APPLEボード東阪名（長期）①媒体料（はる木ビル）2015",
      KOUKOKU_NM: "Apple",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1797,
      ANKEN_NM: "APPLEボード東阪名（長期）①媒体料（藤田万年堂） 2015",
      KOUKOKU_NM: "Apple",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1798,
      ANKEN_NM: "【大型ビジョン放映料】JYJプロモーション",
      KOUKOKU_NM: "JYJプロモーション",
      USER_NM: "葛谷健一",
    },
    {
      ANKEN_NO: 1799,
      ANKEN_NM: "陽光セントラルビル　年間掲出料",
      KOUKOKU_NM: "バカルディジャパン",
      USER_NM: "石原岳人",
    },
    {
      ANKEN_NO: 1802,
      ANKEN_NM: "SPP1～4個別　2014",
      KOUKOKU_NM: "コンバース",
      USER_NM: "成澤壮一郎",
    },
    {
      ANKEN_NO: 1803,
      ANKEN_NM: "APPLEボード東阪名（長期）防犯システム 2014-2015",
      KOUKOKU_NM: "Apple",
      USER_NM: "田中真哉",
    },
    {
      ANKEN_NO: 1804,
      ANKEN_NM: "【DMM】秋葉原・高和ビル/外神田YSビル 掲出料 2015",
      KOUKOKU_NM: "DMM",
      USER_NM: "田中真哉",
    },
  ];
  totalRecords = 26;

  //-------------------------------------------
  //円グラフのデータ。
  chartData4d1 = {
    labels: [
      "ビルボード",
      "ポスタージャック",
      "商業施設",
      "デジタルビルボード",
      "交通",
      "その他メディア",
      "その他",
    ],
    datasets: [
      {
        data: [30, 20, 15, 10, 5, 15, 5],
        backgroundColor: [
          "#77CEFF",
          "#0079AF",
          "#123E6B",
          "#97B0C4",
          "#A5C8ED",
        ],
      },
    ],
  };
  //円グラフのデータ2。
  chartData4d3 = {
    labels: [
      "広告",
      "製造/製作",
      "印刷",
      "イベント",
      "通信/放送サービス",
      "ファッション",
      "美容",
      "化粧品",
      "端末",
      "家電",
      "自動車/バイク",
      "音楽",
      "スポーツ",
      "旅行",
      "ゲーム",
      "書籍",
      "映画",
      "食品",
      "飲料",
      "運輸・観光",
      "教育",
      "外食",
      "医療/医薬",
      "証券",
      "金融/保険",
      "不動産",
      "消費者金融",
      "官庁",
      "政治/宗教",
      "マッチング",
      "ギャンブル",
      "その他",
    ],
    datasets: [
      {
        data: [
          3, 2, 4, 5, 6, 4, 2, 3, 4, 4, 2, 2, 2, 3, 4, 4, 5, 2, 1, 2, 2, 2, 2,
          3, 4, 5, 4, 5, 3, 3, 2, 1,
        ],
        backgroundColor: [
          "#77CEFF",
          "#0079AF",
          "#123E6B",
          "#97B0C4",
          "#A5C8ED",
        ],
      },
    ],
  };

  //円グラフのオプション。
  chartOptions4d1 = ref<ChartOptions<"doughnut">>({
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  });

  reRender = true;
  //折れ線＋棒グラフのデータ。
  chartData4d2 = {
    labels: [
      "2023/1",
      "2023/2",
      "2023/3",
      "2023/4",
      "2023/5",
      "2023/6",
      "2023/7",
      "2023/8",
      "2023/9",
      "2023/10",
      "2023/11",
      "2023/12",
    ],
    datasets: [
      // {
      //   type: "bar",
      //   data: [10, 30, 50, 70, 90, 20, 25, 25, 80, 95, 10, 5],
      //   label: "当期売上【商業施設】",
      //   barPercentage: 0.5,
      //   yAxisID: "y-axis-1",
      //   order: 3,
      //   borderWidth: 1,
      //   backgroundColor: "#77CEFF",
      //   borderColor: "#77CEFF",
      // },
      // {
      //   type: "bar",
      //   data: [10, 30, 50, 70, 90, 20, 25, 25, 80, 95, 10, 5],
      //   label: "当期売上【ポスタージャック】",
      //   barPercentage: 0.5,
      //   yAxisID: "y-axis-1",
      //   order: 4,
      //   borderWidth: 1,
      //   backgroundColor: "#0079AF",
      //   borderColor: "#0079AF",
      // },
      // {
      //   type: "bar",
      //   data: [100, 30, 50, 70, 90, 20, 25, 25, 80, 95, 10, 5],
      //   label: "当期売上【ビルボード】",
      //   barPercentage: 0.5,
      //   yAxisID: "y-axis-1",
      //   order: 5,
      //   borderWidth: 1,
      //   backgroundColor: "#123E6B",
      //   borderColor: "#123E6B",
      // },
      // {
      //   type: "bar",
      //   data: [100, 30, 50, 70, 90, 20, 25, 25, 80, 95, 10, 5],
      //   label: "当期売上【デジタルビルボード】",
      //   barPercentage: 0.5,
      //   yAxisID: "y-axis-1",
      //   order: 6,
      //   borderWidth: 1,
      //   backgroundColor: "#97B0C4",
      //   borderColor: "#97B0C4",
      // },
      {
        type: "bar",
        data: [
          17000000, 33000000, 50000000, 75000000, 100000000, 125000000,
          150000000, 175000000, 200000000, 225000000, 250000000, 275000000,
        ],
        label: "当期月別売上",
        barPercentage: 0.5,
        yAxisID: "y-axis-1",
        order: 3,
        borderWidth: 1,
        backgroundColor: "#77CEFF",
        borderColor: "#77CEFF",
      },
      {
        type: "bar",
        data: [
          16000000, 23000000, 30000000, 46000000, 61000000, 76000000, 92000000,
          107000000, 122000000, 138000000, 153000000, 168000000,
        ],
        label: "前期月別売上",
        barPercentage: 0.5,
        yAxisID: "y-axis-1",
        order: 4,
        borderWidth: 1,
        backgroundColor: "#0079AF",
        borderColor: "#0079AF",
      },
      {
        type: "bar",
        data: [
          16000000, 14000000, 11000000, 17000000, 22000000, 28000000, 34000000,
          39000000, 45000000, 51000000, 56000000, 62000000,
        ],
        label: "前前期月別売上",
        barPercentage: 0.5,
        yAxisID: "y-axis-1",
        order: 5,
        borderWidth: 1,
        backgroundColor: "#0079AF",
        borderColor: "#0079AF",
      },
      {
        type: "line",
        data: [
          2400000000, 2400000000, 2400000000, 2400000000, 2400000000,
          2400000000, 2400000000, 2400000000, 2400000000, 2400000000,
          2400000000, 2400000000,
        ],
        label: "当期売上目標",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 1,
        backgroundColor: "#Be2d2d",
        borderColor: "#Be2d2d",
      },
      {
        type: "line",
        data: [
          200000000, 400000000, 600000000, 900000000, 1200000000, 1500000000,
          1800000000, 2100000000, 2400000000, 2700000000, 3000000000,
          3300000000,
        ],
        label: "当期累計売上実績",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 1,
        backgroundColor: "rgba(26,179,148,0.5)",
        borderColor: "rgba(26,179,148,0.7)",
      },
      {
        type: "line",
        data: [
          197000000, 281000000, 365000000, 549000000, 733000000, 917000000,
          1101000000, 1285000000, 1469000000, 1653000000, 1837000000,
          2021000000,
        ],
        label: "前期累計売上実績",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#8297b3",
        borderColor: "#8297b3",
      },
      {
        type: "line",
        data: [
          194000000, 162000000, 130000000, 198000000, 266000000, 334000000,
          402000000, 470000000, 538000000, 606000000, 674000000, 742000000,
        ],
        label: "前前期累計売上実績",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
    ],
  };
  //折れ線＋棒グラフのデータ2。
  chartData4d4 = {
    labels: [
      "2023/1",
      "2023/2",
      "2023/3",
      "2023/4",
      "2023/5",
      "2023/6",
      "2023/7",
      "2023/8",
      "2023/9",
      "2023/10",
      "2023/11",
      "2023/12",
    ],
    datasets: [
      {
        type: "line",
        data: [
          203000000, 519000000, 835000000, 1251000000, 1667000000, 2083000000,
          2499000000, 2915000000, 3331000000, 3747000000, 4163000000,
          4579000000,
        ],
        label: "ビルボード",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 1,
        backgroundColor: "rgba(26,179,148,0.5)",
        borderColor: "rgba(26,179,148,0.7)",
      },
      {
        type: "line",
        data: [
          200000000, 400000000, 600000000, 900000000, 1200000000, 1500000000,
          1800000000, 2100000000, 2400000000, 2700000000, 3000000000,
          3300000000,
        ],
        label: "ポスタージャック",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 1,
        backgroundColor: "rgba(26,179,148,0.5)",
        borderColor: "rgba(26,179,148,0.7)",
      },
      {
        type: "line",
        data: [
          197000000, 281000000, 365000000, 549000000, 733000000, 917000000,
          1101000000, 1285000000, 1469000000, 1653000000, 1837000000,
          2021000000,
        ],
        label: "商業施設",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#8297b3",
        borderColor: "#8297b3",
      },
      {
        type: "line",
        data: [
          194000000, 162000000, 130000000, 198000000, 266000000, 334000000,
          402000000, 470000000, 538000000, 606000000, 674000000, 742000000,
        ],
        label: "デジタルビルボード",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
      {
        type: "line",
        data: [
          212000000, 876000000, 1540000000, 2304000000, 3068000000, 3832000000,
          4596000000, 5360000000, 6124000000, 6888000000, 7652000000,
          8416000000,
        ],
        label: "交通",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
      {
        type: "line",
        data: [
          209000000, 757000000, 1305000000, 1953000000, 2601000000, 3249000000,
          3897000000, 4545000000, 5193000000, 5841000000, 6489000000,
          7137000000,
        ],
        label: "その他メディア",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
      {
        type: "line",
        data: [
          206000000, 638000000, 1070000000, 1602000000, 2134000000, 2666000000,
          3198000000, 3730000000, 4262000000, 4794000000, 5326000000,
          5858000000,
        ],
        label: "その他",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        yAxisID: "y-axis-2",
        order: 2,
        backgroundColor: "#84a7d8",
        borderColor: "#84a7d8",
      },
    ],
  };
  //折れ線＋棒グラフのオプション。
  chartOptions4d2 = ref<ChartOptions<"bar">>({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        // stacked: true,
      },
      "y-axis-1": {
        // stacked: true,
        title: {
          display: true,
          text: "月別売上額(円)",
        },
      },
      "y-axis-2": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "累積売上額(円)",
        },
        ticks: {
          display: true,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  });
  //折れ線＋棒グラフのオプション2。
  chartOptions4d4 = ref<ChartOptions<"bar">>({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        // stacked: true,
      },
    },
  });
}
