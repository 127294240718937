export class EntityRequestCostsacct {
public c_request_costsacct_id:number | null = null;
public c_request_id:number | null = null;
public c_request_costsacct_type:number | null = null;
public c_request_costsacct_flag_rwdacct_match:number | null = null;
public c_request_costsacct_bank_name:string | null = null;
public c_request_costsacct_bank_type:number | null = null;
public c_request_costsacct_bank_branch_name:string | null = null;
public c_request_costsacct_bank_number:string | null = null;
public c_request_costsacct_bank_account_name:string | null = null;
public c_request_costsacct_reg_user:string | null = null;
public c_request_costsacct_reg_at:Date | null = null;
public c_request_costsacct_upd_user:string | null = null;
public c_request_costsacct_upd_at:Date | null = null;
}