import { CONST_BREAKDOWNS, CONST_ITEM, CONST_SUBJECTS } from "../constant/Constants";
import { EntitySearchClaimNo } from "../entity/EntitySearchClaimNo";
import { EntityProject } from "../entity/EntityProject";
import { DtoApiPager } from "./abst/DtoApiPager";

export class DtoSearchClaimNoGetRes extends EntitySearchClaimNo {
    public items: EntitySearchClaimNo[] = [];
    public pager = new DtoApiPager();
    public exitems: Exitem[] = [];


    public async setEximtes(clientmap: Map<number, string>, projectData: EntityProject[]) {

        let tmp: Exitem;
        for (let index = 0; index < this.items.length; index++) {
            tmp = new Exitem();

            tmp.c_claim_id = this.items[index].c_claim_id;
            tmp.c_project_id = this.items[index].c_project_id;
            tmp.c_claim_number = this.items[index].c_claim_number;
            tmp.c_claim_datetime = this.items[index].c_claim_datetime;
            tmp.c_client_id = this.items[index].c_client_id;
            
            tmp.c_sales_item = this.items[index].c_sales_item;
            tmp.c_sales_subject = this.items[index].c_sales_subject;
            tmp.c_sales_breakdown = this.items[index].c_sales_breakdown;
            tmp.c_sales_price = this.items[index].c_sales_price;

            tmp.c_project_number = projectData[0].c_project_number
            tmp.c_project_name = projectData[0].c_project_name;
            tmp.c_client_name = clientmap.get(this.items[index].c_client_id!)!;

            if (tmp.c_sales_item != null) {
                tmp.c_sales_item_name = CONST_ITEM[tmp.c_sales_item];
            } else {
                tmp.c_sales_item_name = "";
            }

            if (tmp.c_sales_subject != null) {
                tmp.c_sales_subject_name = CONST_SUBJECTS[tmp.c_sales_subject];
            } else {
                tmp.c_sales_subject_name = "";
            }

            if (tmp.c_sales_breakdown != null) {
                tmp.c_sales_breakdown_name = CONST_BREAKDOWNS[tmp.c_sales_breakdown];
            } else {
                tmp.c_sales_breakdown_name = "";
            }


            this.exitems.push(tmp);

        }

    }


}

export class Exitem extends EntitySearchClaimNo {
    public c_project_number: number | null = null;
    public c_project_name: string | null = null;
    public c_client_name: string | null = null;
    public c_sales_item_name: string | null = null;
    public c_sales_subject_name: string | null = null;
    public c_sales_breakdown_name: string | null = null;

}