
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoSearchSupplysGetRes } from "mediadept-common/src/common/dto/DtoSearchSupplysGetRes";
import { DtoSearchSupplysGetReq } from "mediadept-common/src/common/dto/DtoSearchSupplysGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_searchSupplys } from "@/functions/Cl_searchSupplys";
import {
  CONST_OP_SUPPLY_TYPE,
  CONST_OP_ITEMTYPE,
  CONST_CLAIM_ROUDING_SISYAGONYU,
  CONST_OP_CHKCLAIM,
  CONST_OP_PROJECT_TYPE,
  CONST_OP_SUBJECT,
} from "mediadept-common/src/common/constant/Constants";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";

import Or_selectClient from "@/components/organisms/Or_selectClient.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";

import Or_selectSupplier from "@/components/organisms/Or_selectSupplier.vue";
import { EntitySupplier } from "mediadept-common/src/common/entity/EntitySupplier";
import { Watch } from "vue-property-decorator/lib/decorators/Watch";

import { CommonPrice4MD } from "mediadept-common/src/common/function/CommonPrice4MD";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
    Mt_dtp4month,
    Or_selectClient,
    Or_selectSupplier,
  },
})
export default class Shiirelist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSearchSupplysGetRes();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();
  delegateSupplier = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;
  //検索条件dom
  domProjectNo_start = "";
  domProjectNo_end = "";
  domProjectName = "";
  domProClientid: number | null = null;
  domProjectSponsorName = "";
  domProStaffId = "";
  domProjectType = "";
  domClaimNo_start = "";
  domClaimNo_end = "";
  domSupplyMonth_start = new DtoMdDtp();
  domSupplyMonth_end = new DtoMdDtp();
  domSupplyItem = "";
  domSupplySubject = "";
  domSupplyBreakdown = "";
  domSupplyRemarks = "";
  domSupplierid: number | null = null;
  domSupplyDateExpPayment_start = new DtoMdDtp();
  domSupplyDateExpPayment_end = new DtoMdDtp();
  domSalesRemarks = "";

  domVariationType = "";
  domItemType = "";
  domChkClaim = "";
  domSubject = "";

  // strVariationType = [
  //   "",
  //   "媒体・製作施工",
  //   "媒体・施工",
  //   "製作施工",
  //   "媒体費",
  //   "施工費",
  //   "取付費",
  //   "撤去費",
  //   "撤去（前受）",
  //   "プリント",
  //   "製作費",
  //   "工事（資産）",
  //   "電気代",
  //   "申請費",
  //   "その他",
  // ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  opSupplyType = CONST_OP_SUPPLY_TYPE;
  opItemType = CONST_OP_ITEMTYPE;
  opChkClaim = CONST_OP_CHKCLAIM;
  opSubject = CONST_OP_SUBJECT;

  // optionsOp3 = [
  //   { label: "新規", code: 1 },
  //   { label: "継続", code: 2 },
  // ];
  // optionsOp4 = [
  //   { label: "長期看板", code: 1 },
  //   { label: "短期看板", code: 2 },
  //   { label: "リムジンバス", code: 3 },
  //   { label: "都営交通", code: 4 },
  //   { label: "交通広告", code: 5 },
  //   { label: "ビジョン", code: 6 },
  //   { label: "経費", code: 7 },
  //   { label: "その他", code: 8 },
  // ];
  // optionsOp5 = [
  //   { label: "媒体料", code: 1 },
  //   { label: "プリント", code: 2 },
  //   { label: "施工", code: 3 },
  //   { label: "製作施工", code: 4 },
  //   { label: "工事(資産)", code: 5 },
  //   { label: "保険・申請", code: 6 },
  //   { label: "撤去前受", code: 7 },
  //   { label: "その他", code: 8 },
  // ];

  optionsOp7 = CONST_OP_PROJECT_TYPE.slice(1);

  columns = [
    {
      label: "受注",
      field: "btnDet",
    },
    {
      label: "請求",
      field: "btnDetClaim",
    },
    {
      label: "受注番号",
      field: "c_project_number",
    },
    {
      label: "請求No",
      field: "c_claim_number",
    },
    {
      label: "案件名",
      field: "c_project_name",
    },
    {
      label: "請求先",
      field: "pro_c_client_name",
    },
    {
      label: "広告主",
      field: "c_project_sponsor_name",
    },
    {
      label: "計上月",
      field: "c_supply_month",
      type: "date",
      dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      dateOutputFormat: "yyyy/MM",
    },
    {
      label: "担当者",
      field: "c_staff_name",
    },
    {
      label: "商品名",
      field: "c_item_name",
    },
    {
      label: "バリエーション名",
      field: "c_variation_name",
    },
    {
      label: "新継",
      field: "c_supply_subject",
    },
    {
      label: "項目",
      field: "c_supply_item",
    },
    // {
    //   label: "項目",
    //   field: "c_supply_item_name",
    // },
    // {
    //   label: "科目",
    //   field: "c_supply_subject_name",
    // },
    // {
    //   label: "内訳",
    //   field: "c_supply_breakdown_name",
    // },
    {
      label: "仕入先",
      field: "c_supplier_name",
    },
    {
      label: "入力金額",
      field: "c_supply_price",
    },
    {
      label: "税率",
      field: "c_supply_taxrate_name",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "税抜金額",
      field: "zeinuki",
    },
    {
      label: "税込金額",
      field: "c_supply_taxrate",
    },
    {
      label: "請求確認",
      field: "c_supply_flag_chkclaim",
    },
    {
      label: "支払予定日",
      field: "c_supply_date_exp_payment",
      type: "date",
      dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      dateOutputFormat: "yyyy/MM/dd",
    },
  ];

  //請求先選択用
  evDatas: {
    selectedItem: EntityClient | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  //TODO:#3931
  //仕入先選択用
  evDatas4Supplier: {
    selectedItem: EntitySupplier | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    // await this.getlist(1);
    this.getStaffList();
    this.getClientList();
    this.getSupplierList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSearchSupplysGetReq();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;
      Cl_common.logger("pager");
      Cl_common.logger(dto.pager.count);
      Cl_common.logger(dto.pager.cursor);

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProStaffId != "" && this.domProStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domProStaffId;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.supplyMonth_start = this.domSupplyMonth_start;
      if (this.domSupplyMonth_start.date != null) {
        dto.supplyMonth_start = new Date(this.domSupplyMonth_start.date);
      }

      // dto.supplyMonth_end = this.domSupplyMonth_end;
      if (this.domSupplyMonth_end.date != null) {
        dto.supplyMonth_end = new Date(this.domSupplyMonth_end.date);
      }

      if (this.domSupplyItem != "" && this.domSupplyItem != null)
        dto.supplyItem = Number(this.domSupplyItem);

      if (this.domSupplySubject != "" && this.domSupplySubject != null)
        dto.supplySubject = Number(this.domSupplySubject);

      if (this.domSupplyBreakdown != "" && this.domSupplyBreakdown != null)
        dto.supplyBreakdown = Number(this.domSupplyBreakdown);

      if (this.domSupplierid != null)
        dto.supplierid = Number(this.domSupplierid);

      if (this.domProjectType != "" && this.domProjectType != null)
        dto.projectType = Number(this.domProjectType);

      dto.supplyRemarks = this.domSupplyRemarks;

      // dto.supplyDateExpPayment_start = this.domSupplyDateExpPayment_start;
      if (this.domSupplyDateExpPayment_start.date != null) {
        dto.supplyDateExpPayment_start = new Date(
          this.domSupplyDateExpPayment_start.date
        );
      }

      // dto.supplyDateExpPayment_end = this.domSupplyDateExpPayment_end;
      if (this.domSupplyDateExpPayment_end.date != null) {
        dto.supplyDateExpPayment_end = new Date(
          this.domSupplyDateExpPayment_end.date
        );
      }

      if (this.domVariationType != "" && this.domVariationType != null)
        dto.VariationType = Number(this.domVariationType);

      if (this.domItemType != "" && this.domItemType != null)
        dto.ItemType = Number(this.domItemType);

      if (
        // this.domChkClaim != "" &&
        // this.domChkClaim != null &&
        this.domChkClaim == "1" ||
        this.domChkClaim == "0"
      ) {
        dto.chkClaim = Number(this.domChkClaim);
      }
      if (this.domSubject != "" && this.domSubject != null) {
        dto.subject = Number(this.domSubject);
      }

      // Cl_common.logger("dto");
      // Cl_common.logger(dto);
      //API実行
      //-------
      this.delegate = await Cl_searchSupplys.getList(dto);
      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchSupplysGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  cnvDetails(price: any) {
    let cnvPrice;

    //金額
    if (price != null) {
      cnvPrice = price!.toLocaleString("ja-JP");
    } else {
      cnvPrice = "";
    }
    Cl_common.logger("cnvPrice");
    Cl_common.logger(cnvPrice);
    return cnvPrice;
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "ShiireList";

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProStaffId != "" && this.domProStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domProStaffId;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.supplyMonth_start = new Date(this.domSupplyMonth_start.date!);
      // dto.supplyMonth_end = new Date(this.domSupplyMonth_end.date!);
      if (this.domSupplyMonth_start.date != null) {
        dto.supplyMonth_start = new Date(this.domSupplyMonth_start.date);
      }
      if (this.domSupplyMonth_end.date != null) {
        dto.supplyMonth_end = new Date(this.domSupplyMonth_end.date);
      }

      if (this.domSupplyItem != "" && this.domSupplyItem != null)
        dto.supplyItem = Number(this.domSupplyItem);

      if (this.domSupplySubject != "" && this.domSupplySubject != null)
        dto.supplySubject = Number(this.domSupplySubject);

      if (this.domSupplyBreakdown != "" && this.domSupplyBreakdown != null)
        dto.supplyBreakdown = Number(this.domSupplyBreakdown);

      if (this.domSupplierid != null)
        dto.supplierid = Number(this.domSupplierid);

      if (this.domProjectType != "" && this.domProjectType != null)
        dto.projectType = Number(this.domProjectType);

      dto.supplyRemarks = this.domSupplyRemarks;

      // dto.supplyDateExpPayment_start = new Date(
      //   this.domSupplyDateExpPayment_start.date!
      // );

      // dto.supplyDateExpPayment_end = new Date(
      //   this.domSupplyDateExpPayment_end.date!
      // );

      if (this.domSupplyDateExpPayment_start.date != null) {
        dto.supplyDateExpPayment_start = new Date(
          this.domSupplyDateExpPayment_start.date
        );
      }

      if (this.domSupplyDateExpPayment_end.date != null) {
        dto.supplyDateExpPayment_end = new Date(
          this.domSupplyDateExpPayment_end.date
        );
      }

      if (this.domVariationType != "" && this.domVariationType != null)
        dto.VariationType = Number(this.domVariationType);

      if (this.domItemType != "" && this.domItemType != null)
        dto.ItemType = Number(this.domItemType);

      if (
        // this.domChkClaim != "" &&
        // this.domChkClaim != null &&
        this.domChkClaim == "1" ||
        this.domChkClaim == "0"
      ) {
        dto.chkClaim = Number(this.domChkClaim);
      }
      if (this.domSubject != "" && this.domSubject != null) {
        dto.subjectSupply = Number(this.domSubject);
      }

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *社員プルダウン取得用
   */
  async getStaffList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //TODO:#4134
      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 1;
      //表示用
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegateStaff = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateStaff = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *顧客プルダウン取得用
   */
  async getClientList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行顧客マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;

      //休日用準備
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegateClient = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateClient);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateClient = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 税抜き金額を計算する
   * @param salesPrice
   * @param taxRate
   */
  cnvExcludingTaxPrice(salesPrice: any, taxRate: any) {
    let res;

    let rounding = CONST_CLAIM_ROUDING_SISYAGONYU;

    //金額
    if (salesPrice != null) {
      res = CommonPrice4MD.calcExcludTax(salesPrice, taxRate, rounding);
    } else {
      res = "";
    }
    return res;
  }
  /**
   * 税込み金額を計算する
   * @param salesPrice
   * @param taxRate
   */
  cnvIncludingTaxPrice(Price: any, taxRate: any) {
    let res;

    let rounding = CONST_CLAIM_ROUDING_SISYAGONYU;

    //金額
    if (Price != null) {
      res = CommonPrice4MD.calcTax(Price, taxRate, rounding);
    } else {
      res = "";
    }
    return res;
  }

  /**
   * 顧客選択時のイベント
   * watch用
   */
  @Watch("evDatas.isSelected")
  async selectClient() {
    if (!this.evDatas.isSelected) {
      return;
    }
    this.domProClientid = this.evDatas.selectedItem!.c_client_id!;
  }

  /**
   *仕入先プルダウン取得用
   */
  async getSupplierList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行仕入れ先マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getSuppliers = 1;
      dtoMaster.delFlag4suppliers = 1;

      //API実行
      //-------
      this.delegateSupplier = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateSupplier);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateSupplier = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  //TODO:#3931
  /**
   * 仕入選択時のイベント
   * watch用
   */
  @Watch("evDatas4Supplier.isSelected")
  async selectSupplier() {
    if (!this.evDatas4Supplier.isSelected) {
      return;
    }
    this.domSupplierid = this.evDatas4Supplier.selectedItem!.c_supplier_id!;
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/AnkenInput?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }

  get_url_claim(params: any) {
    let url = "";
    if (params == 1) {
      url = "seikyusyocreate?claimnum=";
    } else {
      url = "seikyusyocreatenotsales?claimnum=";
    }
    return url;
  }
  /**
   *請求確認フラグを受け取り、アイコン用のHTMLを返す
   */
  cnvChkClaim2Icon(flagNum: number | null): string {
    if (flagNum == 1) {
      return '<span  class="badge badge-success badge-pill" style="font-size: 1em;">確認済</span>';
    } else {
      return '<span  class="badge badge-dark badge-pill" style="font-size: 1em;">未確認</span>';
    }
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  /**
   * 請求先選択用モーダル
   */
  async showModalclaim(): Promise<void> {
    this.evDatas.selectedItem = null;
    this.evDatas.isSelected = false;

    const modalClient: any = this.$refs.Mo_modal_selectType;
    await modalClient.showModal();
  }
  async closeModalclaim(): Promise<void> {
    const modalClient: any = this.$refs.Mo_modal_selectType;
    modalClient.closeModal();
  }

  //TODO:#3931
  /**
   * 仕入先選択用モーダル
   */
  async showModalsupplier(): Promise<void> {
    this.evDatas4Supplier.selectedItem = null;
    this.evDatas4Supplier.isSelected = false;

    const modalSupplier: any = this.$refs.Mo_modal_selectType4Supplier;
    await modalSupplier.showModal();
  }
  async closeModalsupplier(): Promise<void> {
    const modalSupplier: any = this.$refs.Mo_modal_selectType4Supplier;
    modalSupplier.closeModal();
  }
}
