
import { Prop, Options, Vue, Watch } from "vue-property-decorator";

import {
  DtoAttendancesGetResImple,
  attendanceItem,
} from "mediadept-common/src/common/dto/DtoAttendancesGetResImple";
import {
  CONST_OP_OVERTIME_TYPE,
  CONST_OP_HOLIDAY_PART_TYPE,
  CONST_OP_HOLIDAY_TYPE,
} from "mediadept-common/src/common/constant/Constants";

@Options({
  components: {},
})
export default class Mo_tableAttendance_row extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  item: attendanceItem;
  @Prop({ required: true })
  isDisable: boolean;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //
  }

  isHoliday(): boolean {
    if (this.item.c_attendance_date == null) {
      return false;
    }
    let dateTmp = new Date(this.item.c_attendance_date);
    const dayOfWeek = dateTmp.getDay();

    if (dayOfWeek >= 1 && dayOfWeek <= 5 && this.item.item4holiday == null) {
      // 月曜から金曜までの処理
      return false;
    } else {
      // 土曜または日曜の処理
      return true;
    }
  }

  showRequestStatus(): string {
    let res = "";
    let txtTmp: string | undefined = "";
    let txtTmpHoliday: string | undefined = "";

    // 残業申請。
    //-------
    for (
      let index = 0;
      index < this.item.item4requestOvertime.length;
      index++
    ) {
      txtTmp = CONST_OP_OVERTIME_TYPE.find(
        (e) =>
          e.code ===
          this.item.item4requestOvertime[index].c_request_overtime_type!
      )?.label;
      if (txtTmp == undefined) {
        txtTmp = "残業申請";
      }

      res +=
        '<a href="/shinsei_Zangyoedit?id=' +
        this.item.item4requestOvertime[index].c_request_id +
        '"><span class="badge badge-info badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestOvertime[index].c_request_id +
        "</span></a><br>";
    }

    // 休暇申請。
    //-------
    for (let index = 0; index < this.item.item4requestHoliday.length; index++) {
      txtTmp = CONST_OP_HOLIDAY_TYPE.find(
        (e) =>
          e.code ===
          this.item.item4requestHoliday[index].c_request_holiday_type!
      )?.label;
      if (txtTmp == undefined) {
        txtTmp = "休暇申請";
      }
      if (
        this.item.item4requestHoliday[index].c_request_holiday_part_type != null
      ) {
        txtTmpHoliday = CONST_OP_HOLIDAY_PART_TYPE.find(
          (e) =>
            e.code ===
            this.item.item4requestHoliday[index].c_request_holiday_part_type!
        )?.label;

        if (
          txtTmpHoliday != undefined &&
          this.item.item4requestHoliday[index].c_request_holiday_part != null
        ) {
          txtTmp += "<br>" + txtTmpHoliday;
        }
      }

      res +=
        '<a href="/shinsei_Kyukatodokeedit?id=' +
        this.item.item4requestHoliday[index].c_request_id +
        '"><span class="badge badge-success badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestHoliday[index].c_request_id +
        "</span></a><br>";
    }

    //リモートワーク申請。
    //-------
    for (let index = 0; index < this.item.item4requestRemote.length; index++) {
      txtTmp = "リモート";

      res +=
        '<a href="/shinsei_Remoteworkedit?id=' +
        this.item.item4requestRemote[index].c_request_id +
        '"><span class="badge badge-primary badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestOvertime[index].c_request_id +
        "</span></a><br>";
    }

    //出張申請。
    //-------
    for (let index = 0; index < this.item.item4requestBiztrip.length; index++) {
      txtTmp = "出張";

      res +=
        '<a href="/shinsei_Shutchoedit?id=' +
        this.item.item4requestBiztrip[index].c_request_id +
        '"><span class="badge badge-secondary badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestOvertime[index].c_request_id +
        "</span></a><br>";
    }

    return res;
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * クリック時のマップ呼び出し処理
   */
  async showMap(lat: string, lng: string) {
    this.$emit("evShowMap", { lat: lat, lng: lng });
  }
  /**
   * 日時のフォーマット変更
   * @param date
   */
  formatDateToJapanese(date: Date | null): string {
    if (date == null) {
      return "";
    }
    let dateTmp = new Date(date);
    const month = ("00" + (dateTmp.getMonth() + 1)).slice(-2);
    const day = ("00" + dateTmp.getDate()).slice(-2);

    const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
    const weekday = weekdays[dateTmp.getDay()];

    return `${month}月${day}日（${weekday}）`;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
}
