
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoClaimsGetResImple } from "mediadept-common/src/common/dto/DtoClaimsGetResImple";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { Cl_claim } from "@/functions/Cl_claim";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { DtoClaimsGetReqImple } from "mediadept-common/src/common/dto/DtoClaimsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

import Or_selectClient from "@/components/organisms/Or_selectClient.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";
import { Watch } from "vue-property-decorator/lib/decorators/Watch";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Or_selectClient,
  },
})
export default class Misyoninlist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClaimsGetResImple();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domClaimNo_start = "";
  domClaimNo_end = "";
  domClaimDate_start: Date | null = null;
  domClaimDate_end: Date | null = null;
  domClientid: number | null = null;
  domClaimTitle = "";
  domClaimSponsorName = "";
  domClaimPayLimit_start: Date | null = null;
  domClaimPayLimit_end: Date | null = null;
  domStaffId = "";
  domPayment = "";

  // optionsOp = [
  //   { label: "ADK" },
  //   { label: "ATA" },
  //   { label: "エフグラム プロダクト" },
  //   { label: "hit" },
  //   { label: "JALPAK" },
  //   { label: "JALUX" },
  //   { label: "JIC" },
  //   { label: "NKB" },
  //   { label: "no problem LLC." },
  //   { label: "OMS" },
  //   { label: "OOHメディア・ソリューション" },
  //   { label: "Rooftop" },
  //   { label: "スパーダ" },
  //   { label: "TOW" },
  //   { label: "TVC" },
  //   { label: "アーチェリー" },
  //   { label: "アイ・アンドキュ－・アドバタイジング" },
  //   { label: "アウトドア・メディア・サービス" },
  //   { label: "あかうみ" },
  //   { label: "アクト計画" },
  //   { label: "アサツーディ・ケイ" },
  //   { label: "アシストコガ" },
  //   { label: "アディダスジャパン" },
  //   { label: "アド・トゥモロー" },
  //   { label: "アド電通大阪" },
  //   { label: "アド・ブレーン" },
  //   { label: "アドギア" },
  //   { label: "アドマックス" },
  //   { label: "アフィックス" },
  //   { label: "アプレックス" },
  //   { label: "アルセス" },
  //   { label: "アルビレックス新潟" },
  //   { label: "いすゞ自動車首都圏" },
  //   { label: "インターナショナルクリエイティブ" },
  //   { label: "エー・ティ・エー" },
  //   { label: "エムシ－ドゥコ－" },
  //   { label: "エイベックス・ライブ・クリエイティブ" },
  //   { label: "オゾンネットワーク" },
  //   { label: "オリコム" },
  //   { label: "ガリバーインターナショナル" },
  //   { label: "キッザニア東京" },
  //   { label: "キョ－エイ・アド・インタ－ナショナル" },
  //   { label: "キョウシンアド" },
  //   { label: "キリンビバレッジ" },
  //   { label: "クオリケーション" },
  //   { label: "クオリケーション・他" },
  //   { label: "クラウドポイント" },
  //   { label: "クリスマス・カンパニー" },
  //   { label: "ケイト・スペード　ジャパン" },
  //   { label: "ケシオン" },
  //   { label: "コミュニケーションアド" },
  //   { label: "コンセルジェ" },
  //   { label: "サマンサタバサジャパンリミテッド" },
  //   { label: "サンエー/アウトドア" },
  //   { label: "サンエー・インターナショナル" },
  //   { label: "シーツープロモーション" },
  //   { label: "シブヤテレビジョン" },
  //   { label: "ジャングルジャムプロモーション" },
  //   { label: "ジュンアンドロペエンターテイメント" },
  //   { label: "ジンコーポレーション" },
  //   { label: "ゼ・ファー" },
  //   { label: "セイストン" },
  //   { label: "セントラルスポーツ" },
  //   { label: "ゼンハイザージャパン" },
  //   { label: "ターキッシュエアラインズ　東京支社" },
  //   { label: "タワーレコード" },
  //   { label: "チャイナエアライン" },
  //   { label: "ディー・エヌ・エー" },
  //   { label: "ディラン" },
  //   { label: "テレビ朝日" },
  //   { label: "テレビ朝日　広報局宣伝部" },
  //   { label: "テレビ朝日クリエイト" },
  //   { label: "テレビ朝日ミュージック" },
  //   { label: "ドゥコ－" },
  //   { label: "トッパンＴＤＫレーベル" },
  //   { label: "トピー工業" },
  //   { label: "ドライブシール" },
  //   { label: "ドリーミュージック" },
  //   { label: "ドリル" },
  //   { label: "ナイキジャパン" },
  //   { label: "パイオニア企画" },
  //   { label: "パシフィックメディア" },
  //   { label: "ハッピーライフ" },
  //   { label: "ピー・プランニング" },
  //   { label: "ハブカンパニ－" },
  //   { label: "フェイス" },
  //   { label: "ブラスアンドカンパニー" },
  //   { label: "プラム・ブロッサム・プロモーション" },
  //   { label: "プランネット" },
  //   { label: "ブランニューミュージック" },
  //   { label: "フロンティアインタ－ナショナル" },
  //   { label: "フロンテッジ" },
  //   { label: "ベストクルーズ" },
  //   { label: "ベストコミュニケーションズ" },
  //   { label: "ベトナム航空　日本支社" },
  //   { label: "ボウス" },
  //   { label: "ホワイトナイト" },
  //   { label: "ホワイトナイト／ビジネスファンタジア" },
  //   { label: "ホワイトボックス" },
  //   { label: "ホンマ装美" },
  //   { label: "マードゥレクス" },
  //   { label: "マックウィンクリエイト" },
  //   { label: "ミディアム" },
  //   { label: "ムサシノ広告社" },
  //   { label: "メディアポート成田" },
  //   { label: "メトロアドエージェンシー" },
  //   { label: "ラ・ポルト青山 テナント会" },
  //   { label: "ユナイテッドライツ" },
  //   { label: "ワイデン+ケネディ トウキョウ" },
  //   { label: "医療法人　有美加" },
  //   { label: "角川メディアハウス" },
  //   { label: "ION 東京営業所" },
  //   { label: "エイシンプランニグ" },
  //   { label: "CLUB21 JAPAN" },
  //   { label: "DSC" },
  //   { label: "アンデザイン" },
  //   { label: "サドルバック" },
  //   { label: "ジャビック" },
  //   { label: "ピコトン" },
  //   { label: "モスフードサービス" },
  //   { label: "モデア" },
  //   { label: "リアライズ" },
  //   { label: "外為ジャパン" },
  //   { label: "博報堂" },
  //   { label: "近宣　東京支社" },
  //   { label: "山下商会" },
  //   { label: "電通" },
  //   { label: "電通(小林工芸社)" },
  //   { label: "電通（ＯＯＨ）" },
  //   { label: "電通(一部TOW)" },
  //   { label: "電通(一部ケシオン)" },
  //   { label: "電通(電通テック)" },
  //   { label: "博報堂ＤＹメディアパートナーズ" },
  //   { label: "博報堂プロダクツ" },
  //   { label: "リンクアップビューティー" },
  //   { label: "賃貸不動産ニュース（生和コーポレーション）" },
  //   { label: "博報堂DYアドステーション" },
  //   { label: "博報堂マグネット" },
  //   { label: "毎日広告社" },
  //   { label: "矢動丸プロジェクト" },
  //   { label: "エイシンプランニング" },
  //   { label: "ジュンアンドロペ・エンターテイメント" },
  //   { label: "岩見沢市観光協会" },
  //   { label: "京成エージェンシー" },
  //   { label: "京成建設" },
  //   { label: "協立広告" },
  //   { label: "古川 東京" },
  //   { label: "古川広告社　東京支社" },
  //   { label: "古川広告社、OOHメディア・ソリューション他" },
  //   { label: "公和印刷" },
  //   { label: "弘亜社" },
  //   { label: "高島屋ＡＴＡ" },
  //   { label: "済州航空" },
  //   { label: "三井住友信託銀行" },
  //   { label: "三井不動産" },
  //   { label: "首都高速道路" },
  //   { label: "住友不動産" },
  //   { label: "春光社" },
  //   { label: "松林整形外科" },
  //   { label: "小林工芸社（電通）" },
  //   { label: "昭通" },
  //   { label: "城南進学研究社" },
  //   { label: "生和コーポレーション" },
  //   { label: "森ビル" },
  //   { label: "森ビル流通システム" },
  //   { label: "大広" },
  //   { label: "大日本インキ" },
  //   { label: "大日本インキ化学工業" },
  //   { label: "大和通信社" },
  //   { label: "中央三井信託銀行" },
  //   { label: "電子広告社" },
  //   { label: "電通アドギア" },
  //   { label: "電通テック" },
  //   { label: "東急エージェンシー" },
  //   { label: "東急スペース" },
  //   { label: "東急スペース開発・トピー工業株式会社" },
  //   { label: "東京シティ・エアターミナル" },
  //   { label: "東京空港交通" },
  //   { label: "東京工芸大学" },
  //   { label: "東京工芸大学（笠尾先生）" },
  //   { label: "東京大学" },
  //   { label: "一般財団法人東京都営交通協力会" },
  //   { label: "東京都交通局資産運用部事業開発課" },
  //   { label: "東和産業" },
  //   { label: "東信企業" },
  //   { label: "特定非営利法人ｸﾘｴｲﾃｨﾌﾞｽﾏｲﾙ" },
  //   { label: "読広クロスコム" },
  //   { label: "日光久指圧院　はり灸院" },
  //   { label: "日広通信社" },
  //   { label: "日本コロムビア" },
  //   { label: "日本スタデオ" },
  //   { label: "日本経済社" },
  //   { label: "日本コカ・コーラ" },
  //   { label: "福田紀彦　事務所" },
  //   { label: "宝広告社" },
  //   { label: "宝広告社　東京支社" },
  //   { label: "北海道広告" },
  //   { label: "北青山プロパティー" },
  //   { label: "クー" },
  //   { label: "藤島興産" },
  //   { label: "ジャングルジャムプロモーション" },
  //   { label: "合同会社北青山プロパティー" },
  //   { label: "合同産業" },
  //   { label: "阪急阪神ビルマネジメント" },
  //   { label: "売上先なし" },
  //   { label: "売上先未定" },
  //   { label: "警視総監" },
  //   { label: "共和企画" },
  //   { label: "東京都総務局長" },
  //   { label: "三晃" },
  //   { label: "ディプトリクスリテール" },
  //   { label: "東京都産業労働局" },
  //   { label: "共同エージェンシー" },
  //   { label: "ユニカ" },
  //   { label: "近宣　東京支店" },
  //   { label: "JTBコミュニケーションデザイン" },
  //   { label: "G-Production" },
  //   { label: "ピュア" },
  //   { label: "トイボックス" },
  //   { label: "キングレコード" },
  //   { label: "日本経済広告社" },
  //   { label: "中国南方航空" },
  //   { label: "東京都都市整備局" },
  //   { label: "日本航空" },
  //   { label: "古川広告社　大阪本社" },
  //   { label: "東京タカラ商会" },
  //   { label: "ミューカ" },
  //   { label: "古川広告社　名古屋営業所" },
  //   { label: "LDH JAPAN" },
  //   { label: "キッズプロモーション" },
  //   { label: "商店街振興組合原宿表参道欅会" },
  //   { label: "リバーフィールド社" },
  //   { label: "ルート" },
  //   { label: "ハバス　ワールドワイド　ジャパン" },
  //   { label: "ファンクス" },
  //   { label: "東京都生活文化局" },
  //   { label: "JUN" },
  //   { label: "キャトル" },
  //   { label: "クオラス" },
  //   { label: "商店街振興組合原宿表参道欅会" },
  //   { label: "ナノ・ユニバース" },
  //   { label: "イナケン建設事務所" },
  //   { label: "リネン　アンド　カンパニー" },
  //   { label: "メーカーズシャツ鎌倉" },
  //   { label: "タップ" },
  //   { label: "東京都消費生活総合センター" },
  //   { label: "博報堂DYアウトドア" },
  //   { label: "コスモ・コミュニケーションズ" },
  //   { label: "エヌケービー" },
  //   { label: "I&S BBDO" },
  //   { label: "コミュニケーションアド" },
  //   { label: "スタジオマジック・イノベーションズ" },
  //   { label: "読売広告社" },
  //   { label: "ネクステージ" },
  //   { label: "東京都福祉保健局長" },
  //   { label: "東急ホームズ" },
  //   { label: "オーイズミフーズ" },
  //   { label: "森ビル" },
  //   { label: "大和屋ふとん" },
  //   { label: "東急不動産" },
  //   { label: "LOT JAPAN" },
  //   { label: "SANEI" },
  //   { label: "ＥＧ八王子" },
  //   { label: "ヒット" },
  //   { label: "アドインテ" },
  //   { label: "ガルーダ・インドネシア航空会社" },
  //   {
  //     label:
  //       "インドネシア共和国観光省　ビジットインドネシアツーリズムオフィス日本地区事務所",
  //   },
  //   { label: "パティオ" },
  //   { label: "ＵＭＡＭＩ　ＢＵＲＧＥＲ　ＪＡＰＡＮ" },
  //   { label: "古川広告社　福岡営業所" },
  //   { label: "公益財団法人ユニジャパン" },
  //   { label: "JR西日本コミュニケーションズ" },
  //   { label: "スリーエープロダクト" },
  //   { label: "テレビ朝日" },
  //   { label: "パス・コミュニケーションズ" },
  //   { label: "シネブリッジ" },
  //   { label: "博報堂" },
  //   { label: "モードメディア・ジャパン" },
  //   { label: "フジ産業" },
  //   { label: "グリーンポート・エージェンシー" },
  //   { label: "エヌ・ティ・ティ・アド" },
  //   { label: "ジェイ・ウォルター・トンプソンジャパン合同会社" },
  //   { label: "メディアコンシェルジュ" },
  //   { label: "ショートショート実行委員会" },
  //   { label: "合同会社PVH JAPAN" },
  //   { label: "ショートショート アジア実行委員会" },
  //   { label: "アルビオン" },
  //   { label: "ジャガー・ランドローバー・ジャパン" },
  //   { label: "リップルリンク" },
  //   { label: "ニューアド社" },
  //   { label: "パシフィックゴルフマネージメント" },
  //   { label: "大和通信社" },
  //   { label: "東京ハーヴェスト実行委員会 オイシックス・ラ・大地" },
  //   { label: "東京都青少年・治安対策本部長" },
  //   { label: "クオリタ" },
  //   { label: "zenplus" },
  //   { label: "富士フイルムイメージングシステムズ" },
  //   { label: "プロエスト" },
  //   { label: "ペルノ・リカール・ジャパン" },
  //   { label: "アルファトラスト" },
  //   { label: "ADKマーケティング・ソリューションズ" },
  //   { label: "AIAD" },
  //   { label: "Viibar" },
  //   { label: "ADKエモーションズ" },
  //   { label: "オリコミサービス" },
  //   { label: "ルイ・ヴィトン ジャパン" },
  //   { label: "表示灯" },
  //   { label: "イーステージ" },
  //   { label: "MONDO" },
  //   { label: "アドセブンスリー" },
  //   { label: "医療法人社団新正会　安藤歯科医院" },
  //   { label: "プロラボ ホールディングス" },
  //   { label: "ワーナー ブラザース ジャパン合同会社" },
  //   { label: "博報堂マグネット" },
  //   { label: "サードカルチャー" },
  //   { label: "エルメスジャポン" },
  //   { label: "アスリン" },
  //   { label: "エスピーエスエス" },
  //   { label: "フォルクスワーゲングループジャパン" },
  //   { label: "ウォルト・ディズニー・ジャパン" },
  //   { label: "ユニテックシステム" },
  //   { label: "LIVE　BOARD" },
  //   { label: "スバル広告" },
  //   { label: "サイバーエージェント" },
  //   { label: "プロデュース101ジャパン出演者キムヒチョンくんファン一同" },
  //   { label: "電通ライブ" },
  //   { label: "フェイス　営業本部" },
  //   { label: "トーダン" },
  //   { label: "AbemaTV" },
  //   { label: "allfuz" },
  //   { label: "オフィス・ミツキ" },
  //   { label: "Mediakeys Japan" },
  //   { label: "ザイマックス" },
  //   { label: "スタイレム" },
  //   { label: "京急アドエンタープライズ" },
  //   { label: "プーマ ジャパン" },
  //   { label: "リアル・アド・クリエイティブ" },
  //   { label: "日昌ディベロップメント" },
  //   { label: "東京都交通局長" },
  //   { label: "アドフロンテ" },
  //   { label: "リアルゲイト" },
  //   { label: "ジャガー東京" },
  //   { label: "トラッドインターナショナル" },
  //   { label: "マッキャンエリクソン" },
  //   { label: "第一エージェンシー" },
  //   { label: "第一エージェンシー　東京統括本部" },
  //   { label: "vivora" },
  //   { label: "グランボード" },
  //   { label: "Tier Design&Research" },
  //   { label: "東京都主税局長" },
  //   { label: "テスト" },
  //   { label: "東京都政策企画局長" },
  //   { label: "町田まちづくり公社" },
  //   { label: "オリオンビール" },
  //   { label: "朝日オリコミ" },
  //   { label: "ビーアット" },
  //   { label: "一般社団法人　日本パブリックビューイング協会" },
  //   { label: "モメンタムジャパン" },
  //   { label: "熊野前医院" },
  //   { label: "ＭＩＮＴ　ＴＯＫＹＯ" },
  //   { label: "PalledAd" },
  //   { label: "コンセルジェ" },
  //   { label: "MEDIA８" },
  //   { label: "NPO法人薬害研究センター" },
  //   { label: "Coupang Japan合同会社" },
  //   { label: "lululemon athletica JP" },
  //   { label: "東京都交通局" },
  //   { label: "SDクリエーション" },
  //   { label: "ジクマネ" },
  //   { label: "THIRTEEN" },
  //   { label: "電通クリエ－ティブＸ" },
  //   { label: "アイチューザー" },
  //   { label: "シティボード" },
  //   { label: "TMC" },
  //   { label: "KADOKAWA" },
  //   { label: "松竹" },
  //   { label: "AMI Paris Japan" },
  //   { label: "リムジン・パッセンジャーサービス" },
  //   { label: "Bottega Veneta JAPAN" },
  //   { label: "Leading communication" },
  //   { label: "東京都 公営企業管理者 下水道局長" },
  //   { label: "KOS" },
  //   { label: "警視庁" },
  //   { label: "アジリティー" },
  //   { label: "ソニー" },
  //   { label: "リポット" },
  //   { label: "OKULAB" },
  //   { label: "キャンプ" },
  //   { label: "キャンプ" },
  //   { label: "SENKOU SOUND" },
  //   { label: "社会福祉法人偕恵園　横浜市つたのは学園" },
  //   { label: "ジクマネ" },
  //   { label: "SEISHIDO IDEAL" },
  //   { label: "Obito" },
  //   { label: "メディアサービス" },
  //   { label: "Officeroom" },
  //   { label: "フラッグ" },
  //   { label: "クラフトワールドワイド" },
  //   { label: "ジーニー" },
  //   { label: "東映エージエンシー " },
  //   { label: "サニーサイドアップ" },
  //   { label: "日本中央競馬会" },
  //   { label: "TATRAS INTERNATIONAL" },
  //   { label: "Nomad Tokyo合同会社" },
  //   { label: "シグナル" },
  //   { label: "ゴラク" },
  // ];
  // optionsOp2 = [
  //   {
  //     label: "田中真哉",
  //   },
  //   {
  //     label: "國部卓爾",
  //   },
  //   {
  //     label: "江島",
  //   },
  //   {
  //     label: "務",
  //   },
  //   {
  //     label: "神野秀二郎",
  //   },
  //   {
  //     label: "石川幸",
  //   },
  //   {
  //     label: "大竹",
  //   },
  //   {
  //     label: "辻本",
  //   },
  //   {
  //     label: "光田圭吾",
  //   },
  //   {
  //     label: "小針有貴",
  //   },
  //   {
  //     label: "高橋三千夫",
  //   },
  //   {
  //     label: "岩崎",
  //   },
  //   {
  //     label: "山本晋治",
  //   },
  //   {
  //     label: "岩崎",
  //   },
  //   {
  //     label: "村瀬智史",
  //   },
  //   {
  //     label: "折原慎吾",
  //   },
  //   {
  //     label: "藤井伽奈",
  //   },
  //   {
  //     label: "山本り央",
  //   },
  //   {
  //     label: "北島麻琉",
  //   },
  //   {
  //     label: "伊澤　暁",
  //   },
  //   {
  //     label: "高橋ほたる",
  //   },
  //   {
  //     label: "川嶋　康照",
  //   },
  //   {
  //     label: "小泉　圭祐",
  //   },
  //   {
  //     label: "長塚　未来",
  //   },
  //   {
  //     label: "テスト",
  //   },
  // ];
  columns = [
    {
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      label: "請求No",
      field: "c_claim_number",
    },
    {
      label: "発行日",
      field: "c_claim_datetime_short",
      type: "date",
      dateInputFormat: "MM/dd/yyyy",
      dateOutputFormat: "yyyy/MM/dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "請求先",
      field: "c_client_name",
    },
    {
      label: "広告主",
      field: "c_claim_sponsor_name",
    },
    {
      label: "件名",
      field: "c_claim_title_1",
    },
    {
      label: "税抜金額",
      field: "c_claim_subtotal",
    },
    {
      label: "請求合計",
      field: "c_claim_total",
    },
    {
      label: "担当者",
      field: "c_staff_name",
    },
  ];
  // //請求先選択用
  evDatas: {
    selectedItem: EntityClient | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
    await this.getStaffList();
    await this.getClientList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimsGetReqImple();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      dto.ClaimDate_start = this.domClaimDate_start;

      dto.ClaimDate_end = this.domClaimDate_end;

      if (this.domClientid != null) dto.Clientid = Number(this.domClientid);

      dto.ClaimTitle = this.domClaimTitle;

      dto.ClaimSponsorName = this.domClaimSponsorName;

      dto.ClaimPayLimit_start = this.domClaimPayLimit_start;

      dto.ClaimPayLimit_end = this.domClaimPayLimit_end;

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.StaffId = Number(this.domStaffId);

      dto.ClaimStatus = "3";

      //API実行
      //-------
      this.delegate = await Cl_claim.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoClaimsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "MisyoninList";

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      dto.ClaimDate_start = this.domClaimDate_start;

      dto.ClaimDate_end = this.domClaimDate_end;

      if (this.domClientid != null) dto.Clientid = Number(this.domClientid);

      dto.ClaimTitle = this.domClaimTitle;

      dto.ClaimSponsorName = this.domClaimSponsorName;

      dto.ClaimPayLimit_start = this.domClaimPayLimit_start;

      dto.ClaimPayLimit_end = this.domClaimPayLimit_end;

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.StaffId = Number(this.domStaffId);

      dto.ClaimStatus = Number("3");

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 顧客選択時のイベント
   * watch用
   */
  @Watch("evDatas.isSelected")
  async selectClient() {
    if (!this.evDatas.isSelected) {
      return;
    }
    this.domClientid = this.evDatas.selectedItem!.c_client_id!;
  }

  async moveDet(claimnum: string, flag: number) {
    //詳細ページに移動
    if (1 == flag) {
      this.$router.push("/seikyusyocreate?claimnum=" + claimnum);
    } else {
      this.$router.push("/seikyusyocreatenotsales?claimnum=" + claimnum);
    }
  }

  /**
   *社員プルダウン取得用
   */
  async getStaffList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //TODO:#4134
      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 1;
      //表示用
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegateStaff = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateStaff = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *顧客プルダウン取得用
   */
  async getClientList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行顧客マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;

      //API実行
      //-------
      this.delegateClient = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateClient);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateClient = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }

  cnvDetails(price: any) {
    let cnvPrice;

    //金額
    if (price != null) {
      cnvPrice = price!.toLocaleString("ja-JP");
    } else {
      cnvPrice = "";
    }
    return cnvPrice;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  /**
   * 請求先選択用モーダル
   */
  async showModalclaim(): Promise<void> {
    this.evDatas.selectedItem = null;
    this.evDatas.isSelected = false;

    const modalClient: any = this.$refs.Mo_modal_selectType;
    await modalClient.showModal();
  }
  async closeModalclaim(): Promise<void> {
    const modalClient: any = this.$refs.Mo_modal_selectType;
    modalClient.closeModal();
  }
}
