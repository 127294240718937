import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoRequestBiztripsGetReqImple } from "mediadept-common/src/common/dto/DtoRequestBiztripsGetReqImple";
import { DtoRequestBiztripsGetResImple } from "mediadept-common/src/common/dto/DtoRequestBiztripsGetResImple";
import { DtoRequestBiztripsPostReq } from "mediadept-common/src/common/dto/abst/DtoRequestBiztripsPostReq";
import { DtoRequestBiztripsPostRes } from "mediadept-common/src/common/dto/abst/DtoRequestBiztripsPostRes";
import { DtoRequestBiztripsPutReq } from "mediadept-common/src/common/dto/abst/DtoRequestBiztripsPutReq";
import { DtoRequestBiztripsPutRes } from "mediadept-common/src/common/dto/abst/DtoRequestBiztripsPutRes";
import { DtoRequestBiztripsDeleteReq } from "mediadept-common/src/common/dto/abst/DtoRequestBiztripsDeleteReq";
import { DtoRequestBiztripsDeleteRes } from "mediadept-common/src/common/dto/abst/DtoRequestBiztripsDeleteRes";

export class Cl_requestbiztrip {
  public static async getList(
    dto: DtoRequestBiztripsGetReqImple
  ): Promise<DtoRequestBiztripsGetResImple> {
    return Object.assign(
      new DtoRequestBiztripsGetResImple(),
      await CallAPI.getRequest(
        "/requestbiztrips?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoRequestBiztripsGetReqImple
  ): Promise<DtoRequestBiztripsGetResImple> {
    return Object.assign(
      new DtoRequestBiztripsGetResImple(),
      await CallAPI.getRequest("/requestbiztrips/" + dto.c_request_biztrip_id)
    );
  }

  public static async post(
    dto: DtoRequestBiztripsPostReq
  ): Promise<DtoRequestBiztripsPostRes> {
    return Object.assign(
      new DtoRequestBiztripsPostRes(),
      await CallAPI.postRequest("/requestbiztrips", dto)
    );
  }

  public static async put(
    dto: DtoRequestBiztripsPutReq
  ): Promise<DtoRequestBiztripsPutRes> {
    return Object.assign(
      new DtoRequestBiztripsPutRes(),
      await CallAPI.putRequest(
        "/requestbiztrips/" + dto.c_request_biztrip_id,
        dto
      )
    );
  }

  public static async del(
    dto: DtoRequestBiztripsDeleteReq
  ): Promise<DtoRequestBiztripsDeleteRes> {
    return Object.assign(
      new DtoRequestBiztripsDeleteRes(),
      await CallAPI.deleteRequest(
        "/requestbiztrips?" + Cl_common.cnvObj2GetParam(dto)
      )
    );
  }
}
