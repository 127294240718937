
import { Prop, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_max_force from "@/components/molecules/Mo_modal_max_force.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoDocsGetResImple } from "mediadept-common/src/common/dto/DtoDocsGetResImple";
import { DtoSearchProjectGetRes } from "mediadept-common/src/common/dto/DtoSearchProjectGetRes";
import { DtoSearchProjectGetReq } from "mediadept-common/src/common/dto/DtoSearchProjectGetReq";
import { DtoPro2docsPostReq } from "mediadept-common/src/common/dto/abst/DtoPro2docsPostReq";
import { DtoPro2docsPostRes } from "mediadept-common/src/common/dto/abst/DtoPro2docsPostRes";
import { Cl_common } from "@/functions/Cl_common";
import {
  CONST_OP_ITEMTYPE,
  CONST_OP_OWNER,
  CONST_OP_TERM_ITEM,
  CONST_OP_TERM_TYPE,
} from "mediadept-common/src/common/constant/Constants";

import { DtoItemsGetReqImple } from "mediadept-common/src/common/dto/DtoItemsGetReqImple";
import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_item } from "@/functions/Cl_item";

import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { Cl_master } from "@/functions/Cl_master";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { Cl_searchProject } from "@/functions/Cl_searchProject";
import { Cl_pro2doc } from "@/functions/Cl_pro2doc";

@Options({
  components: {
    vSelect,
    Mo_modal_max_force,
    VueGoodTable,
  },
})
export default class Or_selectProject extends Vue {
  // selectedVariation: EntityVariationImple | null = null;
  // selectItem: DtoItemsGetResImple | null = null;
  @Prop({ required: true })
  docData: DtoDocsGetResImple;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSearchProjectGetRes();
  delegateMasters = new DtoMastersGetRes();

  /**
   * モーダル内で紐付けられたProjectId
   */
  inputedProIds: number[] = [];

  domProjectNo_start = "";
  domProjectNo_end = "";
  domProjectName = "";

  domProClientid: number | null = null;
  domProStaffId = "";

  //--------
  detNote = "";
  detRemarks = "";
  detInfo = "";
  detItempageLink = "";
  detItempageLink4selected = "";

  detStartDate = "";
  detCount = 1;

  //ボタン制御用
  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opItemtype = CONST_OP_ITEMTYPE;
  opOwner = CONST_OP_OWNER;
  opTerm = CONST_OP_TERM_ITEM;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
    },
    {
      label: "案件番号",
      field: "c_project_number",
    },
    {
      label: "案件名",
      field: "c_project_name",
    },
    {
      label: "請求先",
      field: "c_client_name",
    },
    // {
    //   label: "広告主",
    //   field: "c_project_sponsor_name",
    // },
    {
      label: "担当者",
      field: "c_staff_name",
    },
    // {
    //   label: "商品名",
    //   field: "c_item_name",
    // },
  ];
  //----------------------
  //モーダル用プロパティ
  //----------------------

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    //コンポーネントが表示されていない、読み込み時に呼ばれるので注意。
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container4selectPro");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 0;
      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegateMasters = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      // //モーダルのメッセージを設定
      // this.message4ModalErrorConf = "エラーが発生しました";
      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      this.delegateMasters = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container4selectPro");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSearchProjectGetReq();
      //検索条件設定
      // dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.count = 5;
      dto.pager.cursor = currentPage;

      if (this.domProjectNo_start != "") {
        dto.projectNo_start = Number(this.domProjectNo_start);
      }

      if (this.domProjectNo_end != "") {
        dto.projectNo_end = Number(this.domProjectNo_end);
      }

      dto.projectName = this.domProjectName;

      if (this.domProClientid != null) {
        dto.proClientid = Number(this.domProClientid);
      }

      if (this.domProStaffId != "" && this.domProStaffId != null) {
        dto.staffId = Number(this.domProStaffId);
      }

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      this.delegate = await Cl_searchProject.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);

      // Cl_common.logger("docData");
      // Cl_common.logger(this.docData);
    } catch (error) {
      //モーダルのメッセージを設定
      // this.message4ModalErrorConf = "エラーが発生しました";
      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchProjectGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 紐付けを設定する
   */
  async add(proId: number) {
    // console.log(this.inputedProId);

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container4selectPro");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoPro2docsPostReq();

      dto.c_doc_id = this.docData.c_doc_id;
      dto.c_project_id = proId;

      Cl_common.logger("dto");
      Cl_common.logger(dto);

      //API実行
      //-------
      await Cl_pro2doc.post(dto);

      this.inputedProIds.push(proId);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      // this.message4ModalErrorConf = "エラーが発生しました";
      // //モーダル表示
      // const modal: any = this.$refs.Mo_modal_errorConf;
      // modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchProjectGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 案件紐付ボタンの有効・無効を判定する
   * @param params
   */
  isDisabledPro(proId: number): boolean {
    //新規の追加対象か。
    //-------
    if (this.inputedProIds.includes(proId)) {
      return true;
    }

    //delegateに入力されているか。
    //-------
    if (this.docData.projects.some((pro) => pro.c_project_id === proId)) {
      return true;
    }

    return false;
  }
  //------------------------------------
  //詳細データ変換系メソッド
  //------------------------------------
  /**
   * 商品種別用
   */
  // getItemType(itemType: number): string {
  //   var found = CONST_OP_ITEMTYPE.find((e) => e.code === itemType);

  //   if (found != undefined) {
  //     return found!.label;
  //   } else {
  //     return "";
  //   }
  // }

  //------------------------------------

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }

  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegateMasters.getStaffSortNum4NoneDeleteAndDelete();
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async showModal4Project(): Promise<void> {
    //初期化
    this.inputedProIds = [];

    //起動時に読み込み
    this.getlist(1);
    this.getMastersList();

    const modalComp: any = this.$refs.modal_itemSelect;
    modalComp.showModal();
  }
  async closeModal4Project(): Promise<void> {
    const modalComp: any = this.$refs.modal_itemSelect;
    modalComp.closeModal();
    this.$emit("modalclosed");
  }
  // --------
}
