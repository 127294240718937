import { Store } from "vuex";
import { State } from "vue";

/**
 * メニューの表示・非表示を管理するクラス。
 * 管理用のエクセルファイルがあるので、個別に編集するのは禁止。
 */
export class Cl_Authorization_menu {
  public showDashboard_1 = false; //メニュー表示フラグダッシュボード
  public showSales_2 = false; //メニュー表示フラグ売上管理
  public showReport_3 = false; //メニュー表示フラグ帳票作成
  public showMaster_4 = false; //メニュー表示フラグマスタ情報管理
  public showRequest_5 = false; //メニュー表示フラグ申請書管理
  public showDataportal_6 = false; //メニュー表示フラグデータポータル
  public showAttendance_7 = false; //メニュー表示フラグ勤怠管理
  public showProject_100 = false; //メニュー表示フラグ売上管理（受注情報入力）
  public showProjectsearch_101 = false; //メニュー表示フラグ売上管理（受注情報検索）
  public showSalessearch_102 = false; //メニュー表示フラグ売上管理（売上検索）
  public showSuppliersearch_103 = false; //メニュー表示フラグ売上管理（仕入検索）
  public showUnapproved_104 = false; //メニュー表示フラグ売上管理（未承認一覧）
  public showDeposited_105 = false; //メニュー表示フラグ売上管理（入金確定表）
  public showClaimsearch_106 = false; //メニュー表示フラグ売上管理（請求一覧）
  public showClaimnum_107 = false; //メニュー表示フラグ売上管理（請求No検索）
  public showLock_108 = false; //メニュー表示フラグ売上管理（データロック）
  public showClaim_109 = false; //メニュー表示フラグ帳票作成（請求書作成）
  public showClaimnosale_110 = false; //メニュー表示フラグ帳票作成（請求書作成(売上なし)）
  public showReportgetuji_111 = false; //メニュー表示フラグ帳票作成（月次報告管理書作成）
  public showReportsiire_130 = false; //メニュー表示フラグ帳票作成（仕入先別支払表作成）
  public showReporttorihikisaki_112 = false; //メニュー表示フラグ帳票作成（取引先別売上表作成）
  public showReportkoukokunusi_113 = false; //メニュー表示フラグ帳票作成（広告主別売上表作成）
  public showReportgetujibunkatu_114 = false; //メニュー表示フラグ帳票作成（月次報告分割一覧表作成）
  public showReporturiage_115 = false; //メニュー表示フラグ帳票作成（売上請求書一覧表作成）
  public showReporthakkou_116 = false; //メニュー表示フラグ帳票作成（発行請求書一覧表作成）
  public showReportnyuukinn_117 = false; //メニュー表示フラグ帳票作成（入金予定表作成）
  public showReportsiharai_118 = false; //メニュー表示フラグ帳票作成（支払予定表作成）
  public showClient_119 = false; //メニュー表示フラグマスタ情報管理（売上先マスタ管理）
  public showSupplier_120 = false; //メニュー表示フラグマスタ情報管理（仕入先マスタ管理）
  public showStaff_121 = false; //メニュー表示フラグマスタ情報管理（社員マスタ管理）
  public showBank_122 = false; //メニュー表示フラグマスタ情報管理（口座マスタ管理）
  public showMessage_123 = false; //メニュー表示フラグマスタ情報管理（掲示板マスタ管理）
  public showGoal_124 = false; //メニュー表示フラグマスタ情報管理（目標マスタ管理）
  public showHistory_125 = false; //メニュー表示フラグマスタ情報管理（操作履歴）
  public showsalesanaly_126 = false; //メニュー表示フラグ売上分析
  public showItem_127 = false; //メニュー表示フラグマスタ情報管理（商品マスタ管理）
  public showAttendancelist_128 = false; //メニュー表示フラグ勤怠管理（勤怠管理）
  public showAttendanceedit_129 = false; //メニュー表示フラグ勤怠管理（勤怠一覧）
  public showReportShiireshiharai_130 = false; //メニュー表示フラグ帳票作成（仕入先別支払表作成）
  public showHolidaylist_131 = false; //メニュー表示フラグマスタ情報管理（休日管理）
  public showDatadiagnosis_132 = false; //メニュー表示フラグデータポータル（データ診断）

  async getAuthorization(store: Store<State>) {
    //ローカルストレージから権限を取得
    //-------------------------
    store.commit("revStore");
    const authType = store.state.authType;

    //フラグを立てる
    //-------------------------
    //メニュー
    //--------------------
    // 1:入力者;2:集計者;3:権限者;4:管理者;
    switch (authType) {
      case 1:
        //入力者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showMaster_4 = true;
        this.showRequest_5 = true;
        this.showAttendance_7 = true;
        this.showProject_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showClaimsearch_106 = true;
        this.showClaimnum_107 = true;
        this.showClaim_109 = true;
        this.showClaimnosale_110 = true;
        this.showItem_127 = true;
        this.showAttendanceedit_129 = true;
        this.showReportShiireshiharai_130 = true;

        break;
      case 2:
        //集計者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showMaster_4 = true;
        this.showRequest_5 = true;
        this.showAttendance_7 = true;
        this.showProject_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showClaimsearch_106 = true;
        this.showClaimnum_107 = true;
        this.showClaim_109 = true;
        this.showClaimnosale_110 = true;
        this.showReportgetuji_111 = true;
        this.showReportsiire_130 = true;
        this.showReporttorihikisaki_112 = true;
        this.showReportkoukokunusi_113 = true;
        this.showReportgetujibunkatu_114 = true;
        this.showReporturiage_115 = true;
        this.showReporthakkou_116 = true;
        this.showReportnyuukinn_117 = true;
        this.showReportsiharai_118 = true;
        this.showItem_127 = true;
        this.showAttendancelist_128 = true;
        this.showAttendanceedit_129 = true;
        this.showReportShiireshiharai_130 = true;

        break;
      case 3:
        //権限者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showMaster_4 = true;
        this.showRequest_5 = true;
        this.showDataportal_6 = true;
        this.showAttendance_7 = true;
        this.showProject_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showUnapproved_104 = true;
        this.showDeposited_105 = true;
        this.showClaimsearch_106 = true;
        this.showClaimnum_107 = true;
        this.showClaim_109 = true;
        this.showClaimnosale_110 = true;
        this.showReportgetuji_111 = true;
        this.showReportsiire_130 = true;
        this.showReporttorihikisaki_112 = true;
        this.showReportkoukokunusi_113 = true;
        this.showReportgetujibunkatu_114 = true;
        this.showReporturiage_115 = true;
        this.showReporthakkou_116 = true;
        this.showReportnyuukinn_117 = true;
        this.showReportsiharai_118 = true;
        this.showClient_119 = true;
        this.showSupplier_120 = true;
        this.showMessage_123 = true;
        this.showGoal_124 = true;
        this.showsalesanaly_126 = true;
        this.showItem_127 = true;
        this.showAttendancelist_128 = true;
        this.showAttendanceedit_129 = true;
        this.showReportShiireshiharai_130 = true;
        this.showDatadiagnosis_132 = true;

        break;
      case 4:
        //管理者
        this.showDashboard_1 = true;
        this.showSales_2 = true;
        this.showReport_3 = true;
        this.showMaster_4 = true;
        this.showRequest_5 = true;
        this.showDataportal_6 = true;
        this.showAttendance_7 = true;
        this.showProject_100 = true;
        this.showProjectsearch_101 = true;
        this.showSalessearch_102 = true;
        this.showSuppliersearch_103 = true;
        this.showUnapproved_104 = true;
        this.showDeposited_105 = true;
        this.showClaimsearch_106 = true;
        this.showClaimnum_107 = true;
        this.showLock_108 = true;
        this.showClaim_109 = true;
        this.showClaimnosale_110 = true;
        this.showReportgetuji_111 = true;
        this.showReportsiire_130 = true;
        this.showReporttorihikisaki_112 = true;
        this.showReportkoukokunusi_113 = true;
        this.showReportgetujibunkatu_114 = true;
        this.showReporturiage_115 = true;
        this.showReporthakkou_116 = true;
        this.showReportnyuukinn_117 = true;
        this.showReportsiharai_118 = true;
        this.showClient_119 = true;
        this.showSupplier_120 = true;
        this.showStaff_121 = true;
        this.showBank_122 = true;
        this.showMessage_123 = true;
        this.showGoal_124 = true;
        this.showHistory_125 = true;
        this.showsalesanaly_126 = true;
        this.showItem_127 = true;
        this.showAttendancelist_128 = true;
        this.showAttendanceedit_129 = true;
        this.showReportShiireshiharai_130 = true;
        this.showHolidaylist_131 = true;
        this.showDatadiagnosis_132 = true;

        break;
      default:
        break;
    }
  }
}
