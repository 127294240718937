
import { Options, Vue } from "vue-class-component";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_lock } from "@/functions/Cl_lock";
import { DtoLocksPostReqImple } from "mediadept-common/src/common/dto/DtoLocksPostReqImple";
import { DtoLocksGetReqImple } from "mediadept-common/src/common/dto/DtoLocksGetReqImple";

@Options({
  components: {
    Mo_modal_alert,
  },
})
export default class Datalock extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  domProcessingDate = "";
  domStatus = "";
  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";
  message4ModalSuccess = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
  }

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoLocksGetReqImple();

      //API実行
      //-------
      let res = await Cl_lock.get(dto);

      //レスポンス処理
      //-------
      this.domProcessingDate = res.processingDate;
      if (0 < res.processingDate.length) {
        this.domStatus = "ロック済み";
      } else {
        this.domProcessingDate = "-";
        this.domStatus = "未ロック";
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * ロックまたはアンロックする
   */
  async upd(isUnlock: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoLocksPostReqImple();
      const date = new Date();
      dto.c_lock_month = String(date.getMonth() + 1);
      dto.isUnlock = isUnlock;

      //API実行
      //-------
      await Cl_lock.post(dto);
      //レスポンスの処理
      //-------

      //モーダルを表示
      if (isUnlock) {
        this.message4ModalSuccess = "データのロック解除が完了しました。";
      } else {
        this.message4ModalSuccess = "データのロックが完了しました。";
      }
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //----------------------
}
