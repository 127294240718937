import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { DtoAttendancesPutReq } from "./abst/DtoAttendancesPutReq";
import { attendanceItem } from "./DtoAttendancesGetResImple";

export class DtoAttendancesPutReqImple extends DtoAttendancesPutReq {
  public items4Attendance: attendanceItem[] = [];
  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    res = res.concat(super.IsValid());

    return res;
  }
}
