export class EntityOutputqueue {
public c_outputqueue_id:number | null = null;
public c_outputqueue_date_start:Date | null = null;
public c_outputqueue_date_end:Date | null = null;
public c_outputqueue_filename:string | null = null;
public c_outputqueue_code:string | null = null;
public c_outputqueue_flag_end:number | null = null;
public c_outputqueue_flag_success:number | null = null;
public c_outputqueue_flag_failure:number | null = null;
public c_outputqueue_message:string | null = null;
public c_outputqueue_reg_user:string | null = null;
public c_outputqueue_reg_at:Date | null = null;
public c_outputqueue_upd_user:string | null = null;
public c_outputqueue_upd_at:Date | null = null;
}