import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { DtoPro2docsGetReqImple } from "mediadept-common/src/common/dto/DtoPro2docsGetReqImple";
import { DtoPro2docsGetResImple } from "mediadept-common/src/common/dto/DtoPro2docsGetResImple";
import { DtoPro2docsPostReq } from "mediadept-common/src/common/dto/abst/DtoPro2docsPostReq";
import { DtoPro2docsPostRes } from "mediadept-common/src/common/dto/abst/DtoPro2docsPostRes";
import { DtoPro2docsPutReqImple } from "mediadept-common/src/common/dto/DtoPro2docsPutReqImple";
import { DtoPro2docsPutResImple } from "mediadept-common/src/common/dto/DtoPro2docsPutResImple";
import { DtoPro2docsDeleteReqImple } from "mediadept-common/src/common/dto/DtoPro2docsDeleteReqImple";
import { DtoPro2docsDeleteResImple } from "mediadept-common/src/common/dto/DtoPro2docsDeleteResImple";

export class Cl_pro2doc {
  public static async getList(
    dto: DtoPro2docsGetReqImple
  ): Promise<DtoPro2docsGetResImple> {
    return Object.assign(
      new DtoPro2docsGetResImple(),
      await CallAPI.getRequest(
        "/pro2docs?" +
          Cl_common.cnvObj2GetParam(dto.pager) +
          "&" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async get(
    dto: DtoPro2docsGetReqImple
  ): Promise<DtoPro2docsGetResImple> {
    return Object.assign(
      new DtoPro2docsGetResImple(),
      await CallAPI.getRequest("/pro2docs/" + dto.c_pro2doc_id)
    );
  }

  public static async post(
    dto: DtoPro2docsPostReq
  ): Promise<DtoPro2docsPostRes> {
    return Object.assign(
      new DtoPro2docsPostRes(),
      await CallAPI.postRequest("/pro2docs", dto)
    );
  }

  public static async put(
    dto: DtoPro2docsPutReqImple
  ): Promise<DtoPro2docsPutResImple> {
    return Object.assign(
      new DtoPro2docsPutResImple(),
      await CallAPI.putRequest("/pro2docs/" + dto.c_pro2doc_id, dto)
    );
  }

  public static async del(
    dto: DtoPro2docsDeleteReqImple
  ): Promise<DtoPro2docsDeleteResImple> {
    return Object.assign(
      new DtoPro2docsDeleteResImple(),
      await CallAPI.deleteRequest("/pro2docs/" + dto.c_pro2doc_id)
    );
  }
}
