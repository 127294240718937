import CallAPI from "@/functions/Cl_api";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_attendance } from "./Cl_attendance";
import { DtoAttendancesGetReqImple } from "mediadept-common/src/common/dto/DtoAttendancesGetReqImple";
import { DtoAttendancesGetResImple } from "mediadept-common/src/common/dto/DtoAttendancesGetResImple";
import { DtoAttendancesPostReq } from "mediadept-common/src/common/dto/abst/DtoAttendancesPostReq";
import { DtoAttendancesPostRes } from "mediadept-common/src/common/dto/abst/DtoAttendancesPostRes";
import { DtoAttendancesPutReqImple } from "mediadept-common/src/common/dto/DtoAttendancesPutReqImple";
import { DtoAttendancesPutResImple } from "mediadept-common/src/common/dto/DtoAttendancesPutResImple";
import { DtoStaff4attendancesGetResImple } from "mediadept-common/src/common/dto/DtoStaff4attendancesGetResImple";

export class Cl_attendanceImple extends Cl_attendance {
  public static async get4Attendance(
    dto: DtoAttendancesGetReqImple
  ): Promise<DtoAttendancesGetResImple> {
    return Object.assign(
      new DtoAttendancesGetResImple(),
      await CallAPI.getRequest("/attendances?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
  public static async get4AttendanceList(
    dto: DtoAttendancesGetReqImple
  ): Promise<DtoStaff4attendancesGetResImple> {
    return Object.assign(
      new DtoStaff4attendancesGetResImple(),
      await CallAPI.getRequest("/attendances?" + Cl_common.cnvObj2GetParam(dto))
    );
  }
  public static async get4AttendanceTable(
    dto: DtoAttendancesGetReqImple
  ): Promise<DtoAttendancesGetResImple> {
    return Object.assign(
      new DtoAttendancesGetResImple(),
      await CallAPI.getRequest(
        "/attendances/" +
          Number(dto.c_attendance_id) +
          "?" +
          Cl_common.cnvObj2GetParam(dto)
      )
    );
  }

  public static async post4Attendance(
    dto: DtoAttendancesPostReq
  ): Promise<DtoAttendancesPostRes> {
    return Object.assign(
      new DtoAttendancesPostRes(),
      await CallAPI.postRequest("/attendances", dto)
    );
  }

  public static async putImple(
    dto: DtoAttendancesPutReqImple
  ): Promise<DtoAttendancesPutResImple> {
    return Object.assign(
      new DtoAttendancesPutResImple(),
      await CallAPI.putRequest("/attendances/0", dto)
    );
  }
}
