
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_client } from "@/functions/Cl_client";
import { DtoClientsGetReqImple } from "mediadept-common/src/common/dto/DtoClientsGetReqImple";
import { DtoClientsGetResImple } from "mediadept-common/src/common/dto/DtoClientsGetResImple";
import { DtoClientsPutReq } from "mediadept-common/src/common/dto/abst/DtoClientsPutReq";
import { DtoClientsPutRes } from "mediadept-common/src/common/dto/abst/DtoClientsPutRes";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoClientsPostReq } from "mediadept-common/src/common/dto/abst/DtoClientsPostReq";
import { DtoClientsPostRes } from "mediadept-common/src/common/dto/abst/DtoClientsPostRes";

import {
  CONST_OP_CLAIM_LANGUAGE,
  CONST_OP_PAYMENTSITE,
} from "mediadept-common/src/common/constant/Constants";

// バリデーション
import * as Yup from "yup";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class Uriagesakiedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    clientName: Yup.string().required("売上先名を入力してください。"),
    furigana: Yup.string()
      .required("ふりがなを入力してください。")
      .matches(
        /^([\u3040-\u309F―ー‐－0-9]|\s|\p{P}|\p{S})+$/,
        "ふりがなはひらがなで入力してください。"
      ),
  });
  schemaUpd = Yup.object().shape({
    clientName: Yup.string().required("売上先名を入力してください。"),
    furigana: Yup.string()
      .required("ふりがなを入力してください。")
      .matches(
        /^([\u3040-\u309F―ー‐－0-9]|\s|\p{P}|\p{S})+$/,
        "ふりがなはひらがなで入力してください。"
      ),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClientsGetResImple();
  btnDisable = false;
  isEditMode = true;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  op4CompanyType = [
    { label: "指定なし", code: 1 },
    { label: "株式", code: 2 },
    { label: "有限", code: 3 },
  ];
  op4SignagePosi = [
    { label: "前", code: 1 },
    { label: "後", code: 2 },
  ];

  op4PaymentSite = CONST_OP_PAYMENTSITE;
  op4PClaimLanguage = CONST_OP_CLAIM_LANGUAGE;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      if (id == "") {
        //新規追加
        this.isEditMode = false;
        //初期値設定
        this.delegate.c_client_company_type = 1;
        this.delegate.c_client_flag_company_signage_posi = 1;
        this.delegate.c_client_claim_language = 1;
        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoClientsGetReqImple();

      dto.c_client_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_client.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_client_id == null) {
        this.$router.push("/uriagesakilist");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * データを更新する
   */
  async upd(isDel: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClientsPutReq();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_client_id = this.delegate.c_client_id;
        dto.c_client_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;

        this.schemaUpd.validateSync({
          clientName: this.delegate.c_client_name,
          furigana: this.delegate.c_client_name_pt,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoClientsPutReq(), this.delegate);
      }

      //API実行
      //-------
      await Cl_client.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;

      this.schemaAdd.validateSync({
        clientName: this.delegate.c_client_name,
        furigana: this.delegate.c_client_name_pt,
      });

      //準備
      //-------
      let dto = Object.assign(new DtoClientsPostReq(), this.delegate);
      dto.c_client_flag_del = 0;

      //API実行
      //-------
      const res = await Cl_client.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/uriagesakiedit",
        query: { id: res.c_client_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_client_id +
      "】" +
      this.delegate.c_client_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true);
  }

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
