import { commonRegx } from "xcommon-node/src/common/commonRegx";
import { EntitySales } from "../../entity/EntitySales";

export class DtoSalessPutReq extends EntitySales {

  //入金確定表 入金日 入金日更新用
  //-----------------------
  /**
   * 1で入金日更新モード
   */
  public isUpd_date: number | null = null;


  public IsValid(): string[] {
    let res: string[] = new Array();
    //単項目チェック
    //--------------
    if (!commonRegx.isMatch(this.c_sales_id!.toString(), "^\\d+$", commonRegx._stdOption)) {
      res.push("type error");
    }

    return res;
  }
}
