export class EntitySales {
public c_sales_id:number | null = null;
public c_project_id:number | null = null;
public c_variation_id:number | null = null;
public c_sales_rownum:number | null = null;
public c_claim_id:number | null = null;
public c_sales_claimnum_tmp:number | null = null;
public c_sales_issue:Date | null = null;
public c_sales_month:Date | null = null;
public c_sales_subject:number | null = null;
public c_sales_term:number | null = null;
public c_sales_item:string | null = null;
public c_sales_count:number | null = null;
public c_sales_date_start:Date | null = null;
public c_sales_date_end:Date | null = null;
public c_sales_price:number | null = null;
public c_sales_taxrate:number | null = null;
public c_sales_date_exp_payment:Date | null = null;
public c_sales_date_cmp_payment:Date | null = null;
public c_sales_remarks:string | null = null;
public c_sales_flag_lock:number | null = null;
public c_sales_flag_alert:number | null = null;
public c_sales_reg_user:string | null = null;
public c_sales_reg_at:Date | null = null;
public c_sales_upd_user:string | null = null;
public c_sales_upd_at:Date | null = null;
}