import { EntityStaff } from "../entity/EntityStaff";
import { commonOperation } from "xcommon-node/src/common/commonOperation";

/**
 * 社員関係の共通処理を担当する
 */
export class CommonStaff {
  /**
   * mastars等のEntityStaff[]と社員IDを受け取って
   * 該当社員の氏名を返す。存在しない場合は、空白を返す。
   * nullも空白を返す。
   */
  public static cnvStaffNum2Name(
    staffDatas: EntityStaff[],
    staffid: number | null
  ): string {
    if (staffid == null) {
      return "";
    }

    return (
      staffDatas.find((staff) => staff.c_staff_id === staffid)?.c_staff_name ||
      ""
    );
  }
}
