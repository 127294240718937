
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DtoApiError } from "mediadept-common/src/common/dto/abst/DtoApiError";

import Or_tableClaim from "@/components/organisms/Or_tableClaim.vue";
import { EntityClaimDet } from "mediadept-common/src/common/entity/EntityClaimDet";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_filemaster } from "@/functions/Cl_filemaster";

import { Cl_claim } from "@/functions/Cl_claim";
import { DtoClaimsGetReqImple } from "mediadept-common/src/common/dto/DtoClaimsGetReqImple";
import { DtoClaimsGetResImple } from "mediadept-common/src/common/dto/DtoClaimsGetResImple";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { DtoClaimsPutReqImple } from "mediadept-common/src/common/dto/DtoClaimsPutReqImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";

// バリデーション
import * as Yup from "yup";

import { AxiosError } from "axios";
import {
  CONST_AUTHORITY_STAFF_KANRISYA,
  CONST_CLAIMSTATUS_SAKUSEIZUMI,
} from "mediadept-common/src/common/constant/Constants";

// 請求先検索
import Or_selectClient from "@/components/organisms/Or_selectClient.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";
import { Watch } from "vue-property-decorator/lib/decorators/Watch";
import { EntityStaff } from "mediadept-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Or_tableClaim,
    Mo_modal_alert,
    Or_selectClient,
  },
})
export default class Seikyusyocreate extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schema = Yup.object().shape({
    clientName: Yup.string().required("請求先を選択してください。"),
    staffName: Yup.string().required("担当者を選択してください。"),
    claimDay: Yup.string().required("請求日を選択してください。"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClaimsGetResImple();
  delegate4list = new DtoMastersGetRes();
  claimDate: string | undefined = "";
  payLimit: string | undefined = "";
  btnDisable = false;
  claimNumber = "";
  isMissMatchPrice = false;
  domRemarks = "";

  //NULL判定のCSSバインド用。
  isNull = false;
  //入力欄の有効無効フラグ
  inputDisable4conf = false;
  //各ボタン個別の有効無効フラグ
  btnDisable4conf = false;
  btnDisable4delete = false;
  btnDisable4save = false;
  btnDisable4create = false;
  btnDisable4Rewrite = false;
  //各ボタンの個別の表示非表示フラグ
  btnShow4conf = true;
  btnShow4delete = true;
  btnShow4save = true;
  btnShow4create = true;
  btnShow4Rewrite = true;
  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConf4Admin = false;
  fmShowConf4self = false;
  fmShowConfEx = false;

  //請求先選択用
  evDatas: {
    selectedItem: EntityClient | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalErrorConf = "";
  message4ModalRewriteConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  async adjButton() {
    //storeの値とLSの値を同期
    this.$store.commit("revStore");

    const detTable: any = this.$refs.Or_tableClaim;

    // ※読み込み失敗は全て無効化
    //-------------------------
    if (this.delegate.c_claim_id == null) {
      this.btnDisable4conf = true;
      this.btnDisable4delete = true;
      this.btnDisable4save = true;
      this.btnDisable4create = true;
      this.btnDisable4Rewrite = true;
      detTable.eventAdjButton(true);
      this.fmShowConf = false;
      this.fmShowConf4Admin = false;
      this.fmShowConf4self = false;
      this.fmShowConfEx = false;
      this.claimNumber = "";
      this.isNull = true;
      return;
    } else {
      this.btnDisable4conf = false;
      this.btnDisable4delete = false;
      this.btnDisable4save = false;
      this.btnDisable4create = false;
      this.btnDisable4Rewrite = false;

      this.fmShowConf = false;
      this.fmShowConf4Admin = false;
      this.fmShowConf4self = false;
      this.fmShowConfEx = false;

      detTable.eventAdjButton(false);
      this.isNull = false;
    }

    // 請求データ登録						ステータス0 or 9で表示
    //-------------------------
    if (
      this.delegate.c_claim_status == 0 ||
      this.delegate.c_claim_status == 9
    ) {
      this.btnShow4save = true;
      this.btnDisable4save = false;
    } else {
      this.btnShow4save = false;
      this.btnDisable4save = true;
    }

    // 承認依頼						自身が請求承認必要(1:必要;2:不要;)＋ステータス0 or 9で表示、それ以外は非表示
    //-------------------------
    if (
      this.$store.state.flagAuthClaim == 1 &&
      (this.delegate.c_claim_status == 0 || this.delegate.c_claim_status == 9)
    ) {
      this.btnShow4conf = true;
      this.btnDisable4conf = false;
    } else {
      this.btnShow4conf = false;
      this.btnDisable4conf = true;
    }
    // 作成						ステータスが2 or 3、それ以外は非表示
    //-------------------------
    if (
      // this.$store.state.flagAuthClaim == 2 ||
      this.delegate.c_claim_status == 2 ||
      this.delegate.c_claim_status == 3
    ) {
      this.btnShow4create = true;
      this.btnDisable4create = false;
    } else {
      this.btnShow4create = false;
      this.btnDisable4create = true;
    }
    // 再編集						ステータスが2 or 3、それ以外は非表示
    //-------------------------
    if (
      // this.$store.state.flagAuthClaim == 2 ||
      this.delegate.c_claim_status == 2 ||
      this.delegate.c_claim_status == 3
    ) {
      this.btnShow4Rewrite = true;
      this.btnDisable4Rewrite = false;
    } else {
      this.btnShow4Rewrite = false;
      this.btnDisable4Rewrite = true;
    }

    // 自己承認						ステータスが0 or 9かつ、自身が承認不要(1:必要;2:不要;)な場合表示、それ以外は非表示
    //-------------------------
    if (
      (this.delegate.c_claim_status == 0 ||
        this.delegate.c_claim_status == 9) &&
      this.$store.state.flagAuthClaim == 2
    ) {
      this.fmShowConf4self = true;
    } else {
      this.fmShowConf4self = false;
    }

    // 承認						ステータスが1、かつ自身が承認者の場合表示、それ以外は非表示
    // 差戻					ステータスが1、かつ自身が承認者の場合表示、それ以外は非表示
    //-------------------------

    if (this.delegate.c_claim_status == 1) {
      if (this.delegate.elderStaffIds.indexOf(this.$store.state.id) != -1) {
        //承認依頼中で自身が承認者
        this.fmShowConf = true;
        this.btnDisable4conf = true;
        this.btnDisable4delete = true;
        this.btnDisable4save = true;
        this.btnDisable4create = true;
        detTable.eventAdjButton(true);
      } else {
        const authType = this.$store.state.authType;

        if (authType == CONST_AUTHORITY_STAFF_KANRISYA) {
          //承認依頼中で自身が承認者ではないが、管理者
          this.fmShowConf4Admin = true;
          this.btnDisable4conf = true;
          this.btnDisable4delete = true;
          this.btnDisable4save = true;
          this.btnDisable4create = true;
          detTable.eventAdjButton(true);
        } else {
          //承認依頼中で自身が承認者でない
          this.fmShowConfEx = true;
          this.btnDisable4conf = true;
          this.btnDisable4delete = true;
          this.btnDisable4save = true;
          this.btnDisable4create = true;
          detTable.eventAdjButton(true);
        }
      }
    } else {
      this.fmShowConf = false;
      this.fmShowConf4Admin = false;
      this.fmShowConfEx = false;
    }

    //入力用UI　　　　　　　ステータスが2 or 3 or isHolisticlock=1(統合ロック対象の明細と紐付いている)で編集不可能、それ以外は編集可能。
    //-------------------------
    if (
      this.delegate.c_claim_status == 2 ||
      this.delegate.c_claim_status == 3 ||
      this.delegate.isHolisticlock == 1
    ) {
      this.inputDisable4conf = true;
      detTable.eventAdjButton(true);
    } else {
      this.inputDisable4conf = false;
      detTable.eventAdjButton(false);
    }
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 0;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;
      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;
      dtoMaster.getBank = 1;
      dtoMaster.delFlag4Bank = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを読み込む
   */
  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      this.isMissMatchPrice = false;

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("claimnum");
      // if (id == "") {
      //   //新規追加
      //   // this.isEditMode = false;
      //   return;
      // } else {
      //   // //編集
      //   // this.isEditMode = true;
      // }

      //準備
      //-------
      let dto = new DtoClaimsGetReqImple();

      dto.c_claim_id = Number(id); //APIのURL構成の関係で、請求書Noを請求書IDに入れているので注意
      dto.flagSales = 1;

      //API実行
      //-------
      this.delegate = await Cl_claim.get(dto);
      Cl_common.logger(this.delegate);

      if (this.delegate.c_claim_id == null) {
        const delegateTmp = this.delegate;
        //新しいdelegateを作成し
        this.delegate = new DtoClaimsGetResImple();
        this.delegate.claimNumberPrev = delegateTmp.claimNumberPrev;
        this.delegate.claimNumberNext = delegateTmp.claimNumberNext;
        return;
      }

      //日付展開
      this.claimDate = this.delegate.c_claim_datetime?.toString().split("T")[0];
      this.payLimit = this.delegate.c_claim_paylimit?.toString().split("T")[0];

      //備考を展開
      if (this.delegate.c_claim_remarks) {
        this.domRemarks = this.delegate.c_claim_remarks;
      } else {
        this.domRemarks = "";
      }

      //請求No展開
      this.claimNumber = this.delegate.c_claim_number!.toString();

      //明細の金額にカンマを付ける調整
      await this.cnvDetails();

      this.inputDisable4conf = false;

      //明細のトータル取得
      const tableClaim: any = this.$refs.Or_tableClaim;
      const sum = tableClaim.getSum();

      // Cl_common.logger(sum);
      // Cl_common.logger(this.delegate.sumSales);
      // Cl_common.logger(this.delegate.c_claim_subtotal);

      if (
        this.delegate.sumSales != this.delegate.c_claim_subtotal &&
        this.delegate.sumSales != sum[0].replaceAll(",", "")
      ) {
        this.isMissMatchPrice = true;
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
      this.delegate = new DtoClaimsGetResImple();
      this.inputDisable4conf = true;
    } finally {
      //button調整
      await this.adjButton();
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 明細内のデータを調整
   */
  async cnvDetails() {
    for (let i = 0; i < this.delegate.details.length; ++i) {
      //金額
      if (
        this.delegate.details[i].c_claim_det_price == null ||
        this.delegate.details[i].c_claim_det_price == ""
      ) {
        // this.delegate.details[i].c_claim_det_price = "0";
      } else {
        //カンマ補完
        while (
          this.delegate.details[i].c_claim_det_price !=
          (this.delegate.details[i].c_claim_det_price = this.delegate.details[
            i
          ].c_claim_det_price!.replace(/^(-?\d+)(\d{3})/, "$1,$2"))
        );
      }
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isRequest: boolean, isNoModal: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");

    if (!isNoModal) {
      Array.from(panel).map((element: Element) => {
        element.classList.add("enable-loader");
      });
    }

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimsPutReqImple();
      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_claim_id = this.delegate.c_claim_id;
        dto.c_claim_flag_del = 1;
      } else {
        //バリデーション
        //-----------------
        this.errors = null;
        this.schema.validateSync({
          clientName: this.delegate.c_client_id,
          staffName: this.delegate.c_staff_id,
          claimDay: this.claimDate,
        });

        //更新処理：delegateからdtoにデータ投入。
        dto = Object.assign(new DtoClaimsPutReqImple(), this.delegate);
        //日付をDTOに戻す
        if (this.claimDate != undefined) {
          // console.log(this.claimDate);
          dto.c_claim_datetime = new Date(this.claimDate);
        }
        if (this.payLimit != undefined) {
          dto.c_claim_paylimit = new Date(this.payLimit);
        }

        //備考をDTOに戻す
        if (this.domRemarks) {
          dto.c_claim_remarks = this.domRemarks;
        }

        //明細の合計を取得
        const tableClaim: any = this.$refs.Or_tableClaim;
        const sum = tableClaim.getSum();
        dto.c_claim_subtotal = sum[0].replaceAll(",", "");
        dto.c_claim_total = sum[1].replaceAll(",", "");

        if (isRequest) {
          //承認申請押下
          dto.c_claim_status = 1;
        }
      }

      //API実行
      //-------
      await Cl_claim.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      if (!isNoModal) {
        const modal: any = this.$refs.Mo_modal_success;
        modal.showModal();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          case "elder_user is none":
            this.message4ModalErrorConf =
              "請求書担当者に上長が設定されていません";
            break;
          default:
            this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
      }

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      if (!isNoModal) {
        this.btnDisable = false;
        Array.from(panel).map((element: Element) => {
          element.classList.remove("enable-loader");
        });
      }
    }
  }
  /**
   * データを出力する
   */
  async exp(expType: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //金額が不一致であれば、出力ができない
      //TODO:金不一致での出力を可能にするのは、ここのコメントアウトで可能。
      //-----------------
      if (this.isMissMatchPrice) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "売上明細の金額と、請求書の金額が一致していない為、請求書を作成できません";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //バリデーション
      //-----------------
      this.errors = null;
      this.schema.validateSync({
        clientName: this.delegate.c_client_id,
        staffName: this.delegate.c_staff_id,
        claimDay: this.claimDate,
      });

      //まず保存
      //-----------------
      await this.upd(false, false, true);
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "claim";
      dto.claimExpType = expType;
      dto.claimNumber = this.delegate.c_claim_number;
      dto.claimId = this.delegate.c_claim_id;
      dto.projectId = this.delegate.c_project_id;
      dto.deliverySlip = this.delegate.c_claim_flag_delivery;

      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);
      Cl_common.logger(res);
      Cl_common.logger(res.url);

      //レスポンス処理
      //-------
      location.href = res.url;

      //レスポンスの処理
      //-------
      //正常に出力されたら、ステータスを出力済みに変更
      this.delegate.c_claim_status = CONST_CLAIMSTATUS_SAKUSEIZUMI;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //バリデーション
      //-----------------
      this.errors = null;
      this.schema.validateSync({
        clientName: this.delegate.c_client_id,
        staffName: this.delegate.c_staff_id,
        claimDay: this.claimDate,
      });

      //まず保存
      //-----------------
      await this.upd(false, false, true);

      //準備
      //-------
      let dto = new DtoClaimsPutReqImple();
      dto.c_claim_id = this.delegate.c_claim_id;
      dto.c_claim_flag_sales = this.delegate.c_claim_flag_sales;
      dto.c_claim_number = this.delegate.c_claim_number;
      dto.c_claim_title_1 = this.delegate.c_claim_title_1;
      dto.c_staff_id = this.delegate.c_staff_id;
      if (isApproval) {
        //承認
        dto.isApproval = 1;
        if (this.claimDate != undefined) {
          // console.log(this.claimDate);
          dto.c_claim_datetime = new Date(this.claimDate);
        }
        Cl_common.logger(dto);
      } else {
        //差戻
        dto.isApproval = 0;
      }

      //API実行
      //-------
      await Cl_claim.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "請求No:" +
      this.delegate.c_claim_number +
      "　" +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false, false);
  }
  //-------------------
  /**
   * 再編集確認用のモーダルを表示
   */
  async reriteConf() {
    //モーダルのメッセージを設定
    this.message4ModalRewriteConf =
      "請求No:" +
      this.delegate.c_claim_number +
      "　" +
      "を再編集するために未承認の状態に戻します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_rewiteConf;
    modal.showModal();
  }
  /**
   * 請求書を未承認状態に戻す
   */
  async rewrite() {
    this.closeModal4RewriteConf();

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimsPutReqImple();

      //削除処理：削除フラグを立てる。
      dto.c_claim_id = this.delegate.c_claim_id;
      dto.isRewite = 1;

      //API実行
      //-------
      await Cl_claim.put(dto);
      //レスポンスの処理
      //-------
      //モーダルのメッセージ
      this.message4ModalSuccess = "正常に変更されました";
      const modal: any = this.$refs.Mo_modal_success;

      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //エラーメッセージのバリエーション
      if (this.isAxiosError(error)) {
        let response = Object.assign(
          new DtoApiError(),
          (error as AxiosError).response!.data
        );
        switch (response.message) {
          case "elder_user is none":
            this.message4ModalErrorConf =
              "請求書担当者に上長が設定されていません";
            break;
          default:
            this.message4ModalErrorConf = "エラーが発生しました";
            break;
        }
      }

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------

      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  //-------------------
  /**
   * プロジェクトに遷移
   */
  async goProject(event?: MouseEvent) {
    if (this.delegate.project.c_project_number == null) {
      return;
    }
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(
        "/jyutyuinput?record_id=" + this.delegate.project.c_project_number
      );
    } else {
      await this.$router.push(
        "/jyutyuinput?record_id=" + this.delegate.project.c_project_number
      );
    }
  }

  // /**
  //  * 売上金額と小計に差があるかを返す
  //  */
  // isMatchPrice(): boolean {
  //   const tableClaim: any = this.$refs.Or_tableClaim;
  //   const sum = tableClaim.getSum();
  //   return this.delegate.sumSales == Number(sum[0].replaceAll(",", ""));
  // }

  /**
   * 指定の請求Noへ遷移
   */
  async goEnt() {
    await this.$router.push("/seikyusyocreate?claimnum=" + this.claimNumber);
    await this.get();
  }
  /**
   * 前の請求書に遷移
   */
  async goPrev() {
    await this.$router.push(
      "/seikyusyocreate?claimnum=" + this.delegate.claimNumberPrev
    );
    await this.get();
  }
  /**
   * 次の請求書に遷移
   */
  async goNext() {
    await this.$router.push(
      "/seikyusyocreate?claimnum=" + this.delegate.claimNumberNext
    );
    await this.get();
  }

  //----------------------
  /**
   * 明細行の商品選択時のイベント
   * watch用
   */
  @Watch("evDatas.isSelected")
  async selectClient() {
    if (!this.evDatas.isSelected) {
      return;
    }
    // console.log(this.evDatas.selectedItem?.c_client_name);
    this.delegate.c_client_id = this.evDatas.selectedItem!.c_client_id!;
  }
  //----------------------

  /**
   * チェックボックスの値を数値に変換する
   * @param event
   */
  cnvCb(event: any): number {
    if (event.target.checked) {
      return 1;
    } else {
      return 0;
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4RewriteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_rewiteConf;
    modal.closeModal();
  }
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  /**
   * 請求先選択用モーダル
   */
  async showModalclaim(): Promise<void> {
    this.evDatas.selectedItem = null;
    this.evDatas.isSelected = false;

    const modalClient: any = this.$refs.Mo_modal_selectType;
    await modalClient.showModal();
  }
  async closeModalclaim(): Promise<void> {
    const modalClient: any = this.$refs.Mo_modal_selectType;
    modalClient.closeModal();
  }

  //----------------------
  //その他
  //----------------------
  /**
   * AxiosErrorかどうかを返す。
   * @param error
   * @returns
   */
  isAxiosError(error: any): error is AxiosError {
    return !!error.isAxiosError;
  }
  //モック時のコード
  //---------------------------
}
